<template>
  <div class="modal">
    <div ref="pdfContent" class="pdf-content">
      <h1 class="store-name">{{ storeName }} Stock Report</h1>
      <table class="table">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th>Product Name</th>
            <th>Closing Stock</th>
            <th>Unit</th>
          </tr>
        </thead>
        <tbody>
          <!-- Multiply the data by 10 times -->
          <tr v-for="(stock, index) in tableData" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ stock["productLists.productName"] || "N/A" }}</td>
            <td>{{ stock.closingStock || 0 }}</td>
            <td>{{ stock["productLists.unitType"] || "N/A" }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// Import the html2pdf library correctly
import html2pdf from "html2pdf.js";

export default {
  name: "StoreItemsPdf",
  props: {
    tableData: Array, // The data passed from the parent
    storeName: String, // The store name passed from the parent
  },
  computed: {
    // Multiply the data 10 times
    repeatedTableData() {
      let repeatedData = [];
      // Multiply the tableData by 10
      for (let i = 0; i < 10; i++) {
        repeatedData = [...repeatedData, ...this.tableData];
      }
      return repeatedData;
    },
  },
  methods: {
    downloadPDF() {
      const element = this.$refs.pdfContent;
      const customStyles = `
  <style>
    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
    }

    .report-title {
      font-size: 2rem;
      font-weight: bold;
      text-transform: uppercase;
      color: #4CAF50;
      margin: 20px 0;
      text-align: center;
      border-bottom: 2px solid #4CAF50;
      padding-bottom: 10px;
    }

    .table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
      table-layout: fixed; /* Prevent table columns from expanding unevenly */
    }

    .table th{
      text-align: left;
      background-color: rgb(172, 165, 165);
      font-size: 12px;
      word-wrap: break-word; /* Ensure long words wrap instead of overflowing */
      overflow-wrap: break-word;
      white-space: normal; /* Allow text to wrap */
    }

    .table td {
      padding: 10px ;
      border: 1px solid #ddd;
      text-align: left;
      font-size: 12px;
      word-wrap: break-word; /* Ensure long words wrap instead of overflowing */
      overflow-wrap: break-word;
      white-space: normal; /* Allow text to wrap */
    }

    .table tbody tr:nth-child(odd) {
      background-color: grey;
    }

    .table tbody tr:nth-child(even) {
      background-color: #ffffff;
    }

   

    .table tbody tr:hover {
      background-color: #f1f1f1;
    }

    .footer {
      margin-top: 30px;
      text-align: center;
      font-size: 12px;
      color: #555;
    }

    @media (max-width: 768px) {
      .table {
        font-size: 10px;
      }
    }
  </style>
`;

      // Clone the content to inject styles
      const contentWithStyles = document.createElement("div");
      contentWithStyles.innerHTML = customStyles + element.outerHTML;

      // Generate the PDF with html2pdf
      html2pdf()
        .from(contentWithStyles)
        .set({
          margin: [10, 10, 10, 10],
          filename: `${this.storeName}-Stock-Report.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: {
            dpi: 300,
            scale: 2,
            letterRendering: true,
          },
          jsPDF: {
            unit: "mm",
            format: "a4",
            orientation: "portrait",
          },
        })
        .save();
    },
  },
};
</script>

<style scoped>
/* Apply the same styles as used for the PDF generation */
.store-name {
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  margin: 20px 0;
  text-align: left;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-family: Arial, sans-serif;
}

.table th,
.table td {
  border: none;
  outline: none;
  padding: 8px 15px;
  text-align: left;
  font-size: 12px; /* Adjust font size for readability */
}

.table tbody tr {
  page-break-inside: avoid; /* Prevent table rows from breaking across pages */
}

.table thead {
  background-color: rgb(208, 208, 208);
  color: rgb(36, 36, 36);
  font-weight: bold;
}

.table tbody tr:nth-child(odd) {
  background-color: #fadede; /* Alternate row color */
}

.table tbody tr:nth-child(even) {
  background-color: #ffffff;
}

.table th:nth-child(1),
.table td:nth-child(1) {
  width: 15%;
}

.table th:nth-child(2),
.table td:nth-child(2) {
  width: 35%;
}

.table th:nth-child(3),
.table td:nth-child(3) {
  width: 15%;
}

.table th:nth-child(4),
.table td:nth-child(4) {
  width: 15%;
}

.table tbody tr:hover {
  background-color: #ddd;
}

.table td {
  font-size: 12px;
}

@media (max-width: 768px) {
  .table {
    font-size: 10px; /* Smaller text for smaller screens */
  }
}
</style>
