<template>
  <div>
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <div class="notes-header">
          <h5 class="notes-title">Notes</h5>
          <div class="notes-buttons">
            <button @click="fetchNotes()">
              <span> <img class="notes-img" src="../../assets/icons/myNotes.png" /></span>
              My Notes
            </button>
            <button @click="fetchStared()">
              <span>
                <img
                  class="notes-img"
                  src="../../assets/icons/favorites.png"
                  alt="Favorite Icon"
                />
              </span>
              Favorites
            </button>
            <button @click="fetchShared()">
              <span
                ><img class="notes-img" src="../../assets/icons/sharedWithMe.png"
              /></span>
              Shared With Me
            </button>
            <button>
              <a href="/AddNotes"
                ><img class="notes-img" src="../../assets/icons/greyEdit.png"
              /></a>
              New Note
            </button>
          </div>
        </div>

        <!-- Cards Section -->
        <div class="cards-container">
          <div class="card" v-for="data in NotesDetails" :key="data.id">
            <div class="card-header">
              <img
                class="profile-image"
                src="../../assets/icons/profileIconBefore.png"
                alt="Profile Picture"
              />
              <div class="profile-details">
                <span class="profile-name"
                  >{{ data["employees.firstName"] }}
                  {{ data["employees.lastName"] }}</span
                >
                <small class="profile-date">{{ data.date }}</small>
              </div>
              <div class="icons">
                <img
                  @click="markUnFav(data.id)"
                  v-if="data.noteStarted === 'yes'"
                  class="profile-icon"
                  src="../../assets/icons/yellowFavourite.png"
                  alt="Star Icon"
                />
                <img
                  v-else
                  @click="markFav(data.id)"
                  class="profile-icon"
                  src="../../assets/icons/yellowUnfavourite.png"
                  alt="Star Icon"
                />
              </div>
            </div>
            <div class="card-body" @click="goToAddnotes(data.id)">
              <h5 class="title">{{ data.header }}</h5>
              <p class="task-description">
                {{ data.description ? truncateText(data.description, 110) : "" }}
              </p>
            </div>
            <div class="card-footer" @click="goToAddnotes(data.id)">
              <div class="task-stats">
                <div class="stat">
                  <img
                    class="stat-icon"
                    src="../../assets/icons/commentBlack.png"
                    alt="Comment Icon"
                  />
                  <span>{{ data.attachmentsCount }}</span>
                </div>
                <div class="stat">
                  <img
                    class="stat-icon"
                    src="../../assets/icons/attachment.png"
                    alt="Comment Icon"
                  />
                  <span>{{ data.commentsCount }}</span>
                </div>
              </div>
              <div
                class="collaborator-container"
                v-if="data.collabs && data.collabs.length > 0"
              >
                <span
                  v-for="(data, index) in data.collabs"
                  :key="index"
                  class="collaborator-initials"
                  :style="{ zIndex: index }"
                >
                  {{ data.initials }}
                </span>
              </div>
            </div>
            <!-- <i class="fa-10x fas fa-folder"></i> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import axios from "axios";

export default {
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      NotesDetails: [],
    };
  },

  mounted() {
    this.fetchNotes();
  },

  methods: {
    async fetchNotes() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/notes/getMyNotes/`);
        this.NotesDetails = response.data;
        console.log("Fetched Notes:", this.NotesDetails);
      } catch (error) {
        console.error("Error fetching Notes:", error);
      }
    },

    async fetchStared() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/notes/getMyNotes/stared`);
        this.NotesDetails = [];
        this.NotesDetails = response.data; // No need to clear array first
        console.log("Fetched Starred Notes:", this.NotesDetails);
      } catch (error) {
        console.error("Error fetching Starred Notes:", error);
      }
    },

    async fetchShared() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/notes/getMyNotes/shared`);
        this.NotesDetails = [];
        this.NotesDetails = response.data; // No need to clear array first
        console.log("Fetched shared Notes:", this.NotesDetails);
      } catch (error) {
        console.error("Error fetching shared Notes:", error);
      }
    },

    async markFav(id) {
      try {
        await axios.post(`${this.$apiEndPoint}/notes/manageStared/${id}/Yes`);
        this.$toast("Marked As Favourite", "success");
        this.fetchNotes();
      } catch (error) {
        console.error("Error marking note as favorite:", error);
        this.$toast("Failed to mark as favorite", "error");
      }
    },

    async markUnFav(id) {
      try {
        await axios.post(`${this.$apiEndPoint}/notes/manageStared/${id}/No`);
        this.$toast("Marked As Unfavourite", "success");
        this.fetchStared();
      } catch (error) {
        console.error("Error marking note as Unfavorite:", error);
        this.$toast("Failed to mark as Unfavorite", "error");
      }
    },

    truncateText(text, length) {
      if (text && text.length > length) {
        return text.substring(0, length) + "...";
      }
      return text;
    },

    goToAddnotes(noteId) {
      this.$router.push({
        name: "AddNotes",
        params: {
          Id: noteId,
        },
      });
    },
  },
};
</script>

<style scoped>
.containerChild {
  display: flex;
  flex-direction: column;
  padding: 2% 1%;
}

.notes-header {
  display: flex;
  justify-content: space-between; /* Aligns title to the left and buttons to the right */
  align-items: center; /* Vertically centers the content */
  margin-bottom: 20px; /* Space between header and cards */
}

.notes-title {
  font-weight: bold;
  margin-left: 22px; /* Remove extra margin */
}

.notes-buttons {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap; /* Allow wrapping for responsiveness */
}

.notes-buttons button {
  color: #202020;
  background-color: #dfdede;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.notes-buttons button:hover {
  background-color: cornflowerbluse;
  transform: translateY(-2px);
  color: #171717;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.notes-img {
  height: 22px;
  width: 22px;
}

.collaborator-container {
  position: relative;
  display: flex;
  align-items: center;
}

.collaborator-initials {
  font-size: 10px;
  background-color: #4884f7;
  border-radius: 50%;
  color: #fcfcfc;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: -8px;
  /* Adjust this to control overlap */
  border: 2px solid #fff;
  /* Optional: adds a white border around each badge */
}

@media (max-width: 768px) {
  .notes-buttons {
    justify-content: center; /* Center buttons on smaller screens */
  }

  .notes-buttons button {
    font-size: 12px; /* Adjust font size for smaller screens */
    padding: 6px 12px; /* Adjust padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .notes-buttons {
    flex-direction: column; /* Stack buttons vertically on very small screens */
    gap: 8px; /* Adjust gap between buttons */
  }

  .notes-buttons button {
    width: 100%; /* Make buttons full-width */
  }
}

/* General Styles */
.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.card {
  background-color: #ffffff;
  border-radius: 12px;
  min-height: 200px;
  border: none;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}

.card:hover {
  transform: translateY(-6px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

/* Card Header */
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
}

.card-header:nth-child(1) {
  background-color: rgb(253, 221, 200);
  opacity: 0.7;
}

.card-footer:last-child {
  background-color: transparent;
}

.profile-image {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-details {
  flex: 1;
  margin-left: 15px;
}

.profile-name {
  font-weight: 600;
  font-size: 16px;
  color: #333;
}

.profile-date {
  font-size: 12px;
  color: #333;
  display: block;
}

.icons {
  display: flex;
  gap: 10px;
}

.profile-icon {
  background-color: transparent; /* Removes default background */
  width: 24px; /* Adjust size */
  height: 24px;
  object-fit: contain; /* Ensures the image scales properly */
  display: block; /* Makes sure the icon doesn't have extra inline spacing */
}

.profile-icon:hover {
  transform: scale(1.2);
}

/* Card Body */
.card-body {
  padding: 10px;
}

.title {
  font-size: 14px;
  font-weight: 600;
  color: #2c3e50;
  margin: 0;
}

.task-description {
  font-size: 14px;
  color: #555;
  margin: 5px 0 0;
}

/* Card Footer */
.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task-stats {
  display: flex;
  gap: 15px;
}

.stat {
  display: flex;
  align-items: center;
  gap: 5px;
}

.stat-icon {
  height: 18px;
  width: 18px;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.stat-icon:hover {
  opacity: 1;
}

.profile-images {
  display: flex;
  gap: -10px;
}

.team-member {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}

/* Responsive Design */
@media (max-width: 768px) {
  .cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .cards-container {
    grid-template-columns: 1fr;
  }

  .profile-image {
    height: 40px;
    width: 40px;
  }

  .team-member {
    height: 25px;
    width: 25px;
  }
}
</style>
