<template>
  <div>
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <div class="notes-header">
          <input
            placeholder="Write here note header..."
            v-model="NoteHeader"
            class="notes-title"
            @blur="handleInput()"
          />
          <div class="notes-buttons">
            <img
              class="fav-img"
              src="../../assets/icons/yellowUnfavourite.png"
              alt="edit Icon"
            />
            <div class="collaborator-container">
              <span
                class="collaborator-initials"
                v-for="(collabs, index) in Collaborators"
                :key="index"
                :style="{ zIndex: index }"
              >
                {{ collabs.initials }}
              </span>
            </div>

            <button class="btn share-btn">
              Share
              <span>
                <img
                  class="notes-img"
                  src="../../assets/icons/shareWith.png"
                  alt="note Icon"
                />
              </span>
            </button>
            <button class="btn delete-btn">
              Delete
              <span>
                <img
                  class="notes-img"
                  src="../../assets/icons/redDelete.png"
                  alt="Favorite Icon"
                />
              </span>
            </button>
            <button class="btn download-btn">
              Download
              <span>
                <img
                  class="notes-img"
                  src="../../assets/icons/blackDownload.png"
                  alt="share Icon"
                />
              </span>
            </button>
            <img
              class="notes-img cancel-img"
              src="../../assets/icons/cancel.png"
              alt="edit Icon"
              @click="gotToNotes()"
            />
          </div>
        </div>

        <!-- description Section -->
        <div class="cards-container">
          <!-- Description Section -->
          <div class="cards-container">
            <textarea
              class="textarea"
              placeholder="Write here description......"
              v-model="NoteDescription"
              @blur="handleInput()"
            ></textarea>
          </div>
        </div>

        <div class="tab-buttons">
          <button class="tab-button active">
            Notes
            <span class="plus"
              ><img
                data-bs-toggle="modal"
                data-bs-target="#textAreaModal"
                class="plus-icon"
                src="../../assets/icons/plusIconSales.png"
            /></span>
          </button>
          <button class="tab-button">Comments <span class="plus">+</span></button>
          <button class="tab-button">Attachments <span class="plus">+</span></button>
        </div>
        <div class="notes-content-wrapper">
          <div
            class="notes-content"
            v-for="(contents, index) in NoteContents"
            :key="index"
          >
            <div
              class="profile-header"
              data-bs-toggle="modal"
              data-bs-target="#textAreaModal"
              @click="openmodal(contents.id)"
            >
              <img
                src="../../assets/icons/kebabMenu.png"
                alt="Profile Picture"
                class="profile-img"
              />
              <img
                src="../../assets/icons/profile.png"
                alt="Profile Picture"
                class="profile-img"
              />
              <div class="profile-info">
                <h6>
                  {{ contents["employees.firstName"]
                  }}{{ contents["employees.lastName"] }}
                </h6>
                <span v-if="contents.date">{{ contents.date }}</span>
              </div>
            </div>
            <div
              class="content"
              data-bs-toggle="modal"
              data-bs-target="#textAreaModal"
              @click="openmodal(contents.id)"
            >
              <ul>
                <li>{{ contents.text }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <NotesModal :selectedNoteId="this.selectedNoteId" @update-note="updateNote" />
</template>

<script>
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import NotesModal from "../Modal/NotesModal.vue";
import axios from "axios";

export default {
  components: {
    Header,
    Nav,
    NotesModal,
  },
  data() {
    return {
      Notedetails: [],
      NoteContents: [],
      NoteId: this.$route.params.Id,
      NoteHeader: "",
      NoteDescription: "",
      selectedNoteId: null,
    };
  },

  mounted() {
    this.fetchNotes();
    this.Contents();
  },

  methods: {
    gotToNotes() {
      this.$router.push({
        name: "Notes",
      });
    },

    async fetchNotes() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/notes/getNoteById/${this.NoteId}`
        );
        this.Collaborators = response.data.collaborators;
        this.NoteHeader = response.data.header;
        this.NoteDescription = response.data.description;

        console.log("Fetched Note details:", this.Collaborators);
      } catch (error) {
        console.error("Error fetching Note details:", error);
      }
    },

    async Contents() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/notesContent/getContentByNotesId/${this.NoteId}`
        );
        this.NoteContents = response.data;
        console.log("Fetched Note contenst:", this.NoteContents);
      } catch (error) {
        console.error("Error fetching Note contents:", error);
      }
    },

    openmodal(id) {
      this.selectedNoteId = id;
    },

    async handleInput() {
      try {
        const noteData = {
          header: this.NoteHeader,
          description: this.NoteDescription,
        };

        let response;

        if (this.NoteId) {
          response = await axios.put(
            `${this.$apiEndPoint}/notes/updateNote/${this.NoteId}`,
            noteData
          );
          this.$toast("Note updated successfully!", {
            timeout: 5000, // Timeout in ms
            closeButton: true, // Show a close button
            pauseOnHover: true, // Pause the toast timeout on hover
          });
        } else {
          response = await axios.post(`${this.$apiEndPoint}/notes/createNote`, noteData);
          this.$toast("Note created successfully!");
        }

        console.log("Response:", response.data);
      } catch (error) {
        console.error("Error handling note:", error);
        this.$toast("An error occurred while handling the note!");
      }
    },

    async updateNote({ id, text }) {
      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/notesContent/updateNotesContent/${id}`,
          { text }
        );
        console.log("Note updated successfully:", response.data);

        const index = this.NoteContents.findIndex((note) => note.id === id);
        if (index !== -1) {
          this.NoteContents[index] = { ...this.NoteContents[index], text };
        }
        this.$toast("Note Updated successfully", "success");
        this.selectedNoteId = null;
      } catch (error) {
        console.error("Error updating note:", error);
      }
    },
  },
};
</script>

<style scoped>
.collaborator-container {
  display: flex; /* Align items in a row */
  align-items: center; /* Center items vertically */
}

.collaborator-initials {
  font-size: 12px; /* Font size for initials */
  background-color: #4884f7; /* Blue background color */
  border-radius: 50%; /* Circular shape */
  color: #fff; /* White text color */
  width: 30px; /* Circle width */
  height: 30px; /* Circle height */
  display: flex; /* Enables flexbox for centering */
  align-items: center; /* Centers text vertically */
  justify-content: center; /* Centers text horizontally */
  position: relative; /* Keeps the element in normal flow */
  margin-left: -10px; /* Creates the overlap effect */
  border: 2px solid #fff; /* Adds a white border for contrast */
  z-index: auto; /* Ensures proper stacking order */
}

.collaborator-initials:hover {
  transform: scale(1.1); /* Slightly enlarges on hover */
  z-index: 10; /* Brings hovered element to the front */
}

.containerChild {
  display: flex;
  flex-direction: column;
  padding: 2% 1%;
  height: 90vh; /* Ensure it takes the full viewport height */
}

.notes-content-wrapper {
  overflow-y: auto; /* Enable vertical scrolling */
  flex-grow: 1; /* Allow this section to take up the remaining space */
  padding-top: 10px;
  max-height: 420px;
}

.notes-header {
  position: sticky;
  top: 0; /* Keep it at the top */
  display: flex;
  justify-content: space-between; /* Aligns title to the left and buttons to the right */
  align-items: center; /* Vertically centers the content */
}

.notes-title {
  margin-top: 12px;
  height: 40px;
  width: 50%;
  border: #dfdede;
}

.tab-buttons {
  position: sticky;
  top: 60px; /* Adjust based on the height of .notes-header */
  background-color: white; /* Match the background */
  z-index: 9;
  margin-top: 20px;
  display: flex;
  gap: 10px;
  padding-bottom: 30px;
}

.tab-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  border: 2px solid #ccc;
  border-radius: 30px;
  background-color: white;
  color: black;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.tab-button .plus {
  margin-left: 10px;
  font-size: 18px;
}

.plus-icon {
  height: 20px;
  width: 20px;
  padding: 3px;
  border-radius: 3px;
}

.tab-button.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.tab-button:not(.active):hover {
  background-color: #f0f0f0;
}

.notes-buttons {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap; /* Allow wrapping for responsiveness */
}

.notes-buttons button {
  background-color: transparent;
  color: #202020;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 26px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.notes-buttons button:hover {
  background-color: cornflowerbluse;
  transform: translateY(-2px);
  color: #171717;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.notes-img {
  height: 20px;
  width: 20px;
}
.share-btn {
  border: 2px solid #53a0fd;
  color: #6a11cb;
}

.share-btn:hover {
  color: #ffffff;
  border: 2px solid #0072ff;
}

.delete-btn {
  border: 2px solid #fb6a4d;
  color: #ff512f;
}

.delete-btn:hover {
  color: #ffffff;
  border: 2px solid #ff512f;
}

.download-btn {
  border: 2px solid rgb(44, 44, 44);
  color: #0072ff;
}

.download-btn:hover {
  border: 2px solid rgba(44, 44, 44);
  color: #ffffff;
}

.notes-img {
  width: 20px;
  height: 20px;
}

.cancel-img {
  cursor: pointer;
  width: 25px;
  height: 25px;
  transition: transform 0.3s ease;
}

.fav-img {
  cursor: pointer;
  width: 25px;
  height: 25px;
  transition: transform 0.3s ease;
}

.fav-img:hover {
  transform: scale(1.1); /* Slightly enlarges on hover */
  z-index: 10;
}

.cancel-img:hover {
  transform: rotate(90deg);
}

.textarea {
  background-color: rgb(231, 231, 231);
  padding: 6px;
  min-height: 80px;
  border-radius: 6px;
  border: none;
  font-size: 14px;
}

/* General Styles */
.cards-container {
  display: grid;
  gap: 20px;
  padding: 20px 0px 0px;
  width: 100%;
}

.notes-content {
  max-width: 600px;
  margin: 20px auto;
  display: contents;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-header {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.profile-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 15px;
}

.profile-info h6 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.profile-info span {
  font-size: 12px;
  color: #888;
}

.content {
  padding: 10px;
  width: 50%;
}
.content h4 {
  margin: 15px 0 10px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.content ul {
  margin: 0 0 15px 20px;
  padding: 0;
  list-style-type: disc;
  color: #444;
  width: 100%; /* Full width by default */
}

.content ul li {
  margin: 5px 0;
  font-size: 14px;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .notes-header {
    flex-direction: column; /* Stack title and buttons vertically */
    align-items: flex-start;
  }

  .notes-title {
    margin-right: 0; /* Remove right margin */
    margin-bottom: 10px; /* Add spacing below the title */
    width: 100%; /* Full width */
  }

  .notes-buttons {
    justify-content: space-between; /* Align buttons evenly */
    width: 100%; /* Full width */
    gap: 10px; /* Reduce button gaps */
  }

  .tab-buttons {
    flex-wrap: wrap; /* Allow buttons to wrap */
    gap: 5px; /* Reduce spacing between buttons */
  }
}

@media (max-width: 768px) {
  .notes-content {
    max-width: 90%; /* Reduce width on smaller screens */
    margin: 10px auto;
    padding: 10px;
  }

  .profile-header {
    align-items: flex-start; /* Align items to the start */
    margin-bottom: 0;
  }

  .profile-img {
    margin-bottom: 10px; /* Add spacing */
  }

  .profile-info h6 {
    font-size: 14px; /* Adjust font size for name */
  }

  .profile-info span {
    font-size: 12px; /* Adjust font size for date */
  }

  .content h4 {
    font-size: 14px; /* Smaller font for headings */
  }

  .content ul {
    margin-left: 10px; /* Adjust margins for smaller screens */
  }

  .content ul li {
    font-size: 13px; /* Reduce font size for list items */
  }

  .notes-buttons button {
    font-size: 12px; /* Adjust font size */
    padding: 6px 15px; /* Reduce padding */
  }

  .tab-button {
    font-size: 12px; /* Adjust font size for smaller screens */
    padding: 5px 10px; /* Adjust padding */
  }

  .textarea {
    font-size: 12px; /* Smaller font */
    min-height: 60px; /* Reduce height */
  }
}

@media (max-width: 480px) {
  .notes-header {
    gap: 10px; /* Add spacing between elements */
  }

  .notes-title {
    font-size: 14px; /* Reduce font size */
  }

  .notes-buttons button {
    font-size: 10px; /* Further reduce font size */
    padding: 5px 10px; /* Compact padding */
  }

  .tab-buttons {
    align-items: stretch; /* Full-width buttons */
    gap: 15px; /* Compact gaps */
  }

  .content {
    width: 100%; /* Full width for content */
  }

  .content ul li {
    font-size: 12px; /* Further reduce font size */
  }
}
</style>
