<template>
  <div>
    <Header />
    <div class="content-container">
      <Nav />
      <div class="containerChild">
        <div class="attendance-page">
          <h6 class="text-start title p-3">Attendance</h6>

          <div class="attendance-container">
            <div class="row">
              <div
                class="col-md-2 col-sm-12 d-flex align-items-center justify-content-center"
              >
                <div class="profile-image me-3">
                  <img
                    v-if="EmployeeprofileImage"
                    :src="EmployeeprofileImage"
                    alt="Profile Photo"
                    class="uploaded-photo"
                  />
                  <img v-else src="../../assets/icons/profile.png" alt="Profile Image" />
                </div>
              </div>
              <div
                class="col-md-3 col-sm-12 d-flex align-items-start me-5 TodayAttendanceContainer"
              >
                <div class="employee-details">
                  <div class="employee-details-row mt-3">
                    <span>
                      <label class="text-muted">Name</label>
                      <p>
                        {{ employeeDetails.firstName }} {{ employeeDetails.lastName }}
                      </p>
                    </span>
                    <span>
                      <label class="text-muted">Employee ID</label>
                      <p>{{ employeeDetails.employeeNumber }}</p>
                    </span>
                    <span>
                      <label class="text-muted">Date</label>
                      <p>{{ this.$formatDateDMY(this.currentDate) }}</p>
                    </span>
                  </div>
                  <div v-if="!this.$route.params.selectedEmployeeId">
                    <h6>Attendance</h6>
                  </div>
                  <div
                    class="action-buttons mt-3"
                    v-if="!this.$route.params.selectedEmployeeId"
                  >
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-success me-5"
                      @click="handleCheckIn('CheckIn')"
                    >
                      Check In
                    </button>
                    <button
                      :disabled="
                        todayAttendance.length > 0 &&
                        todayAttendance[todayAttendance.length - 1].checkOut !== null
                      "
                      type="button"
                      class="btn btn-sm btn-outline-danger text-nowrap"
                      @click="handleCheckIn('CheckOut')"
                    >
                      Check Out
                    </button>
                  </div>
                </div>
              </div>

              <!-- Right Section: Today's Attendance -->
              <div class="col-md-6 col-sm-12 TodayAttendanceContainer ms-5">
                <h6 class="section-title">Today's Attendance</h6>
                <div class="attendance-table-container">
                  <table class="table table-striped">
                    <thead>
                      <tr class="sticky-top">
                        <th>Time</th>
                        <th>Status</th>
                        <th>Location</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="record in todayAttendance" :key="record.id">
                        <td>
                          {{
                            record.checkIn
                              ? this.formatTime(record.checkIn)
                              : this.formatTime(record.checkOut)
                          }}
                        </td>
                        <td :style="{ color: record.checkIn ? 'green' : 'red' }">
                          {{ record.checkIn ? "Check In" : "Check Out" }}
                        </td>
                        <td
                          class="ellipsis"
                          :title="
                            record.checkIn
                              ? record.checkInLocation
                              : record.checkOutLocation
                          "
                        >
                          {{
                            record.checkIn
                              ? record.checkInLocation
                              : record.checkOutLocation
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <!-- Filter Section -->
            <div class="row mt-4 align-items-center justify-content-between">
              <!-- Buttons Section -->
              <div class="col-auto d-flex flex-wrap gap-3">
                <button
                  v-for="(item, index) in counts"
                  :key="index"
                  :class="[
                    'btn',
                    'rounded-pill',
                    'd-flex',
                    'justify-content-between',
                    'align-items-center',
                    'px-3',
                    'py-1',
                    getColorClass(item.type),
                  ]"
                  class="btn btn-sm"
                  style="min-width: 150px"
                >
                  <span style="font-size: 12px">{{ item.type }}</span>
                  <span
                    class="rounded-circle d-flex justify-content-center align-items-center ms-2"
                    style="
                      background-color: white;
                      color: black;
                      width: 20px;
                      height: 20px;
                      font-size: 10px;
                    "
                  >
                    {{ item.count }}
                  </span>
                </button>
              </div>

              <!-- Year/Month Filters -->
              <div class="col-auto d-flex align-items-center gap-2 yearFilter">
                <select
                  v-model="selectedYear"
                  class="form-select me-2 year-select"
                  @change="handleMonthChange"
                  style="width: auto"
                >
                  <option v-for="year in years" :key="year" :value="year">
                    {{ year }}
                  </option>
                </select>
                <select
                  v-model="selectedMonth"
                  class="form-select month-select"
                  @change="handleMonthChange"
                  style="width: auto"
                >
                  <option
                    v-for="(month, index) in months"
                    :key="index"
                    :value="index + 1"
                  >
                    {{ month }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Attendance History -->
            <div class="row mt-3 WEB">
              <div class="col-12">
                <div class="attendance-history-table-container">
                  <table class="table table-striped">
                    <thead class="sticky-top">
                      <tr>
                        <th>Date</th>
                        <th>Check In Time</th>
                        <th>Check Out Time</th>
                        <th>Check In Location</th>
                        <th>Check Out Location</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="record in attendanceHistory" :key="record.id">
                        <td>{{ this.$formatDateDMY(record.date) }}</td>
                        <td>{{ formatTime(record.logInTime) }}</td>
                        <td>{{ formatTime(record.logOutTime) }}</td>
                        <td :title="record.firstLogInLocation" class="ellipsis">
                          {{ record.firstLogInLocation || "-" }}
                        </td>
                        <td :title="record.lastLogOutLocation" class="ellipsis">
                          {{ record.lastLogOutLocation || "-" }}
                        </td>
                        <td>
                          <span
                            :class="{
                              badge: true,
                              'bg-success': record.status?.toLowerCase() === 'present',
                              'bg-danger': record.status?.toLowerCase() === 'absent',
                              'bg-info': record.status?.toLowerCase() === 'halfday',
                            }"
                          >
                            {{ record.status }}
                          </span>
                        </td>
                      </tr>
                      <tr v-if="attendanceHistory.length === 0">
                        <td colspan="6" class="text-center">
                          No attendance records found
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row mt-3 MOBILE">
              <div class="col-12">
                <div class="attendance-history-table-container">
                  <table class="table table-striped">
                    <thead class="sticky-top">
                      <tr>
                        <th>Date</th>
                        <th>In</th>
                        <th>Out</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="record in attendanceHistory" :key="record.id">
                        <td>{{ this.$formatDateDMY(record.date) }}</td>
                        <td>{{ formatTime(record.logInTime) }}</td>
                        <td>{{ formatTime(record.logOutTime) }}</td>

                        <td class="d-flex align-items-center justify-content-between">
                          <!-- Badge -->
                          <span
                            :class="{
                              badge: true,
                              'bg-success': record.status?.toLowerCase() === 'present',
                              'bg-danger': record.status?.toLowerCase() === 'absent',
                              'bg-info': record.status?.toLowerCase() === 'halfday',
                            }"
                            class="me-auto"
                          >
                            {{ record.status || "Not Marked" }}
                          </span>

                          <!-- Info Icon -->
                          <img
                            src="../../assets/icons/info.png"
                            alt="Info"
                            class="ms-3"
                            style="width: 18px; height: 18px; cursor: pointer"
                            data-bs-target="#tableDetailModal"
                            data-bs-toggle="modal"
                            @click="showDetails(record)"
                          />
                        </td>
                      </tr>
                      <tr v-if="attendanceHistory.length === 0">
                        <td colspan="6" class="text-center">
                          No attendance records found
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <!-- Modal -->

    <div v-if="showLocationModal" class="modal-overlay">
      <div class="modal-container">
        <!-- Header Section -->
        <div class="modal-header">
          <h6>Workplace Check-In Confirmation</h6>
          <button
            class="btn btn-sm btn-success d-flex align-items-center"
            v-if="exactLocation"
            @click="setExactLocation"
          >
            <input
              type="radio"
              id="current-location"
              value="exactLocation"
              class="me-2"
              v-model="selectedLocationId"
            />
            <span>Set Current Location</span>
          </button>

          <!-- <button class="close-button" @click="closeModal">&times;</button> -->
        </div>

        <!-- Content Section -->
        <div class="modal-body">
          <!-- Location Table -->
          <table class="location-table">
            <thead>
              <tr>
                <th></th>
                <th>Location Name</th>
                <th>Address</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="location in locationData"
                :key="location.id"
                :class="{ selected: location.id === selectedLocationId }"
              >
                <td>
                  <input type="radio" :value="location.id" v-model="selectedLocationId" />
                </td>
                <td>{{ location.name }}</td>
                <td>
                  {{ location.address }}, {{ location.city }}, {{ location.state }} -
                  {{ location.pincode }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Footer Section -->
        <div class="modal-footer">
          <button class="btn btn-sm btn-secondary" @click="closeModal">Cancel</button>
          <button
            class="btn btn-sm btn-primary"
            @click="submitSelectedLocation"
            :disabled="!selectedLocationId"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
  <TableDetailsModal :details="details" />
</template>

<script>
import TableDetailsModal from "../Modal/TableDetailsModal.vue"; // Import the modal
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import axios from "axios";
export default {
  name: "MyWorkHub",
  data() {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const companyRegisteredYear = parseInt(
      this.$LocalUser.user.companyRegisteredYear,
      10
    );
    return {
      counts: [],
      selectedEmployeeId: this.$LocalUser.user.employeeId,
      attendanceHistory: [],
      todayAttendance: [],
      Checktype: "CheckIn",
      exactLocation: null,
      selectedLocationId: null,
      locationData: [],
      showLocationModal: false,
      currentDate: new Date().toISOString().split("T")[0],
      EmployeeprofileImage: null,
      employeeDetails: {},
      selectedYear: currentYear, // Set default to current year
      selectedMonth: currentMonth, // Set default to current month index
      years: Array.from({ length: 11 }, (_, i) => companyRegisteredYear + i),
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      details: [],
    };
  },
  components: {
    Header,
    Nav,
    TableDetailsModal,
  },
  mounted() {
    if (this.$route.params.selectedEmployeeId) {
      this.selectedEmployeeId = this.$route.params.selectedEmployeeId;
      this.fetchTodayAttendance();
      this.fetchMonthlyAttendance();
    }
    this.fetchTodayAttendance();
    this.fetchMonthlyAttendance();
  },
  methods: {
    getColorClass(type) {
      switch (type.toLowerCase()) {
        case "present":
          return "btn-success"; // Green
        case "halfday":
          return "btn-info"; // Blue
        case "leave":
          return "btn-warning"; // Yellow
        case "absent":
          return "btn-danger"; // Red
        default:
          return "btn-secondary"; // Default gray
      }
    },
    showDetails(record) {
      // Map row data to label-value pairs
      this.details = [
        { label: "Date", value: this.$formatDateDMY(record.date) },
        { label: "Check In", value: this.formatTime(record.logInTime) },
        { label: "Check Out", value: this.formatTime(record.logOutTime) },
        { label: "In Location", value: record.firstLogInLocation || "-" },
        { label: "Out Location", value: record.lastLogOutLocation || "-" },
        { label: "Status", value: record.status },
      ];
    },
    async fetchMonthlyAttendance() {
      try {
        if (!this.selectedYear || !this.selectedMonth) return;

        const response = await axios.get(
          `${this.$apiEndPoint}/myWorkHub/${this.selectedYear}/${this.selectedMonth}/${this.selectedEmployeeId}`
        );
        this.counts = response.data.counts;
        this.attendanceHistory = response.data.attendance;
      } catch (error) {
        this.$handleError(error);
      }
    },
    formatTime(time) {
      if (!time) return "";
      const [hours, minutes] = time.split(":");
      const ampm = hours >= 12 ? "p.m" : "a.m";
      const formattedHours = hours % 12 || 12;
      return `${formattedHours}:${minutes} ${ampm}`;
    },
    async fetchTodayAttendance() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/myWorkHub/getTodayAttendance/${this.selectedEmployeeId}`
        );
        this.todayAttendance = response.data.todayAttendance;
        this.employeeDetails = response.data.employee;
        this.EmployeeprofileImage = response.data.employee.signedUrl;
      } catch (error) {
        this.$handleError(error);
      }
    },
    // featchEmployeeData() {
    //   axios
    //     .get(`${this.$apiEndPoint}/employee/${this.selectedEmployeeId}`)
    //     .then((response) => {
    //       this.employeeDetails = response.data;
    //       this.EmployeeprofileImage = response.data.signedUrl;
    //     })
    //     .catch((error) => {
    //       this.$handleError(error);
    //     });
    // },
    async submitSelectedLocation() {
      try {
        let locationAddress;

        if (this.selectedLocationId === "exactLocation") {
          if (!this.exactLocation) {
            this.$toast("Unable to fetch your current location", "error");
            return;
          }
          locationAddress = this.exactLocation;
        } else {
          const selectedLocation = this.locationData.find(
            (location) => location.id === this.selectedLocationId
          );

          if (!selectedLocation) {
            this.$toast("Please select a location", "error");
            return;
          }

          // Build the location address from parts
          const addressParts = [
            selectedLocation.address,
            selectedLocation.city,
            selectedLocation.state,
            selectedLocation.pincode,
          ].filter((part) => part); // Remove null or undefined parts
          locationAddress = addressParts.join(", ");
        }

        console.log(locationAddress);
        if (!locationAddress) {
          this.$toast("Invalid location details", "error");
          return;
        }

        const now = new Date();
        const currentTime = `${String(now.getHours()).padStart(2, "0")}:${String(
          now.getMinutes()
        ).padStart(2, "0")}:${String(now.getSeconds()).padStart(2, "0")}`;

        const payload = {
          location: locationAddress,
          time: currentTime,
        };

        await axios.post(`${this.$apiEndPoint}/myWorkHub/${this.Checktype}`, payload);
        this.$toast(`${this.Checktype} successful`, "success");

        await this.fetchTodayAttendance();
        this.fetchMonthlyAttendance();
        this.closeModal();
      } catch (error) {
        this.$handleError(error);
      }
    },

    handleMonthChange() {
      if (this.selectedYear && this.selectedMonth) {
        this.fetchMonthlyAttendance();
      }
    },
    // ..

    handleCheckIn(Checktype) {
      this.Checktype = Checktype;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            this.fetchLocationData(latitude, longitude);
          },
          (error) => {
            console.error("Error getting location:", error.message);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    },
    async fetchLocationData(latitude, longitude) {
      this.showLocationModal = true; // Show the modal with location details
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/myWorkHub/getGoogleLocation`,
          {
            latitude,
            longitude,
          }
        );
        this.locationData = response.data.similarAddresses || [];
        this.exactLocation = response.data.googleAddress;
      } catch (error) {
        console.error("Error fetching location:", error);
      }
    },
    closeModal() {
      this.showLocationModal = false;
      this.exactLocation = false;
    },
    setExactLocation() {
      this.selectedLocationId = "exactLocation";
    },
  },
};
</script>

<style scoped>
.exact-location-banner {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  font-size: 0.9rem;
  text-align: center;
}
.attendance-page {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.title {
  font-size: 1.2rem;
  font-weight: bold;
}

.attendance-container {
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 10px;
  font-size: 0.9rem;
}

.profile-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #000;
}

.profile-image {
  display: flex;
  align-items: center;
  /* Align image to the start */
}

.TodayAttendanceContainer {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 10px;
  background-color: #eee;
}

.employee-details span {
  display: block;
  margin-bottom: 5px;
}

.employee-details label {
  font-size: 12px;
}

.employee-details-row {
  display: flex;
  justify-content: space-between;
  gap: 35px;
}

.action-buttons button {
  width: 80px;
  font-size: 0.8rem;
}

.section-title {
  font-size: 1rem;
  margin-bottom: 10px;
}

.attendance-table-container {
  max-height: 150px;
  overflow-y: auto;
}

.year-select,
.month-select {
  width: auto;
  font-size: 0.85rem;
}

.attendance-history-table-container {
  /* Replace percentage height with fixed height or viewport units */
  height: 40vh; /* or use vh units: 40vh */
  overflow-y: auto;
  /* Add these properties for better table behavior */
  position: relative;
}

table th {
  background-color: #ccc !important;
}

table th:first-child {
  border-radius: 5px 0 0 5px;
}

table th:last-child {
  border-radius: 0 5px 5px 0;
}

.modal-header {
  border-bottom: 1px solid #eee;
  margin-top: 10px;
  padding: 10px;
  background-color: #ffffff !important;
}

.location-ticker {
  margin-top: 10px;
  background: linear-gradient(90deg, #f8f9fa, #e9ecef);

  padding: 8px;
  overflow: hidden;
  position: relative;
}

.ticker-content {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #495057;
  animation: slide 10s linear infinite;
  white-space: nowrap;
}

.ticker-content i {
  color: #dc3545;
  margin-right: 8px;
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

/* Optional: Pause animation on hover */
.location-ticker:hover .ticker-content {
  animation-play-state: paused;
}

.location-table-body tr:hover {
  background-color: #f0f0f0;
}

.close-btn {
  font-size: 24px;
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Container */
.modal-container {
  background: white;
  border-radius: 12px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Header */
.modal-header {
  padding: 6px 16px;
  background-color: #e9ecef;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h3 {
  margin: 0;
  font-size: 1.2rem;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
}

/* Body */
.modal-body {
  padding: 16px;
  overflow-y: auto;
  max-height: 300px;
}

.location-option {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.location-option label {
  margin-left: 8px;
  font-size: 1rem;
  cursor: pointer;
}

.current-location {
  margin-left: 10px;
  color: gray;
  font-size: 0.85rem;
  font-style: italic;
}

/* Location Table */
.location-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0px;
}

.location-table thead th {
  background-color: #f1f1f1;
  padding: 4px 8px;
  text-align: left;
  font-size: 12px !important;
  border-bottom: 1px solid #ddd;
}

.location-table tbody tr {
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s;
}

.location-table tbody tr:hover {
  background-color: #f9f9f9;
}

.location-table td {
  padding: 6px 8px !important;
  text-align: left;
  font-size: 12px !important;
}

.location-table .selected {
  background-color: #d4edda;
}

/* Footer */
.modal-footer {
  padding: 16px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.cancel-button,
.submit-button {
  padding: 8px 16px;
  font-size: 0.9rem;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s, color 0.3s;
}

.cancel-button {
  background-color: #f1f1f1;
  color: #333;
}

.cancel-button:hover {
  background-color: #ddd;
}

.submit-button {
  background-color: #007bff;
  color: white;
}

.submit-button:hover {
  background-color: #0056b3;
}

button {
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover {
  transform: scale(1.05); /* Slight zoom effect */
}

.rounded-circle {
  border-radius: 50%; /* Ensures the count is circular */
}
.yearFilter {
  display: flex;
  justify-content: flex-end; /* Align filters to the right */
}

@media (max-width: 768px) {
  .employee-details-row {
    flex-direction: column;
    gap: 10px;
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;
  }

  .TodayAttendanceContainer {
    margin-left: 0 !important;
    /* Removes ms-5 margin on mobile */
    margin-top: 15px;
    /* Adds some vertical spacing instead */
  }

  .attendance-page {
    height: 100%;
    width: 100%;
    overflow: scroll;
  }
  th,
  td {
    font-size: 11px;
  }
  .yearFilter {
    justify-content: flex-start; /* Align filters to the left on small screens */
    margin-top: 20px;
  }
}
</style>
