<template>
  <div
    class="modal fade"
    id="ProcurementProductList"
    tabindex="-1"
    aria-labelledby="ProcurementProductList"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-decoration-underline" id="exampleModalLabel">
            Product Detail
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="custom-modal-body">
          <form>
            <div class="form-group row">
              <label for="productName" class="col-sm-3 col-form-label"
                >Product Name</label
              >
              <div class="col-sm-4">
                <input
                  type="text"
                  class="form-control w-100"
                  id="productName"
                  v-model="productlist.productName"
                />
              </div>

              <label for="articleNo" class="col-sm-2 col-form-label">Article No.</label>
              <div class="col-sm-3">
                <input
                  type="text"
                  class="form-control w-100"
                  id="articleNo"
                  v-model="productlist.articleNo"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="description" class="col-sm-3 col-form-label"
                >Product Description</label
              >
              <div class="col-sm-9">
                <textarea
                  class="form-control w-100"
                  id="description"
                  style="height: 60px"
                  v-model="productlist.description"
                ></textarea>
              </div>
            </div>
            <div class="form-group row">
              <label for="discount" class="col-sm-3 col-form-label">Discount</label>
              <div class="col-sm-3">
                <input
                  type="text"
                  class="form-control w-100"
                  id="discount"
                  v-model="productlist.discount"
                />
              </div>
              <label for="unitPrice" class="col-sm-3 col-form-label">Unit Price</label>
              <div class="col-sm-3">
                <input
                  type="text"
                  class="form-control w-100"
                  id="unitPrice"
                  v-model="productlist.unitPrice"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="category" class="col-sm-3 col-form-label">Category</label>
              <div class="col-sm-3">
                <input
                  type="text"
                  class="form-control w-100"
                  id="category"
                  v-model="productlist.category"
                />
              </div>
              <label for="hsnNo" class="col-sm-3 col-form-label">HSN No</label>
              <div class="col-sm-3">
                <input
                  type="text"
                  class="form-control w-100"
                  id="hsnNo"
                  v-model="productlist.HSNCode"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="status" class="col-sm-3 col-form-label">Status</label>
              <div class="col-sm-3">
                <select
                  class="form-control form-select w-100"
                  id="status"
                  v-model="productlist.status"
                >
                  <option>Active</option>
                  <option>Discontinue</option>
                </select>
              </div>
              <label for="unitType" class="col-sm-3 col-form-label">Unit Type</label>
              <div class="col-sm-3">
                <input
                  type="text"
                  class="form-control w-100"
                  id="unitType"
                  v-model="productlist.unitType"
                  @input="filterUnitTypes(productlist.unitType)"
                />
                <ul
                  v-if="productlist.filteredUnitTypes.length > 0"
                  class="dropdown-menu unitDropDown show h-50 overflow-scroll"
                >
                  <li
                    v-for="unit in productlist.filteredUnitTypes"
                    :key="unit.value"
                    @click="selectUnitType(unit)"
                    class="p-1"
                  >
                    {{ unit.label }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label for="gst" class="col-sm-3 col-form-label">GST (%)</label>
              <div class="col-sm-3">
                <select class="form-select w-100" v-model="productlist.GST">
                  <option v-for="option in GSTOptions" :key="option" :value="option">
                    {{ option }}%
                  </option>
                </select>
              </div>

              <div class="col-sm-6">
                <input
                  type="file"
                  class="form-control w-100"
                  @change="handleFileUpload"
                  accept="image/*"
                  placeholder="Choose Product Img"
                />
              </div>
            </div>
          </form>
        </div>

        <div class="modal-footer justify-content-center">
          <button
            v-if="!selectedTargetId"
            type="button"
            class="btn btn-primary"
            @click="addToList"
            data-bs-dismiss="modal"
          >
            Add to List
          </button>
          <button
            v-else
            class="btn btn-success"
            @click="TargetUpdateToEmployee"
            data-bs-dismiss="modal"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ProcurementProductList",
  props: {
    selectedTargetId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      GSTOptions: [0, 5, 12, 18, 28],
      activeRow: null,
      productlist: {
        id: null,
        productName: "",
        articleNo: null,
        description: null,
        discount: null,
        unitPrice: null,
        category: "",
        HSNCode: null,
        status: "Active",
        unitType: "",
        GST: null,
        awsFileId: null,
        filteredUnitTypes: [],
      },
      unitTypes: [
        { label: "Millimeter - mm", value: "mm" },
        { label: "Centimeter - cm", value: "cm" },
        { label: "Meter - m", value: "m" },
        { label: "Inch - In", value: "In" },
        { label: "Foot - ft", value: "ft" },
        { label: "Square Millimeter - sq.mm", value: "sq.mm" },
        { label: "Square Centimeter - sq.cm", value: "sq.cm" },
        { label: "Square Meter - sq.m", value: "sq.m" },
        { label: "Square Inch - sq.In", value: "sq.In" },
        { label: "Square Foot - sq.ft", value: "sq.ft" },
        { label: "Numbers - No's", value: "No's" },
        { label: "Pieces - pcs", value: "pcs" },
        { label: "Box - box", value: "box" },
        { label: "Pair - Pr", value: "Pr" },
        { label: "Set - Set", value: "Set" },
        { label: "Unit - unit", value: "unit" },
        { label: "Running Feet - RFT", value: "RFT" },
        { label: "Running Meter - RMT", value: "RMT" },
        { label: "Kilogram - Kg", value: "Kg" },
        { label: "Gram - gm", value: "gm" },
      ],
    };
  },
  watch: {
    selectedTargetId(newValue) {
      if (newValue) {
        this.productlist.id = newValue;
        this.fetchTargetIdValue();
      } else {
        this.resetForm();
      }
    },
  },
  methods: {
    selectUnitType(unit) {
      this.productlist.unitType = unit.value;
      this.productlist.filteredUnitTypes = [];
      // Clear the dropdown after selection
    },
    filterUnitTypes(searchString) {
      if (searchString.length > 0) {
        this.productlist.filteredUnitTypes = this.unitTypes.filter((unit) =>
          unit.value.toLowerCase().includes(searchString.toLowerCase())
        );
      } else {
        this.productlist.filteredUnitTypes = []; // Clear the unit type list for that row
      }
    },
    async fetchTargetIdValue() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/productList/getById/${this.selectedTargetId}`
        );
        this.productlist = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async TargetUpdateToEmployee() {
      try {
        await axios.put(
          `${this.$apiEndPoint}/productList/${this.selectedTargetId}`,
          this.productlist
        );
        this.$toast(`Product updated successfully.`, "success");
        this.$emit("fetchData");
        this.resetForm();
      } catch (error) {
        this.$handleError(error);
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;
      this.$globalState.isLoading = true;
      const payload = {
        fileName: file.name,
        fileSize: file.size,
        contentType: file.type,
        moduleName: "procurement",
        fileCategory: "procurementProduct",
      };

      axios
        .post(`${this.$apiEndPoint}/company/putFileSignedURL`, payload)
        .then((response) => {
          const signedUrl = response.data.s3URL;
          this.productlist.awsFileId = response.data.id;

          const xhr = new XMLHttpRequest();
          xhr.open("PUT", signedUrl, true);
          xhr.setRequestHeader("Content-Type", file.type);

          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
              this.$globalState.isLoading = false;
              this.$toast("File uploaded successfully to S3", "success");
            }
          };

          xhr.send(file);
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    addToList() {
      axios
        .post(`${this.$apiEndPoint}/productList`, this.productlist)
        .then(() => {
          this.$emit("fetchData");
          this.resetForm();
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    resetForm() {
      this.productlist = {
        id: null,
        productName: "",
        articleNo: null,
        description: null,
        discount: null,
        unitPrice: null,
        category: "",
        HSNCode: null,
        status: "Active",
        unitType: "",
        GST: null,
        awsFileId: null,
        filteredUnitTypes: [],
      };
    },
  },
};
</script>

<style scoped>
.custom-modal {
  max-width: 800px;
  margin: 30px auto;
  border-radius: 10px;
  border: 1px solid #ddd;
}
.custom-modal-header {
  border-bottom: 1px solid #ddd;
  padding: 15px;
}
.custom-modal-header .modal-title {
  font-weight: bold;
}
.custom-modal-body {
  padding: 20px;
}
.custom-modal-footer {
  padding: 15px;
  border-top: 1px solid #ddd;
  text-align: right;
}
.form-group {
  margin-bottom: 1rem;
}
.form-control {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
}
.form-control-textarea {
  height: 80px;
  resize: none;
}
.btn-primary-custom {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
}
.btn-primary-custom:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}
.status-toggle {
  display: flex;
  justify-content: flex-start;
}
.status-toggle button {
  flex: 1;
  border: 1px solid #ccc;
  padding: 10px;
  margin-right: 10px;
  background-color: #fff;
  cursor: pointer;
}
.status-toggle button.active {
  background-color: #007bff;
  color: #fff;
}
.status-toggle button:last-child {
  margin-right: 0;
}
.modal-lg {
  max-width: 50% !important; /* Custom width for larger modal */
  max-height: 80vh;
}
.modal-content {
  height: 100%; /* Make sure the content takes full height */
}
.modal-body {
  max-height: calc(80vh - 200px); /* Adjust the modal body's height */
  overflow-y: auto; /* Make the modal body scrollable if content exceeds height */
  padding: 15px; /* Adding padding for better spacing */
}
.form-label {
  white-space: nowrap; /* Prevent label text from wrapping */
  text-overflow: ellipsis; /* Add ellipsis (...) if the text is too long */
  display: block; /* Ensure labels are block-level elements */
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
  box-shadow: none;
}
/* Add your scoped styles here */
.selected-employee {
  margin-bottom: 5px;
}

.remove-employee {
  cursor: pointer;
  margin-left: 5px;
}
label {
  font-weight: 500;
  color: black;
}
input,
select {
  border: 1px solid #000000;
  outline: none !important;
  width: 50%;
}
select {
  height: fit-content;
}
</style>
