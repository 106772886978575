<template>
  <ShareWithprojectModal
    :SourceId="sourceId"
    :SourceName="sourceName"
    @fetchCollaboratorData="fetchCollaboratorData"
  />
  <DeleteDesignProjectModa :SourceId="sourceId" :SourceName="sourceName" />
  <CreateLeadModal
    :defaultSourceId="sourceIdFromParentPage"
    :selectedLeadId="selectedLeadId"
    @fetchData="fetchData"
  />
  <LeadDetailsModal
    :defaultSourceId="sourceIdFromParentPage"
    :selectedLeadId="selectedLeadId"
    @fetchData="fetchData"
  />
  <Header />
  <div class="content-Container">
    <Nav />
    <div class="containerChild">
      <div class="sales-source-component">
        <div class="sourceProfile">
          <div
            class="col-lg-12 col-md-12 col-sm-12 d-flex align-items-center justify-content-start"
          >
            <img
              src="../../../assets/icons/clarity_clipboard-outline-badged.png"
              alt=""
              class="profile-image"
            />
            <div class="col-lg-6 col-md-6 col-sm-6 SourceName">
              <div
                class="row align-items-center d-flex flex-column justify-content-between"
              >
                <div class="d-flex mt-1 gap-4">
                  <div>
                    <!-- <input
                      v-if="sourceId === 'All'"
                      v-model="this.sourceId"
                      style="min-width: 200px; border: none; max-width: auto"
                      class="sourceName mainHeading text-nowrap position-relative pb-1"
                    /> -->

                    <select
                      v-if="sourceId !== 'All'"
                      v-model="this.sourceId"
                      @change="changeSourceId(sourceId)"
                      class="form-select"
                      style="
                        width: fit-content;
                        :focus {
                          outline: none; /* Remove default outline */
                          box-shadow: none; /* Add a custom focus shadow */
                        }
                      "
                    >
                      <!-- <option value="All">All</option> -->
                      <option
                        v-for="source in sources"
                        :key="source.id"
                        :value="source.id"
                      >
                        {{ source.name }}
                      </option>
                    </select>

                    <span> </span>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-3 mb-2 StatusDiv">
                    <span>
                      <img
                        v-if="sourceData.status === 'Completed'"
                        src="../../../assets/icons/completed.png"
                        alt="Completed"
                      />
                      <img
                        v-else-if="sourceData.status === 'On Hold'"
                        src="../../../assets/icons/onHold.png"
                        alt="On Hold"
                      />
                      <img
                        v-else-if="sourceData.status === 'In Progress'"
                        src="../../../assets/icons/inProgress.png"
                        alt="In Progress"
                      />
                    </span>
                    <span class="ms-1 mt-1" style="font-size: 14px">{{
                      sourceData.status
                    }}</span>
                  </div>
                </div>

                <div class="row d-flex justify-content-between mt-2">
                  <!-- Left Column: Overview, Unclaimed Leads, Claimed Leads -->
                  <div
                    class="col-lg-8 col-md-8 col-sm-8 d-flex justify-content-between align-items-center"
                  >
                    <div
                      v-if="sourceId !== 'All'"
                      class="text-nowrap"
                      style="cursor: pointer"
                      :class="{
                        'active-SubHeading': showOverview,
                        'inactive-SubHeading': !showOverview,
                      }"
                      @click="toggleOverviewLead"
                    >
                      Overview
                    </div>

                    <div
                      style="cursor: pointer"
                      v-if="sourceId !== 'All'"
                      class="text-nowrap mx-4"
                      :class="{
                        'active-SubHeading': showUnclaimedList,
                        'inactive-SubHeading': !showUnclaimedList,
                      }"
                      @click="toggleUnclaimedLead"
                    >
                      Unclaimed ({{ totalCount }})
                    </div>

                    <div
                      style="cursor: pointer"
                      class="text-nowrap"
                      :class="{
                        'active-SubHeading': showClaimedList,
                        'inactive-SubHeading': !showClaimedList,
                      }"
                      @click="toggleClaimedList"
                    >
                      Lead Pipeline
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-1 col-sm-0"></div>
                  <!-- Middle Column: Report Dropdown -->
                  <div class="col-lg-2 col-md-3 col-sm-4">
                    <div class="d-flex align-items-center gap-2 mb-1">
                      <label
                        for="input1"
                        class="form-label text-muted text-nowrap mt-1"
                        style="font-size: 14px"
                      >
                        Report :
                      </label>
                      <select
                        class="form-select form-control"
                        v-model="selectedEmployeeId"
                        @change="setSelectedEmployee"
                        style="
                          cursor: pointer;
                          font-size: 12px;
                          height: 28px;
                          min-width: 150px;
                          width: fit-content;
                          select:focus {
                            outline: none;
                            box-shadow: none;
                          }
                        "
                      >
                        <option value="All">Team</option>
                        <option
                          v-for="employee in employees"
                          :key="employee['employees.id']"
                          :value="employee['employees.id']"
                        >
                          {{ employee["employees.firstName"] }}
                          {{ employee["employees.lastName"] }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- Right Column: Empty for spacing -->
                </div>
              </div>
            </div>
            <!-- In-progress column -->
            <div
              class="col-lg-4 col-md-4 col-sm-4 mb-4 d-flex justify-content-end gap-5 CollabratorSection"
            >
              <div
                class="col-lg-2 col-md-2 col-sm-2 d-flex justify-content-center align-items-center mb-2"
              >
                <div
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  v-for="(collaborator, index) in collaborators.slice(0, 3)"
                  :key="index"
                  class="collaborator-avatar"
                  @mouseover="showEmployeeName(collaborator)"
                  @mouseleave="hideEmployeeName"
                >
                  <img
                    class="initials"
                    v-if="collaborator['employees.signedUrl'] !== null"
                    :src="collaborator['employees.signedUrl']"
                    alt=""
                  />
                  <span class="initials" v-else>{{
                    collaborator["employees.initials"]
                  }}</span>
                </div>
                <span v-if="collaborators.length > 3" class="additional-count ms-1">
                  +{{ collaborators.length - 3 }}
                </span>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <ul>
                    <li
                      v-for="(employee, employeeIndex) in collaborators"
                      :key="employeeIndex"
                      class="d-flex p-2 text-start"
                    >
                      <span class="d-flex ms-2 text-start"
                        >{{ employee["employees.firstName"] }}
                        {{ employee["employees.lastName"] }}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="col-lg-2 col-md-2 col-sm-2 d-flex justify-content-between align-items-center mb-2"
              >
                <div class="button-container" style="display: flex; gap: 20px">
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-primary"
                    style="display: flex; align-items: center"
                    v-if="isSalesManager()"
                    data-bs-toggle="modal"
                    data-bs-target="#shareProjectModal"
                  >
                    Share

                    <img
                      src="../../../assets/icons/customerIcon.png"
                      alt="Share"
                      class="button-icon"
                    />
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-danger"
                    style="display: flex; align-items: center"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteProjectModal"
                    v-if="isSalesManager()"
                  >
                    Delete
                    <img
                      src="../../../assets/icons/redDelete.png"
                      alt="Delete"
                      class="button-icon"
                    />
                  </button>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>

        <div v-if="showOverview">
          <div class="ms-2 mt-2">
            <apexchart
              height="480"
              width="1100"
              type="bar"
              :options="chartOptions"
              :series="series"
            >
            </apexchart>
          </div>
        </div>
        <!-- content Of the Header Below -->
        <div class="scrollable-container" v-if="showClaimedList">
          <div class="row DesignStageContainer">
            <!-- Iterate over each stage and display data -->
            <div class="cardThings" v-for="(stage, index) in stages" :key="index">
              <div
                class="p-1 mt-1 z-3 card top-0 d-flex flex-row align-items-center headerOfCard"
                :style="{ zIndex: 10 - index }"
              >
                <span class="dot" :style="{}"></span>
                <span class="text-left">{{ stage.title }}</span>
                <span class="text-muted ms-2" style="font-size: 14px"
                  >({{ stage.items.length }})</span
                >
              </div>
              <div
                class="card-container mt-2 bodytext"
                style="background-color: #f8f8f8"
                :id="'stage-' + index"
                @dragover.prevent
                @drop="dropItem(index)"
              >
                <template v-if="cardLoading">
                  <div
                    v-for="n in skeletonItems[index].length"
                    :key="n"
                    class="draggable-item card m-1"
                    :class="{ skeleton: cardLoading }"
                  >
                    <div class="card-body Profile" style="min-height: 150px"></div>
                  </div>
                </template>
                <!-- Iterate over each item in the stage -->
                <template v-if="!cardLoading">
                  <!-- Iterate over each item in the stage -->
                  <div
                    class="draggable-item card m-1"
                    v-for="(item, itemIndex) in stage.items"
                    :key="itemIndex"
                    draggable="true"
                    @dragstart="dragStart(item, stage, itemIndex)"
                  >
                    <div class="card-body Profile" style="background-color: #eee">
                      <div class="row">
                        <div class="col-12 m-2">
                          <h5
                            style="font-size: 14px; font-weight: bolder"
                            class="d-flex justify-content-between"
                          >
                            <span
                              @click="openModal(item.id)"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal2"
                              data-bs-whatever="@mdo"
                            >
                              {{ item.name }}</span
                            >
                            <span>
                              <div
                                class="d-flex d-inline-flex align-items-center justify-content-around"
                                style="margin-right: 5px"
                              >
                                <button
                                  type="button"
                                  :class="priorityButtonClass(item)"
                                  class="btn btn-sm"
                                  style="
                                    width: 50px !important;
                                    height: 20px !important;
                                    font-size: 10px;
                                    padding: 3px;
                                  "
                                >
                                  {{ item.priority }}
                                </button>

                                <img
                                  class="ms-2"
                                  src="../../../assets/icons/phoneCall.png"
                                  alt=""
                                  @click="makePhoneCall(item.contactNo)"
                                  style="width: 18px; height: 18px"
                                />
                                <img
                                  @click="hideEmployeeDropdown('null')"
                                  id="btnGroupDrop1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  class="ms-1"
                                  src="../../../assets/icons/threeDot.jpg"
                                  alt=""
                                  style="
                                    width: 15px;
                                    height: 14px;
                                    margin-top: 1.5px;
                                    margin-right: 5px;
                                  "
                                />
                                <div
                                  class="dropdown-menu ms-3 text-black p-2 mt-1"
                                  aria-labelledby="btnGroupDrop1"
                                >
                                  <p
                                    @click="createEstimate(item)"
                                    @mouseenter="hideEmployeeDropdown('null')"
                                  >
                                    Create Estimate
                                  </p>
                                  <p @mouseenter="showEmployeeDropdown(index)">
                                    Assigned To
                                  </p>
                                  <section
                                    v-if="activeDropdownIndex === index"
                                    class="employee-dropdown card p-1"
                                  >
                                    <p
                                      v-for="employee in employees"
                                      :key="employee.employeeId"
                                      @click="
                                        assignEmployee(
                                          item.id,
                                          employee['employees.id'],
                                          stage
                                        )
                                      "
                                    >
                                      {{ employee["employees.firstName"] }}
                                      {{ employee["employees.lastName"] }}
                                    </p>
                                  </section>
                                </div>
                              </div>
                            </span>
                          </h5>
                          <span
                            @click="openModal(item.id)"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal2"
                            data-bs-whatever="@mdo"
                          >
                            <h6
                              class="card-subtitle mb-2 mt-1 text-muted"
                              style="font-size: 12px"
                            >
                              {{ item.email }}
                            </h6>
                            <h6
                              class="card-subtitle mb-2 mt-1 text-muted"
                              style="font-size: 12px"
                            >
                              {{ item.contactNo }}
                            </h6>
                            <h6
                              class="card-subtitle text-muted mt-1 mb-2"
                              style="font-size: 12px"
                            >
                              {{ item["leadAddresses.address"] }}
                            </h6>
                            <h6
                              class="card-subtitle text-muted mt-1 mb-2"
                              style="font-size: 12px"
                            >
                              Claimed By
                              <span
                                style="margin-left: 1px; font-weight: 500; color: #000000"
                              >
                                : {{ item["claimedBy.firstName"] }}
                                {{ item["claimedBy.lastName"] }}</span
                              >
                            </h6>
                            <h6
                              class="card-subtitle text-muted mt-1"
                              style="font-size: 12px"
                              v-if="item.assignTo"
                            >
                              Assignee &nbsp; &nbsp;
                              <span
                                style="margin-left: 1px; font-weight: 500; color: #000000"
                              >
                                : {{ item["assignee.firstName"] }}
                                {{ item["assignee.lastName"] }}</span
                              >
                            </h6>
                            <h6
                              class="card-subtitle text-muted mt-1"
                              style="font-size: 12px"
                              v-if="item.followUpdate"
                            >
                              Follow-up &nbsp;
                              <span
                                style="margin-left: 2px; font-weight: 500; color: #000000"
                              >
                                : {{ this.$formatDateDMY(item.followUpdate) }}
                              </span>
                            </h6>
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- End of item iteration -->
                  </div>
                </template>
              </div>
              <!-- End of stage iteration -->
            </div>
          </div>
        </div>

        <div class="FormatTable" v-if="showUnclaimedList">
          <div class="col-md-12">
            <div class="row headerOfTable d-flex align-content-center">
              <div class="col-md-6 d-flex align-items-center justify-content-between">
                <div class="d-flex">
                  <div class="subHeadingHeader">Unclaimed Leads</div>
                  <div>
                    <img
                      src="../../../assets/icons/blueAddicon.png"
                      alt=""
                      @click="openModal(null)"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal1"
                      data-bs-whatever="@mdo"
                      id="addicon"
                    />
                  </div>
                </div>
                <div>
                  <button
                    class="btn btn-sm btn-outline-success"
                    style="font-size: 10px"
                    @click="downloadTemplate"
                  >
                    Download Template
                  </button>
                </div>
              </div>
              <div class="col-md-6 d-flex align-items-center">
                <div class="col-md-3 ImportExport ms-2" style="cursor: pointer">
                  <div class="row" @click="triggerFileInput">
                    <div class="col-md-4 tableTextEvenRow">
                      <label
                        class="col-md-4 tableTextEvenRow import-label bodyText"
                        style="cursor: pointer"
                      >
                        Import
                        <input
                          type="file"
                          ref="fileInput"
                          @change="handleFileUpload"
                          style="display: none"
                        />
                      </label>
                    </div>
                    <div class="col-md-3 tableTextEvenRow">
                      <img
                        @click="triggerFileInput"
                        src="../../../assets/icons/import.png"
                        alt="Import"
                        id="ImportIcon"
                        style="cursor: pointer"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-3 ImportExport">
                  <div class="row" style="cursor: pointer" v-if="showExecative">
                    <div
                      class="col-md-4 tableTextEvenRow bodyText"
                      style="margin-top: 2px"
                      @click="exportToCSV"
                    >
                      Export
                    </div>
                    <div class="col-md-3 tableTextEvenRow">
                      <img
                        src="../../../assets/icons/export.png"
                        alt=""
                        id="ImportIcon"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-1">
                  <img
                    src="../../../assets/icons/archive.png"
                    alt=""
                    id="ArchiveIcon"
                    @click="handleArchiveClick"
                  />
                </div>
                <div class="col-md-4 searchboxOfTable">
                  <div class="search-box">
                    <img
                      src="../../../assets/icons/search.png"
                      alt="Search Icon"
                      id="SerchIconOfTable"
                    />
                    <input
                      type="text"
                      name="search"
                      id=""
                      v-model="searchString"
                      @input="searchLeads"
                    />
                  </div>

                  <span>
                    <img
                      src="../../../assets/icons/filter.png"
                      alt="Search Icon"
                      id="FilterIconOfTable"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="table-wrapper">
            <table class="tableFormat">
              <caption class="d-none">
                Table
              </caption>
              <thead>
                <tr>
                  <th class="">
                    <div class="custom-checkbox">
                      <input
                        type="checkbox"
                        id="checkbox1"
                        v-model="selectAll"
                        @change="selectAllRows"
                      />
                    </div>
                    <span
                      id="btnGroupDrop1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="../../../assets/icons/threeDot.jpg"
                        alt=""
                        class="dropdown-toggle"
                        aria-expanded="false"
                        id="blackThreeDots"
                      />
                    </span>
                    <div
                      class="dropdown-menu text-black p-2"
                      aria-labelledby="btnGroupDrop1"
                    >
                      <p @click="UnclaimLeads('Archive')">Claim</p>
                      <p @click="ArchiveLeads('Archive')">Archive</p>
                      <p @click="deleteLead('ArchiveDelete')">Delete</p>
                    </div>
                  </th>
                  <th class="tableHeaderHeading">Name</th>
                  <th class="tableHeaderHeading">Date</th>
                  <th class="tableHeaderHeading">Contact No</th>
                  <th class="tableHeaderHeading">Email ID</th>
                  <th class="tableHeaderHeading">Property Address</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="cardLoading">
                  <TableSkeletonLoader class="mt-2" />
                </template>
                <template v-else>
                  <tr v-for="(data, index) in displayedData" :key="index">
                    <td class="bodyText">
                      <div class="custom-checkbox">
                        <input
                          type="checkbox"
                          id="checkbox1"
                          v-model="selectedCustomers"
                          :value="data.id"
                        />
                      </div>

                      <span
                        id="btnGroupDrop1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src="../../../assets/icons/threeDot.jpg"
                          alt=""
                          class="dropdown-toggle"
                          aria-expanded="false"
                          id="blackThreeDots"
                        />
                      </span>
                      <div
                        class="dropdown-menu ms-3 text-black p-2"
                        aria-labelledby="btnGroupDrop1"
                      >
                        <p @click="UnclaimLead(data, index)">Claim</p>

                        <p @click="ArchiveLead(data, index)">Archive</p>
                        <p @click="DeleteLead(data, index)">Delete</p>
                      </div>
                    </td>
                    <td
                      @click="openModal(data.id)"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal1"
                      data-bs-whatever="@mdo"
                    >
                      {{ data.name }}
                    </td>
                    <td>{{ data.date }}</td>
                    <td>{{ data.contactNo }}</td>
                    <td>{{ data.email }}</td>
                    <td>{{ data["leadAddresses.address"] }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="paginationOfTable" style="bottom: 0%">
            <div class="pageControlsTable">
              <img
                src="../../../assets/icons/blueLeftarrow.png"
                @click="goToPreviousPage"
                :disabled="!hasPreviousPage"
                alt=""
              />
              <div class="page-numbers">
                <span
                  v-for="pageNumber in pagination"
                  :key="pageNumber"
                  :class="{
                    'page-number': true,
                    'current-page': pageNumber === currentPage,
                  }"
                  @click="goToPage(pageNumber)"
                  >{{ pageNumber }}</span
                >
              </div>

              <img
                src="../../../assets/icons/blueRightarrow.png"
                @click="goToNextPage"
                :disabled="!hasNextPage"
                alt=""
              />
            </div>

            <div class="entries-info">
              <span class="entries-text">Entries: {{ displayedData.length }} Rows</span>
            </div>
          </div>
        </div>
        <div class="FormatTable" v-if="showArchiveList">
          <div class="row headerOfTable d-flex">
            <div class="col-md-12 d-flex align-content-center">
              <div class="col-md-6 d-flex">
                <div class="col-md-3 text-nowrap active-SubHeading">
                  <h5>Archived Leads</h5>
                </div>
                <div class="col-md-1">
                  <img
                    src="../../../assets/icons/unarchive.png"
                    alt=""
                    id="ArchiveIcon"
                    @click="handleArchiveClick"
                  />
                </div>
                <div class="col-md-2" style="visibility: hidden">
                  <img
                    src="../../../assets/icons/blueAddicon.png"
                    alt=""
                    class="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal1"
                    data-bs-whatever="@mdo"
                    id="addicon"
                  />
                </div>
              </div>
              <div class="col-md-6 d-flex align-items-center">
                <div class="col-md-3 ImportExport">
                  <div class="row" style="visibility: hidden">
                    <div class="col-md-4 tableTextEvenRow">Import</div>
                    <div class="col-md-3 tableTextEvenRow">
                      <img
                        src="../../../assets/icons/import.png"
                        alt=""
                        id="ImportIcon"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-3 ImportExport" style="visibility: hidden">
                  <div class="row">
                    <div class="col-md-4 tableTextEvenRow" @click="exportToCSV">
                      Export
                    </div>
                    <div class="col-md-3 tableTextEvenRow">
                      <img
                        src="../../../assets/icons/export.png"
                        alt=""
                        id="ImportIcon"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-1" style="visibility: hidden">
                  <img
                    src="../../../assets/icons/archive.png"
                    alt=""
                    id="ArchiveIcon"
                    @click="handleArchiveClick"
                  />
                </div>
                <div class="col-md-4 searchboxOfTable">
                  <div class="search-box">
                    <img
                      src="../../../assets/icons/search.png"
                      alt="Search Icon"
                      id="SerchIconOfTable"
                    />
                    <input
                      type="text"
                      name="search"
                      id=""
                      v-model="searchString"
                      @input="searchArchiveLeads"
                    />
                  </div>
                  <span>
                    <img
                      src="../../../assets/icons/filter.png"
                      alt="Search Icon"
                      id="FilterIconOfTable"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="table-wrapper">
            <table class="tableFormat">
              <caption class="d-none">
                Table
              </caption>
              <thead>
                <tr>
                  <th class="">
                    <div class="custom-checkbox">
                      <input
                        type="checkbox"
                        id="checkbox1"
                        v-model="selectAll"
                        @change="selectAllArchiveRows"
                      />
                    </div>
                    <span
                      id="btnGroupDrop1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="../../../assets/icons/threeDot.jpg"
                        alt=""
                        class="dropdown-toggle"
                        aria-expanded="false"
                        id="blackThreeDots"
                      />
                    </span>
                    <div
                      class="dropdown-menu ms-3 text-black p-2"
                      aria-labelledby="btnGroupDrop1"
                    >
                      <p @click="ArchiveLeads('UnArchive')">UnArchive</p>
                    </div>
                  </th>
                  <th class="tableHeaderHeading">Name</th>
                  <th class="tableHeaderHeading">Date</th>
                  <th class="tableHeaderHeading">Contact No</th>
                  <th class="tableHeaderHeading">Email ID</th>
                  <th class="tableHeaderHeading">Property Address</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="cardLoading">
                  <TableSkeletonLoader class="mt-2" />
                </template>
                <template v-else>
                  <tr v-for="(data, index) in displayedArchiveData" :key="index">
                    <td class="bodyText">
                      <div class="custom-checkbox">
                        <input
                          type="checkbox"
                          id="checkbox1"
                          v-model="selectedCustomers"
                          :value="data.id"
                        />
                      </div>
                      <span
                        id="btnGroupDrop1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src="../../../assets/icons/threeDot.jpg"
                          alt=""
                          class="dropdown-toggle"
                          aria-expanded="false"
                          id="blackThreeDots"
                        />
                      </span>
                      <div
                        class="dropdown-menu ms-3 text-black p-2"
                        aria-labelledby="btnGroupDrop1"
                      >
                        <p @click="ArchiveLead(data, index)">UnArchive</p>
                      </div>
                    </td>
                    <td
                      @click="openModal(data.id)"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal1"
                      data-bs-whatever="@mdo"
                    >
                      {{ data.name }}
                    </td>
                    <td>{{ data.date }}</td>
                    <td>{{ data.contactNo }}</td>
                    <td>{{ data.email }}</td>
                    <td>{{ data["leadAddresses.address"] }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="paginationOfTable">
            <div class="pageControlsTable">
              <img
                src="../../../assets/icons/blueLeftarrow.png"
                @click="goToArchivePreviousPage"
                :disabled="!hasArchivePreviousPage"
                alt=""
              />
              <div class="page-numbers">
                <span
                  v-for="pageNumber in paginationArchive"
                  :key="pageNumber"
                  :class="{
                    'page-number': true,
                    'current-page': pageNumber === currentPage,
                  }"
                  @click="goToPage(pageNumber)"
                  >{{ pageNumber }}</span
                >
              </div>

              <img
                src="../../../assets/icons/blueRightarrow.png"
                @click="goToArchiveNextPage"
                :disabled="!hasArchiveNextPage"
                alt=""
              />
            </div>

            <div class="entries-info">
              <span class="entries-text"
                >Entries: {{ displayedArchiveData.length }} Rows</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button
    type="button"
    class="btn btn-primary"
    data-bs-toggle="modal"
    data-bs-target="#transactionModal"
    id="transactionModal"
    style="display: none"
  ></button>
  <div
    class="modal fade"
    id="transactionModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="transactionModalLabel"
    aria-hidden="true"
    ref="transactionModal"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h5 class="modal-title" id="transactionModalLabel">Transaction Details</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <!-- Modal Body -->
        <div class="modal-body">
          <!-- Transaction ID Textarea -->
          <div class="form-group">
            <textarea
              class="form-control"
              id="transactionId"
              placeholder="Enter transaction details"
              rows="4"
              v-model="transactionDetails"
            ></textarea>
          </div>
        </div>
        <!-- Modal Footer -->
        <div class="modal-footer">
          <!-- Bind the disabled attribute to the computed property -->
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            :disabled="isDoneButtonDisabled"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showBulkUploadModal">
    <div class="modal mt-4" role="dialog" style="display: block">
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style="min-width: 80%; overflow: auto"
      >
        <div
          class="modal-content"
          style="
            background-color: white;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          "
        >
          <div class="modal-header">
            <h5 class="modal-title">Bulk Upload Leads</h5>
            <img
              src="../../../assets/icons/cancel.png"
              alt=""
              style="
                width: 20px;
                height: 20px;
                position: absolute;
                right: 10px;
                top: 2.5%;
                cursor: pointer;
              "
              @click="closeModal()"
            />
          </div>
          <div class="modal-body" style="max-height: 320px; overflow: auto">
            <!-- Header Mapping Section -->
            <div v-if="!areHeadersSame">
              <p style="font-size: 14px; color: #555; margin-bottom: 5px">
                Please map the uploaded file's headers to the required fields below:
              </p>
              <br />
              <br />
              <div class="d-flex flex-row justify-content-between">
                <div
                  v-for="defaultHeader in defaultHeaders"
                  :key="defaultHeader"
                  class="d-flex flex-row align-items-center"
                >
                  <span>
                    <label class="me-2" style="font-size: 12px">{{
                      defaultHeader
                    }}</label>
                    <br />
                    <select
                      v-model="headersMapping[defaultHeader]"
                      class="form-select form-select-sm mt-1"
                      style="
                        font-size: 12px;
                        select:focus {
                          outline: none;
                          box-shadow: none;
                        }
                      "
                    >
                      <option value="">Select a Header</option>
                      <option
                        v-for="uploadedHeader in uploadedHeaders"
                        :key="uploadedHeader"
                        :value="uploadedHeader"
                      >
                        {{ uploadedHeader }}
                      </option>
                    </select>
                  </span>
                </div>
              </div>
            </div>

            <!-- Data Preview Table -->
            <div v-if="data.leads.length">
              <table
                style="max-height: 320px; overflow: auto"
                class="table table-responsive"
              >
                <thead>
                  <tr>
                    <th v-for="defaultHeader in defaultHeaders" :key="defaultHeader">
                      {{ defaultHeader }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(lead, index) in data.leads" :key="index">
                    <td v-for="defaultHeader in defaultHeaders" :key="defaultHeader">
                      {{ lead[headersMapping[defaultHeader]] || "" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button
              type="button"
              class="btn btn-sm btn-primary confirmationButton"
              :disabled="loading"
              @click="approveBulkLeads"
            >
              Continue Upload
            </button>
            <button type="button" class="btn btn-sm btn-secondary" @click="closeModal()">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showTransactionDetails" class="modal show d-block">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title">Enter Transaction Details</p>
          <button
            type="button"
            class="btn-close"
            @click="hideTransactionDetails"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label for="transactionDetails" style="font-size: 16px"
                >Payment Transaction Details:</label
              >
              <textarea
                class="form-control border-black"
                id="transactionDetails"
                v-model="transactionDetails"
                rows="4"
              ></textarea>
            </div>
          </form>
          <div class="d-flex justify-content-center align-content-center m-1 mt-2">
            <button
              type="submit"
              class="btn btn-sm btn-primary ms-2"
              @click="updateLeadStatus(currentleadId)"
            >
              Confirm
            </button>
            <button
              n
              type="button"
              @click="hideTransactionDetails"
              class="btn btn-sm btn-secondary ms-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeleteDesignProjectModa from "../../Modal/DeleteDesignProjectModal.vue";
import ShareWithprojectModal from "../../Modal/ShareWithProjectModal.vue";
import axios from "axios";
import Header from "../../Header.vue";
import Nav from "../../Nav.vue";
import CreateLeadModal from "../../Modal/CreateLeadModal.vue";
import * as XLSX from "xlsx";
import VueApexCharts from "vue3-apexcharts";
import LeadDetailsModal from "@/components/Modal/LeadDetailsModal.vue";
let pageFlag = true;

export default {
  name: "SourceById",
  components: {
    Header,
    Nav,
    CreateLeadModal,
    DeleteDesignProjectModa,
    ShareWithprojectModal,
    apexchart: VueApexCharts,
    LeadDetailsModal,
  },
  data() {
    return {
      headersMapping: {}, // For mapping headers
      defaultHeaders: ["Name", "Email", "ContactNo", "Address", "PropertyType"], // Default headers
      uploadedHeaders: [], // Headers from the uploaded file

      skeletonItems: [],
      cardLoading: true,
      AllStageData: [],
      totalCount: null,
      totalArchiveCount: null,
      sources: [],
      activeDropdownIndex: null,
      employees: [],
      selectedEmployeeId: this.$LocalUser.user.employeeId,
      loading: false,
      currentleadId: null,
      chartOptions: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: "#000000",
              fontWeight: "400", // Change color to black
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "#000000",
              fontWeight: "400", // Change color to black
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            borderRadiusApplication: "end",
            horizontal: true,
          },
        },
      },
      series: [
        {
          name: "series-1",
          data: [],
        },
      ],
      userRoles: this.$LocalUser.employeeRoles,
      showBulkUploadModal: false,
      showExecative: false,
      leadCounts: {},
      lastThreeProjects: [],
      selectedFile: null,
      transactionDetails: "",
      showTransactionDetails: false,
      showProjectNameDropDown: false,
      stages: [
        { title: "Claimed", items: [] },
        { title: "RNR", items: [] },
        { title: "ContactMode", items: [] },
        { title: "Meeting", items: [] },
        { title: "Estimate", items: [] },
        { title: "Negotiation", items: [] },
        { title: "Sold", items: [] },
        { title: "Lost", items: [] },
        { title: "Trash", items: [] },
      ],
      titleColors: [
        "rgba(216, 174, 23, 0.25)",
        "rgba(131, 216, 23, 0.25)",
        "rgba(34, 79, 73, 0.25)",
        "rgba(96, 23, 216, 0.25)",
        "rgba(174, 27, 210, 0.25)",
        "rgba(23, 135, 216, 0.25)",
        "rgba(255, 0, 0, 0.25)", // Red color for "Lost"
        "rgba(255, 165, 0, 0.25)", // Orange color for "Trash"
      ],
      titleDotColors: [
        "#D8ae17",
        "#83D817",
        "#224f49",
        "#6017D8",
        "#AE1BD2",
        "#1787D8",
        "#FF0000",
        "#FFA500",
      ], // Adjusted dot colors accordingly
      draggedItem: null,
      sourceStage: null,
      sourceItemIndex: null,
      searchString: "",
      leadDropdownStates: {},
      tabledropdownEdits: false,
      showOverview: false,
      showClaimedList: false,
      showUnclaimedList: true,
      selectAll: false,
      customers: [],
      selectedCustomers: [],
      TableData: [],
      ArchiveData: [],
      pageSize: 50,
      currentPage: 1,

      showArchiveList: false,
      selectedEmployees: [],
      searchResults: [],
      leadTypePage: true,
      dropdownOpen: false,
      statusDropdownOpen: false,
      selectedStatus: "In Progress",
      showDropdown: false,
      sourceData: {},
      collaborators: [],
      isInviteModalOpen1: false,
      isInviteModalOpen: false,
      isSharewithModalOpen: false,
      showTeamMember: false,
      showCollaborators: true,
      teamMembers: [],
      PropertyteamMembers: [],
      PropertyDeleteModal: false,
      propertyId: atob(this.$route.params.Id),
      sourceIdFromParentPage: atob(this.$route.params.Id),
      sourceId: atob(this.$route.params.Id) || "",
      sourceName: "",
      selectedLeadId: null,
      routeParams: {
        Member: "",
      },
      // data: [],
      data: {
        leads: [],
      },
      drag: true,
    };
  },
  computed: {
    areHeadersSame() {
      // Check if the arrays are the same length
      if (this.uploadedHeaders.length !== this.defaultHeaders.length) {
        return false;
      }
      // Check if every element in uploadedHeaders exists in defaultHeaders
      return this.uploadedHeaders.every((header) => this.defaultHeaders.includes(header));
    },
    priorityButtonClass() {
      return (data) => {
        // Determine which class to apply based on data.priority
        switch (data.priority) {
          case "Low":
            return "btn-priority-low"; // Define this class in your CSS
          case "Medium":
            return "btn-priority-medium"; // Define this class in your CSS
          case "High":
            return "btn-priority-high"; // Define this class in your CSS
          default:
            return ""; // Default class if no priority is set or unknown priority
        }
      };
    },
    hasPreviousPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < Math.ceil(this.totalCount / this.pageSize);
    },
    hasArchivePreviousPage() {
      return this.currentPage > 1;
    },
    hasArchiveNextPage() {
      return this.currentPage < Math.ceil(this.totalArchiveCount / this.pageSize);
    },

    pagination() {
      const pageCount = Math.ceil(this.totalCount / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },

    paginationArchive() {
      const pageCount = Math.ceil(this.totalArchiveCount / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },
    displayedData() {
      return this.TableData;
    },
    displayedArchiveData() {
      return this.ArchiveData;
    },
  },
  mounted() {
    this.generateSkeletonItems();
    this.fetchSource();
    const encodedId = this.$route.params.Id;
    if (encodedId === "MA==") {
      this.showUnclaimedList = false;
      this.showClaimedList = true;
      // Ensure you're comparing to '0' as a string
      this.sourceId = "All";

      this.fetchAllEmployees();
    } else {
      this.sourceId = atob(encodedId); // Decode the ID only if it's not 0
      this.fetchEmployees();
    }

    this.showExecative = this.userRoles.some(
      (role) => role.team === "Sales" && role.role === "Manager"
    );
    this.fetchCollaboratorData();
    this.fetchSourceData();
    this.fetchData();
    this.fetchArchiveData();
    this.fetchAllStageData();
    this.fetchLeadCounts();
  },
  methods: {
    generateSkeletonItems() {
      this.skeletonItems = this.stages.map(() => {
        const randomCount = Math.floor(Math.random() * 2) + 2; // Random count between 2-6
        return Array.from({ length: randomCount });
      });
    },
    changeSourceId(sourceId) {
      this.sourceId = sourceId;
      this.fetchCollaboratorData();
      this.fetchSourceData();
      this.fetchData();
      this.fetchArchiveData();

      this.fetchAllStageData();
      this.fetchLeadCounts();
    },
    async fetchSource() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/source/`);
        this.sources = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    triggerFileInput() {
      // Trigger a click on the hidden file input when the image is clicked
      this.$refs.fileInput.click();
    },
    showEmployeeDropdown(index) {
      this.activeDropdownIndex = index;
    },
    hideEmployeeDropdown(index) {
      this.activeDropdownIndex = index;
    },
    async assignEmployee(leadId, employeeId, stage) {
      try {
        await axios.put(`${this.$apiEndPoint}/leads/${leadId}`, {
          assignTo: employeeId,
        });

        this.activeDropdownIndex = null;
        this.fetchStageData(stage);
        this.$toast(`Lead assigned successfully.`, "success");
      } catch (error) {
        this.$handleError(error);
      }
    },

    createEstimate(selectedLead) {
      this.$router.push({
        name: "CreateEstimate",
        params: {
          Clientname: selectedLead.name,
          ContactNo: selectedLead.contactNo,
          Email: selectedLead.email,
          leadId: selectedLead.id,
          address: `${selectedLead["leadAddresses.address"]}, ${selectedLead["leadAddresses.city"]}, ${selectedLead["leadAddresses.state"]}`,
          propertyName: selectedLead["property.name"],
        },
      });
    },
    setSelectedEmployee() {
      this.fetchAllStageData();
      this.fetchLeadCounts();
    },
    async fetchAllEmployees() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/employee/myTeam/Sales`);
        this.employees = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchEmployees() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/salesPersonSource/source/${this.sourceId}`
        );
        this.employees = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    downloadTemplate() {
      const link = document.createElement("a");
      link.href = "/Lead_Import_Template.csv"; // Path to the file in the public folder
      link.setAttribute("download", "Lead_Import_Template.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async fetchLeadCounts() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/leads/leadsCount/${this.sourceId}/${this.selectedEmployeeId}`
        );
        this.Data = response.data;
        this.leadCounts = response.data.leadCounts;
        this.seriess = [parseFloat(response.data.ratio.EstimateToSoldRatio)];
        const Categories = Object.keys(this.leadCounts);
        const data = Object.values(this.leadCounts);

        this.series = [
          {
            name: "Lead Counts",
            data: data,
          },
        ];

        // Update chart categories with keys from leadCounts
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            ...this.chartOptions.xaxis,
            categories: Categories,
          },
        };

        // Update last three projects
        this.lastThreeProjects = response.data.lastThreeProjects;
      } catch (error) {
        this.$handleError(error);
      }
    },
    isSalesManager() {
      return this.$LocalUser.employeeRoles.some(
        (role) => role.team === "Sales" && role.role === "Manager"
      );
    },
    changeSourceName() {
      const payload = {
        name: this.sourceData.name,
      };

      axios
        .put(`${this.$apiEndPoint}/source/${this.sourceId}`, payload)
        .then(() => {
          this.fetchSourceData();
        })
        .catch(() => {});
    },

    handleFileUpload(event) {
      this.$globalState.isLoading = true;
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = async (e) => {
          try {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            // Extract headers and check if they match default headers
            this.uploadedHeaders = jsonData[0];
            this.data.leads = jsonData.slice(1).map((row) => {
              const lead = {};
              this.uploadedHeaders.forEach((header, index) => {
                lead[header] = row[index] !== undefined ? row[index] : "";
              });
              return lead;
            });

            // Initialize headersMapping with default headers
            this.headersMapping = this.defaultHeaders.reduce((mapping, header) => {
              mapping[header] = this.uploadedHeaders.includes(header) ? header : ""; // Pre-fill if possible
              return mapping;
            }, {});

            this.showUnclaimedList = false;
            this.showBulkUploadModal = true;
            this.$globalState.isLoading = false;
          } catch (error) {
            this.$handleError(error);
          }
        };
      }
    },
    closeModal() {
      this.showBulkUploadModal = false;
      this.showUnclaimedList = true;
    },
    async approveBulkLeads() {
      const btn = document.querySelector(".confirmationButton");
      btn.classList.add("confirmationButton--loading");
      try {
        this.loading = true;

        // Map uploaded data to default headers based on user selection
        const mappedLeads = this.data.leads.map((lead) => {
          const mappedLead = {};
          Object.entries(this.headersMapping).forEach(
            ([defaultHeader, uploadedHeader]) => {
              mappedLead[defaultHeader] = uploadedHeader
                ? lead[uploadedHeader] || ""
                : "";
            }
          );
          return mappedLead;
        });

        const backendData = {
          leads: mappedLeads.map((lead) => ({
            name: lead.Name,
            email: lead.Email,
            contactNo: lead.ContactNo,
            propertyType: lead.PropertyType,
            propertyAddress: lead.Address,
          })),
        };

        const response = await axios.post(
          `${this.$apiEndPoint}/leads/bulkCreate/${this.sourceId}`,
          backendData
        );

        btn.classList.remove("confirmationButton--loading");
        if (response.status === 201) {
          this.$toast(`Bulk leads upload successful.`, "success");
          this.showBulkUploadModal = false;
          this.showUnclaimedList = true;
          this.fetchData();
          this.data.leads = [];
          this.loading = false;
        } else {
          this.loading = false;
        }
      } catch (error) {
        this.$handleError(error);
        this.loading = false;
        this.data.leads = [];
        this.showBulkUploadModal = false;
        this.showUnclaimedList = true;
        btn.classList.remove("confirmationButton--loading");
      }
    },

    convertToDDMMYYYY(date) {
      // Format date as "DD-MM-YYYY"
      const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day with leading zero
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two-digit month with leading zero
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },
    showTransactionModal() {
      this.showTransactionDetails = true;
    },

    hideTransactionDetails() {
      this.showTransactionDetails = false;
    },
    updateLeadStatus(itemId) {
      if (this.transactionDetails.trim() !== "") {
        const payload = {
          status: "Sold",
          transactionDetails: this.transactionDetails,
        };

        axios
          .put(`${this.$apiEndPoint}/leads/changeStatus/${itemId}`, payload)
          .then(() => {
            this.leadData.status = this.selectedAction; // Update local data
          })
          .catch(() => {});

        this.hideTransactionDetails();
        this.fetchAllStageData();
      }
    },

    dragStart(item, sourceStage, sourceItemIndex) {
      if (this.drag === true) {
        // Store the dragged item and its source stage
        this.draggedItem = item;
        this.sourceStage = sourceStage;
        this.sourceItemIndex = sourceItemIndex;
      }
    },

    dropItem(targetStageIndex) {
      if (this.drag === true) {
        const targetStage = this.stages[targetStageIndex];

        if (targetStage.title === "Sold") {
          this.currentleadId = this.draggedItem.id;
          if (this.draggedItem.estimateApprovalStatus > 0) {
            this.showTransactionDetails = true;
          } else {
            alert("To Sold Lead  The Approved Estimate of Lead Should Prasent");
          }
        }

        if (this.sourceStage !== targetStage && targetStage.title !== "Sold") {
          this.sourceStage.items.splice(this.sourceItemIndex, 1);
          targetStage.items.push(this.draggedItem);
          this.drag = false;
          // Make an API call to update the status
          this.updateStatus(targetStage, this.draggedItem.id); // Pass the item id
        }
      }
    },

    showEmployeeName() {
      // Show the dropdown
      this.showDropdown = true;
    },
    // Hide employee name dropdown on mouseleave
    hideEmployeeName() {
      // Hide the dropdown
      this.showDropdown = false;
    },
    async fetchCollaboratorData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/salesPersonSource/source/${this.sourceId}`
        );
        this.collaborators = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    toggleProjectNameDropDown() {
      this.showProjectNameDropDown = !this.showProjectNameDropDown;
    },
    async fetchAllStageData() {
      this.cardLoading = true;
      let searchData = {
        searchString: this.searchString,
      };
      const hasSearchData = searchData.searchString;
      if (hasSearchData && pageFlag === true) {
        pageFlag = false;
      }
      axios
        .post(
          `${this.$apiEndPoint}/leads/byStatusAndSourceId/All/${this.sourceId}/${this.selectedEmployeeId}/All`,
          searchData
        )
        .then((response) => {
          this.AllStageData = response.data.rows;

          this.filterLeadsByStage();
        })
        .catch((error) => {
          this.$handleError(error);
        });
      // const promises = this.stages.map((stage) => this.fetchStageData(stage));
      // await Promise.all(promises);
    },
    filterLeadsByStage() {
      this.stages.forEach((stage) => {
        // Filter leads with a status matching the stage title
        stage.items = this.AllStageData.filter((lead) => lead.status === stage.title);
      });
      this.cardLoading = false;
    },
    async fetchStageData(stage) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/leads/byStatusAndSourceId/All/${this.sourceId}/${this.selectedEmployeeId}/`
        );
        stage.items = response.data;
        this.drag = true;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async updateStatus(stage, itemId) {
      try {
        // Make an API call to update the status
        await axios.put(`${this.$apiEndPoint}/leads/changeStatus/${itemId}`, {
          status: stage.title,
        });
        this.fetchStageData(stage); // Fetch updated data for the stage
      } catch (error) {
        this.$handleError(error);
      }
    },

    openModal(leadId) {
      this.selectedLeadId = leadId;
    },
    NullSelectedLeadValue() {
      this.selectedLeadId = null;
    },

    SigleLeadThingstd(lead) {
      this.selectedLead = lead;
      this.leadDropdownStates[lead.id] = !this.leadDropdownStates[lead.id];
    },
    toggletabledrowpdown() {
      this.tabledropdownEdits = !this.tabledropdownEdits;
    },
    toggleOverviewLead() {
      this.fetchLeadCounts();
      this.showUnclaimedList = false;
      this.showClaimedList = false;
      this.showArchiveList = false;
      this.showOverview = true;
    },
    toggleClaimedList() {
      this.showArchiveList = false;
      this.showOverview = false;
      this.showUnclaimedList = false;
      this.showClaimedList = true;
    },
    toggleUnclaimedLead() {
      this.showOverview = false;
      this.showClaimedList = false;
      this.showUnclaimedList = true;
    },

    handleArchiveClick() {
      this.fetchArchiveData();
      this.showUnclaimedList = !this.showUnclaimedList;
      this.showArchiveList = !this.showArchiveList;
    },

    async fetchData() {
      this.cardLoading = true;
      let searchData = {
        searchString: this.searchString,
      };
      const hasSearchData = searchData.searchString;
      if (hasSearchData && pageFlag === true) {
        this.currentPage = 1;
        pageFlag = false;
      }
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/leads/byStatusAndSourceId/FreshLead/${this.sourceId}/All/${this.currentPage}`,
          searchData
        );
        this.totalCount = response.data.count;
        this.TableData = response.data.rows;
        this.cardLoading = false;
        this.fetchAllStageData();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchArchiveData() {
      this.cardLoading = true;
      let searchData = {
        searchString: this.searchString,
      };
      const hasSearchData = searchData.searchString;
      if (hasSearchData && pageFlag === true) {
        this.currentPage = 1;
        pageFlag = false;
      }
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/leads/byStatusAndSourceId/FreshLead/${this.sourceId}/All/${this.currentPage}/archived`,
          searchData
        );
        this.totalArchiveCount = response.data.count;
        this.ArchiveData = response.data.rows;
        this.cardLoading = false;
      } catch (error) {
        this.$handleError(error);
      }
    },
    //select and unselect checkbox function
    selectAllRows() {
      if (this.selectAll) {
        this.selectedCustomers = this.displayedData.map((customer) => customer.id);
      } else {
        this.selectedCustomers = [];
      }
    },
    selectAllArchiveRows() {
      if (this.selectAll) {
        this.selectedCustomers = this.displayedArchiveData.map((customer) => customer.id);
      } else {
        this.selectedCustomers = [];
      }
    },
    // Method to delete the selected lead
    UnclaimLead(data, index) {
      this.TableData.splice(index, 1);
      this.ArchiveData.splice(index, 1);
      axios
        .put(`${this.$apiEndPoint}/leads/claimLead`, { id: [data.id] })
        .then(() => {
          this.fetchAllStageData();
          this.fetchData();
          this.fetchArchiveData();

          this.leadDropdownStates[data.id] = false; // Hide dropdown after action
        })
        .catch(() => {});
    },

    // Method to delete the selected lead
    async DeleteLead(data, index) {
      const result = await this.$showAlert(
        "Are you sure?",
        "Are you sure you want to delete?",
        "warning",
        "Yes, delete it",
        "Cancel"
      );

      // If the user clicked Cancel, don't proceed
      if (!result.isConfirmed) return;
      this.TableData.splice(index, 1);
      this.ArchiveData.splice(index, 1);
      axios
        .delete(`${this.$apiEndPoint}/leads/deleteLeads`, {
          data: { id: [data.id] },
        })
        // Pass an array with a single ID to match the API's expected format
        .then(() => {
          this.fetchData();
          this.fetchArchiveData();
          this.leadDropdownStates[data.id] = false; // Hide dropdown after action
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    ArchiveLead(data, index) {
      this.TableData.splice(index, 1);
      this.ArchiveData.splice(index, 1);
      axios
        .put(`${this.$apiEndPoint}/leads/archive`, { id: [data.id] })
        // Pass an array with a single ID to match the API's expected format
        .then(() => {
          this.fetchData();
          this.fetchArchiveData();
          this.leadDropdownStates[data.id] = false; // Hide dropdown after action
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    searchArchiveLeads() {
      pageFlag = true;
      this.fetchArchiveData();
    },

    //for Serch api for Unclaimleads
    searchLeads() {
      pageFlag = true;
      this.fetchData();
    },
    //for all selected lead Unclaim and delete api

    UnclaimLeads(action) {
      if (this.selectedCustomers.length === 0) {
        return;
      }
      if (action === "Archive") {
        this.TableData = this.TableData.filter(
          (customer) => !this.selectedCustomers.includes(customer.id)
        );
        this.selectAll = false;
      } else if (action === "UnArchive") {
        this.ArchiveData = this.ArchiveData.filter(
          (customer) => !this.selectedCustomers.includes(customer.id)
        );
        this.selectAll = false;
      }
      const payload = {
        id: this.selectedCustomers,
        // Assuming salesPersonId needs to be provided
      };

      axios
        .put(`${this.$apiEndPoint}/leads/claimLead`, payload)
        .then(() => {
          this.fetchAllStageData();
          this.fetchData();
          this.fetchArchiveData();

          this.selectAll = false;
          this.tabledropdownEdits = false; // Close the dropdown after action
          this.selectedCustomers = []; // Clear selected customers
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    ArchiveLeads(action) {
      if (this.selectedCustomers.length === 0) {
        return;
      }
      if (action === "Archive") {
        this.TableData = this.TableData.filter(
          (customer) => !this.selectedCustomers.includes(customer.id)
        );
        this.selectAll = false;
      } else if (action === "UnArchive") {
        this.ArchiveData = this.ArchiveData.filter(
          (customer) => !this.selectedCustomers.includes(customer.id)
        );
        this.selectAll = false;
      }
      const payload = {
        id: this.selectedCustomers,
      };

      axios
        .put(`${this.$apiEndPoint}/leads/archive`, payload)
        .then(() => {
          this.selectedCustomers = [];
          this.fetchData();
          this.fetchArchiveData();
          this.selectAll = false;
          this.tabledropdownEdits = false;
        })
        .catch(() => {});
    },

    async deleteLead(action) {
      if (this.selectedCustomers.length === 0) {
        return;
      }
      const result = await this.$showAlert(
        "Are you sure?",
        "Are you sure you want to delete?",
        "warning",
        "Yes, delete it",
        "Cancel"
      );

      // If the user clicked Cancel, don't proceed
      if (!result.isConfirmed) return;
      if (action === "Archive") {
        this.TableData = this.TableData.filter(
          (customer) => !this.selectedCustomers.includes(customer.id)
        );
        this.selectAll = false;
      } else if (action === "UnArchive") {
        this.ArchiveData = this.ArchiveData.filter(
          (customer) => !this.selectedCustomers.includes(customer.id)
        );
        this.selectAll = false;
      }
      const payload = {
        id: this.selectedCustomers,
      };

      axios
        .delete(`${this.$apiEndPoint}/leads/deleteLeads`, { data: payload })
        .then(() => {
          this.selectedCustomers = [];
          this.fetchData();
          this.fetchArchiveData();
          this.selectAll = false;
          this.tabledropdownEdits = false;
        })
        .catch(() => {});
    },

    //footer pagination

    goToPage(pageNumber) {
      this.selectAll = false;
      this.currentPage = pageNumber;

      this.fetchData();
      this.fetchArchiveData();
      this.selectedCustomers = [];
    },
    changePage(delta) {
      this.selectAll = false;
      this.lastPages = this.currentPage;
      this.currentPage += delta;
      this.fetchData();
      this.fetchArchiveData();
      this.selectedCustomers = [];
    },
    goToPreviousPage() {
      if (this.hasPreviousPage) {
        this.changePage(-1); // Go to the previous page
      }
    },
    goToNextPage() {
      if (this.hasNextPage) {
        this.changePage(1); // Go to the next page
      }
    },
    goToArchivePreviousPage() {
      if (this.hasArchivePreviousPage) {
        this.changePage(-1); // Go to the previous page
      }
    },
    goToArchiveNextPage() {
      if (this.hasArchiveNextPage) {
        this.changePage(1); // Go to the next page
      }
    },

    //featch all source
    async fetchSourceData() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/source/${this.sourceId}`);
        this.sourceData = response.data;
        this.sourceName = this.sourceData.name;
      } catch (error) {
        this.$handleError(error);
      }
    },
    // Redirect to the dial pad with the provided phone number
    makePhoneCall(phoneNumber) {
      window.location.href = "tel:" + phoneNumber;
    },

    searchAndFillLeadDetails() {
      // Make an API call to search for leads
      axios
        .get(`${this.$apiEndPoint}/employee/`, {
          params: {
            search: this.routeParams.Member,
          },
        })
        .then((response) => {
          // Filter leads based on the presence of alphabets in their names
          this.searchResults = response.data.filter((lead) =>
            this.containsAlphabets(lead["users.firstname"])
          );
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    selectClient(client) {
      // Toggle selection status
      const index = this.selectedEmployees.findIndex((item) => item.id === client.id);

      if (index === -1) {
        // Add to selected employees
        this.selectedEmployees.push({
          id: client.id,
          name: client["users.firstname"],
        });
      } else {
        // Remove from selected employees
        this.selectedEmployees.splice(index, 1);
      }

      // Update the input field with selected names
      this.updateInputField();
    },

    updateInputField() {
      this.routeParams.Member = this.selectedEmployees
        .map((employee) => employee.name)
        .join(", ");
    },

    containsAlphabets(str) {
      // Check if the string contains alphabets
      const regex = /[a-zA-Z]/;
      return regex.test(str);
    },

    async fetchEmployeeList() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/employee`);
        this.teamMembers = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    toggleDeleteProperty() {
      this.isSharewithModalOpen = false;
      this.isInviteModalOpen = false;
      this.PropertyDeleteModal = !this.PropertyDeleteModal;
    },
    closeDelete() {
      this.PropertyDeleteModal = false;
    },
    toggleTeamMembers() {
      this.showTeamMember = !this.showTeamMember;
      this.showCollaborators = false;
    },
    toggleCollaboratorsMember() {
      this.showCollaborators = !this.showCollaborators;
      this.showTeamMember = false;
    },
    toggleInviteModal() {
      this.PropertyDeleteModal = false;
      this.isSharewithModalOpen = false;
      this.isInviteModalOpen = !this.isInviteModalOpen;
    },
    toggleInviteModal1() {
      this.dropdownOpen = false;
      this.isSharewithModalOpen = false;
      this.isInviteModalOpen1 = !this.isInviteModalOpen1;
    },
    closeInviteModal() {
      this.isInviteModalOpen1 = false;
      this.isInviteModalOpen = false;
    },

    toggleDropdown(index) {
      if (this.dropdownOpen === index) {
        this.dropdownOpen = null;
      } else {
        this.dropdownOpen = index;
      }
    },
    toggleStatusDropdown() {
      this.statusDropdownOpen = !this.statusDropdownOpen;
    },
    toggleShareWithModal() {
      this.PropertyDeleteModal = false;
      this.isInviteModalOpen = false;
      this.isSharewithModalOpen = !this.isSharewithModalOpen;
    },
    closeShareWithModal() {
      this.isSharewithModalOpen = false;
    },

    changeStatus(status) {
      this.selectedStatus = status;
      this.statusDropdownOpen = false;
      this.dropdownOpen = true;
    },

    exportToCSV() {
      // Combine displayedData and displayedArchiveData based on your current view
      const allData = this.showArchiveList ? this.ArchiveData : this.TableData;

      // Extract only the fields you want to include in the CSV
      const filteredData = allData.map((data) => ({
        Name: data.name || "N/A",
        Date: data.date || "N/A",
        "Contact No": data.contactNo || "N/A",
        "Email ID": data.email || "N/A",
        "Property Address": this.formatAddress(data["leadAddresses.address"]) || "N/A",
        Source: data["sources.name"] || "N/A",
        Priority: data.priority || "N/A",
      }));

      // Convert filtered data to CSV format
      const csvContent = this.convertToCSV(filteredData);

      // Trigger download
      this.downloadCSV(csvContent);
    },

    formatAddress(address) {
      // Replace newline characters with commas
      return address ? address.replace(/\n/g, ", ") : "N/A";
    },

    convertToCSV(data) {
      const headers = Object.keys(data[0]).join(",") + "\n";
      const csvData = data.map((item) => Object.values(item).join(",")).join("\n");
      return headers + csvData;
    },

    downloadCSV(csvContent) {
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "table_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // Method to archive an email
  },
};
</script>
<style scoped>
#transactionDetails {
  border: 2px solid #000000;
  outline: none;
  margin-top: 10px;
}

.transaction-details {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 400px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  text-decoration: underline;
}

.sourceName::-webkit-scrollbar {
  display: none;
}

.sourceName:hover {
  outline: 1px solid #ccc;
  border-radius: 5px;
}

.showProjectNameDropDown {
  position: fixed;
  top: 13%;
  left: 24%;
  border-radius: 5px;
  background-color: #ffffff;
  z-index: 99;
  border: 1px solid #333;
  width: 130px;
  height: 80px;
  max-height: fit-content;
}

.showProjectNameDropDown ol {
  list-style-type: none;
  padding: 2% 5%;
}

.showProjectNameDropDown ol li {
  font-weight: 600;
  color: #333;
  margin: 5% 2%;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  padding: 1%;
}

.showProjectNameDropDown ol li:hover {
  background-color: #f8f8f8;
  border-radius: 5px;
}

/* Style for horizontal scrolling container */
.scrollable-container {
  max-width: 98%;
  margin: 0.5% 1%;
  padding: 0 0.5%;
  height: 86%;
  overflow-x: auto;
  z-index: 9;
  /* Enable horizontal scrolling */
  overflow-y: hidden;
  /* Disable vertical scrolling */
}

.headerOfCard {
  z-index: 999;
  position: sticky;
  top: 10%;
  background-color: #ffffff;
}

/* Style for stage container */
.DesignStageContainer {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  z-index: 9;
  background-color: #ffffff;
}

/* Hide the scrollbar for the stage container */
.DesignStageContainer::-webkit-scrollbar {
  display: none;
}

/* Style for individual stage cards */
.cardThings {
  width: 330px;
  z-index: 9;
}

/* Ensure the left section of the card (images) retains proper sizing */
.LeadCardLeft img {
  width: 80%;
}

.LeadCardLeft img:nth-child(1) {
  width: 120%;
}

/* Style for the dot in the card header */
.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

/* Style for the card container */
.card-container {
  min-height: 90%;
  max-height: 90%;
  margin-bottom: 2vh;
  max-width: 320px;
  z-index: 1;
  border: none;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  /* Changed to auto for automatic scrollbar when needed */
}

/* Style for the card body */
.Profile {
  border-radius: 10px;

  padding: 5px;
}

.container-Board {
  width: 100%;
  height: 87%;
  overflow-x: scroll;
  position: relative;
}

.container-Board::-webkit-scrollbar {
  display: none;
}

.ClaimedlistBoard {
  max-height: 100%;
  max-width: 97%;
  position: relative;
}

.LeadListCard::-webkit-scrollbar,
.ClaimedlistBoard::-webkit-scrollbar {
  display: none;
}

.sales-source-component {
  width: 100%;
  height: 100%;
  position: relative;
  /* changed from scroll to auto for better UX */
}

.profile-image {
  width: 50px;
  /* Adjust width as needed */
  height: 50px;
  margin: 1%;

  /* Maintain aspect ratio */
}

.profile-icon {
  width: 40%;
  /* Adjust width as needed */
  height: auto;
  margin-left: 10%;
  /* Maintain aspect ratio */
}

.progress-icon {
  width: 6%;
  /* Adjust width as needed */
  height: auto;
  /* Maintain aspect ratio */
}

.CollabratorSection {
  /* Center horizontally */
  display: flex;
  text-align: start;
}

.StatusDiv {
  /* Align items at the top */
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.employee-name-dropdown {
  top: 0;
}

/* Style for profile images */
.profile-image {
  width: 40px;
  height: 40px;

  position: relative;
  z-index: 1;
}

.collaborator-avatar {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: left;
  margin-right: -5px;

  /* Adjust margin to control the overlap */
}

/* Style for initials */
.initials {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #4884f7; /* Blue background color */
  color: #ffffff; /* White text color */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  z-index: 1;
  margin-right: -4px; /* Adjust margin to control the overlap */
  position: relative; /* Ensure z-index works correctly */
  border: 2px solid white;
}
.additional-count {
  width: 15px;
  /* Adjust size as needed */
  height: 15px;
  /* Adjust size as needed */
  border-radius: 50%;
  /* Make the container circular */
  background-color: #b1c5df;
  /* Provide a background color */
  color: #000000;
  /* Set text color */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  /* Adjust font size as needed */
  cursor: pointer;

  z-index: 2;
}
/* Styles for employee name dropdown */
.employee-name-dropdown {
  position: absolute;
  top: 60px;
  /* Adjust distance from avatars */
  left: 0;
  background-color: #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 5px;
  border-radius: 5px;
}

/* Styles for individual employee names */
.employee-name {
  padding: 5px;
  cursor: pointer;
  color: #000000;
}

/* Hide dropdown by default */
.employee-name-dropdown {
  display: none;
}

.leadCardFooter img {
  width: 20px;
  height: 20px;
  cursor: pointer;
  aspect-ratio: 3/2;
  object-fit: contain;
  padding-top: 5px;
}

.leadCardFooter img:nth-child(2) {
  width: 20px;
  height: 17px;
  cursor: pointer;
  aspect-ratio: 3/2;
  object-fit: contain;
}

.ClaimedlistBoard {
  max-height: 100%;
  max-width: 97%;
  position: relative;
}

.LeadBlock {
  overflow: scroll;
}

.LeadBlock::-webkit-scrollbar {
  display: none;
}

.sales-source-component {
  width: 100%;
  height: 100%;
  /* changed from scroll to auto for better UX */
}

.sourceProfile {
  position: sticky;
  top: 0;
  z-index: 99;
  border-bottom: 4px solid #ccc;
  background-color: #ffffff;
}

.sourceProfile::-webkit-scrollbar {
  display: none;
}

.profile-image {
  width: 50px;
  /* Adjust width as needed */
  height: 50px;
  margin: 1%;

  /* Maintain aspect ratio */
}

.profile-icon {
  width: 20px;
  /* Adjust width as needed */
  height: auto;
  margin-left: 10%;
  /* Maintain aspect ratio */
}

.progress-icon {
  width: 6%;
  /* Adjust width as needed */
  height: auto;
  /* Maintain aspect ratio */
}

.StatusDiv {
  white-space: nowrap;
  margin-right: 20%;
  font-size: 14px;
}

.StatusDiv img {
  width: 12px;
  height: 12px;
}

.dropdown-menu {
  z-index: 999;
}

#checkBox2 {
  width: 16px;
  height: 17px;
}

.BoardHeader {
  display: flex;
  justify-content: space-between;
  /* Ensure items are spaced apart */
  align-items: center;
  /* Center items vertically */
  white-space: nowrap;
  /* Prevent wrapping */
}

.bolderHeadings {
  font-size: 16px;

  color: #000000;
  font-weight: bolder;
}

.tabledropdownEdits {
  position: absolute;
  display: inline-block;
  background-color: #ffffff;
  width: 150px;
  height: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 10px 20px;
  z-index: 99;
  top: 60%;
}

.tabledropdownEdits p {
  padding: 4px 8px;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: #000000;
}

.SigleLeadThings {
  position: absolute;
  display: inline-block;
  background-color: #ffffff;
  width: 150px;
  height: 240px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 10px 20px;
  z-index: 999;
  top: 60%;
}

.SigleLeadThings p {
  padding: 4px 8px;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: #000000;
}

.SourceNameThings {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.SourceNameThings div {
  cursor: pointer;
}

.modal.show {
  display: block;

  /* Semi-transparent background */
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  margin: auto;
}

.tableFormat:nth-child(2) {
  cursor: pointer;
}

.btn-priority-low {
  background-color: #f4e8fb;
  color: #ba69e2;
}

.btn-priority-medium {
  background-color: #ecebec;
  color: #7e7882;
}

.btn-priority-high {
  background-color: #feabab;
  color: #fd0000;
}

.btn-priority-Normal {
  background-color: #eaf3f1;
  color: #4b9b86;
}

#LeadFollowDate {
  color: rgb(107, 60, 60);
}

.FormatTable {
  height: calc(100% - 74px);
  width: calc(100% - 74px);
  table-layout: fixed;
  position: fixed;
  display: flex;
  flex-direction: column;
}

.tableFormat:nth-child(2) {
  cursor: pointer;
}

.headerOfTable {
  width: calc(100% - 74px);
  padding: 0.5% 1%;
}

.table-wrapper {
  width: 100%;
  padding: 0 1%;
  overflow-x: auto;
  overflow-y: auto;
  height: 76%;
  padding-bottom: 20px;
}

.tableFormat {
  width: 100%;
  border-collapse: collapse;
}

.tableFormat th:nth-child(1),
.tableFormat td:nth-child(1) {
  display: inline-flex;

  background-color: #ffffff;
}

.tableFormat thead {
  background-color: #bcbfc6;
  position: sticky;
  z-index: 99;
  top: 0;
}

.tableFormat thead tr th:not(:first-child) {
  background-color: #bcbfc6;
}

.tableFormat th,
.tableFormat td {
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  /* Prevent text wrapping */
}

#blackThreeDots {
  width: 15px;
  height: 14px;
  margin-left: 30%;
  position: relative;
  cursor: pointer;
  margin-bottom: 5px;
}

.tableHeaderCheckbox,
.bodyCheckbox {
  width: 200px;
  /* Adjust width as needed */
}

.tableHeaderHeading {
  min-width: 150px;
  /* Minimum width to ensure readability */
  text-align: center;
  /* Center align headers */
}

.bodyText {
  text-align: center;
  /* Center align body text */
}

.custom-checkbox input[type="checkbox"] {
  margin-right: 5px;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
}
.dropdown-menu ul {
  list-style: none; /* Optional: Remove bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.table-wrapper
  .tableFormat
  tbody
  tr:not(:first-child):nth-child(even)
  td:not(:first-child) {
  background-color: #f8f8f8;
}

#ImportIcon {
  width: 20px;
  cursor: pointer;
}

#ArchiveIcon {
  width: 15px;
  cursor: pointer;
}

#SerchIconOfTable {
  width: 6%;
  margin-right: 5%;
}

.search-box {
  border-bottom: 2px solid #000;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
}

.searchboxOfTable {
  width: 100%;
  display: flex;
  align-items: center;
}

#FilterIconOfTable {
  width: 20%;
  height: 5%;
  margin-left: 10%;
}

input[type="text"] {
  border: none;
  outline: none;
  font-size: 16px;
  width: 100%;
  text-align: left;
}

#addicon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
}
.button-container button {
  font-size: 12px;
}
.button-icon {
  width: 16px; /* Adjust size as needed */
  height: 16px; /* Adjust size as needed */
  margin-left: 5px; /* Space between image and text */
  vertical-align: middle; /* Aligns the image with the text */
}

@media (max-width: 768px) {
  /* Styles for mobile devices */

  .showProjectNameDropDown {
    position: fixed;
    top: 10%;
    left: 24%;
    border-radius: 5px;
    background-color: #f5f5f5;
    z-index: 999;
    border: 1px solid #333;
    width: 130px;
    height: 110px;
  }

  .table-wrapper {
    max-height: 85%;
    overflow-y: auto;
  }

  .leadCardFooter {
    margin-left: 40%;
    display: flex;
    align-items: center;
  }

  .leadCardFooter img {
    width: 80%;
    cursor: pointer;
    aspect-ratio: 3/2;
    object-fit: contain;
  }

  .leadCardFooter img:nth-child(2) {
    width: 40%;
    cursor: pointer;
    aspect-ratio: 3/2;
    object-fit: contain;
    margin-right: 3%;
  }

  .LeadBlock {
    min-width: 90%;
  }

  .progress-icon {
    width: 20%;
  }

  .CollabratorSection {
    display: flex;
    flex-direction: column;
    margin-top: 1%;
  }

  .profile-icon {
    display: none;
  }

  .LeadCardLeft img {
    width: 20px;
  }

  .LeadCardLeft img:nth-child(1) {
    width: 25px;
  }

  .SourceName {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .SourceNameThings {
    display: flex;
    flex-direction: row;
  }

  .SourceNameThings div {
    padding: 3%;
    cursor: pointer;
  }

  .CollabratorSection {
    margin-left: 10%;
  }

  .headerOfTable {
    padding: 0.5% 1%;
    width: auto;
    overflow: scroll;
  }

  .table-wrapper {
    max-height: 90%;
    /* Adjust as needed */
    overflow-y: auto;
  }

  .headerOfTable {
    width: 100%;
    overflow: scroll;
  }

  #ArchiveIcon {
    width: 30%;
  }

  .ImportExport {
    display: none;
  }

  .scrollable-container {
    overflow-x: scroll;
    width: 120%;
    position: fixed;
    height: 80%;
  }

  .tableFormat th,
  .tableFormat td {
    font-size: 12px;
    /* Adjust font size for smaller screens */
  }

  .tableHeaderCheckbox,
  .bodyCheckbox {
    width: 150px;
    /* Adjust width for smaller screens */
  }

  #addicon {
    width: 100%;
  }

  .table-wrapper {
    max-height: 90%;
    /* Adjust as needed */
    overflow-y: auto;
  }

  .headerOfTable {
    width: 100%;
    overflow: scroll;
  }

  #ArchiveIcon {
    width: 30%;
  }

  .ImportExport {
    display: none;
  }

  .paginationOfTable {
    bottom: 0.5%;
  }
}
</style>
