<template>
  <div>
    <!-- Share Modal -->
    <div
      class="modal fade"
      id="PibShareModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="PibShareModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header d-flex align-items-center">
            <!-- <input
              type="text"
              class="form-control me-3"
              placeholder="Enter email addresses (comma-separated)"
              v-model="emailInput"
            />
            <button class="btn btn-primary" @click="sendEmail">Share</button> -->
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="d-flex align-items-center">
              <input
                type="text"
                class="form-control me-3"
                placeholder="Enter email addresses (comma-separated)"
                v-model="emailInput"
              />
              <button class="btn btn-primary" @click="sendEmail">Share</button>
            </div>
            <div class="mt-3">
              <h6>Shared With</h6>
              <div
                v-for="(user, index) in Emails"
                :key="user.id"
                class="d-flex align-items-center justify-content-between mb-3"
              >
                <div class="d-flex align-items-center">
                  <!-- <img
                    :src="user.photoUrl"
                    alt="User Photo"
                    class="rounded-circle me-2"
                    style="width: 40px; height: 40px; object-fit: cover"
                  /> -->
                  <span>{{ user }}</span>
                </div>
                <button class="btn btn-outline-info btn-sm" @click="removeAccess(index)">
                  Remove Access
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="input-group">
              <input
                type="text"
                id="shareLinkInput"
                class="form-control"
                :value="shareLink"
                readonly
              />
              <button class="btn btn-outline-secondary" @click="copyLink">
                Copy Link
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    designProjectId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      shareLink: "",
      emailInput: "",
      shareTitle: "",
      Emails: [],
      // sharedUsers: [
      //   { id: 1, name: "John Doe", photoUrl: "https://via.placeholder.com/40" },
      //   { id: 2, name: "Jane Smith", photoUrl: "https://via.placeholder.com/40" },
      // ],
    };
  },
  watch: {
    designProjectId(newId) {
      this.fetchShareLink(newId);
      this.featchShare(newId);
    },
  },
  mounted() {
    this.fetchShareLink(this.designProjectId);
    this.featchShare(this.designProjectId);
  },
  methods: {
    featchShare(Id) {
      axios
        .get(`${this.$apiEndPoint}/projectInfoBoxSection/sharedEmails/${Id}`)
        .then((response) => {
          this.Emails = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    openModal() {
      this.fetchShareLink(this.designProjectId);
    },
    fetchShareLink(projectId) {
      const apiUrl = `${this.$apiEndPoint}/projectInfoBoxSection/generatelink`;
      axios
        .post(apiUrl, { designProjectId: projectId })
        .then((response) => {
          this.shareLink = response.data.link;
        })
        .catch((error) => {
          this.$handleError("Error generating link:", error);
        });
    },
    copyLink() {
      navigator.clipboard.writeText(this.shareLink).then(() => {
        alert("Link copied to clipboard!");
      });
    },
    sendEmail() {
      const apiUrl = `${this.$apiEndPoint}/projectInfoBoxSection/sendPib`;
      const emailList = this.emailInput.split(",").map((email) => email.trim());
      console.log(emailList);
      console.log(this.designProjectId);

      if (emailList.length > 0 && this.validateEmails(emailList)) {
        axios
          .post(apiUrl, {
            customerEmailId: emailList,
            designProjectId: this.designProjectId,
          })
          .then(() => {
            alert("Email sent successfully!");
            this.emailInput = " ";
            this.featchShare(this.designProjectId);
          })
          .catch((error) => {
            this.$handleError(error);
          });
      } else {
        alert("Please enter valid email addresses.");
      }
    },
    validateEmails(emailList) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailList.every((email) => emailRegex.test(email));
    },
    removeAccess(index) {
      console.log(this.Emails);
      this.Emails.splice(index, 1); // Remove user from Emails array
      alert("Access removed ");
      console.log(this.Emails);
      this.UpdatemailId();
    },
    UpdatemailId() {
      console.log(this.Emails);
      try {
        const response = axios.put(
          `${this.$apiEndPoint}/projectInfoBoxSection/removeAccess/${this.designProjectId}`,
          {
            customerEmailId: this.Emails,
          }
        );
        console.log(response.data);
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none;
  box-shadow: none;
}

.modal-dialog {
  max-width: 500px;
  margin: 30px auto;
}

.modal-header {
  background-color: #f7f7f7;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-footer {
  background-color: #f7f7f7;
  border-top: 1px solid #ddd;
}

.input-group .form-control {
  border-radius: 5px 0 0 5px;
}

.input-group .btn {
  border-radius: 0 5px 5px 0;
}

.btn-outline-secondary {
  background-color: #f8f9fa;
}

button {
  font-weight: 600;
}

.btn-close {
  font-size: 1rem;
  margin-left: 1%;
}

.form-label {
  font-weight: 600;
}

.form-control {
  border-radius: 5px;
}

.shared-users {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .modal-dialog {
    max-width: 90%;
  }
}
</style>
