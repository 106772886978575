<template>
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-decoration-underline" id="exampleModalLabel">
            Create Source
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="mb-3">
              <div class="row">
                <div class="col-md-3">
                  <label for="inputName" class="form-label">Source Name:</label>
                </div>
                <div class="col-md-9">
                  <input
                    v-model="name"
                    type="text"
                    class="form-control inputbg"
                    id="inputName"
                    placeholder="Enter Name"
                    @input="validateSetValue"
                    required
                  />
                  <div v-if="!isValidSetValue" class="text-danger">
                    Enter Source is already exist.
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="row">
                <div class="col-md-3">
                  <label for="inputAssignee" class="form-label">Assign To:</label>
                </div>
                <div class="col-md-9">
                  <div
                    v-if="selectedAssignees.length > 0"
                    style="
                      font-size: 14px;
                      border: 2px solid #000;
                      margin: 5px;
                      padding: 5px;
                      border-radius: 5px;
                    "
                  >
                    <div
                      v-for="(employeeId, index) in selectedAssignees"
                      :key="index"
                      class="selected-employee"
                    >
                      {{ getEmployeeFullName(employeeId) }}
                      <span @click="removeEmployee(index)" class="remove-employee"
                        >&#10005;</span
                      >
                    </div>
                  </div>
                  <div>
                    <select
                      v-if="searchText.length === 0"
                      v-model="selectedEmployee"
                      class="form-control inputbg"
                      id="inputAssignee"
                      @change="addEmployee"
                    >
                      <option disabled value="">Select an Employee</option>
                      <option
                        v-for="employee in filteredEmployees"
                        :key="employee.employeeId"
                        :value="employee.employeeId"
                        class="EmployeeList"
                      >
                        {{ getEmployeeFullName(employee.employeeId) }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            class="btn btn-primary"
            @click="saveRecord"
            data-bs-dismiss="modal"
            :disabled="name.trim().length === 0"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "SourceCreate",
  data() {
    return {
      name: "",
      searchText: "",
      selectedEmployee: "",
      selectedAssignees: [],
      employees: [],
      SourceData: [],
      isValidSetValue: true,
    };
  },
  computed: {
    filteredEmployees() {
      return this.employees.filter((employee) => {
        const fullName = `${employee["employees.firstName"]} ${employee["employees.lastName"]}`;
        return fullName.toLowerCase().includes(this.searchText.toLowerCase());
      });
    },
  },
  created() {
    if (this.$LocalUser && this.$LocalUser.user) {
      // If $LocalUser and $LocalUser.user are defined
      this.fetchEmployees();
      this.fetchSource();
    } else {
      // If $LocalUser or $LocalUser.user is not defined
      console.warn("User is not logged in or LocalUser is not defined.");
    }
  },
  methods: {
    validateSetValue() {
      const value = this.name.trim().toLowerCase();
      this.isValidSetValue = !this.SourceData.some(
        (source) => source.name.toLowerCase() === value
      );
    },

    async fetchSource() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/source/`);
        this.SourceData = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchEmployees() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/employee/myTeam/Sales`);
        const currentUserId = this.$LocalUser.user.employeeId;
        // Filter out the current user from the list of employees
        this.employees = response.data.filter(
          (employee) => employee.employeeId !== currentUserId
        );
      } catch (error) {
        this.$handleError("Error fetching employees:", error);
      }
    },

    searchEmployees() {
      // Filter employees based on searchText
      // This function can be left empty if the filtering is done client-side
    },
    addEmployee() {
      if (this.selectedEmployee) {
        const employeeIndex = this.filteredEmployees.findIndex(
          (emp) => emp.employeeId === this.selectedEmployee
        );
        if (employeeIndex !== -1) {
          this.filteredEmployees.splice(employeeIndex, 1); // Remove the selected employee from filteredEmployees
        }
        this.selectedAssignees.push(this.selectedEmployee);
        this.selectedEmployee = ""; // Clear the selectedEmployee after adding
      }
    },

    removeEmployee(index) {
      const removedEmployeeId = this.selectedAssignees.splice(index, 1)[0]; // Remove the employee ID from selectedAssignees
      const employee = this.employees.find((emp) => emp.employeeId === removedEmployeeId);
      if (employee) {
        this.filteredEmployees.push(employee); // Push the removed employee back to filteredEmployees
        this.filteredEmployees.sort((a, b) => a.employeeId - b.employeeId); // Sort the list to maintain consistency
      }
    },

    getEmployeeFullName(employeeId) {
      const employee = this.employees.find((emp) => emp.employeeId === employeeId);
      return employee
        ? `${employee["employees.firstName"]} ${employee["employees.lastName"]}`
        : "";
    },
    async saveRecord() {
      const data = {
        name: this.name,
      };

      try {
        const response = await axios.post(`${this.$apiEndPoint}/source/`, data);
        const sourceId = response.data.id;
        this.selectedAssignees.push(this.$LocalUser.user.employeeId);

        if (this.selectedAssignees.length > 0) {
          // Check if the array is not empty
          const requestData = {
            salesPersonId: this.selectedAssignees, // Array of selected salesPersonIds
            sourceId: sourceId,
          };
          try {
            await axios.post(`${this.$apiEndPoint}/salesPersonSource/`, requestData);
          } catch (error) {
            this.$handleError(error);
          }
        } else {
          this.$handleError("No sales person selected."); // Handle the case when no sales person is selected
        }

        this.name = " ";
        this.selectedAssignees = [];
        this.$emit("sourceCreated");
        this.$toast(`Source created successfully..`, "success");
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>
<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}
/* Add your scoped styles here */
.selected-employee {
  margin-bottom: 5px;
}

.remove-employee {
  cursor: pointer;
  margin-left: 5px;
}
label {
  font-weight: 500;
  color: black;
}
input,
select {
  border: 1px solid #000000;
  outline: none !important;
}
select {
  height: fit-content;
}
</style>
