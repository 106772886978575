<template>
  <div
    class="modal fade"
    id="textAreaModal"
    tabindex="-1"
    aria-labelledby="textAreaModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="textAreaModalLabel">Updated Note</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <textarea
            class="control"
            rows="5"
            placeholder="Enter your text here..."
            v-model="content"
          ></textarea>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" @click="handleUpdate">
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    selectedNoteId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      content: "",
    };
  },

  watch: {
    selectedNoteId(newId) {
      if (newId !== null) {
        this.fetchNotes();
      }
    },
  },

  mounted() {
    if (this.selectedNoteId !== null) {
      this.fetchNotes();
    }
  },

  methods: {
    async fetchNotes() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/notesContent/getContentById/${this.selectedNoteId}`
        );
        this.content = response.data.text;
        console.log("Fetched modal details:", this.content);
      } catch (error) {
        console.error("Error fetching modal details:", error);
      }
    },

    handleUpdate() {
      // Emit the event with the updated content and note ID
      this.$emit("update-note", { id: this.selectedNoteId, text: this.content });
    },
  },
};
</script>

<style scoped>
.custom-modal {
  position: fixed;
  top: 0;
  width: 30%;
  height: 100%;
  background: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1055;
  display: none;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);
}

.modal-dialog {
  margin-left: 70.5%;
  margin-top: 10%;
  width: 150%;
  height: 76%;
}
.modal-title {
  font-size: medium;
}

.modal-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

textarea.control {
  min-height: 100%;
  width: 100%;
  border: none;
}

.modal-header {
  border-bottom: 2px solid black;
}
.modal-footer {
  border: none;
}

.modal-body {
  flex: 1;
  overflow-y: auto;
  width: 100%;
}

@media (max-width: 768px) {
  .modal-dialog {
    width: 100%;
    padding: 6px;
    margin-left: 0%;
  }
  .modal-content {
    height: 100%;
    width: 100%;
    margin-top: 15%;
    display: flex;
    flex-direction: column;
  }
  .modal-body {
    flex: 1;
    overflow-y: auto;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .modal-dialog {
    width: 100%;
    margin-left: 0%;
    padding: 6px;
  }
  .modal-content {
    height: 100%;
    width: 100%;
    margin-top: 15%;
    display: flex;
    flex-direction: column;
  }
  .modal-body {
    flex: 1;
    overflow-y: auto;
    width: 100%;
  }
}
</style>
