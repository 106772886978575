import { createApp } from 'vue';
import App from './App.vue';
import router from './routers';
import GlobalProperties from './plugins/globalProperties';
import TableSkeletonLoader from './components/TableSkeletonLoader.vue';
import './assets/sass/style.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { globalState } from "./plugins/Store";
import { navigationGuard } from './plugins/navigationGuard'; 
import 'font-awesome/css/font-awesome.css';
import { showAlert } from './util/sweetAlert';





const app = createApp(App);
 
// Global registration of TableSkeletonLoader
app.config.globalProperties.$showAlert = showAlert;
app.config.globalProperties.$globalState = globalState;
app.component('TableSkeletonLoader', TableSkeletonLoader);

// Global functions for CSV conversion and download
app.config.globalProperties.$convertToCSV = function (data) {
  const headers = Object.keys(data[0]).join(",") + "\n";
  const csvData = data.map((item) => Object.values(item).join(",")).join("\n");
  return headers + csvData;
};

app.config.globalProperties.$downloadCSV = function (csvContent) {
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", "table_data.csv");
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

app.use(GlobalProperties);
router.beforeEach((to, from, next) => {
  // If the user navigates directly via URL, ensure navigationGuard handles it properly
  if (to.name === 'Home' || to.name === 'Login' ) {
    next(); // Allow navigation to safe routes
    return;
  }

  navigationGuard(to, from, next);
});




app.use(router);
app.mount('#app');
