<template>
  <div>
    <div
      class="modal fade"
      id="UploadEstimateModal"
      tabindex="-1"
      aria-labelledby="UploadEstimateModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Upload Estimate</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3 row">
              <label for="clientName" class="col-md-4 col-form-label"
                >Client Name: <span class="text-danger">*</span></label
              >
              <div class="col-md-8">
                <input
                  type="text"
                  class="form-control"
                  id="clientName"
                  placeholder="Search and Select Client"
                  v-model="routeParams.clientName"
                  @input="searchAndFillLeadDetails"
                />
                <div
                  v-if="searchResults.length && routeParams.clientName"
                  class="serchMyLeads"
                >
                  <ul>
                    <li
                      v-for="result in searchResults"
                      :key="result.id"
                      @click="selectClient(result)"
                    >
                      {{ result.name }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="mb-3 row">
              <label for="estimateTotal" class="col-md-4 col-form-label">
                Total Amount: <span class="text-danger">*</span>
              </label>
              <div class="col-md-8">
                <input
                  type="number"
                  class="form-control"
                  id="estimateTotal"
                  v-model="estimate.total"
                  @input="calculateTotals"
                />
              </div>
            </div>

            <div class="mb-3 row">
              <label for="gst" class="col-md-4 col-form-label">
                GST (%): <span class="text-danger">*</span>
              </label>
              <div class="col-md-8">
                <select
                  v-model="estimate.gst"
                  class="form-select"
                  @change="calculateTotals"
                >
                  <option v-for="option in GSTOptions" :key="option" :value="option">
                    {{ option }}%
                  </option>
                </select>
              </div>
            </div>

            <div class="mb-3 row">
              <label for="gstAmount" class="col-md-4 col-form-label">
                GST Amount: <span class="text-danger">*</span>
              </label>
              <div class="col-md-8">
                <input
                  type="number"
                  class="form-control"
                  id="gstAmount"
                  v-model="estimate.gstAmount"
                />
              </div>
            </div>

            <div class="mb-3 row">
              <label for="grandTotal" class="col-md-4 col-form-label">
                Grand Total: <span class="text-danger">*</span>
              </label>
              <div class="col-md-8">
                <input
                  type="number"
                  class="form-control"
                  id="grandTotal"
                  v-model="estimate.grandTotal"
                />
              </div>
            </div>

            <div class="mb-3 row">
              <label for="EstimateAwsFileId" class="col-md-4 col-form-label">
                Upload Document: <span class="text-danger">*</span>
              </label>
              <div class="col-md-8">
                <input
                  type="file"
                  class="form-control"
                  id="EstimateAwsFileId"
                  @change="handleFileUpload"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="resetForm"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary"
              :disabled="
                loading ||
                !this.routeParams.clientName ||
                !this.estimate.total ||
                !this.estimate.gst ||
                !this.awsFileId
              "
              @click="submitForm"
            >
              <span
                v-if="loading"
                class="spinner-border spinner-border-sm"
                role="status"
              ></span>
              <span v-else>Submit</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UploadEstimateModal",
  data() {
    return {
      GSTOptions: [0, 5, 12, 18, 28],
      searchResults: [],
      routeParams: { clientName: "" },
      estimate: { total: null, gst: null, gstAmount: null, grandTotal: null },
      transactionDetails: "",
      awsFileId: null,
      loading: false,
    };
  },
  methods: {
    searchAndFillLeadDetails() {
      if (!this.routeParams.clientName) {
        this.searchResults = [];
        return;
      }
      axios
        .post(`${this.$apiEndPoint}/leads/searchLeadForEstimate`, {
          searchString: this.routeParams.clientName,
        })
        .then((response) => {
          this.searchResults = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    selectClient(client) {
      this.routeParams.clientName = client.name;
      this.selectedLeadId = client.id; // Save the lead ID for the API payload
      this.searchResults = [];
    },

    calculateTotals() {
      const gstAmount = ((this.estimate.total || 0) * (this.estimate.gst || 0)) / 100;
      this.estimate.gstAmount = gstAmount;
      this.estimate.grandTotal = this.estimate.total + gstAmount;
    },
    handleFileUpload() {
      const file = document.getElementById("EstimateAwsFileId").files[0];
      if (file) {
        axios
          .post(`${this.$apiEndPoint}/company/putFileSignedURL`, {
            fileName: file.name,
            fileSize: file.size,
            contentType: file.type,
            fileCategory: "estimate",
            moduleName: "sales",
          })
          .then((response) => {
            const signedUrl = response.data.s3URL;
            this.awsFileId = response.data.id;

            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUrl, true);
            xhr.setRequestHeader("Content-Type", file.type);
            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4 && xhr.status === 200) {
                this.$toast("File uploaded successfully", "success");
              } else if (xhr.readyState === 4) {
                this.$handleError(xhr.responseText);
              }
            };
            xhr.send(file);
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },
    resetForm() {
      this.routeParams.clientName = "";
      this.estimate = { total: null, gst: null, gstAmount: null, grandTotal: null };
      this.transactionDetails = "";
      this.awsFileId = null;
    },
    submitForm() {
      if (
        !this.routeParams.clientName ||
        !this.estimate.total ||
        !this.estimate.gst ||
        !this.awsFileId
      ) {
        this.$toast("Please fill all required fields.", "error");
        return;
      }

      const payload = {
        salesPersonId: this.$LocalUser.user.employeeId, // Assuming salesPersonId is fetched from the store
        leadId: this.selectedLeadId || null, // Ensure you set this dynamically when selecting a client
        total: this.estimate.total,
        gst: this.estimate.gst,
        gstAmount: parseFloat(this.estimate.gstAmount),
        grandTotal: parseFloat(this.estimate.grandTotal),
        awsFileId: this.awsFileId,
        status: "Finalized",
      };
      console.log(payload);
      this.loading = true;

      axios
        .post(`${this.$apiEndPoint}/estimates/uploadEstimate`, payload)
        .then((response) => {
          console.log(response.data);
          this.$toast("Estimate uploaded successfully!", "success");
          this.resetForm();
          this.loading = false;
          window.location.reload();
        })
        .catch((error) => {
          this.$handleError(error);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}

.modal-content {
  min-width: 550px; /* Set a fixed width */
  max-width: 550px;
  min-height: 450px; /* Set a fixed height */
  max-height: 450px;
  display: flex;
  flex-direction: column; /* Allow header, body, and footer to stack */
}

.modal-header,
.modal-footer {
  padding: 0.5rem 1rem; /* Reduce padding */
}

.modal-header {
  margin-bottom: 0; /* Remove bottom margin to reduce space */
}

.modal-footer {
  margin-top: 0; /* Remove top margin to reduce space */
}

.modal-title {
  font-size: 1.25rem; /* Adjust title size if needed */
}
.modal-body {
  font-size: 14px;
  overflow-y: auto; /* Enable vertical scrolling */
  flex: 1; /* Allow modal body to take up remaining space */
}
.modal-body::-webkit-scrollbar {
  display: none;
}

.steps-header {
  margin: 10px 20px;
  background-color: #f8f8f8;
  border-radius: 10px;
}

.step-item {
  flex: 1;
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 600;
}

.step-item span {
  display: block;
  background-color: #ddd;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin: 10px auto;
  line-height: 25px;
  color: #fff;

  background-color: #007bff;
}

.step-item.active span {
  background-color: #28a745; /* Green for active/completed steps */
}

.step {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem; /* Space between items */
}

.step-number {
  margin-right: 0.5rem; /* Space between number and text */
  font-weight: bold; /* Make the number bold */
  flex-shrink: 0; /* Prevents the number from shrinking */
}

.step p {
  margin: 0; /* Remove default margin for paragraphs */
  line-height: 1.5; /* Adjust line height for readability */
}
.round {
  width: 30px;
  height: 30px;
  border: 2px dashed #ccc;
  border-radius: 50%;
}
.round {
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-item {
  font-size: 10px;
  background-color: #f8f8f8;
  padding: 10px;
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.file-icon {
  width: 25px;
  height: 25px;
}

.file-info {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.file-name {
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 200px;
}

.file-size {
  font-size: 10px;
}

.remove-file-button {
  cursor: pointer;
  margin-left: 20px;
}

.serchMyLeads {
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  margin-top: 5px;
  z-index: 999 !important;
  background-color: #fff;
}
.serchMyLeads ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.serchMyLeads li {
  padding: 5px;
  cursor: pointer;
}
.serchMyLeads li:hover {
  background-color: #f0f0f0;
}
</style>
