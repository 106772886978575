<template>
  <div
    class="modal"
    id="OnSiteOutwordEntriesModal"
    aria-labelledby="OnSiteOutwordEntriesModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="min-width: 80%; overflow: auto"
    >
      <div class="modal-content">
        <div class="modal-header" style="margin-bottom: 0">
          <div class="d-flex justify-content-between align-items-center w-100 flex-wrap">
            <h6 class="modal-title">Inward Entry</h6>
            <div class="d-flex align-items-center mb-2 mb-md-0 me-2">
              <label for="date" class="col-form-label text-nowrap me-2">Date:</label>
              <input
                type="text"
                class="form-control p-1"
                v-model="form.date"
                style="max-width: 150px"
                readonly
              />
            </div>
            <div class="d-flex align-items-center mb-2 mb-md-0">
              <label for="projectName" class="col-form-label text-nowrap me-2">
                From Store:
              </label>
              <input
                type="text"
                class="form-control p-1"
                v-model="form['fromStores.storeName']"
                style="max-width: 200px"
                readonly
              />
            </div>
            <img
              src="../../assets/icons/cancel.png"
              alt="Close"
              style="width: 20px; height: 20px; cursor: pointer"
              data-bs-dismiss="modal"
            />
          </div>
        </div>
        <div class="modal-body" style="max-height: 500px; overflow: auto">
          <table
            class="table files-table table-responsive"
            style="background-color: #ccc"
          >
            <caption class="d-none">
              Product Details
            </caption>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Product Name</th>
                <th>Sent Qty</th>
                <th>Received Qty</th>
                <th>Damaged Qty</th>
                <th>Missing Qty</th>
                <th>Remark</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in Rows"
                :key="index"
                @mouseover="deleteItems = true"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ item["products.productName"] }}</td>
                <td>
                  <input
                    type="checkbox"
                    v-model="item.checked"
                    @change="updateQuantities(index)"
                  />
                  {{ item.sentQuantity }}
                </td>
                <td>
                  <input
                    class="form-control"
                    type="number"
                    v-model="item.receivedQuantity"
                    :disabled="item.checked"
                    @input="validateInput(index, 'receivedQuantity')"
                  />
                </td>
                <td>
                  <input
                    class="form-control"
                    type="number"
                    v-model="item.damagedQuantity"
                    :disabled="item.checked"
                    @input="validateInput(index, 'damagedQuantity')"
                  />
                </td>
                <td>
                  <input
                    class="form-control"
                    type="number"
                    v-model="item.missingQuantity"
                    :disabled="item.checked"
                    @input="validateInput(index, 'missingQuantity')"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    v-model="item.remark"
                    :disabled="item.checked"
                    class="form-control"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <div v-for="(row, rowIndex) in Rows" :key="rowIndex" class="mt-3 MOBILE">
                    
            <div class="activity-card">
              <div class="card-header d-flex justify-content-between ">
                <span class="activity-title">
               
                  {{ row["products.productName"] }}</span>
                <span class="d-flex align-items-center" style="padding: 0 10px">
                
                 
                  <img class="ms-3" src="../../assets/icons/3blackDots.png" alt=""
                    style="width: 17px; height: 17px" id="dropdownMenuButton" data-bs-toggle="dropdown"
                    aria-expanded="false" />
                  <div class="dropdown-menu dropdown-menu-center ActionImage"
                    aria-labelledby="dropdownMenuButton">
                 

                    <span >
                      <img src="../../assets/icons/yellowEdit.png" data-toggle="tooltip"
                        data-placement="top-start" title="Edit" alt=""  @click="editRow(rowIndex)"   />
                    </span>
                  
                  </div>

                </span>
              </div>

              <div class="card-details">
                <div class="detail-item">
                  <label>Order</label>
                  <span >{{ row.sentQuantity }}</span>
                </div>
                <div class="detail-item">
                  <label>Received</label>
                  <span>{{ row.receivedQuantity }}</span>
                </div>
                <div class="detail-item">
                  <label>Damaged</label>
                  <span>{{ row.damagedQuantity }}</span>
                </div>
                <div class="detail-item">
                  <label>Missing</label>
                  <span>{{ row.missingQuantity}} </span>
                </div>
              </div>
            </div>
         
          </div> -->
        </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            class="browse"
            @click="CreateOutWordEntry"
            data-bs-dismiss="modal"
          >
            Save
          </button>
          <button type="button" class="cancelButton" data-bs-dismiss="modal">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>

  <div v-if="isModalVisible" class="modal show d-block" role="dialog">
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="min-width: 80%; overflow: auto"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Row</h5>
          <button
            type="button"
            class="btn-close"
            @click="isModalVisible = false"
          ></button>
        </div>
        <div class="modal-body">
          <!-- Use selectedRow to bind modal fields -->
          <label>Product Name:</label>
          <input
            type="text"
            v-model="selectedRow['products.productName']"
            class="form-control"
          />

          <label>Sent Quantity:</label>
          <input type="number" v-model="selectedRow.sentQuantity" class="form-control" />

          <label>Received Quantity:</label>
          <input
            type="number"
            v-model="selectedRow.receivedQuantity"
            class="form-control"
          />

          <label>Damaged Quantity:</label>
          <input
            type="number"
            v-model="selectedRow.damagedQuantity"
            class="form-control"
          />

          <label>Missing Quantity:</label>
          <input
            type="number"
            v-model="selectedRow.missingQuantity"
            class="form-control"
          />
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" @click="saveChanges">Save</button>
          <button class="btn btn-secondary" @click="isModalVisible = false">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "OnSiteOutwordEntriesModal",
  props: {
    FromStore: {
      type: Number,
      required: true,
    },
    InwardEntryId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedRow: {
        receivedQuantity: 0,
        damagedQuantity: 0,
        missingQuantity: 0,
      }, // Placeholder for selected row data
      isModalVisible: false, // Controls modal visibility
      localInwardEntryId: null,
      InwardEntrieStatus: null,
      Rows: [
        {
          productId: "",
          sentQuantity: 0,
          receivedQuantity: 0,
          damagedQuantity: 0,
          missingQuantity: 0,
          remark: "",
        },
      ],
      deleteItems: false,
      Projects: [],
      Products: [],
      form: {
        date: new Date().toISOString().slice(0, 10),
        fromStore: null,
        toStore: null,
        status: "Accepted",
      },
    };
  },

  watch: {
    FromStore(newValue) {
      this.localQuotationId = newValue;
      this.form.fromStore = this.localQuotationId;
    },
    InwardEntryId(newValue) {
      this.localInwardEntryId = newValue;
      this.featchEntriesById(this.localInwardEntryId);
    },
  },
  methods: {
    editRow(rowIndex) {
      this.selectedRow = { ...this.Rows[rowIndex] }; // Clone the selected row data
      this.isModalVisible = true; // Show modal
    },
    saveChanges() {
      // Find the index of the selected row
      const index = this.Rows.findIndex(
        (row) => row.sentQuantity === this.selectedRow.sentQuantity
      );
      console.log(this.Rows[index].sentQuantity);
      console.log(this.selectedRow.sentQuantity);
      if (index !== -1) {
        console.log(this.Rows[index]);
        console.log();
        // Update the properties of the row directly
        this.Rows[index].damagedQuantity = this.selectedRow.damagedQuantity;
        this.Rows[index].receivedQuantity = this.selectedRow.receivedQuantity;
        this.Rows[index].missingQuantity = this.selectedRow.missingQuantity;
        console.log(this.Rows[index]);

        // Add more fields as necessary
      }

      // Do not hide the modal
      this.isModalVisible = false; // Keep modal open
    },

    updateQuantities(index) {
      if (this.Rows[index].checked) {
        this.Rows[index].receivedQuantity = this.Rows[index].sentQuantity;
        this.Rows[index].damagedQuantity = 0;
        this.Rows[index].missingQuantity = 0;
        this.Rows[index].remark = "";
      } else {
        // Ensure receivedQuantity does not exceed order sentQuantity
        if (this.Rows[index].receivedQuantity > this.Rows[index].sentQuantity) {
          this.Rows[index].receivedQuantity = this.Rows[index].sentQuantity;
        }

        // Ensure the sum of received, damaged, and missing quantities equals the order sentQuantity
        let totalQty = this.Rows[index].sentQuantity;
        let currentTotal =
          this.Rows[index].receivedQuantity +
          this.Rows[index].damagedQuantity +
          this.Rows[index].missingQuantity;

        if (currentTotal > totalQty) {
          // Adjust damagedQuantity and missingQuantity if the current total exceeds the order sentQuantity
          this.Rows[index].damagedQuantity = 0;
          this.Rows[index].missingQuantity = 0;
          currentTotal = this.Rows[index].receivedQuantity;

          if (currentTotal > totalQty) {
            this.Rows[index].receivedQuantity = totalQty;
          }
        }

        if (currentTotal < totalQty) {
          // Distribute the remaining sentQuantity to receivedQuantity
          this.Rows[index].receivedQuantity =
            totalQty -
            this.Rows[index].damagedQuantity -
            this.Rows[index].missingQuantity;
        }
      }
    },
    async featchEntriesById(InwardEntryId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/inwardOutwardEntries/${InwardEntryId}`
        );
        this.form = response.data;
        this.InwardEntrieStatus = response.data.status;
        this.Rows = response.data.Lineitems;
      } catch (error) {
        this.$handleError(error);
      }
    },
    validateInput(index, field) {
      let item = this.Rows[index];
      let totalQty = item.sentQuantity;
      let receivedQuantity = item.receivedQuantity;
      let damagedQuantity = item.damagedQuantity;
      let missingQuantity = item.missingQuantity;

      // Ensure receivedQuantity does not exceed order sentQuantity
      if (field === "receivedQuantity" && receivedQuantity > totalQty) {
        item.receivedQuantity = totalQty;
        receivedQuantity = totalQty;
      }

      // Ensure the sum of received, damaged, and missing quantities does not exceed the order sentQuantity
      let sumQty = receivedQuantity + damagedQuantity + missingQuantity;
      if (sumQty > totalQty) {
        let diff = sumQty - totalQty;
        if (field === "receivedQuantity") {
          item.receivedQuantity -= diff;
        } else if (field === "damagedQuantity") {
          item.damagedQuantity -= diff;
        } else if (field === "missingQuantity") {
          item.missingQuantity -= diff;
        }
      }
    },

    CreateOutWordEntry() {
      let dataToSend = {
        date: this.form.date,
        fromStore: this.form.fromStore,
        toStore: this.form.toStore,
        status: "Accepted",
        Rows: this.Rows.map((item) => ({
          id: item.id,
          productId: item.productId,
          sentQuantity: item.sentQuantity,
          receivedQuantity: item.receivedQuantity,
          damagedQuantity: item.damagedQuantity,
          missingQuantity: item.missingQuantity,
          remark: item.remark,
        })),
      };
      console.log(dataToSend);

      if (this.InwardEntrieStatus === "Sent") {
        axios
          .put(
            `${this.$apiEndPoint}/inwardOutwardEntries/acceptInward/${this.localInwardEntryId}`,
            dataToSend
          )
          .then(() => {
            this.$emit("fetchInwardEntries");
            this.form = {};
          })
          .catch((error) => {
            this.$handleError(error);
          });
      } else {
        axios
          .put(
            `${this.$apiEndPoint}/inwardOutwardEntries/acceptInward/${this.localInwardEntryId}`,
            dataToSend
          )
          .then(() => {
            this.$emit("fetchInwardEntries");

            this.form = {};
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none;
  box-shadow: none;
}
.selected-employee {
  margin-bottom: 5px;
}
.remove-employee {
  cursor: pointer;
  margin-left: 5px;
}
label {
  font-weight: 500;
  color: black;
}

.modal-body {
  max-height: 500px;
  overflow-y: auto;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.files-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.files-table th,
.files-table td {
  text-align: left;
  padding: 6px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
}

.files-table th {
  background-color: #bcbfc6;
}

.files-table tr:hover {
  background-color: #f5f5f5;
}

.files-table th:nth-child(2),
.files-table td:nth-child(2) {
  width: 30%;
  text-align: left;
}

.files-table th:nth-child(7),
.files-table td:nth-child(7) {
  width: 20%;
  text-align: left;
}

.ActionImage img {
  padding: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  white-space: nowrap;
}

.ActionImage img:hover {
  background-color: #868686;
  border-radius: 50%;
}
@media (max-width: 768px) {
  .modal-header .d-flex {
    flex-wrap: wrap;
    gap: 10px;
  }

  .modal-header label {
    white-space: nowrap;
  }

  .activity-card {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 12px;
    padding: 12px;
    background-color: #f8f8f8;
  }

  .sub-activity {
    margin-left: 0px;
    border-left: 3px solid #e0e0e0;
    background: #fff;
  }

  .card-header {
    border-bottom: 1px solid #eee;
    padding-bottom: 8px;
    margin-bottom: 8px;
  }

  .activity-title {
    font-size: 14px;
    font-weight: 500;
    color: #333;
  }

  .card-details {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }

  .detail-item {
    display: flex;
    flex-direction: column;
  }

  .detail-item label {
    font-size: 10px;
    color: #666;
    margin-bottom: 2px;
  }

  .detail-item span {
    font-size: 10px;
    color: #333;
  }

  .sub-activities {
    margin-top: 8px;
    padding-left: 0px;
  }
  .ScopeOfWorkTable {
    height: 75vh;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .form-group {
    font-size: 12px !important;

    margin-bottom: 10px;
  }
  .form-group label {
    margin-bottom: 5px;
  }
}
</style>
