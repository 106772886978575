<template>
  <div class="HeaderofApp">
    <div class="header blackBgColor">
      <div class="div1">
        <img
          src="../assets/icons/black_burgerIcon.png"
          alt=""
          @click="expandNavContent"
          id="burgerMenu"
        />
        <a href="/home" style="text-decoration: none">
          <img
            src="../assets/icons/ArivuLogo.png"
            alt=""
            style="width: 28px; height: 28px"
            class="arivuLogo WEB"
          />
        </a>
        <a href="/home" style="text-decoration: none">
          <h3 style="font-family: 'Athena', sans-serif" class="NameOfTheAppliction">
            arivu
          </h3>
        </a>
      </div>

      <div class="div2">
        <div class="searchBar">
          <img
            class="searchIcon"
            src="../assets/icons/whiteSearch.png"
            alt="searchIcon"
          />
          <input
            type="text"
            ref="searchBarInput"
            placeholder="Search...."
            v-model="searchQuery"
            @input="filterRoutes"
          />
          <div v-if="filteredRoutes.length" class="search-results">
            <ul>
              <li
                v-for="route in filteredRoutes"
                :key="route.name"
                @click="navigateToRoute(route.path)"
              >
                {{ route.meta.title }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="div3">
        <div class="notification-icon-wrapper position-relative ms-5">
          <span
            v-if="count > 0"
            class="notification-count badge bg-danger text-white position-absolute"
            title="New Notifications"
          >
            {{ count }}
          </span>
          <img
            @click="MarkAsSeen()"
            src="../assets/icons/notificationHeader.png"
            alt="Notifications"
            data-bs-toggle="offcanvas"
            data-bs-target="#NotificationModal"
            style="cursor: pointer"
          />
        </div>
        <img
          class="WEB"
          src="../assets/icons/black_Setting.png"
          @click="SettingPage"
          style="cursor: pointer"
          v-if="showSalesSettings"
          alt="Setting"
        />

        <div class="dropdown-Menu" v-if="showEmployeeSettings">
          <!-- Dropdown menu items -->
          <!-- Insert your user information in this section -->
          <div class="menuItems WEB">
            <a id="menuItemHeader" href="/EmployeeList">
              <img
                src="../assets/icons/addEmployee.png"
                alt="Menu"
                style="cursor: pointer"
            /></a>
          </div>
        </div>
        <div class="dropdown">
          <span class="dot">
            <img
              v-if="EmployeeprofileImage !== null"
              :src="EmployeeprofileImage"
              alt="Profile"
              class="EmployeeprofileImage"
            />
            <h5 v-else id="initials">{{ initials }}</h5>
          </span>

          <!-- Dropdown content -->
          <div class="dropdown-content">
            <!-- Display user profile information -->
            <div class="user-profile">
              <!-- Display user profile photo -->
              <div class="d-flex flex-column align-items-center justify-content-center">
                <img
                  v-if="EmployeeprofileImage"
                  :src="EmployeeprofileImage"
                  alt="Profile Photo"
                  class="profile-photo"
                />
                <img
                  v-else
                  class="profile-photo"
                  src="../assets/icons/profile.png"
                  alt="Profile Photo"
                />
              </div>

              <!-- Display user name, email, and department -->
              <div class="user-info">
                <p style="font-weight: 500; white-space: nowrap">
                  {{ fullName }}
                </p>
                <p class="text-muted" style="font-size: 10px">{{ userEmail }}</p>
                <!-- Display user roles in the specified format -->
                <p class="text-muted" style="font-size: 10px">
                  <span
                    v-if="
                      userRoles.some(
                        (role) => role.team === 'Admin' && role.role === 'Manager'
                      )
                    "
                  >
                    Business Owner
                  </span>
                  <span
                    v-else-if="
                      userRoles.some(
                        (role) => role.team === 'Admin' && role.role === 'Executive'
                      )
                    "
                  >
                    Tool Admin
                  </span>
                  <span
                    v-else
                    v-for="role in userRoles"
                    :key="role.team"
                    class="d-flex flex-column"
                  >
                    <span>
                      {{ role.team }}
                      <span style="font-size: 8px">({{ role.role }})</span>
                    </span>
                  </span>
                </p>
              </div>
            </div>

            <!-- Dropdown options (Profile, Settings, Logout) -->
            <div class="d-flex align-items-center" style="background-color: #f8f8f8">
              <span class="ms-2">
                <img
                  src="../assets/icons/profileIconBefore.png"
                  alt=""
                  style="width: 15px; height: 15px"
              /></span>

              <a
                style="font-size: 12px; font-weight: 500"
                class="mt-1"
                @click="goToEmployeeProfile"
                >Profile</a
              >
            </div>
            <div class="d-flex align-items-center" style="background-color: #f8f8f8">
              <span class="ms-2">
                <img
                  src="../assets/icons/setting.png"
                  alt=""
                  style="width: 15px; height: 15px"
              /></span>

              <a style="font-size: 12px; font-weight: 500" class="mt-1">Settings</a>
            </div>
            <div class="d-flex align-items-center" style="background-color: #f8f8f8">
              <span class="ms-2">
                <img
                  src="../assets/icons/logout.png"
                  alt=""
                  style="width: 15px; height: 15px"
              /></span>
              <a
                style="font-size: 12px; font-weight: 500; color: red"
                class="mt-1"
                @click="logout"
                >Logout</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <NotificationModal :projectId="DesignProjectId" @setProjectId="setProjectId" />
  </div>
</template>

<script>
import axios from "axios";
import NotificationModal from "./Modal/NotificationModal.vue";

export default {
  name: "DesktopHeader",
  components: {
    NotificationModal,
  },
  data() {
    return {
      count: 0,
      DesignProjectId: null,
      showEmployeeSettings: false,
      showSalesSettings: false,
      EmployeeprofileImage: null,
      initials: "",
      fullName: "",
      userEmail: "",
      userRoles: [],
      searchQuery: "",
      filteredRoutes: [], // Stores the filtered route list
    };
  },
  computed: {
    // Filter user roles based on the employeeId
    /*    userRoles() {
         return this.employeeRoles.filter(role => role.team.toLowerCase() === this.user.team.toLowerCase());
       } */
    routes() {
      return this.$router.options.routes;
    },
  },
  watch: {
    // Add this watcher
    "$window.width"() {
      const navContent = document.querySelector(".nav-content");
      if (navContent) {
        if (window.innerWidth <= 1025) {
          navContent.style.width = "0px";
        } else {
          navContent.style.width = this.navContentWidth;
        }
      }
    },
  },
  methods: {
    MarkAsSeen() {
      this.DesignProjectId = Math.floor(Math.random() * 1000) + 1;
      this.count = 0;
      try {
        // Call the API to update the status
        const response = axios.put(`${this.$apiEndPoint}/notification/markAsSeen`);
        if (response.data) {
          this.fetchNotification();
        }
      } catch (error) {
        console.error("Error marking as read:", error);
      }
    },
    setProjectId() {
      this.DesignProjectId = null;
      console.log(this.DesignProjectId);
    },
    fetchNotification() {
      if (!this.$LocalUser || !this.$LocalUser.user) {
        console.warn("User is not logged in or LocalUser is not defined.");
        return; // Exit early if user is not logged in
      }
      const url = `${this.$apiEndPoint}/notification/unreadCounts`;

      axios
        .get(url)
        .then((response) => {
          console.log(response.data);
          this.count = response.data; // Update total notifications count
        })
        .catch((error) => {
          console.error("Error fetching notifications:", error);
        });
    },
    filterRoutes() {
      if (this.searchQuery.trim()) {
        // Filter routes based on user roles (teams) and search query
        this.filteredRoutes = this.routes.filter((route) => {
          const hasAccess =
            route.meta &&
            route.meta.teams &&
            route.meta.teams.some((team) =>
              this.userRoles.some((userRole) => userRole.team === team)
            );

          const matchesSearch =
            route.meta &&
            route.meta.title &&
            route.meta.title.toLowerCase().includes(this.searchQuery.toLowerCase());

          return hasAccess && matchesSearch;
        });
      } else {
        // Clear the filteredRoutes array if searchQuery is empty
        this.filteredRoutes = [];
      }
    },

    navigateToRoute(path) {
      // Navigate to the selected route
      this.$router.push(path);
      this.searchQuery = ""; // Clear search input
      this.filteredRoutes = []; // Clear results
    },

    SettingPage() {
      if (this.showSalesSettings) {
        this.$router.push({ name: "Setting" });
      }
    },
    goToEmployeeProfile() {
      const user = localStorage.getItem("user-info");
      if (user) {
        try {
          const userData = JSON.parse(user);
          const userObject = userData.user;
          const encodedId = btoa(userObject.employeeId.toString());
          // Navigate to EmployeeProfile route with emplId parameter
          this.$router.push({
            name: "EmployeeProfile",
            params: { employeeId: encodedId },
          });
        } catch (error) {
          this.$handleError(error);
        }
      } else {
        this.$router.push({ name: "Login" });
      }
    },
    expandNavContent() {
      const navContent = document.querySelector(".nav-content");

      if (window.matchMedia("(max-width: 1025px)").matches) {
        const isOpen = navContent && navContent.style.width === "250px";
        if (navContent) {
          navContent.style.width = isOpen ? "0px" : "250px";
          navContent.style.transition = "0.3s ease";
        }

        if (!isOpen) {
          document.addEventListener("click", this.handleOutsideClick.bind(this));
        } else {
          document.removeEventListener("click", this.handleOutsideClick.bind(this));
        }
      }
    },

    handleOutsideClick(event) {
      const navContent = document.querySelector(".nav-content");

      if (
        navContent &&
        !navContent.contains(event.target) &&
        event.target.id !== "burgerMenu"
      ) {
        navContent.style.width = "0px";
        navContent.style.transition = "0.3s ease";
        document.removeEventListener("click", this.handleOutsideClick.bind(this));
      }
    },

    fetchEmployeeProfile(employeeId) {
      const PayLoad = {
        entityId: employeeId,
        fileCategory: "profile",
        moduleName: "company",
      };

      // Make API request to fetch employee profile photo URL
      axios
        .post(`${this.$apiEndPoint}/company/getFileSignedURL/`, PayLoad)
        .then((response) => {
          this.EmployeeprofileImage = Object.values(response.data)[0];
        })
        .catch((error) => {
          // Handle different error status codes
          if (error.response && error.response.status === 401) {
            this.logout();
          } else {
            this.$handleError(error);
          }
        });
    },

    logout() {
      localStorage.clear();
      this.$router.push({ name: "Login" });
    },
  },
  mounted() {
    let user = localStorage.getItem("user-info");
    let employeeRoles = localStorage.getItem("user-info");
    if (user) {
      try {
        const DepartmentData = JSON.parse(employeeRoles);
        const userData = JSON.parse(user);
        const userObject = userData.user;
        this.userRoles = DepartmentData.employeeRoles;
        this.initials = userObject.initials;
        this.fullName = `${userObject.firstName} ${userObject.lastName}`;
        this.userEmail = userObject.email;
        const employeeId = userObject.employeeId;

        this.userId = userObject.userId; // Set fullName in component's data
        this.fetchEmployeeProfile(employeeId);
        this.fetchNotification();
        if (this.userRoles) {
          // Check if the user is a Manager in the Sales or Admin team
          this.showSalesSettings = this.userRoles.some(
            (role) =>
              (role.team === "Sales" ||
                role.team === "HR" ||
                role.team === "Onsite" ||
                role.team === "Admin") &&
              role.role === "Manager"
          );
          this.showEmployeeSettings = this.userRoles.some(
            (role) => role.team === "Admin"
          );
        }
      } catch (error) {
        this.$handleError(error);
      }
    } else {
      this.$router.push({ name: "Login" });
    }
  },
};
</script>

<style scoped>
.searchBar {
  position: relative;
}
.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 5px;
  background: #ffffff;
  border: 1px solid #ddd;
  max-height: 200px;
  z-index: 10;
  border-radius: 10px;
  color: #1e2125;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Prevent content from spilling out */
}

.search-results ul {
  max-height: 180px;
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 5px;
  margin: 0;
  list-style-type: none;
}

.search-results li {
  padding: 8px;
  cursor: pointer;
  list-style-type: square;
}
.search-results li:hover {
  background: #f0f0f0;
  color: #000000;
}
.NameOfTheAppliction {
  font-weight: 100 !important;
}

.user-info {
  background-color: #ffffff;
}

a {
  cursor: pointer;
}

.HeaderofApp {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.header {
  height: 50px;
  display: flex;
  width: 100%;
  z-index: 1;
  position: relative;
}

.header .div1 {
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
}

.header .div1 h3 {
  color: #ffffff;

  text-align: left;
  margin-left: 1px;
}
.arivuLogo {
  margin: 0 10px;
}
.header .div2 {
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.div2 .searchBar {
  width: 65%;
  height: 65%;
  border-radius: 25px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid #ffffff;
}

.div2 .searchIcon {
  margin: 20px 10px;
  width: 20px;
  height: 20px;
}

.div2 .searchBar input {
  width: 80%;
  height: 90%;
  text-align: left;
  background-color: transparent;
  border-radius: 25px;
  color: #ffffff;
  border: none;
  padding-left: 20px;
  font-size: 1.2rem;
  font-family: "Inter", sans-serif;
}

.div2 .searchBar input:focus {
  border: none;
  outline: none;
}

.header .div3 {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: right;
  position: absolute;
  right: 1%;
  gap: 15px;
}

.div3 img {
  width: 30px;
  height: 30px;
}

.dropdown {
  position: relative;
  display: inline-block;
  z-index: 999;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: #000000;
  padding: 4px 8px;
  background-color: #f8f8f8;
  font-size: 10px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s ease;
}

.dropdown-content:before {
  content: "";
  position: absolute;
  right: 8px;
  top: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #2381f3 transparent;
  z-index: 9999;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f8f8f8;
  border-radius: 5px;
  color: #000000;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-Menu {
  position: relative;
  display: inline-block;
  z-index: 9999;
}

/* Dropdown Content (Hidden by Default) */

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #000000;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 2px;
  border-radius: 15px;
  transition: opacity 0.3s ease, transform 0.3s ease;
  padding: 10px;
}

.dropdown-content-Menu {
  display: none;
  position: absolute;
  right: 0;
  min-width: 80px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 36px;
  border-radius: 15px;
  transition: opacity 0.3s ease, transform 0.3s ease;
  padding: 10px 10px 10px 10px;
  letter-spacing: 2px;
  background-color: #1e2125;
  color: #ffffff;
}

/* Links inside the dropdown */
.dropdown-content-Menu a {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  padding: 12px 16px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.dropdown-content-Menu:before {
  content: "";
  position: absolute;
  right: 30px;
  top: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #000000 transparent;
  z-index: 9999;
}

.menu-item .icon {
  border: none;
  outline: none;
}

/* Change color of dropdown links on hover */
.dropdown-content-Menu #menuItemHeader:hover {
  background-color: #396e76;
  border-radius: 10px;
  color: black;
}

/* Show the dropdown menu on hover */
.dropdown-Menu:hover .dropdown-content-Menu {
  display: block;
  flex-direction: row;
  position: absolute;
}

.menuItems {
  display: flex;
  flex-direction: column;
}

.menu-row {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
}

.plusRegister {
  width: 35px;
  height: 35px;
}

.menu-item a {
  color: white;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  text-decoration: none;
  margin-top: 5px;
}

.menu-item a:hover {
  border-radius: 10px;
  color: black;
}

.dropdown {
  position: relative;
  display: inline-block;

  cursor: pointer;
}

.dot {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot:hover .dropdown-content {
  display: block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  z-index: 1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
  color: #000000;
  padding: 6px 12px;
  text-decoration: none;
  display: block;
}
.dropdown-content a:hover {
  background-color: #ccc;
}

.user-profile {
  display: flex;
  align-items: flex-start;
  /* Align items to the top of the container */
  justify-content: space-around;

  padding: 10px;
}

.profile-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
  object-position: center;
  image-rendering: auto;
  background-color: #f0f0f0; /* Fallback background color */
  display: inline-block;
}

/* Optional: Placeholder styling for cases where no image is provided */
.profile-photo::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #dcdcdc; /* Light gray background for empty state */
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.user-info p {
  margin: 0;
  font-size: 14px;
  background-color: #ffffff;
}
.EmployeeprofileImage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 1px;
  margin-bottom: 8px;
  background-color: #b1c5df;
}
.dot {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}
#initials {
  height: 30px;
  width: 30px;
  background-color: #2381f3;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 17px;
}

.notification-icon-wrapper {
  display: inline-block;
  position: relative;
}

.notification-count {
  top: -3px; /* Adjust to position the count above the image */
  right: -8px; /* Adjust for horizontal alignment */
  font-size: 10px; /* Adjust font size for readability */
  padding: 2px 6px;
  border-radius: 50%;
  min-width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
}

@media (max-width: 768px) {
  .WEB {
    display: none !important; /* Hide web content on mobile */
  }
  .MOBILE {
    display: block !important; /* Show mobile content */
  }

  .nav-content {
    width: 0px;
  }

  .header .div1 h3 {
    margin-top: 5%;
  }

  #burgerMenu {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: left;
  }
  .content-Container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    text-align: left;
    font-family: "Inter", sans-serif;
    position: relative;
    background-color: #ffffff;
  }
  body {
    background-color: #ffffff !important;
  }

  .containerChild {
    max-height: 100%;
    overflow-y: scroll;
    width: 100%;
    margin: 0px;
  }

  .header .div2 {
    width: 60%;

    align-items: center;
    display: none;
  }
  .div2 .searchBar {
    margin-left: 40%;
    border-radius: 5px;
    border: none;
  }

  .searchIcon {
    display: none;
  }
  .div2 .searchBar input {
    width: 100%;
    height: 90%;
    text-align: left;
    margin-left: 5px;
    padding: 1%;
  }

  .MOBILE .tableFormat th:nth-child(1) {
    border-radius: 5px 0px 0px 5px; /* Applies rounded corners to the first column header */
  }

  .MOBILE .tableFormat th:last-child {
    border-radius: 0px 5px 5px 0px; /* Applies rounded corners to the last column header */
  }
  .header .div3 {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
  }
  .paginationOfTable {
    display: flex;

    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 2%;
    padding: 0% 0;
    z-index: 999;
    font-size: 12px;
    border-radius: 0;
    background-color: #ffffff;
    width: calc(100%);
  }
  .EmployeeprofileImage {
    margin-right: 5px;
  }
}
</style>
