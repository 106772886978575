<template>
  <div class="containerChild">
    <div class="ProjectInfoContent StandardScroller mb-5 p-2">
      <div class="d-flex flex-column">
        <div
          v-for="(section, sectionIndex) in sections"
          :key="section.id"
          class="ProjectInfoContentSection"
        >
          <span>
            <img
              v-if="section.showInfoBox"
              @click="toggleInfoBox(sectionIndex)"
              src="../../assets/icons/blackUparrow-1.png"
              alt=""
              style="width: 15px; height: 10px; margin-left: 10px; cursor: pointer"
            />
            <img
              v-else
              @click="toggleInfoBox(sectionIndex)"
              src="../../assets/icons/rightArrowbrown.png"
              alt=""
              style="width: 15px; height: 15px; margin-left: 10px; cursor: pointer"
            />
            <input
              type="text"
              :ref="'sectionNameInput' + sectionIndex"
              v-model="section.sectionName"
              :placeholder="'Section ' + (sectionIndex + 1) + ' Header'"
              class="tableHeaderHeading"
              readonly
            />
          </span>
          <div class="sectionAreaOfProjectInfo1" v-if="section.showInfoBox">
            <div
              v-for="(value, index) in section.values"
              :key="value.id"
              class="sectionAreaOfProjectInfo"
            >
              <div
                class="d-flex flex-column"
                style="
                  border: 3px solid #000000;
                  border-radius: 15px;
                  max-height: 350px;
                  max-width: 400px;
                  padding: 5px;
                "
              >
                <section
                  class="d-flex justify-content-between align-items-center"
                  style="width: 100%"
                >
                  <input
                    v-model="value.title"
                    :placeholder="'Title of the area ' + (index + 1)"
                    type="text"
                    class="text-muted text-decoration-underline"
                    style="width: 50%"
                    :ref="'areaTitleInput' + sectionIndex + index"
                    readonly
                  />

                  <span
                    class="image-icons-wrapper d-flex justify-content-end align-items-center"
                    style="width: 50%"
                  >
                    <!-- <img
                      src="../../assets/icons/downArrowbrown.png"
                      alt="plus-icon"
                      style="height: 13px; width: 13px; transform: rotate(-270deg)"
                    /> -->

                    <span
                      v-if="value.images && value.images.length > 0"
                      class="d-flex flex-row"
                    >
                      <span
                        v-for="(image, imageIndex) in value.images"
                        :key="image.id"
                        class="d-flex align-items-center"
                        @click="
                          GoToDesignProjectInformodal(value.id, sectionIndex, index)
                        "
                      >
                        <img
                          :src="image.signedUrl"
                          :alt="'Image ' + (imageIndex + 1)"
                          class="fetched-image"
                          @click="openModal(value.images, imageIndex)"
                        />
                      </span>
                    </span>

                    <!-- <img
                      v-if="value.id"
                      src="../../assets/icons/addCircle.png"
                      alt="plus-icon"
                      class="fetched-image"
                      data-bs-toggle="modal"
                      data-bs-target="#DesignInfoBoxModal"
                      @click="GoToDesignProjectInformodal(value.id, sectionIndex, index)"
                    /> -->
                    <!-- <img
                      v-else
                      src="../../assets/icons/addCircle.png"
                      alt="plus-icon"
                      class="add-icon-disabled"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="First fill the box title"
                    /> -->
                    <!-- 
                    <img
                      src="../../assets/icons/downArrowbrown.png"
                      alt="plus-icon"
                      style="height: 13px; width: 13px; transform: rotate(-90deg)"
                    /> -->

                    <!-- <img
                      src="../../assets/icons/activityIcon.png"
                      alt="plus-icon"
                      class="fetched-image"
                      data-bs-toggle="modal"
                      data-bs-target="#HistoryModal"
                      @click="GoToDesignProjectInformodal(value.id, sectionIndex, index)"
                    />
                    <img
                      src="../../assets/icons/purchaseRequest.png"
                      alt="plus-icon"
                      class="fetched-image"
                      data-bs-toggle="modal"
                      data-bs-target="#CommentModal"
                      @click="GoToDesignProjectInformodal(value.id, sectionIndex, index)"
                    /> -->
                  </span>
                </section>

                <textarea
                  v-model="value.text"
                  :placeholder="'Text Area ' + (index + 1)"
                  @blur="
                    handleTextAreaChange(
                      section.id,
                      value.title,
                      value.text,
                      sectionIndex,
                      index
                    )
                  "
                  readonly
                ></textarea>

                <!-- <section class="d-flex justify-content-end">
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-secondary small-btn d-flex align-items-center"
                  >
                    <img
                      src="../../assets/icons/greyEdit.png"
                      alt="Edit Icon"
                      class="me-2"
                    />
                    Edit
                  </button>
                </section> -->
              </div>
            </div>

            <!-- <span id="AddSectionAreaImage">
              <img
                src="../../assets/icons/addArea.png"
                alt="plus-icon"
                @click="addTextArea(sectionIndex)"
              />
            </span> -->
          </div>
        </div>
        <div class="row container mt-1">
          <div class="col-3">
            <!-- <button
              @click="addSection"
              class="btn btn-sm btn-outline-primary text-nowrap d-flex justify-content-start"
            >
              Add Section
            </button> -->
          </div>
        </div>
      </div>
      <div>
        <img
          src="../../assets/icons/threeDot.jpg"
          alt=""
          srcset=""
          id="btnGroupDrop1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style="
            width: 15px;
            height: 16px;
            right: 5px;
            top: 10px;
            position: absolute;
            cursor: pointer;
            display: none;
          "
        />
        <div class="dropdown-menu ms-3 text-black p-2" aria-labelledby="btnGroupDrop1">
          <p data-bs-toggle="modal" data-bs-target="#PibShareModal">Share</p>
          <p>Download</p>
          <p>Delete</p>
        </div>
      </div>
    </div>

    <div v-if="isModalOpen" class="custom-modal" @click.self="closeModal">
      <div class="modal-content">
        <span class="close-btn" @click="closeModal">&times;</span>
        <img
          :src="currentImageUrl"
          :alt="'Image ' + (currentImageIndex + 1)"
          class="modal-image"
        />
        <div class="modal-controls">
          <button @click="prevImage" :disabled="currentImageIndex === 0">Prev</button>
          <button
            @click="nextImage"
            :disabled="currentImageIndex === currentImages.length - 1"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "sharepib",
  components: {},
  props: {
    uuidToken: {
      type: String,
      required: true,
    },
    accessToken: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isModalOpen: false,
      currentImages: [],
      currentImageIndex: 0,

      sectionIndex: null,
      valueIndex: null,
      selectedSignOffDocumentId: null,
      hover1: null,
      hover: null,
      selectedProjectId: null,
      TrashFiles: false,
      PhotoUrl: null,
      Id: this.$route.params.Id,
      ProjectName: this.$route.params.projectName,
      showProjectNameDropDown: false,
      showOverview: true,
      showProjectInfo: false,
      showProjectTimeLine: false,
      showTabularView: true,
      showGraphicalView: false,
      showEstimateOfDesignProject: false,
      sectionHeader: "",
      textAreas: [{ value: "" }],
      sections: [],
      sectionImages: [],
      attachedPhoto: [],
      attachedPhotoIds: [],
      timelineRows: [],
      estimates: [],
      EditDesignProject: false,
      SharewithDesignProject: false,
      AddMemberdiv: false,
      showTeamMember: false,
      showCollaborators: true,
      deleteDesignProject: false,
      mainProjectId: null,
      DesignProjectProfile: {
        customerName: "",
        projectName: "", // Ensure projectName is initialized
        customerNumber: "",
        customerId: "",
        customerPropertyName: "",
        familyStatus: "",
        buildingConfiguration: "",
        assignTo: "",
        projectStatus: "",
        designStage: "",
        projectDescription: "",
        designStartDate: "",
        contactNo: "",
        EmailId: "",
        propertyAddress: "",
      },
      currentStep: "",
      collaborators: [],
      showDropdown1: false,
      selectedEmployee: "",
      Employees: [],
      employeeId: "",
      enteredProjectName: "",
      confirmationText: "",
      showFileOfDesignProject: false,
      ParentFolderId: "0",
      ParentFolderName: "Root",
      Folders: [],
      attachedFiles: [],
      awsFileId: [],
      BackFolderId: "null",
      HeaderName: [],
      currentTimelineId: null,
      ProjectInfoBoxId: null,
      currentImageId: null,
      showInfoBox: true,
    };
  },

  computed: {
    currentImageUrl() {
      return this.currentImages[this.currentImageIndex]?.signedUrl || "";
    },
    isDesignExecutive() {
      const roles = this.$LocalUser.employeeRoles;
      return roles.some((role) => role.team === "Design" && role.role === "Executive");
    },
    filteredEmployees() {
      const searchTerm = this.selectedEmployee.toLowerCase();
      return this.Employees.filter((Employee) => {
        const fullName = `${Employee["users.firstname"]} ${Employee["users.lastname"]}`.toLowerCase();
        return fullName.includes(searchTerm);
      });
    },
  },
  mounted() {
    this.Id = this.$route.params.Id;
    console.log("UUID Token:", this.uuidToken);
    console.log("Access Token:", this.accessToken);
    this.fetchSectionData();
  },
  // watch: {
  //   "$route.params.Id"(newId) {
  //     this.Id = newId;
  //     this.fetchTimeLineData();
  //     this.featchProjectProfileData();
  //     this.fetchSectionData();
  //     this.fetchCollaborators();
  //     this.fetchEmployees();

  //     this.fetchFolderOrFiles();
  //   },
  // },

  methods: {
    openModal(images, index) {
      this.currentImages = images;
      this.currentImageIndex = index;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.currentImages = [];
      this.currentImageIndex = 0;
    },
    prevImage() {
      if (this.currentImageIndex > 0) {
        this.currentImageIndex--;
      }
    },
    nextImage() {
      if (this.currentImageIndex < this.currentImages.length - 1) {
        this.currentImageIndex++;
      }
    },
    async fetchSectionData() {
      this.sectionImages = [];
      try {
        if (this.accessToken) {
          axios.defaults.headers.common["Authorization"] = `Bearer ${this.accessToken}`;
        } else {
          delete axios.defaults.headers.common["Authorization"];
        }
        const response = await axios.get(
          `${this.$apiEndPoint}/projectInfoBoxSection/validateUUID/${this.uuidToken}`
          // {
          //   headers: {
          //     Authorization: `Bearer ${this.accessToken}`, // Replace with your actual access token source
          //   },
          // }
        );

        this.sections = response.data;

        // Set showInfoBox to true for every section
        this.sections.forEach((section) => {
          section.showInfoBox = true;
        });

        // Uncomment if you want to fetch images after fetching the sections
        // await Promise.all(
        //   this.sections.map((section) =>
        //     Promise.all(
        //       section.values.map((value) => this.fetchProjectInfoBoxImages(value.id))
        //     )
        //   )
        // );
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchSectionDataOfBox(ProjectInfoBoxId) {
      this.ProjectInfoBoxId = null;
      this.fetchProjectInfoBoxImages(ProjectInfoBoxId);
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/projectInfoBoxArea/${ProjectInfoBoxId}`
        );
        this.sections[this.sectionIndex].values[this.valueIndex].title =
          response.data.title;
        this.sections[this.sectionIndex].values[this.valueIndex].text =
          response.data.text;
        this.sectionIndex = null;
        this.valueIndex = null;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchProjectInfoBoxImages(boxId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/projectInfoBoxImages/${boxId}`
        );

        // Ensure response.data is an array and push it directly to sectionImages
        let data = Array.isArray(response.data) ? response.data : [response.data];
        this.sectionImages[boxId] = data; // Push the array as a whole

        await Promise.all(
          this.sectionImages[boxId].map(async (image) => {
            image.sectionPhotoUrl = await this.fetchInfoImage(
              image["awsFiles.awsFileName"],
              image["awsFiles.fileName"]
            );
          })
        );
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchInfoImage(AwsFileName, FileName) {
      const PayLoad = {
        awsFileName: AwsFileName,
        fileCategory: "projectInfoBox",
        moduleName: "design",
        fileName: FileName,
      };

      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/company/getFileSignedURL/`,
          PayLoad
        );
        return Object.values(response.data)[0];
      } catch (error) {
        this.$handleError(error);
        return null;
      }
    },
  },
};
</script>
<style scoped>
@import url("https://db.onlinewebfonts.com/c/405f05e6bf58471a25729ed060cda2d8?family=Glacial+Indifference+Regular");

.containerChild {
  height: calc(100% - 12px);
  width: calc(100% - 12px);
  margin: 5px 5px 5px 5px;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ffffff;
  border-radius: 15px;
  font-family: "Inter", sans-serif;
}

/* Firefox scrollbar customization */
.ProjectInfoContent {
  height: calc(100%);
  width: 100%;
  display: flex;

  overflow: auto;
  position: relative;

  /* Thumb and track colors */
}

.ProjectInfoContentSection {
  display: flex;
  flex-direction: column;
}

.sectionAreaOfProjectInfo1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 10px;
  position: relative;
}

.sectionAreaOfProjectInfo1::-webkit-scrollbar {
  display: none;
}

.sectionAreaOfProjectInfo1 #AddSectionAreaImage {
  align-items: center;
  position: relative;
  width: 20%;
}

.sectionAreaOfProjectInfo1 #AddSectionAreaImage img {
  display: flex;
  align-items: center;
  margin-top: 5%;
  cursor: pointer;
  width: 35px;
  height: 35px;
}

.ProjectInfoContent input {
  background-color: transparent;
  padding: 0.5% 1%;
  border: none;
  outline: none;
  color: #000000;
}

.sectionAreaOfProjectInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.sectionAreaOfProjectInfo div {
  margin: 10px 10px;
}

.sectionAreaOfProjectInfo textarea {
  height: 350px;
  width: 385px;
  margin-right: 5px;
  padding-left: 5px;
  border: none;
  font-size: 14px;
  border-radius: 0 0 15px 15px;
  resize: none; /* Disable resizing if needed */
}

.sectionAreaOfProjectInfo img {
  cursor: pointer;
}

#chart-container {
  display: inline-block;
  min-width: 100%;
  /* Ensure it at least takes up 100% width initially */
  width: 2000px;
  /* Arbitrary large width to ensure scrolling; adjust as needed */
}

#chart {
  width: 100%;
}

a {
  text-decoration: none;
}

.remarks-container {
  position: relative;
}

.image-icons-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px; /* Space between images and icons */
  overflow-x: auto;
  padding-right: 5px;
}

.fetched-image {
  height: 20px;
  width: 20px;
  border-radius: 5px;
  margin: 0 2px;
  object-fit: cover; /* Ensures image is contained within its container */
}

.add-icon,
.add-icon-disabled {
  height: 15px;
  width: 15px;
  cursor: pointer;
}

.add-icon-disabled:hover {
  cursor: not-allowed;
}
.btn-custom {
  width: fit-content;
  height: 30px;
  font-size: 12px;
  font-weight: bold;
  background-color: inherit;
  color: #0f0e0e;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  border: 1px solid #b1c5df;
  letter-spacing: 1px;
  gap: 8px;
  border-radius: 6px;
}

.btn-custom img {
  width: 18px;
  height: 18px;
}
.btn-custom:hover {
  background-color: purple;
  color: white;
}

/* Responsive Css  */

.small-btn {
  font-size: 12px; /* Smaller font size */
  padding: 6px 8px; /* Reduced padding */
  line-height: 1; /* Tighter spacing */
}
.small-btn img {
  height: 14px; /* Adjust icon size */
  width: 14px; /* Adjust icon size */
}
.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  min-width: 600px;
  text-align: center;
}

.modal-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover; /* Ensures the image covers the container */
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
}

.modal-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.modal-controls button {
  padding: 5px 6px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-controls button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@media (max-width: 767px) {
  .showProjectNameDropDown {
    border-radius: 5px;
    background-color: #f5f5f5;
    z-index: 999;
    border: 1px solid #333;
    width: 130px;
    height: 110px;
  }

  .head .head_1 {
    font-size: 24px;
  }

  .head .head_2 {
    font-size: 16px;
  }

  .DesignProjectHeader .DesignProjectHeaderRow1 input {
    display: flex;
    width: 25%;
    padding: 1%;
    border: none;
    outline: none;
    color: black;
  }

  .DesignProjectHeaderUsers {
    margin-left: -60%;
    display: flex;
    margin-bottom: 20px;
  }

  .DesignProjectComponent .DesignProjectComponentHeader {
    ul {
      flex-direction: column;
    }

    ul li {
      flex-direction: row;
    }

    ul li .progress {
      margin: 0 30px;
    }

    .progress::after {
      width: 5px;
      height: 20px;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }

    .one::after {
      height: 0;
    }

    ul li .icon {
      margin: 15px 0;
    }

    .DesignProjectProfileThings {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: left;
    }

    .DesignProjectProfileThings section span {
      display: flex;
      width: 100%;
      margin: 2%;
    }
  }

  ul {
    flex-direction: column;
  }

  ul li {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  ul li .text {
    margin-top: 5%;
  }

  ul li:nth-child(1) {
    margin-right: 3%;
  }

  ul li .progress {
    margin: 0 30px;
  }

  .progress::after {
    width: 5px;
    height: 20px;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }

  .one::after {
    height: 0;
  }

  ul li .icon {
    margin: 15px 0;
  }

  .DesignProjectProfileThings {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: left;
  }

  .DesignProjectProfileThings section span label {
    white-space: nowrap;
    font-size: 1rem;
  }

  .DesignProjectProfileThings section span input {
    background-color: transparent;
    font-size: 1rem;
    color: white;
    outline: none;
    border: 2px solid black;
    padding: 0.5% 1%;
  }
}
</style>
