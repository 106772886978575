<template>
  <div>
    <Header />
    <div class="content-container">
      <Nav />
      <div class="containerChild">
        <div class="attendance-page">
          <h6 class="text-start title mt-3 ms-3 mb-2">Attendance</h6>

          <div class="attendance-container">
            <!-- Filter Section -->
            <div class="row">
              <div class="col-12 d-flex justify-content-between flex-wrap gap-2">
                <div class="gap-3 d-flex">
                  <select
                    v-model="selectedTeam"
                    class="form-select me-3 year-select"
                    @change="handleTeamChange"
                  >
                    <option value="All">All</option>
                    <option
                      v-for="(department, index) in departments"
                      :key="index"
                      :value="department.name"
                    >
                      {{ department.name }}
                    </option>
                  </select>

                  <span class="d-flex align-items-center" v-if="TableView">
                    <label for="" class="text-nowrap me-2">Selected Date:</label>
                    <input
                      type="date"
                      class="form-control"
                      v-model="selectedDate"
                      @change="fetchMonthlyAttendance()"
                    />
                  </span>
                </div>

                <div>
                  <span
                    @click="exportToCSV"
                    class="btn btn-sm me-3 btn-outline-primary reportButton"
                    >Export</span
                  >
                  <button
                    v-if="TableView"
                    @click="changeview()"
                    class="btn btn-sm me-3 btn-outline-secondary reportButton"
                  >
                    Report -
                    <img
                      src="../../assets/icons/calandar.png"
                      class="mb-1"
                      style="width: 16px; height: 16px"
                      alt="Calendar Icon"
                    />
                  </button>
                  <button
                    v-else
                    @click="changeview()"
                    class="btn btn-sm me-3 btn-outline-secondary reportButton"
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>

            <!-- Conditional Views -->
            <div v-if="TableView">
              <!-- Table View -->
              <div class="row mt-3">
                <div class="col-12">
                  <div class="attendance-history-table-container">
                    <table class="table table-striped">
                      <thead class="sticky-top">
                        <tr>
                          <th>Employee Name</th>
                          <th>Employee ID</th>
                          <th>Login</th>
                          <th>Logout</th>
                          <th>Status</th>
                          <th>Attendance</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="record in attendanceHistory" :key="record.id">
                          <td @click="goNevigateWorkHubPage(record.id)">
                            <span style="margin-right: 20px">
                              <img
                                v-if="record.signedUrl"
                                :src="record.signedUrl"
                                style="width: 30px; height: 30px; border-radius: 50%"
                                alt=""
                              />
                              <img
                                v-else
                                class="profile-photo"
                                src="../../assets/icons/profile.png"
                                alt="Profile Photo"
                                style="width: 30px; height: 30px; border-radius: 50%"
                              />
                            </span>
                            {{ record.firstName }}&nbsp;{{ record.lastName }}
                          </td>
                          <td>{{ record.employeeNumber }}</td>
                          <td>
                            {{ formatTime(record["employeeAttendance.logInTime"]) }}
                          </td>
                          <td>
                            {{ formatTime(record["employeeAttendance.logOutTime"]) }}
                          </td>
                          <td>{{ record["employeeAttendance.status"] }}</td>
                          <!-- <td>
                            <span
                              v-if="record['employeeAttendance.attendance']"
                              :class="{
                                badge: true,
                                'bg-success':
                                  record[
                                    'employeeAttendance.attendance'
                                  ].toLowerCase() === 'present',
                                'bg-danger':
                                  record[
                                    'employeeAttendance.attendance'
                                  ].toLowerCase() === 'absent',
                                'bg-info':
                                  record[
                                    'employeeAttendance.attendance'
                                  ].toLowerCase() === 'halfday',
                              }"
                            >
                              {{ record["employeeAttendance.attendance"] }}
                            </span>
                          </td> -->
                          <td>
                            <div class="dropdown">
                              <!-- Display Attendance Value -->
                              <button
                                class="btn btn-sm dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                :class="{
                                  badge: true,
                                  'bg-success':
                                    record['employeeAttendance.attendance'] &&
                                    record[
                                      'employeeAttendance.attendance'
                                    ].toLowerCase() === 'present',
                                  'bg-danger':
                                    record['employeeAttendance.attendance'] &&
                                    record[
                                      'employeeAttendance.attendance'
                                    ].toLowerCase() === 'absent',
                                  'bg-warning':
                                    record['employeeAttendance.attendance'] &&
                                    record[
                                      'employeeAttendance.attendance'
                                    ].toLowerCase() === 'leave',
                                  'bg-info':
                                    record['employeeAttendance.attendance'] &&
                                    record[
                                      'employeeAttendance.attendance'
                                    ].toLowerCase() === 'halfday',
                                }"
                              >
                                {{
                                  record["employeeAttendance.attendance"] || "Not Marked"
                                }}
                              </button>

                              <!-- Dropdown Menu -->
                              <ul
                                class="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <li>
                                  <a
                                    class="dropdown-item"
                                    v-for="option in attendanceOptions"
                                    :key="option"
                                    @click="updateAttendance(record, option)"
                                  >
                                    {{ option }}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="attendanceHistory.length === 0">
                          <td colspan="6" class="text-center">
                            No attendance records found
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <!-- Non-Table View -->
              <div class="row mt-3">
                <!-- Left Section -->
                <div class="col-md-3">
                  <select
                    v-model="selectedYear"
                    class="form-select mb-3"
                    @change="updateMonthsAndDays"
                  >
                    <option v-for="year in years" :key="year" :value="year">
                      {{ year }}
                    </option>
                  </select>
                  <!-- <select
                    v-model="this.searchString"
                   
                  >
                  
                  </select> -->
                  <input
                    type="search"
                    name=""
                    class="form-control"
                    id=""
                    v-model="this.searchString"
                    placeholder="Search Employee Name..."
                    @input="fetchReportAttendance()"
                  />
                </div>

                <!-- Right Section -->
                <div class="col-md-9 overflow-auto">
                  <div>
                    <!-- <h6 class="text-start">Months</h6> -->
                    <ul
                      class="list-group mb-3 w-100"
                      style="display: flex; flex-direction: row !important"
                    >
                      <li
                        v-for="(month, index) in months"
                        :key="index"
                        class="list-group-item"
                        :class="{ active: index + 1 === selectedMonth }"
                        @click="handleMonthChange(index + 1)"
                        style="
                          cursor: pointer;
                          padding: 0.75% 1.75%;
                          border-radius: 0 !important; /* Remove border radius */
                        "
                      >
                        {{ month }}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <!-- <h6 class="text-start">Days</h6> -->
                    <ul
                      class="list-group"
                      style="
                        display: flex;
                        flex-direction: row;
                        font-size: 12px;
                        text-align: center;
                      "
                    >
                      <li
                        v-for="(day, index) in daysInMonth"
                        :key="index"
                        class="list-group-item"
                        style="
                          width: 50px; /* Set a fixed width for the boxes */
                          padding: 0; /* Remove padding */
                          border-radius: 0 !important; /* Remove border radius */
                          height: 50px; /* Set a fixed height if needed */
                          display: flex; /* Ensure proper alignment */
                          flex-direction: column;
                          align-items: center; /* Center the content vertically */
                          justify-content: center; /* Center the content horizontally */
                        "
                      >
                        <div>{{ day }}</div>
                        <small style="color: gray">{{ getDayName(index + 1) }}</small>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                class="row d-flex flex-row mt-3 attendance-history-table-container"
                style="padding-bottom: 100px"
              >
                <div class="col-md-3">
                  <div class="col-12">
                    <div class="">
                      <table class="table table-striped">
                        <thead class="sticky-top">
                          <tr>
                            <!-- <th class="">Employee Name</th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="record in ReportView" :key="record.id">
                            <!-- Employee Profile and Details -->
                            <td>
                              <div class="d-flex align-items-center gap-3">
                                <!-- Profile Picture -->
                                <img
                                  v-if="record.signedUrl"
                                  :src="record.signedUrl"
                                  style="width: 40px; height: 40px; border-radius: 50%"
                                  alt="Profile"
                                />
                                <img
                                  v-else
                                  src="../../assets/icons/profile.png"
                                  style="width: 40px; height: 40px; border-radius: 50%"
                                  alt="Default Profile"
                                />

                                <!-- Employee Name and Attendance Counts -->
                                <div>
                                  <div style="font-weight: bold">
                                    {{ record.firstName }} {{ record.lastName }}
                                  </div>
                                  <div class="d-flex gap-2 flex-wrap mt-1">
                                    <span
                                      v-for="count in record.counts"
                                      :key="count.type"
                                      class="badge rounded-pill"
                                      :class="getAttendanceClass(count.type)"
                                      style="padding: 3px 6px; font-size: 10px"
                                    >
                                      {{ count.type.charAt(0) }} - {{ count.count }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- No Records Message -->
                          <tr v-if="ReportView.length === 0">
                            <td colspan="6" class="text-center">
                              No attendance records found
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="col-md-9">
                  <div v-for="record in ReportView" :key="record.id" class="">
                    <ul
                      class="list-group"
                      style="
                        display: flex;
                        flex-direction: row;
                        font-size: 12px;
                        text-align: center;
                      "
                    >
                      <li
                        v-for="(day, index) in daysInMonth"
                        :key="index"
                        class="list-group-item"
                        style="
                          width: 50px; /* Set a fixed width for the boxes */
                          padding: 0; /* Remove padding */
                          border-radius: 0 !important; /* Remove border radius */
                          height: 58.5px; /* Set a fixed height if needed */
                          display: flex; /* Ensure proper alignment */
                          align-items: center; /* Center the content vertically */
                          justify-content: center; /* Center the content horizontally */
                        "
                        :style="{
                          color: getColor(
                            getAttendanceStatus(record.attendance, index + 1)
                          ),
                        }"
                      >
                        <div v-if="getDayNames(day) != 'Su'">
                          {{ getAttendanceStatus(record.attendance, index + 1) }}
                        </div>
                        <div v-else class="text-danger">S</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import axios from "axios";
export default {
  name: "Attendance",
  data() {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const companyRegisteredYear = parseInt(
      this.$LocalUser.user.companyRegisteredYear,
      10
    );
    return {
      selectedDate: new Date().toISOString().split("T")[0],
      attendanceOptions: ["Present", "Absent", "HalfDay", "Leave"],
      TableView: true,
      ReportView: [],
      searchString: "",
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      employees: [], // Populate with API or data source
      // daysInMonth: this.getDaysInMonth(currentYear, currentMonth),
      selectedYear: currentYear,

      selectedEmployeeId: this.$LocalUser.user.employeeId,
      attendanceHistory: [],
      todayAttendance: [],
      Checktype: "CheckIn",
      exactLocation: null,
      selectedLocationId: null,
      locationData: [],
      showLocationModal: false,
      currentDate: new Date().toISOString().split("T")[0],
      EmployeeprofileImage: null,
      employeeDetails: {},
      selectedTeam: "All", // Set default to current year
      selectedMonth: currentMonth, // Set default to current month index
      years: Array.from({ length: 11 }, (_, i) => companyRegisteredYear + i),
      departments: [
        { name: "Marketing" },
        { name: "Sales" },
        { name: "Design" },
        { name: "Procurement" },
        { name: "Onsite" },
        { name: "Execution" },
        { name: "Inventory" },
        { name: "Accounts" },
        { name: "HR" },
        { name: "Admin" },
      ],
    };
  },
  components: {
    Header,
    Nav,
  },
  mounted() {
    this.fetchMonthlyAttendance();
  },
  computed: {
    daysInMonth() {
      return Array.from(
        { length: new Date(this.selectedYear, this.selectedMonth, 0).getDate() },
        (_, i) => i + 1
      );
    },
  },
  methods: {
    exportToCSV() {
      // Define the headers
      const headers = [
        "First Name",
        "Last Name",
        "Employee Number",

        "Date",
        "Log In Time",
        "Log Out Time",
        "HR Marked Attendance",
        "Status",
      ];

      // Convert the data into CSV rows
      const csvData = this.attendanceHistory
        .map((item) => {
          return [
            item.firstName,
            item.lastName,
            item.employeeNumber,

            item["employeeAttendance.date"],
            item["employeeAttendance.logInTime"] || "", // Handle null logInTime
            item["employeeAttendance.logOutTime"] || "", // Handle null logOutTime
            item["employeeAttendance.attendance"],
            item["employeeAttendance.status"],
          ].join(","); // Join the values with commas
        })
        .join("\n"); // Join each row with a newline

      // Add headers and data to final CSV content
      const csvContent = headers.join(",") + "\n" + csvData;
      this.$downloadCSV(csvContent);
    },
    getAttendanceClass(type) {
      switch (type.toLowerCase()) {
        case "present":
          return "bg-success text-white";
        case "halfday":
          return "bg-info text-white";
        case "leave":
          return "bg-warning text-dark";
        case "absent":
          return "bg-danger text-white";
        default:
          return "bg-secondary text-white";
      }
    },
    async updateAttendance(record, newAttendance) {
      try {
        // Construct the API URL
        const url = `${this.$apiEndPoint}/attendance/updateAttendance/${record["employeeAttendance.id"]}`;

        // API Payload
        const payload = {
          attendance: newAttendance,
        };

        // Call the API using Axios
        const response = await axios.put(url, payload);

        // Handle the response
        if (response.status === 200) {
          this.fetchReportAttendance();
          // Update the record's attendance value
          record["employeeAttendance.attendance"] = newAttendance;
          console.log("Attendance updated successfully:", response.data);
        } else {
          console.error("Failed to update attendance:", response.data);
        }
      } catch (error) {
        console.error("Error updating attendance:", error.message);
      }
    },
    getColor(status) {
      switch (status) {
        case "A": // Absent
          return "red";
        case "P": // Present
          return "green";
        case "H": // Holiday
          return "yellow";

        default:
          return "transparent"; // Default background
      }
    },
    getAttendanceStatus(attendanceRecords, day) {
      const dateString = `${this.selectedYear}-${String(this.selectedMonth).padStart(
        2,
        "0"
      )}-${String(day).padStart(2, "0")}`;
      const attendanceRecord = attendanceRecords.find(
        (record) => record.date === dateString
      );
      return attendanceRecord ? attendanceRecord.status.charAt(0) : "-";
    },
    goNevigateWorkHubPage(Id) {
      this.$router.push({
        name: "MyWorkHub",
        params: {
          selectedEmployeeId: Id,
        },
      });
    },

    // Get day name (e.g., Monday)

    //   getDayName(day) {
    //   const date = new Date(this.selectedYear, this.selectedMonth - 1, day);
    //   return date.toLocaleString("en-US", { weekday: "short" }); // Returns short day name like 'Mon', 'Tue'
    // },
    getDayName(day) {
      const date = new Date(this.selectedYear, this.selectedMonth - 1, day);
      return date.toLocaleString("en-US", { weekday: "short" }).charAt(0); // Returns only the first letter of the day name
    },
    getDayNames(day) {
      const date = new Date(this.selectedYear, this.selectedMonth - 1, day);
      return date.toLocaleString("en-US", { weekday: "short" }).slice(0, 2); // Returns the first two letters of the day name
    },

    getDaysInMonth(year, month) {
      return Array.from({ length: new Date(year, month, 0).getDate() }, (_, i) => i + 1);
    },
    updateMonthsAndDays() {
      this.daysInMonth = this.getDaysInMonth(this.selectedYear, this.selectedMonth);
    },
    handleMonthChange(month) {
      this.selectedMonth = month;
      this.daysInMonth = this.getDaysInMonth(this.selectedYear, month);
    },
    changeview() {
      console.log(this.TableView); // Log the current value
      this.TableView = !this.TableView; // Toggle the value
    },

    async fetchMonthlyAttendance() {
      console.log(this.selectedDate);
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/attendance/getAttendance/${this.selectedDate}/${this.selectedTeam}/`
        );
        this.attendanceHistory = response.data;
        this.fetchReportAttendance();
      } catch (error) {
        this.$handleError(error);
      }
    },

    formatTime(time) {
      if (!time) return "";
      const [hours, minutes] = time.split(":");
      const ampm = hours >= 12 ? "p.m" : "a.m";
      const formattedHours = hours % 12 || 12;
      return `${formattedHours}:${minutes} ${ampm}`;
    },
    async fetchReportAttendance() {
      console.log(this.selectedTeam);
      console.log(this.selectedYear);
      console.log(this.selectedMonth);
      const searchData = {
        searchString: this.searchString,
      };
      console.log(searchData);
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/attendance/report/${this.selectedTeam}/${this.selectedYear}/${this.selectedMonth}`,
          searchData
        );
        this.ReportView = response.data;
        console.log(response.data);
      } catch (error) {
        this.$handleError(error);
      }
    },

    handleTeamChange() {
      if (this.selectedTeam) {
        this.fetchMonthlyAttendance();
      }
    },
    // ..

    closeModal() {
      this.showLocationModal = false;
    },
  },
};
</script>

<style scoped>
.attendance-page {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.title {
  font-size: 1.2rem;
  font-weight: bold;
}

.attendance-container {
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 10px;
  font-size: 0.9rem;
}

.profile-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #000;
}

.profile-image {
  display: flex;
  align-items: center;
  /* Align image to the start */
}

.TodayAttendanceContainer {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 10px;
  background-color: #eee;
}

.employee-details span {
  display: block;
  margin-bottom: 5px;
}

.employee-details label {
  font-size: 12px;
}

.employee-details-row {
  display: flex;
  justify-content: space-between;
  gap: 35px;
}

.action-buttons button {
  width: 80px;
  font-size: 0.8rem;
}

.section-title {
  font-size: 1rem;
  margin-bottom: 10px;
}

.attendance-table-container {
  max-height: 150px;
  overflow-y: auto;
}

.year-select,
.month-select {
  width: auto;
  font-size: 0.85rem;
}
.reportButton {
  height: 32px;
  margin-top: 1px;
}

.attendance-history-table-container {
  /* Replace percentage height with fixed height or viewport units */
  height: 70vh; /* or use vh units: 40vh */
  overflow-y: auto;
  /* Add these properties for better table behavior */
  position: relative;
}

table th {
  background-color: #ccc !important;
}

table th:first-child {
  border-radius: 5px 0 0 5px;
}

table th:last-child {
  border-radius: 0 5px 5px 0;
}

.modal-header {
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.location-ticker {
  margin-top: 10px;
  background: linear-gradient(90deg, #f8f9fa, #e9ecef);

  padding: 8px;
  overflow: hidden;
  position: relative;
}

.ticker-content {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #495057;
  animation: slide 10s linear infinite;
  white-space: nowrap;
}

.ticker-content i {
  color: #dc3545;
  margin-right: 8px;
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

/* Optional: Pause animation on hover */
.location-ticker:hover .ticker-content {
  animation-play-state: paused;
}

.location-table-body tr:hover {
  background-color: #f0f0f0;
}

.close-btn {
  font-size: 24px;
  cursor: pointer;
}
input:focus,
textarea:focus,
select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}
input,
textarea,
select,
label {
  font-size: 14px;
}
.export-btn {
  display: inline-block;
  padding: 3px 16px;
  background-color: #007bff; /* Blue background */
  color: white;
  border-radius: 4px; /* Rounded corners */
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.export-btn:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.export-btn:active {
  background-color: #004085; /* Even darker blue when clicked */
}

@media (max-width: 768px) {
  .employee-details-row {
    flex-direction: column;
    gap: 10px;
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;
  }

  .TodayAttendanceContainer {
    margin-left: 0 !important;
    /* Removes ms-5 margin on mobile */
    margin-top: 15px;
    /* Adds some vertical spacing instead */
  }

  .attendance-page {
    height: 100%;
    width: 100%;
    overflow: scroll;
  }
}
</style>
