<template>
  <SalesTargetModal :selectedEmployeeId="selectedEmployeeId" />
  <!-- <div class="dashboardcard-container" v-if="cardLoading">
    <div class="skeleton skeleton-dashboardCard"></div>
    <div class="skeleton skeleton-dashboardCard"></div>
    <div class="skeleton skeleton-dashboardCard"></div>
    <div class="skeleton skeleton-dashboardCard"></div>
  </div> -->
  <div class="dashboardcard-container">
    <div class="card dashboardcard" :class="{ skeleton: cardLoading }">
      <template v-if="!cardLoading">
        <a @click="goToTotalSales()" style="text-decoration: none; color: inherit">
          <div class="d-flex justify-content-between flex-row p-1 m-2">
            <span id="totalSalesAmount" class="cardValue">
              {{ formattedTotalSalesAmount }}
            </span>
            <span>
              <img src="../../assets/icons/salesTarget.png" alt="" />
            </span>
          </div>
          <div class="ms-2 p-1">
            <span id="textColorOfCard">Total Sales</span>
          </div>
        </a>
      </template>
    </div>

    <div class="card dashboardcard" :class="{ skeleton: cardLoading }">
      <template v-if="!cardLoading">
        <div class="d-flex justify-content-between flex-row p-1 m-2">
          <span id="conversionRatio" class="cardValue">
            {{ this.Data.conversionRatio }} %</span
          >
          <span>
            <img src="../../assets/icons/convesionRatio.png" alt="" />
          </span>
        </div>
        <div class="ms-2 p-1">
          <span id="textColorOfCard">Conversion Ratio</span>
        </div>
      </template>
    </div>

    <div
      class="card dashboardcard"
      :class="{ skeleton: cardLoading }"
      @click="goToProjectTableByStatus('In Progress')"
    >
      <template v-if="!cardLoading">
        <div class="d-flex justify-content-between flex-row p-1 m-2">
          <span id="activeProjects" class="cardValue">
            {{ this.Data.activeProjects }}
          </span>
          <span>
            <img src="../../assets/icons/activeProjects.png" alt="" />
          </span>
        </div>
        <div class="ms-2 p-1">
          <span id="textColorOfCard">Active Projects</span>
        </div>
      </template>
    </div>

    <div
      class="card dashboardcard"
      :class="{ skeleton: cardLoading }"
      @click="goToProjectTableByStatus('Completed')"
    >
      <template v-if="!cardLoading">
        <div class="d-flex justify-content-between flex-row p-1 m-2">
          <span class="cardValue" id="totalProjects"> {{ this.Data.totalProjects }}</span>
          <span>
            <img src="../../assets/icons/completedProjects.png" alt="" />
          </span>
        </div>
        <div class="ms-2 p-1">
          <span id="textColorOfCard">Completed Projects</span>
        </div>
      </template>
    </div>
  </div>
  <div class="section WhiteHeadSection">
    <div class="container-fluid contentOfWhiteHeadSection">
      <div class="contentOfWhiteHeadSection2">
        <div class="LeadChart p-2" style="flex: 1" :class="{ skeleton: cardLoading }">
          <template v-if="!cardLoading">
            <div class="d-flex justify-content-between" style="padding: 2px 6px">
              <div class="d-flex flex-column">
                <span class="subHeadingHeader">Leads Summary</span>
                <span>
                  <div
                    class="d-flex justify-content-between align-content-center text-muted mt-1"
                    style="margin-right: 75px; font-size: 12px"
                  >
                    Unclaimed Leads &nbsp;: &nbsp;
                    <strong>{{ this.FreshLeads }}</strong>
                  </div>
                </span>
              </div>
              <div class="">
                <div class="d-flex ms-5">
                  <p
                    class="text-muted p-0 m-1 text-center text-nowrap"
                    style="font-size: 12px"
                  >
                    Sort by:
                  </p>
                  <select
                    v-model="selectedSourceId"
                    @change="setSelectedSource"
                    style="
                      margin-left: 10px;
                      font-size: 12px;
                      height: 30px;
                      max-width: 300px;
                      :focus {
                        outline: none;
                        box-shadow: none;
                      }
                    "
                    class="form-select"
                  >
                    <option value="All" selected>Overall Sources</option>
                    <option v-for="source in sources" :key="source.id" :value="source.id">
                      {{ source.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div v-if="leadCounts" class="ms-2" style="font-size: 12px">
              <div>
                <apexchart
                  height="280"
                  width="100%"
                  type="bar"
                  :options="chartOptions"
                  :series="series"
                >
                </apexchart>
              </div>
            </div>
          </template>
        </div>

        <div class="LeadChart p-2" style="flex: 1" :class="{ skeleton: cardLoading }">
          <template v-if="!cardLoading">
            <div class="d-flex justify-content-between" style="padding: 2px 6px">
              <div>
                <span class="subHeadingHeader m-2">Total Sales</span>

                <span
                  v-if="this.selectedTerm === 'month'"
                  class="d-flex justify-content-between align-content-center ms-2"
                >
                  <span class="mt-1">
                    ₹
                    {{
                      SalesTargetReport.myMonthlyTarget
                        ? SalesTargetReport.myMonthlyTarget.toLocaleString("en-IN")
                        : "0.00"
                    }}</span
                  >
                  &nbsp; &nbsp;
                  <span>
                    <span style="font-size: 10px">
                      <span :class="monthlyChangeClass" class="p-1 change-class">
                        <span class="ms-1">
                          <img
                            v-if="SalesTargetReport.previousMonthPercent < 0"
                            src="../../assets/icons/redDecrease.png"
                            style="width: 10px; height: 10px"
                            alt=""
                          />
                          <img
                            v-else
                            src="../../assets/icons/greenIncreaseIcon.png"
                            style="width: 10px; height: 10px"
                            alt=""
                          />
                        </span>
                        {{ SalesTargetReport.previousMonthPercent }}
                      </span>
                      &nbsp; from Last Month
                    </span>
                  </span>
                </span>
                <span
                  v-if="this.selectedTerm === 'quarter'"
                  class="d-flex justify-content-between align-content-center ms-2"
                >
                  <span class="mt-1">
                    ₹
                    {{
                      SalesTargetReport.quarterlyAchievedAmount
                        ? SalesTargetReport.quarterlyAchievedAmount.toLocaleString(
                            "en-IN"
                          )
                        : "0.00"
                    }}</span
                  >
                  &nbsp; &nbsp;
                  <span>
                    <span style="font-size: 10px">
                      <span :class="quarterlyChangeClass" class="p-1 change-class">
                        <span class="ms-1">
                          <img
                            v-if="SalesTargetReport.previousQuarterPercent < 0"
                            src="../../assets/icons/redDecrease.png"
                            style="width: 10px; height: 10px"
                            alt=""
                          />
                          <img
                            v-else
                            src="../../assets/icons/greenIncreaseIcon.png"
                            style="width: 10px; height: 10px"
                            alt=""
                          />
                        </span>
                        {{ SalesTargetReport.previousQuarterPercent }}
                      </span>
                      &nbsp;from last quarter
                    </span>
                  </span>
                </span>
                <span
                  v-if="this.selectedTerm === 'year'"
                  class="d-flex justify-content-between align-content-center ms-2"
                >
                  <span class="mt-1">
                    ₹
                    {{
                      SalesTargetReport.myYearlyAchievedTarget
                        ? SalesTargetReport.myYearlyAchievedTarget.toLocaleString("en-IN")
                        : "0.00"
                    }}</span
                  >
                  &nbsp; &nbsp;
                  <span>
                    <span style="font-size: 10px">
                      <span :class="yearlyChangeClass" class="p-1 change-class">
                        <span class="ms-1">
                          <img
                            v-if="SalesTargetReport.previousYearPercent < 0"
                            src="../../assets/icons/redDecrease.png"
                            style="width: 10px; height: 10px"
                            alt=""
                          />
                          <img
                            v-else
                            src="../../assets/icons/greenIncreaseIcon.png"
                            style="width: 10px; height: 10px"
                            alt=""
                          />
                        </span>
                        {{ SalesTargetReport.previousYearPercent }}
                      </span>
                      &nbsp; from last year
                    </span>
                  </span>
                </span>
              </div>

              <div class="">
                <div class="d-flex ms-5">
                  <p
                    class="text-muted p-0 m-1 text-center text-nowrap"
                    style="font-size: 12px"
                  >
                    Sort by:
                  </p>
                  <select
                    v-model="selectedTerm"
                    @change="setSelectedTerm"
                    style="
                      margin-left: 10px;
                      font-size: 12px;
                      height: 30px;
                      cursor: pointer;
                      :focus {
                        outline: none;
                        box-shadow: none;
                      }
                    "
                    class="form-select"
                  >
                    <option value="month">Monthly</option>
                    <option value="quarter">Quarterly</option>
                    <option value="year">Yearly</option>
                  </select>
                </div>
              </div>
            </div>

            <div v-if="SalesTargetReport" class="ms-2" style="font-size: 12px">
              <div id="chart">
                <apexchart
                  type="bar"
                  height="280"
                  :options="chartOptions4"
                  :series="series4"
                ></apexchart>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="contentOfWhiteHeadSection2">
        <div class="otherChart" style="flex: 1">
          <section
            class="card"
            style="padding: 2px 6px"
            :class="{ skeleton: cardLoading }"
          >
            <div class="d-flex flex-column" v-if="!cardLoading">
              <p class="p-1 m-0" style="font-size: 14px; border-bottom: 1px solid #000">
                Recently Closed Deals
              </p>
              <div
                class="d-flex align-items-center"
                style="padding: 12px 0"
                v-for="project in lastThreeProjects"
                :key="project.id"
              >
                <div class="ms-2">
                  <img
                    src="../../assets/icons/customerIcon.png"
                    alt=""
                    style="width: 30px; height: 30px"
                  />
                </div>
                <div
                  class="d-flex flex-column align-items-start ms-2"
                  style="font-size: 12px"
                >
                  <p class="m-0 p-0" style="font-size: 14px; font-weight: 600">
                    {{ project["customers.name"] }}
                  </p>
                  <p
                    class="m-0 p-0 d-flex justify-content-between"
                    style="font-size: 10px; width: 200px"
                  >
                    <span class="text-muted">{{ project["customers.contactNo"] }} </span>
                    <span class="text-muted">
                      ₹
                      {{
                        project.amount ? project.amount.toLocaleString("en-IN") : "0.00"
                      }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section
            class="card chartsection"
            style="padding: 2px 6px"
            :class="{ skeleton: cardLoading }"
          >
            <div id="" class="mt-3" v-if="!cardLoading">
              <apexchart
                type="radialBar"
                :options="chartOptionss"
                :series="seriess"
                height="250"
              ></apexchart>
            </div>
          </section>
        </div>
        <div class="otherChart" style="flex: 1">
          <section
            class="card chartsection"
            style="padding: 2px 6px"
            :class="{ skeleton: cardLoading }"
          >
            <div id="chart" :title="this.series3" v-if="!cardLoading">
              <a href="/SalesTarget" style="text-decoration: none; color: inherit">
                <apexchart
                  height="300"
                  type="radialBar"
                  :options="chartOptions3"
                  :series="series3"
                ></apexchart>
              </a>
            </div>
          </section>
          <section
            class="card"
            style="padding: 2px 6px"
            :class="{ skeleton: cardLoading }"
          >
            <div class="d-flex flex-column" v-if="!cardLoading">
              <p class="p-1 m-0" style="font-size: 14px; border-bottom: 1px solid #000">
                My Closed Deals
              </p>
              <div class="d-flex" style="padding: 13px 0px">
                <div class="ms-2" style="width: 14px; height: 30px">₹</div>
                <div class="d-flex flex-column align-items-start">
                  <p class="m-0 p-0" style="font-size: 14px; font-weight: 500">
                    {{
                      SalesTargetReport.myMonthlyTarget
                        ? SalesTargetReport.myMonthlyTarget.toLocaleString("en-IN")
                        : "0.00"
                    }}
                  </p>

                  <p
                    class="m-0 p-0 d-flex justify-content-between"
                    style="font-size: 10px; width: 250px"
                  >
                    <span style="font-size: 12px" class="text-muted">This Month</span>
                    <span class="text-left">
                      <span :class="monthlyChangeClass" class="p-1 change-class">
                        <span class="ms-1">
                          <img
                            v-if="SalesTargetReport.previousMonthPercent < 0"
                            src="../../assets/icons/redDecrease.png"
                            style="width: 10px; height: 10px"
                            alt=""
                          />
                          <img
                            v-else
                            src="../../assets/icons/greenIncreaseIcon.png"
                            style="width: 10px; height: 10px"
                            alt=""
                          />
                        </span>
                        {{ SalesTargetReport.previousMonthPercent }}
                      </span>
                      &nbsp; from Last Month
                    </span>
                  </p>
                </div>
              </div>
              <div class="d-flex" style="padding: 13px 0px">
                <div class="ms-2" style="width: 14px; height: 30px">₹</div>
                <div class="d-flex flex-column align-items-start">
                  <p class="m-0 p-0" style="font-size: 14px; font-weight: 500">
                    {{
                      SalesTargetReport.quarterlyAchievedAmount
                        ? SalesTargetReport.quarterlyAchievedAmount.toLocaleString(
                            "en-IN"
                          )
                        : "0.00"
                    }}
                  </p>
                  <p
                    class="m-0 p-0 d-flex justify-content-between"
                    style="font-size: 10px; width: 250px"
                  >
                    <span style="font-size: 12px" class="text-muted">This Quarter</span>
                    <span class="d-flex">
                      <span :class="quarterlyChangeClass" class="p-1 change-class">
                        <span class="ms-1">
                          <img
                            v-if="SalesTargetReport.previousQuarterPercent < 0"
                            src="../../assets/icons/redDecrease.png"
                            style="width: 10px; height: 10px"
                            alt=""
                          />
                          <img
                            v-else
                            src="../../assets/icons/greenIncreaseIcon.png"
                            style="width: 10px; height: 10px"
                            alt=""
                          />
                        </span>
                        {{ SalesTargetReport.previousQuarterPercent }}
                      </span>
                      &nbsp; from last quarter
                    </span>
                  </p>
                </div>
              </div>
              <div class="d-flex" style="padding: 13px 0px">
                <div class="ms-2" style="width: 14px; height: 30px">₹</div>
                <div class="d-flex flex-column align-items-start">
                  <p class="m-0 p-0" style="font-size: 14px; font-weight: 500">
                    {{
                      SalesTargetReport.myYearlyAchievedTarget
                        ? SalesTargetReport.myYearlyAchievedTarget.toLocaleString("en-IN")
                        : "0.00"
                    }}
                  </p>
                  <p
                    class="m-0 p-0 d-flex justify-content-between"
                    style="font-size: 10px; width: 250px"
                  >
                    <span style="font-size: 12px" class="text-muted">This Year</span>
                    <span class="d-flex">
                      <span :class="yearlyChangeClass" class="p-1 change-class">
                        <span class="ms-1">
                          <img
                            v-if="SalesTargetReport.previousYearPercent < 0"
                            src="../../assets/icons/redDecrease.png"
                            style="width: 10px; height: 10px"
                            alt="Decrease Icon"
                          />
                          <img
                            v-else
                            src="../../assets/icons/greenIncreaseIcon.png"
                            style="width: 10px; height: 10px"
                            alt="Increase Icon"
                          />
                        </span>
                        <span>
                          <!-- Show "500%+" if percentage exceeds 500 -->
                          <span v-if="(SalesTargetReport.previousYearPercent = 500)">
                            500% +</span
                          >
                          <span v-else>{{ SalesTargetReport.previousYearPercent }}%</span>
                        </span>
                      </span>
                      &nbsp; &nbsp; &nbsp; from last year
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="contentOfWhiteHeadSection2">
        <div class="LeadChart2 p-2" style="flex: 1" :class="{ skeleton: cardLoading }">
          <template v-if="!cardLoading">
            <div class="row w-100 ms-1" style="padding: 2px 6px">
              <p class="subHeadingHeader p-1 m-0">Today’s Task</p>
              <hr />
            </div>
            <div
              class="card card2 p-2 m-2"
              v-for="task in todayTasks"
              :key="task.id"
              data-bs-toggle="modal"
              data-bs-target="#taskModal"
              @click="openTaskModal(task.id)"
            >
              <div class="d-flex justify-content-between align-items-center">
                <section class="d-flex align-items-center">
                  <img
                    v-if="task.status === Pending"
                    src="../../assets/icons/taskPending.png"
                    id="titleIcon"
                    alt=""
                    style="width: 17px; height: 17px"
                  />
                  <img
                    v-else
                    src="../../assets/icons/taskCompleted.png"
                    alt="Task Completed"
                    style="width: 17px; height: 17px"
                  />
                  <span
                    :title="task.title"
                    class="ms-2 ellipsis"
                    style="font-size: 14px"
                    >{{ task.title }}</span
                  >
                </section>
                <section class="d-flex align-items-center">
                  <span class="d-flex align-items-center" style="padding: 0 10px">
                    <img
                      src="../../assets/icons/subtask.png"
                      alt="Subtask"
                      style="width: 15px; height: 15px"
                    />
                    <sup style="font-size: 10px; font-weight: 600" class="ms-1">
                      {{ task.subtaskCnt }}
                    </sup>
                  </span>
                  <span style="padding: 0 5px" v-if="task.priority">
                    <button
                      :class="priorityButtonClass(task)"
                      style="
                        width: 70px;
                        height: 25px;
                        font-size: 10px;
                        border: none;
                        border-radius: 5px;
                      "
                    >
                      {{ task.priority }}
                    </button>
                  </span>
                  <span style="padding: 0 5px">
                    <img
                      src="../../assets/icons/threeDot.jpg"
                      alt="More options"
                      style="width: 15px; height: 13px"
                    />
                  </span>
                </section>
              </div>

              <div class="mt-2 d-flex justify-content-between">
                <section class="d-flex align-items-center">
                  <img
                    src="../../assets/icons/black_Calendar.png"
                    alt="Calendar"
                    style="width: 17px; height: 17px"
                  />
                  <span class="mt-1 ms-2 text-muted" style="font-size: 10px">
                    {{ reverseDateFormat(task.date) }}
                  </span>
                </section>
                <section>
                  <span class="collaborator-avatar">
                    <span
                      class="d-flex initials"
                      v-for="(employee, employeeIndex) in task.collaborator || []"
                      :key="employeeIndex"
                    >
                      <span>{{ employee.initials }}</span>
                    </span>
                  </span>
                </section>
              </div>
            </div>
          </template>
        </div>

        <div class="LeadChart2 p-2" style="flex: 1" :class="{ skeleton: cardLoading }">
          <template v-if="!cardLoading">
            <div class="row w-100 ms-1" style="padding: 2px 6px">
              <p class="subHeadingHeader p-1 m-0">Today’s Schedules</p>
              <hr />
            </div>

            <div
              @click="changeView('day')"
              class="card card3 p-2 m-2 border-0 blueactive-SubHeading2 justify-content-between"
              v-for="event in todayEvents"
              :key="event.id"
            >
              <div class="m-1">
                <p style="font-size: 14px; font-weight: 600" class="p-0 mb-1">
                  {{ event.title }}
                </p>
                <p style="font-size: 10px" v-if="event.description !== null">
                  {{ event.description }}
                </p>
              </div>
              <div
                class="mb-1 m-1"
                style="font-size: 12px"
                v-if="event.meetingLink !== null"
              >
                <span>
                  <a :href="'//' + event.meetingLink" target="_blank">{{
                    event.meetingLink
                  }}</a>
                </span>
              </div>
              <div class="d-flex justify-content-between mt-1">
                <section style="font-size: 12px">
                  <p class="p-0 m-1 d-flex align-items-center">
                    <img
                      src="../../assets/icons/time.png"
                      alt="Calendar"
                      class="mt-2"
                      style="width: 15px; height: 15px"
                    />
                    <span class="mt-2 ms-2 text-muted" style="font-size: 10px">
                      {{ convertTo12HourFormat(event.fromTime) }} To
                      {{ convertTo12HourFormat(event.toTime) }}
                    </span>
                  </p>
                  <p class="p-0 m-1 d-flex align-items-center">
                    <img
                      src="../../assets/icons/Calendar.png"
                      alt="Calendar"
                      class="mt-1"
                      style="width: 15px; height: 15px"
                    />
                    <span class="mt-2 ms-2 text-muted" style="font-size: 10px">
                      {{ reverseDateFormat(event.date) }}
                    </span>
                  </p>
                </section>
                <section class="d-flex align-items-center">
                  <span class="collaborator-avatar">
                    <span
                      class="d-flex initials"
                      v-for="(employee, employeeIndex) in event.eventParticipants || []"
                      :key="employeeIndex"
                    >
                      <span>{{ employee["users.initials"] }}</span>
                    </span>
                  </span>
                </section>
              </div>
            </div>
            <div
              @click="changeView('day')"
              class="card card3 p-2 m-2 border-0 Yellow-SubHeading2 justify-content-between"
              v-for="item in todayReminders"
              :key="item.id"
              style=""
            >
              <div class="m-1">
                <p style="font-size: 14px; font-weight: 600" class="p-0 mb-1">
                  {{ item.title }}
                </p>
                <p style="font-size: 10px" v-if="item.description !== null">
                  {{ item.description }}
                </p>
              </div>

              <div class="d-flex justify-content-between mt-1">
                <section style="font-size: 12px">
                  <p class="p-0 m-1 d-flex align-items-center">
                    <img
                      src="../../assets/icons/time.png"
                      alt="Calendar"
                      class="mt-2"
                      style="width: 15px; height: 14px"
                    />
                    <span class="mt-2 ms-2 text-muted" style="font-size: 10px">
                      {{ convertTo12HourFormat(item.fromTime) }} To
                      {{ convertTo12HourFormat(item.toTime) }}
                    </span>
                  </p>
                  <p class="p-0 m-1 d-flex align-items-center">
                    <img
                      src="../../assets/icons/Calendar.png"
                      alt="Calendar"
                      class="mt-1"
                      style="width: 15px; height: 15px"
                    />
                    <span class="mt-2 ms-2 text-muted" style="font-size: 10px">
                      {{ reverseDateFormat(item.date) }}
                    </span>
                  </p>
                </section>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SalesTargetModal from "../Modal/SalesTargetModal";
import axios from "axios";
import VueApexCharts from "vue3-apexcharts";
export default {
  name: "SalesDashboard",
  props: {
    SelectedEmployeeId: {
      type: Number,
      required: true,
    },
  },
  emits: ["fetchTasks"], // Declare the event being emitted
  components: {
    SalesTargetModal,
    apexchart: VueApexCharts,
  },

  data() {
    return {
      cardLoading: true,
      FreshLeads: null,
      selectedTerm: "month",
      SalesTargetReport: {
        myMonthlyTarget: "",
        achievedAmount: {},
        targetAmount: {},
        previousMonthPercent: "",
        quarterlyAchievedAmount: "",
        previousQuarterPercent: "",
        myYearlyAchievedTarget: "",
        myPreviousYearAchievedTarget: "",
        previousYearPercent: "",
        // other fields
      },
      TargetData: {},
      Data: {},
      Target: {},
      todayTasks: [],
      todayEvents: [],
      todayReminders: [],
      currentDay: "",
      formattedDate: "",
      fullName: "",
      greeting: "",
      selectedSourceId: "All",
      selectedEmployeeId: this.SelectedEmployeeId,
      ShowTargetSetter: false,
      employees: [],
      sources: [],
      leadCounts: {},
      SalesTarget: {},
      lastThreeProjects: [],
      chartOptions: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: "#000000",
              fontWeight: "400", // Change color to black
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "#000000",
              fontWeight: "400", // Change color to black
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            borderRadiusApplication: "end",
            horizontal: true,
          },
        },
      },
      series: [
        {
          name: "series-1",
          data: [],
        },
      ],

      seriess: [],
      chartOptionss: {
        chart: {
          type: "radialBar",
          offsetY: -20,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -125,
            endAngle: 125,
            track: {
              background: "#e7e7e7",
              strokeWidth: "97%",
              margin: 10, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: "#999",
                opacity: 1,
                blur: 2,
              },
            },
            dataLabels: {
              name: {
                fontSize: "16px",
                color: undefined,
                offsetY: 105,
              },
              value: {
                offsetY: -13,
                offsetX: 2,
                fontSize: "22px",
                fontweight: "800",
              },
            },
          },
        },
        grid: {
          padding: {
            top: -10,
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91],
          },
        },
        labels: ["Estimate To Sold Ration"],
      },

      series3: [], // Example series value representing the percentage
      salesTargetValue: "", // The value you want to display inside the radial bar chart
      chartOptions3: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "60%", // Reduced hollow size for a thicker circle
            },
            dataLabels: {
              name: {
                show: true,
                fontSize: "22px",
                offsetY: -10,
              },
              value: {
                show: true,
                fontSize: "16px",
                offsetY: 5,
                formatter: function (val) {
                  return `${val}%`;
                },
                background: {
                  enabled: true,
                  foreColor: "#ffffff", // Set the text color to white
                  borderRadius: 5,
                  padding: 5,
                  opacity: 1,
                  borderWidth: 5,
                  borderColor: "#000000", // Set the border color to black
                  style: {
                    fontSize: "16px",
                    fontWeight: "bold",
                    background: "#000000", // Set the background color to black
                  },
                },
              },
              total: {
                show: true,
                label: "Sales Target",
                fontSize: "12px",
                formatter: () => `${this.$formatToIndianCurrency(this.salesTargetValue)}`, // Format the total valueFormat the total value
              },
            },
          },
        },
        colors: ["#00ff00"], // Set the radial bar color to green
      },

      series4: [],
      chartOptions4: {
        chart: {
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: false, // Change to false to make the bars vertical
            columnWidth: "50%", // Adjust width based on selectedTerm
            borderRadius: 5, // Adjust the border radius
            dataLabels: {
              position: "",
            },
          },
        },
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: "Values",
          },
        },
      },
    };
  },
  computed: {
    monthlyChangeClass() {
      return this.SalesTargetReport.previousMonthPercent < 0
        ? "bga-danger "
        : "bga-success ";
    },
    quarterlyChangeClass() {
      return this.SalesTargetReport.previousQuarterPercent < 0
        ? "bga-danger "
        : "bga-success ";
    },
    yearlyChangeClass() {
      return this.SalesTargetReport.previousYearPercent < 0
        ? "bga-danger "
        : "bga-success";
    },
    formattedTotalSalesAmount() {
      const achievedAmount = this.TargetData?.achievedAmount || 0;

      // Convert to crore, lakh, or thousand as appropriate and format the amount
      if (achievedAmount >= 10000000) {
        const croreAmount = achievedAmount / 10000000;
        return `${this.$formatToIndianCurrency(croreAmount)} Cr`;
      } else if (achievedAmount >= 100000) {
        const lakhAmount = achievedAmount / 100000;
        return `${this.$formatToIndianCurrency(lakhAmount)} Lac`;
      }
      // } else if (achievedAmount >= 1000) {
      //     const thousandAmount = achievedAmount / 1000;
      //     return `${this.$formatToIndianCurrency(thousandAmount)} K`;
      // }

      // For amounts less than 1,000, format directly
      return this.$formatToIndianCurrency(achievedAmount);
    },

    formattedTargetAmount() {
      return this.formatNumber(this.TargetData?.targetAmount || 0);
    },
    formattedOutstandingAmount() {
      return this.formatNumber(this.TargetData?.outstandingAmount || 0);
    },

    priorityButtonClass() {
      return (task) => {
        // Determine which class to apply based on data.priority
        switch (task.priority) {
          case "Low":
            return "btn-priority-low"; // Define this class in your CSS
          case "Medium":
            return "btn-priority-medium"; // Define this class in your CSS
          case "High":
            return "btn-priority-high"; // Define this class in your CSS
          case "1":
            return "btn-priority-low"; // Define this class in your CSS
          case "2":
            return "btn-priority-medium"; // Define this class in your CSS
          case "3":
            return "btn-priority-high"; // Define this class in your CSS
          default:
            return ""; // Default class if no priority is set or unknown priority
        }
      };
    },
    titleBackgroundColorClass() {
      return (priority) => {
        if (priority === "Low") {
          return "priority-low";
        } else if (priority === "Medium") {
          return "priority-medium";
        } else if (priority === "High") {
          return "priority-high";
        }
        return "";
      };
    },
  },

  mounted() {
    this.selectedEmployeeId = this.SelectedEmployeeId;
    this.setCurrentDate();
    if (this.selectedEmployeeId) {
      this.fetchSources();
      this.fetchEmployees();
      this.fetchLeadCounts();
    }
    // this.setDynamicYAxisMax();
  },

  watch: {
    SelectedEmployeeId(newVal) {
      this.selectedEmployeeId = newVal;
      this.fetchLeadCounts();
      this.fetchSalesTargetReport();
    },
  },

  methods: {
    // setDynamicYAxisMax() {
    //   // Calculate the highest number in the series data
    //   const maxValue = Math.max(...this.series[0].data);

    //   // Add a margin (e.g., 10%) to the max value for better chart spacing
    //   const maxYAxisValue = maxValue * 1.1;

    //   // Set the dynamic max value to the chart options
    //   this.chartOptions.yaxis.max = maxYAxisValue;
    // },

    goToProjectTableByStatus(statusName) {
      // Navigate to the DesignProjectTableByStatus route with params
      this.$router.push({
        name: "ProjectListByStatus",
        params: {
          statusName: statusName,
          selectedEmployeeId: this.selectedEmployeeId,
        },
      });
    },
    goToTotalSales() {
      this.$router.push({
        name: "TotalSales",
        params: {
          selectedEmployeeId: this.selectedEmployeeId,
        },
      });
    },
    fetchTasks() {
      this.$emit("fetchTasks");
      console.log("dahsboard page");
      this.fetchTodayTasks();
      this.fetchTodayEvents();
      this.fetchTodayReminders();
    },
    openTaskModal(taskId) {
      this.$emit("task-selected", taskId);
    },

    changeView(view) {
      this.currentView = view; // Update the currentView when changing the view
      if (view === "year") {
        // Navigate to the calendar page with the current year
        const currentYear = new Date().getFullYear();
        this.$router.push({ name: "Calendar", params: { year: currentYear } });
      } else if (view === "month") {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Month index starts from 0, so add 1
        this.$router.push({
          name: "Month",
          params: { year: currentYear, month: currentMonth },
        });
      } else if (view === "day") {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        const currentDay = currentDate.getDate();
        this.$router.push({
          name: "Day",
          params: { year: currentYear, month: currentMonth, day: currentDay },
        });
      }
    },
    setSelectedTerm() {
      this.fetchSalesTargetReport();
    },
    async fetchSalesTargetReport() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/salesTarget/targetReport/${this.selectedTerm}/${this.selectedEmployeeId}`
        );

        this.SalesTargetReport = response.data;

        // Process data
        const achievedAmounts = [];
        const targetAmounts = [];
        const monthsOrQuarters = [];

        if (this.selectedTerm === "month") {
          for (const month in response.data.achievedAmount) {
            const [year, monthNumber] = month.split("-");

            // Create a Date object (month is zero-based, so subtract 1)
            const date = new Date(year, monthNumber - 1);

            // Get month name and format the string
            const monthName = date.toLocaleString("default", { month: "short" });
            const formattedDate = `${monthName}`;

            monthsOrQuarters.push(formattedDate);
            achievedAmounts.push(response.data.achievedAmount[month]);
            targetAmounts.push(response.data.targetAmount[month]);
          }
        } else if (this.selectedTerm === "quarter") {
          for (const period in response.data.achievedAmount) {
            let formattedDate;
            formattedDate = period;
            monthsOrQuarters.push(formattedDate);
            achievedAmounts.push(response.data.achievedAmount[period]);
            targetAmounts.push(response.data.targetAmount[period]);
          }
        } else if (this.selectedTerm === "year") {
          for (const year in response.data.achievedAmount) {
            monthsOrQuarters.push(year);
            achievedAmounts.push(response.data.achievedAmount[year]);
            targetAmounts.push(response.data.targetAmount[year]);
          }
        }

        this.series4 = [
          {
            name: "Target",
            data: targetAmounts,
          },
          {
            name: "Achieved",
            data: achievedAmounts,
          },
        ];

        // this.chartOptions4.xaxis.categories = Object.values(monthsOrQuarters);
        // Update chart categories with keys from leadCounts
        this.chartOptions4 = {
          ...this.chartOptions4,
          xaxis: {
            ...this.chartOptions.xaxis,
            categories: monthsOrQuarters,
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                if (value >= 1000) {
                  return value / 1000 + "k";
                }
                return value;
              },
            },
          },
        };
        // Update the chart
        if (this.$refs.chart) {
          this.$refs.chart.updateOptions(this.chartOptions4);
          this.$refs.chart.updateSeries(this.series4);
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    updateChartOptions() {
      if (this.selectedTerm === "month") {
        this.chartOptions4.plotOptions.bar.columnWidth = "50%";
      } else {
        this.chartOptions4.plotOptions.bar.columnWidth = "30%";
      }
    },
    async fetchLeadCounts() {
      this.cardLoading = true;
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/leads/leadsCount/${this.selectedSourceId}/${this.selectedEmployeeId}`
        );

        this.Data = response.data;

        this.FreshLeads = response.data.freshLeads;
        this.TargetData = response.data.salesReport;
        this.leadCounts = response.data.leadCounts;
        const leadCounts = response.data.leadCounts;

        this.seriess = [parseFloat(response.data.ratio.EstimateToSoldRatio)];
        // Extract categories and data from leadCounts object
        const Categories = Object.keys(leadCounts);
        const data = Object.values(leadCounts);

        this.series = [
          {
            name: "Lead Counts",

            data: data,
          },
        ];

        // Update chart categories with keys from leadCounts
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            ...this.chartOptions.xaxis,
            categories: Categories,
          },
        };

        // Update last three projects
        this.lastThreeProjects = response.data.projects;
        this.salesTargetValue = response.data.salesReport.targetAmount;
        this.series3 = [parseFloat(response.data.salesReport.salesPercentage)];

        this.fetchTodayTasks();
        this.fetchTodayEvents();
        this.fetchTodayReminders();
        this.cardLoading = false;
      } catch (error) {
        this.$handleError(error);
      }
    },
    getTotalLeads() {
      return Object.values(this.leadCounts).reduce((acc, val) => acc + val, 0);
    },
    formatNumber(amount) {
      // Check if amount is a valid number
      if (typeof amount !== "number" || isNaN(amount)) {
        return ""; // or any default value you prefer
      }
      return amount.toLocaleString("en-IN");
    },

    convertTo12HourFormat(timeString) {
      // Split the time string into hours and minutes
      const [hours, minutes] = timeString.split(":");

      // Convert hours to a number
      let hour = parseInt(hours, 10);

      // Determine if it's AM or PM
      const period = hour >= 12 ? "PM" : "AM";

      // Convert hours to 12-hour format
      hour = hour % 12 || 12;

      // Return the formatted time string
      return `${hour.toString().padStart(2, "0")}:${minutes} ${period}`;
    },
    reverseDateFormat(date) {
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    async fetchTodayTasks() {
      try {
        if (this.selectedEmployeeId !== "All") {
          const response = await axios.get(
            `${this.$apiEndPoint}/tasks/dashboardTasks/${this.selectedEmployeeId}`
          );
          this.todayTasks = response.data;
        } else {
          this.todayTasks = []; // Set to empty if 'All' is selected, or handle accordingly
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchTodayReminders() {
      try {
        if (this.selectedEmployeeId !== "All") {
          const response = await axios.get(
            `${this.$apiEndPoint}/reminders/dashboardReminder/${this.selectedEmployeeId}`
          );
          this.todayReminders = response.data;
        } else {
          this.todayReminders = [];
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchTodayEvents() {
      try {
        if (this.selectedEmployeeId !== "All") {
          const response = await axios.get(
            `${this.$apiEndPoint}/events/dashboardEvents/${this.selectedEmployeeId}`
          );
          this.todayEvents = response.data;
        } else {
          this.todayEvents = [];
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    isSalesManager() {
      return this.$LocalUser.employeeRoles.some(
        (role) => role.team === "Sales" && role.role === "Manager"
      );
    },
    ShowTargetSetterModal() {
      this.ShowTargetSetter = !this.ShowTargetSetter;
    },

    hideTargetSetterModal() {
      this.ShowTargetSetter = false;
    },
    setSelectedEmployee() {
      this.fetchLeadCounts();
      this.fetchSalesTargetReport();
    },
    setSelectedSource() {
      this.fetchLeadCounts();
    },
    async fetchEmployees() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/employee/myTeam/Sales`);
        this.employees = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchSources() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/source/getByEmployeeId/${this.selectedEmployeeId}`
        );
        this.sources = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    getGreeting() {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();

      if (currentHour < 12) {
        return "Good Morning";
      } else if (currentHour < 17) {
        return "Good Afternoon";
      } else {
        return "Good Evening";
      }
    },
    setCurrentDate() {
      const date = new Date();
      this.currentDay = new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(
        date
      );
      const options = { day: "numeric", month: "long", year: "numeric" };
      this.formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
    },
  },
};
</script>

<style>
.subHeadingHeader {
  font-size: 14px;
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.change-class {
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
}

#textColorOfCard {
  color: #000000;
  font-weight: 500;
}

.blueactive-SubHeading2 {
  font-size: 14px;
  color: #000000;
  font-weight: 500;
  /* Set left 10% background color here */
  background-image: linear-gradient(to right, #2381f3 4px, transparent 3px);
  background-size: 100% 100%;
  /* Ensure gradient fills entire width */
  /* You can adjust other styles as needed */
}

.Yellow-SubHeading2 {
  font-size: 14px;
  color: #000000;
  font-weight: 500;
  /* Set left 10% background color here */
  background-image: linear-gradient(to right, yellow 4px, transparent 3px);
  background-size: 100% 100%;
}
.btn-priority-low {
  background-color: #f4e8fb;
  color: #ba69e2;
}

.btn-priority-medium {
  background-color: #ecebec;
  color: #7e7882;
}

.btn-priority-high {
  background-color: #feabab;
  color: #fd0000;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}

label {
  font-weight: 500;
  color: black;
}

.transaction-details {
  position: fixed;
  display: block;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 400px;
  min-height: 250px;
  height: auto;
  background-color: #f4f4f4;
  color: #000000;
  border: 1px solid #b1c5df;
  border-radius: 10px;
}

#blackThreeDOtDashBoard {
  width: 15px;
  height: 13px;
  position: relative;
  cursor: pointer;
  display: flex;
}

.sales-dashboard {
  margin: 0.5% 2%;
  box-sizing: border-box;
}

.section-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  max-width: 100%;
  position: relative;

  /* Relative positioning for the wrapper */
}

.contentOfWhiteHeadSection {
  position: relative;
  width: 100%;
  background-color: #f4f4f4 !important;
}

.contentOfWhiteHeadSection2 {
  display: flex;
  position: relative;
  gap: 3%;
  padding: 0 2%;
  margin-bottom: 1%;
}

.welcome {
  font-size: 30px;
  font-weight: bold;
  white-space: nowrap;
}

.LeadChart {
  max-height: 350px;
  min-height: 350px;
  background-color: #ffffff;
  border-radius: 5px;
}

.LeadChart2 {
  max-height: 350px;
  min-height: 350px;
  background-color: #ffffff;
  border-radius: 5px;
  overflow-y: auto;
}

.otherChart {
  min-height: 250px;
  max-height: 250px;
  display: flex;
  gap: 2%;
  border-radius: 5px;
}

.otherChart section {
  height: 250px;
  width: 100%;
  border-radius: 5px;
}

.otherChart section::-webkit-scrollbar {
  display: none;
}

.lead-count-chart {
  display: flex;
  flex-direction: column;

  margin-top: 20px;
}

.chart-bar {
  margin-right: 10px;
  text-align: center;
  display: flex;
}

.bar-label {
  font-size: 12px;
  margin-bottom: 5px;
  width: 150px;
  text-align: left;
}

.DashboardSection2 {
  background-color: #000000;
}

.card2 {
  width: 400px;
  height: 80px;
  background-color: #ffffff;
}

.card3 {
  width: 400px;
  max-height: fit-content;
  background-color: #ffffff;
  border-radius: 0 5px 5px 0;
}

.collaborator-avatar {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: left;
  margin-right: 2px;

  /* Adjust margin to control the overlap */
}
.initials {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #4884f7; /* Blue background color */
  color: #ffffff; /* White text color */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  cursor: pointer;
  z-index: 1;
  margin-right: -4px; /* Adjust margin to control the overlap */
  position: relative; /* Ensure z-index works correctly */
}
.bga-danger {
  color: #fd0000 !important;
  background-color: #febfbf !important;
  border-radius: 3px !important;
  width: 60px;
  height: 17px;
}

.bga-success {
  color: #38b525 !important;
  background-color: #cdecc8 !important;
  border-radius: 3px;
  width: 60px;
  height: 17px;
}

.cardValue {
  font-size: 1.5rem;
  font-weight: 600;
}
.chartsection {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .contentOfWhiteHeadSection2 {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 5%;
  }
  .LeadChart,
  .otherChart {
    margin-top: 1%;
  }
  .cardValue {
    font-size: 14px;
  }
  #textColorOfCard {
    font-size: 12px;
  }
}

/* Mobile View Adjustments */
@media (max-width: 768px) {
  .blackHeadSection {
    height: auto;
    padding: 10px;
  }

  .middleHeadSection {
    display: none;
  }

  .LeadChart,
  .LeadChart2,
  .otherChart section {
    min-height: auto;
    max-height: auto;
  }

  .section-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    position: relative;
    padding: 10px;
    overflow-y: scroll !important;
  }

  .contentOfWhiteHeadSection,
  .contentOfWhiteHeadSection2 {
    padding: 10px;
  }
  .contentOfWhiteHeadSection2 {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .otherChart {
    flex-direction: column;
    justify-content: space-around;
  }

  .otherChart section {
    width: 100%;
    margin-bottom: 10px;
  }

  .LeadChart {
    margin-bottom: 10px;
  }

  .lead-count-chart {
    margin-top: 10px;
  }

  .table-responsive thead th {
    font-size: 12px;
    padding: 4px;
  }

  .welcome {
    font-size: 18px;
  }

  .progress-bar-container {
    margin: 5px 0;
  }

  .bar-label {
    width: auto;
  }

  .WhiteHeadSection {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow-y: scroll;
  }

  .contentOfWhiteHeadSection div,
  .contentOfWhiteHeadSection2 div {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .content-container,
  .container-child,
  .section-wrapper {
    flex-direction: column;
  }

  .black-head-section,
  .middle-head-section,
  .white-head-section {
    height: auto;
    overflow-y: auto;
  }

  .black-head-section,
  .middle-head-section,
  .white-head-section {
    height: auto;
  }

  .lead-chart,
  .other-chart,
  .lead-chart2 {
    margin-top: 10px;
  }
}
/*

.bar {
  width: 50px;
  height: 20px;
  background-color: #007bff; 
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}*/
</style>
