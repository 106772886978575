<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="AddQuotation"
      tabindex="-1"
      aria-labelledby="AddQuotationLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header text-center w-100">
            <h5 class="modal-title w-100" id="AddQuotationLabel">Add Quotation</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitForm">
              <div class="mb-3 row">
                <label for="activityDate" class="col-sm-4 col-form-label">Date</label>
                <div class="col-sm-8">
                  <input
                    v-model="form.date"
                    type="date"
                    class="form-control"
                    id="activityDate"
                  />
                </div>
              </div>
              <div class="mb-3 row">
                <label for="contractor" class="col-sm-4 col-form-label">Contractor</label>
                <div class="col-sm-8">
                  <select
                    v-model="form.onSiteContractorId"
                    class="form-control form-select"
                    id="contractor"
                    @change="fetchCategories"
                  >
                    <option
                      v-for="contractor in Contractors"
                      :key="contractor.id"
                      :value="contractor.id"
                    >
                      {{ contractor.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="mb-3 row">
                <label for="category" class="col-sm-4 col-form-label">Category</label>
                <div class="col-sm-8">
                  <select
                    v-model="form.onSiteCategoryId"
                    class="form-control form-select"
                    id="category"
                  >
                    <option
                      v-for="category in Categories"
                      :key="category['onSiteCategory.id']"
                      :value="category['onSiteCategory.id']"
                    >
                      {{ category["onSiteCategory.categoryName"] }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="mb-3 row" v-if="!projectId">
                <label for="projectName" class="col-sm-4 col-form-label"
                  >Project Name</label
                >
                <div class="col-sm-8">
                  <select
                    class="form-control form-select"
                    id="projectName"
                    v-model="form.onSiteProjectId"
                  >
                    <option
                      v-for="Project in Projects"
                      :key="Project.onSiteProjectId"
                      :value="Project.onSiteProjectId"
                    >
                      {{ Project["projects.projectName"] }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="mb-3 row">
                <label for="workDescription" class="col-sm-4 col-form-label"
                  >Work Description</label
                >
                <div class="col-sm-8">
                  <input
                    v-model="form.remark"
                    type="text"
                    class="form-control"
                    id="workDescription"
                    placeholder="Enter work description"
                  />
                </div>
              </div>
              <div class="mb-3 row">
                <label for="quotationValue" class="col-sm-4 col-form-label"
                  >Quotation Value</label
                >
                <div class="col-sm-8">
                  <input
                    v-model="form.quotationValue"
                    type="number"
                    class="form-control"
                    id="quotationValue"
                    placeholder="Enter quotation value"
                  />
                </div>
              </div>
              <div class="mb-3 row d-none">
                <label for="status" class="col-sm-4 col-form-label">Status</label>
                <div class="col-sm-8">
                  <select
                    v-model="form.status"
                    class="form-control form-select"
                    id="status"
                  >
                    <option value="Pending">Pending</option>
                    <option value="Approved">Approved</option>
                    <option value="Rejected">Rejected</option>
                  </select>
                </div>
              </div>
              <div class="mb-3 row">
                <label for="uploadQuotation" class="col-sm-4 col-form-label"
                  >Upload Quotation</label
                >
                <div class="col-sm-8">
                  <div>
                    <input
                      type="file"
                      class="form-control inputBg"
                      name="onSiteQuotation"
                      id="onSiteQuotation"
                      @change="handleFileUpload"
                    />
                  </div>
                  <div v-if="form.awsFileId">
                    <a
                      :href="form['awsFiles.signedUrl']"
                      target="_blank"
                      style="text-decoration: none; color: #000000"
                      >{{ form["awsFiles.fileName"] }}</a
                    >
                  </div>
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <button
                  v-if="!form.id"
                  type="submit"
                  class="btn btn-primary btn-sm"
                  @click="AddQuotation"
                  data-bs-dismiss="modal"
                >
                  Submit
                </button>
                <button
                  v-else
                  type="submit"
                  class="btn btn-primary btn-sm"
                  @click="UpdateActivity"
                  data-bs-dismiss="modal"
                >
                  Update
                </button>
                <button
                  type="button"
                  class="btn btn-danger btn-sm"
                  @click="DeleteTimeLine"
                  v-if="form.id"
                  data-bs-dismiss="modal"
                >
                  Delete
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AddQuotationModal",
  props: {
    quotationId: {
      type: String,
      required: true,
    },
    contractorId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localQuotationId: null,
      form: {
        id: null,
        date: new Date().toISOString().slice(0, 10),
        onSiteProjectId: null,
        quotationValue: null,
        remark: "",
        awsFileId: null,
        onSiteContractorId: null,
        onSiteCategoryId: null,
      },
      Projects: [],
      Contractors: [],
      Categories: [],
    };
  },
  mounted() {
    this.fetchProjects();
    this.fetchContractors();
    if (this.contractorId) {
      this.form.onSiteContractorId = this.contractorId;
      this.fetchCategories();
    }
    if (this.projectId) {
      this.form.onSiteProjectId = this.projectId;
    }
  },
  watch: {
    quotationId(newValue) {
      this.localQuotationId = newValue;
      if (newValue !== "null") {
        this.fetchQuotation();
      } else {
        this.resetForm();

        if (this.projectId) {
          this.form.onSiteProjectId = this.projectId;
        } else {
          this.form.onSiteProjectId = "";
        }
        if (this.contractorId) {
          this.form.onSiteContractorId = this.contractorId;
          this.fetchCategories();
        }
      }
    },
    contractorId(newValue) {
      if (newValue) {
        this.fetchContractors();

        this.form.onSiteContractorId = newValue;
      }
    },
    projectId(newValue) {
      if (newValue) {
        this.form.onSiteProjectId = newValue;
      }
    },
  },
  methods: {
    resetForm() {
      this.form = {
        id: null,
        date: null,
        onSiteProjectId: this.projectId,
        quotationValue: null,
        remark: "",
        awsFileId: null,
        onSiteContractorId: this.contractorId,
        onSiteCategoryId: null,
      };
    },
    fetchCategories() {
      if (!this.form.onSiteContractorId) return; // Ensure contractorId is set
      axios
        .get(`${this.$apiEndPoint}/contractorCategories/${this.form.onSiteContractorId}`)
        .then((response) => {
          this.Categories = response.data;
          console.log(this.form.onSiteCategoryId);
          if (!this.form.onSiteCategoryId) {
            this.form.onSiteCategoryId = response.data[0]["onSiteCategory.id"];
          }
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    fetchContractors() {
      axios
        .post(`${this.$apiEndPoint}/OnSiteContractor/All`, { searchString: "" })
        .then((response) => {
          this.Contractors = response.data.rows;

          // Automatically select the contractor by ID if `contractorId` is set
          if (this.contractorId) {
            const selectedContractor = this.Contractors.find(
              (contractor) => contractor.id === this.contractorId
            );

            if (selectedContractor) {
              this.form.onSiteContractorId = selectedContractor.id;
              this.fetchCategories(); // Fetch categories for the selected contractor
            }
          }
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    async AddQuotation() {
      try {
        await axios.post(`${this.$apiEndPoint}/OnSiteQuotation/`, this.form);
        this.$toast("Quotation created successfully", "success");
        this.$emit("fetchQuotations");

        this.resetForm();
      } catch (error) {
        this.$handleError(error);
      }
    },
    fetchQuotation() {
      if (this.localQuotationId !== "null") {
        this.fetchContractors();
        this.fetchCategories();
        axios
          .get(`${this.$apiEndPoint}/onSiteQuotation/byId/${this.localQuotationId}`)
          .then((response) => {
            if (response.data) {
              this.form = {
                ...this.form, // Spread operator to retain existing properties
                ...response.data,
                onSiteCategoryId: response.data.onSiteCategoryId,
              };
            } else {
              this.resetForm();
            }
          })
          .catch((error) => {
            this.$handleError(error);
          });
      } else {
        this.resetForm();
      }
    },
    handleFileUpload() {
      const file = document.getElementById("onSiteQuotation").files[0]; // Get the selected file
      this.$globalState.isLoading = true;
      if (file) {
        this.form.awsFileId = "";
        // Make API call to get signed URL for file upload
        axios
          .post(`${this.$apiEndPoint}/company/putFileSignedURL`, {
            fileName: file.name,
            fileSize: file.size,
            contentType: file.type,
            fileCategory: "onSiteQuotation",
            moduleName: "onSite",
          })
          .then((response) => {
            const signedUrl = response.data.s3URL;
            this.form.awsFileId = response.data.id;
            this.form["awsFiles.fileName"] = file.name;
            // Use XMLHttpRequest to upload the file to the signed URL
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUrl, true);
            xhr.setRequestHeader("Content-Type", file.type);

            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  this.$globalState.isLoading = false;
                  this.$toast("File uploaded successfully", "success");

                  // Handle successful upload
                } else {
                  this.$handleError(xhr.responseText);
                  this.$globalState.isLoading = false;
                  // Handle upload error
                }
              }
            };

            // Send the file as binary data
            xhr.send(file);
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },
    fetchProjects() {
      axios
        .get(
          `${this.$apiEndPoint}/onSiteSupervisors/collaboratedProjects/${this.$LocalUser.user.employeeId}`
        )
        .then((response) => {
          this.Projects = response.data;
          console.log(this.Projects);
          if (this.form.onSiteProjectId) {
            this.form.onSiteProjectId = this.Projects.find(
              (po) => po.onSiteProjectId === this.form.onSiteProjectId
            );
          }
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    async UpdateActivity() {
      console.log(this.form);
      try {
        await axios.put(
          `${this.$apiEndPoint}/OnSiteQuotation/${this.localQuotationId}`,
          this.form
        );
        this.$toast("Quotation updated successfully", "success");
        this.$emit("fetchQuotations");
      } catch (error) {
        this.$handleError(error);
      }
    },
    async DeleteTimeLine() {
      const result = await this.$showAlert(
        "Are you sure?",
        "Are you sure you want to delete?",
        "warning",
        "Yes, delete it",
        "Cancel"
      );

      // If the user clicked Cancel, don't proceed
      if (!result.isConfirmed) return;

      try {
        await axios.delete(
          `${this.$apiEndPoint}/OnSiteQuotation/${this.localQuotationId}`
        );
        this.$emit("fetchQuotations");
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}
label,
input {
  font-size: 13px;
}

.employee-dropdown {
  position: absolute;
  display: block;
  background-color: #fff;
  border: 1px solid #ccc;
  max-height: 200px;
  border-radius: 5px;
  overflow-y: auto;
  z-index: 1000;
  margin-left: 28%;
  /* Ensure the dropdown appears above other elements */
  width: calc(100% - 28%); /* Adjust width to match the input */
  color: #000000;
  top: 20%; /* Position the dropdown below the input */
  left: 0; /* Align the left edge of the dropdown with the left edge of the input */
}

.employee-dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.employee-dropdown ul li {
  padding: 8px 10px;
  cursor: pointer;
}

.employee-dropdown ul li:hover {
  background-color: #f0f0f0;
}
</style>
