import Swal from 'sweetalert2';

export function showAlert(title, text, icon = 'info', confirmButtonText = 'OK', cancelButtonText = 'Cancel') {
  return Swal.fire({
    title: title,                // Should be a string
    text: text,                  // Should be a string
    icon: icon,                  // Should be a string, e.g., 'info', 'error', 'warning', etc.
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText
  });
}
