<template>
  <div
    v-if="projectId || SourceId || onSiteprojectId || ProcurementProjectId"
    class="modal fade"
    id="shareProjectModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="shareProjectModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="shareProjectModalLabel">Share Project</h5>
          <img
            src="../../assets/icons/cancel.png"
            alt=""
            data-bs-dismiss="modal"
            aria-label="Close"
            style="width: 20px; height: 20px; cursor: pointer"
          />
        </div>
        <div class="modal-body custom-modal-body">
          <div class="form-check border-bottom pb-1 mb-3 border-primary">
            <input
              type="checkbox"
              class="form-check-input border-black border-2"
              v-model="selectAll"
              @change="toggleSelectAll"
              id="selectAllCheckbox"
            />
            <label class="form-check-label" for="selectAllCheckbox">Select All</label>
          </div>

          <div v-for="employee in employees" :key="employee.id">
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input border-black border-2 mt-2"
                v-model="selectedEmployees"
                :value="employee.employeeId"
                :id="'employeeCheckbox_' + employee.employeeId"
              />
              <label
                class="form-check-label d-flex align-content-center ms-1"
                :for="'employeeCheckbox_' + employee.employeeId"
              >
                <span>
                  <img
                    v-if="employee['employees.signedUrl'] !== null"
                    :src="employee['employees.signedUrl']"
                    alt=""
                    style="
                      width: 42px;
                      height: 42px;
                      cursor: pointer;
                      border-radius: 50%;
                      border: 1px solid #000;
                    "
                  />
                  <img
                    v-else
                    src="../../assets/icons/profile.png"
                    alt=""
                    style="width: 40px; height: 40px; cursor: pointer"
                  />
                </span>
                <span class="ms-3">
                  <p style="font-size: 16px" class="p-0 text-black">
                    {{ employee["employees.firstName"] }}
                    {{ employee["employees.lastName"] }} <br />
                    <span class="text-muted" style="font-size: 12px">
                      {{ employee["employees.employeeEmailId"] }}</span
                    >
                  </p>
                </span>
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="cancel"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="shareProject"
            data-bs-dismiss="modal"
          >
            Share
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    projectId: {
      type: String,
      required: true,
    },
    onSiteprojectId: {
      type: String,
      required: true,
    },
    SourceId: {
      type: String,
      required: true,
    },
    ProcurementProjectId: {
      type: String,
      required: true,
    },
    SourceName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedEmployees: [],
      employees: [],
      selectAll: false, // Added selectAll property
      ProjectId: null,
      sourceId: null,
      procurementProjectId: null,
      OnSiteprojectId: null,
    };
  },
  mounted() {
    this.fetchEmployees();
  },
  watch: {
    projectId(newVal) {
      this.ProjectId = newVal;
      this.fetchEmployees();
    },
    SourceId(newVal) {
      this.sourceId = newVal;
      this.fetchEmployees();
    },
    onSiteprojectId(newVal) {
      this.OnSiteprojectId = newVal;

      this.fetchEmployees();
    },
    ProcurementProjectId(newVal) {
      this.procurementProjectId = newVal;
      this.fetchEmployees();
    },
  },
  methods: {
    toggleSelectAll() {
      if (this.selectAll) {
        // If selectAll is true, select all employee IDs
        this.selectedEmployees = this.employees.map(
          (employee) => employee.employeeId || employee.salesPersonId
        );
      } else {
        // If selectAll is false, clear the selection
        this.selectedEmployees = [];
      }
    },

    cancel() {
      this.selectAll = false;
      this.selectedEmployees = [];
    },

    async shareProject() {
      try {
        if (this.projectId) {
          await axios.post(`${this.$apiEndPoint}/designProjectTeam`, {
            designProjectId: this.projectId,
            employeeId: this.selectedEmployees,
          });
        } else if (this.onSiteprojectId) {
          await axios.post(`${this.$apiEndPoint}/onSiteSupervisors`, {
            onSiteProjectId: this.onSiteprojectId,
            employeeId: this.selectedEmployees,
          });
        } else if (this.ProcurementProjectId) {
          await axios.post(`${this.$apiEndPoint}/procurementCollabs`, {
            procurementProjectId: this.ProcurementProjectId,
            employeeId: this.selectedEmployees,
          });
        } else {
          await axios.post(`${this.$apiEndPoint}/salesPersonSource`, {
            sourceId: this.SourceId,
            salesPersonId: this.selectedEmployees,
          });
        }
        this.fetchEmployees();
        location.reload();
        // this.fetchCollaboratorData();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchEmployees() {
      try {
        let allEmployeesResponse, sharedEmployeesResponse, sharedEmployeeIds;

        if (this.projectId && !this.onSiteprojectId && !this.SourceId) {
          allEmployeesResponse = await axios.get(
            `${this.$apiEndPoint}/employee/myTeam/Design/`
          );
          sharedEmployeesResponse = await axios.get(
            `${this.$apiEndPoint}/designProjectTeam/${this.projectId}`
          );
        } else if (this.onSiteprojectId && !this.projectId && !this.SourceId) {
          allEmployeesResponse = await axios.get(
            `${this.$apiEndPoint}/employee/myTeam/Onsite`
          );
          sharedEmployeesResponse = await axios.get(
            `${this.$apiEndPoint}/onSiteSupervisors/${this.onSiteprojectId}`
          );
        } else if (
          this.ProcurementProjectId &&
          !this.projectId &&
          !this.onSiteprojectId
        ) {
          allEmployeesResponse = await axios.get(
            `${this.$apiEndPoint}/employee/myTeam/Procurement`
          );
          sharedEmployeesResponse = await axios.get(
            `${this.$apiEndPoint}/procurementCollabs/${this.ProcurementProjectId}`
          );
        } else {
          allEmployeesResponse = await axios.get(
            `${this.$apiEndPoint}/employee/myTeam/Sales`
          );
          sharedEmployeesResponse = await axios.get(
            `${this.$apiEndPoint}/salesPersonSource/source/${this.SourceId}`
          );
        }

        this.employees = allEmployeesResponse.data;

        // Get the IDs of the selected employees
        sharedEmployeeIds = sharedEmployeesResponse.data.map(
          (employee) => employee.employeeId || employee.salesPersonId
        );

        // Move the selected employees to the top of the list
        this.employees.sort((a, b) => {
          const aIsSelected = sharedEmployeeIds.includes(a.employeeId || a.salesPersonId);
          const bIsSelected = sharedEmployeeIds.includes(b.employeeId || b.salesPersonId);
          return bIsSelected - aIsSelected;
        });

        this.selectedEmployees = sharedEmployeeIds;
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>
<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.custom-modal-body {
  max-height: 300px;
  /* Set a fixed height for the modal body */
  overflow-y: auto;
  /* Enable vertical scroll if content exceeds the height */
}
</style>
