<template>
  <CreateReminderModal @fetchData="fetchDataAndInitialize()" />
  <taskSidePanel @submit-form="fetchDataAndInitialize()" />
  <CreateEventModal @fetchData="fetchDataAndInitialize()" />
  <Header />
  <div class="content-Container">
    <Nav />
    <div class="containerChild">
      <div v-if="isLoading" class="loading-indicator">
        <div class="loader">
          <div class="spinner"></div>
          <div class="loading-text">Loading...</div>
        </div>
      </div>

      <header class="">
        <div class="header-left WEB SubheadingUnderLine">
          <h4>Calendar</h4>
        </div>
        <div class="header-center month-calender month-info">
          <img src="../../assets/icons/leftmove.png" alt="" @click="prevMonth" />
          <select
            name="selectedMonth"
            id="selectedMonth"
            v-model="selectedMonth"
            @change="updateCalendar"
            class="bodyBgcolor month-info text-start form-select"
            style="font-weight: bold; font-size: 18px; width: fit-content"
          >
            <option v-for="(month, index) in months" :value="index + 1" :key="month">
              {{ month }}
            </option>
          </select>
          <span class="monthviewyear">{{ year }}</span>

          <img src="../../assets/icons/rightmove.png" alt="" @click="nextMonth" />
        </div>
        <div class="d-flex viewSection" style="margin-right: 3.4%">
          <p class="mt-1 WEB">View :</p>
          <select
            style="
              margin-left: 3px;
              font-size: 12px;
              height: 30px;
              width: 150px;
              cursor: pointer;
              :focus {
                outline: none;
                box-shadow: none;
              }
            "
            class="form-select"
            v-model="selectedEmployeeId"
            @change="setSelectedEmployee"
          >
            <option
              v-for="employee in employees"
              :key="employee.employeeUserId"
              :value="employee.employeeUserId"
            >
              {{ employee["employees.firstName"] }}
              {{ employee["employees.lastName"] }}
            </option>
          </select>
        </div>
        <div class="header-right WEB" style="font-weight: bold; cursor: pointer">
          <span style="cursor: pointer" @click="changeView('year')">Year</span>
          <span
            @click="changeView('month')"
            style="margin: 0 20px; cursor: pointer; border-bottom: 2px solid blue"
            :class="{ active: currentView === 'month' }"
            >Month</span
          >
          <span style="cursor: pointer" @click="changeView('day')">Day</span>
        </div>
      </header>

      <div
        class="calendar-Month bodyBgcolor tableHeaderHeading mb-5"
        id="calendar"
        style="padding: 0 2%"
      ></div>
    </div>
    <div class="dropdown-RTE" @mouseover="showRTEContent" @mouseout="hideRTEContent">
      <img
        src="../../assets/icons/colleps.png"
        alt=""
        class="plus-button"
        id="plus-button"
        v-if="isRTEContentVisible"
      />
      <img
        src="../../assets/icons/addRows.png"
        alt=""
        class="plus-button"
        id="plus-button"
        v-else
      />
      <div class="drowpdown-content-RTE" v-show="isRTEContentVisible">
        <div class="rteRow">
          <div class="RTE-item">
            <img src="../../assets/icons/Task.png.png" alt="" style="width: 30px" />
            <button
              class="task-btn twentyPxFont text-start"
              data-bs-toggle="modal"
              data-bs-target="#taskModal"
            >
              Task
            </button>
          </div>
          <div class="RTE-item">
            <img src="../../assets/icons/event.png.png" alt="" style="width: 30px" />
            <button
              class="event-btn twentyPxFont"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#eventModal"
            >
              Event
            </button>
          </div>
          <!-- Reminder item in the RTE dropdown -->
          <div class="RTE-item">
            <img src="../../assets/icons/reminder.png.png" alt="" style="width: 30px" />
            <button
              class="reminder-btn twentyPxFont"
              data-bs-toggle="modal"
              data-bs-target="#reminderModal"
            >
              Reminder
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateEventModal from "../Modal/CreateEventModal.vue";
import taskSidePanel from "../Modal/taskSidePanel.vue";
import CreateReminderModal from "../Modal/CreateReminderModal.vue";
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import axios from "axios";

export default {
  name: "Month",
  components: {
    Header,
    Nav,
    CreateReminderModal,
    taskSidePanel,
    CreateEventModal,
  },

  // Data initialization
  data() {
    const currentDate = new Date();
    return {
      currentView: "month",
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
      /*    currentMonthHeading: '',*/
      selectedDate: null,
      currentDate: currentDate, // Initialize currentDate here
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      selectedMonth: currentDate.getMonth() + 1,
      isRTEContentVisible: false,
      reminderModal: false,
      taskModal: false,
      eventModal: false,
      daysInMonth: 0,
      holidays: [],
      leaves: [],
      reminders: [],
      tasks: [],
      events: [],
      isLoading: false,
      peopleList: [
        { userId: 1, userName: "Shreyas" },
        { userId: 2, userName: "Heamanth" },
        { userId: 3, userName: "Vishal" },
        { userId: 4, userName: "Sagar" },
      ],

      filteredPeople: [],
      selectedPeople: [],
      employees: [],
      selectedEmployeeId: this.$LocalUser.user.id,
    };
  },

  computed: {
    calendarDays() {
      const daysInMonth = new Date(this.year, this.selectedMonth, 0).getDate();
      return Array.from({ length: daysInMonth }, (_, i) => i + 1);
    },
  },
  mounted() {
    this.fetchEmployees();
    this.year = parseInt(this.$route.params.year, 10);
    this.selectedMonth = parseInt(this.$route.params.month, 10);
    this.selectedDate = new Date(this.year, this.selectedMonth - 1, 1);
    this.selectedEmployeeId = this.$route.params.selectedEmployeeId;
    this.fetchDataAndInitialize();
  },

  // Method to fetch holidays and leaves data from the server
  methods: {
    setSelectedEmployee() {
      this.fetchDataAndInitialize();
    },
    async fetchEmployees() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/employee/getByRoles`);
        if (response.data && response.data) {
          this.employees = response.data;
        } else {
          // Handle case where the expected data is missing
          this.$toast("No employee data found", "error");
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    changeView(view) {
      this.currentView = view; // Update the currentView when changing the view
      if (view === "year") {
        // Navigate to the calendar page with the current year
        const currentYear = new Date().getFullYear();
        this.$router.push({ name: "Calendar", params: { year: currentYear } });
      } else if (view === "month") {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Month index starts from 0, so add 1
        this.$router.push({
          name: "Month",
          params: { year: currentYear, month: currentMonth },
        });
      } else if (view === "day") {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        const currentDay = currentDate.getDate();
        this.$router.push({
          name: "Day",
          params: { year: currentYear, month: currentMonth, day: currentDay },
        });
      }
    },

    async fetchReminders() {
      try {
        const remindersResponse = await axios.get(
          `${this.$apiEndPoint}/reminders/${this.year}/${this.selectedMonth}/${this.selectedEmployeeId}`
        );
        this.$data.reminders = remindersResponse.data;
        this.updateCalendar(); // Optionally update the calendar with reminders first
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchTasksAndEvents() {
      try {
        const [tasksResponse, eventsResponse] = await Promise.all([
          axios.get(
            `${this.$apiEndPoint}/tasks/calendar/${this.year}/${this.selectedMonth}/${this.selectedEmployeeId}`
          ),
          axios.get(
            `${this.$apiEndPoint}/events/${this.year}/${this.selectedMonth}/${this.selectedEmployeeId}`
          ),
        ]);

        this.$data.tasks = tasksResponse.data;
        this.$data.events = eventsResponse.data;
        this.updateCalendar(); // Update the calendar with tasks and events
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchDataAndInitialize() {
      this.isLoading = true;
      try {
        await this.fetchReminders(); // Fetch reminders first
        this.isLoading = false; // End loading state after the initial fetch
        this.fetchTasksAndEvents(); // Fetch tasks and events after rendering the calendar
      } catch (error) {
        this.$handleError(error);
        this.isLoading = false; // End loading state in case of error
      }
    },

    updateCalendar() {
      const calendar = document.getElementById("calendar");
      // const currentMonthElement = document.getElementById("currentMonth");
      const daysInMonth = new Date(this.year, this.selectedMonth, 0).getDate();
      const firstDayOfWeek = new Date(this.year, this.selectedMonth - 1, 1).getDay();
      const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

      // Clear previous calendar content
      calendar.innerHTML = "";

      // Create weekdays header
      weekdays.forEach((day) => {
        const dayElement = document.createElement("div");
        dayElement.classList.add("weekday");
        dayElement.textContent = day;
        calendar.appendChild(dayElement);
      });

      // Fill in empty cells for the first row
      for (let i = 0; i < firstDayOfWeek; i++) {
        const emptyCell = document.createElement("div");
        emptyCell.classList.add("date", "empty", "empty-date");
        calendar.appendChild(emptyCell);
      }

      // Fill in the dates
      for (let day = 1; day <= daysInMonth; day++) {
        const dateElement = document.createElement("div");
        dateElement.classList.add("date");
        const anchor = document.createElement("a");
        anchor.textContent = `${day}`;
        dateElement.addEventListener("click", () =>
          this.directNavigateToDayCalendar(day)
        );
        dateElement.appendChild(anchor);

        // Check if it's the current day and apply the class
        const currentDate = new Date(this.year, this.selectedMonth - 1, day);
        if (currentDate.toDateString() === this.currentDate.toDateString()) {
          dateElement.classList.add("current-day");
        }

        // Add holiday and leave elements
        this.addCalendarElements(dateElement, day);
        calendar.appendChild(dateElement);
      }

      this.currentMonthHeading = this.months[this.selectedMonth - 1];
    },

    // Method to navigate to a specific day
    directNavigateToDayCalendar(day) {
      this.$router.push(
        `/Calendar/${this.year}/${this.selectedMonth}/${day}/${this.selectedEmployeeId}`
      );
    },

    // Method to add reminder, task, and event elements to a date element
    addCalendarElements(dateElement, day) {
      const CalendarElementsContainer = document.createElement("div");
      CalendarElementsContainer.classList.add("calendar-elements-container");

      const ul = document.createElement("ul");
      ul.classList.add("calendar-elements-list");

      // Reminder
      const reminderCount = this.reminders[day.toString()] || 0;
      if (reminderCount > 0) {
        const reminderLi = document.createElement("li");
        reminderLi.innerHTML = `<img src="${require("../../assets/icons/reminder.png.png")}" alt="">  ${reminderCount}`;
        // Add tooltip  this.addTooltip(reminderLi, `Reminders`) functionality

        ul.appendChild(reminderLi);
      }

      // Task
      const taskCount = this.tasks[day.toString()] || 0;
      if (taskCount > 0) {
        const taskLi = document.createElement("li");
        taskLi.innerHTML = `<img src="${require("../../assets/icons/Task.png.png")}" alt="">${taskCount}`;

        ul.appendChild(taskLi);
      }

      // Event
      const eventCount = this.events[day.toString()] || 0;
      if (eventCount > 0) {
        const eventLi = document.createElement("li");
        eventLi.innerHTML = `<img src="${require("../../assets/icons/event.png.png")}" alt="">${eventCount}`;

        ul.appendChild(eventLi);
      }

      CalendarElementsContainer.appendChild(ul);
      dateElement.appendChild(CalendarElementsContainer);
    },

    // Method to add tooltip functionality
    addTooltip(element, text) {
      element.addEventListener("mouseover", () => {
        const tooltip = document.createElement("div");
        tooltip.classList.add("custom-tooltip");
        tooltip.textContent = text;
        document.body.appendChild(tooltip);

        const rect = element.getBoundingClientRect();
        tooltip.style.top = rect.top + "px";
        tooltip.style.left = rect.left + rect.width / 6 + "px";
      });

      element.addEventListener("mouseout", () => {
        const tooltip = document.querySelector(".custom-tooltip");
        if (tooltip) {
          tooltip.remove();
        }
      });
    },

    async nextMonth() {
      // Update the month, then fetch data and initialize
      this.selectedMonth += 1;
      if (this.selectedMonth > 12) {
        this.selectedMonth = 1;
        this.year += 1;
      }
      await this.fetchDataAndInitialize();
    },

    async prevMonth() {
      // Update the month, then fetch data and initialize
      this.selectedMonth -= 1;
      if (this.selectedMonth < 1) {
        this.selectedMonth = 12;
        this.year -= 1;
      }
      await this.fetchDataAndInitialize();
    },

    // Method to show RTE content on mouseover
    showRTEContent() {
      this.isRTEContentVisible = true;
    },

    // Method to hide RTE content on mouseout
    hideRTEContent(event) {
      // Check if the mouse is leaving the icon, the dropdown, or the reminder modal
      if (
        !event.relatedTarget?.closest(".drowpdown-content-RTE") ||
        event.relatedTarget?.closest(".reminder-form") ||
        event.relatedTarget?.closest(".task-form") ||
        event.relatedTarget?.closest(".event-form")
      ) {
        this.isRTEContentVisible = false;
      }
    },
  },
};

/*
 // Method to check if there are holidays for a specific day
     hasHolidaysForDay(day) {
         const currentDate = new Date(this.year, this.selectedMonth - 1, day);
         return this.holidays.some(holiday => {
             const holidayDate = new Date(holiday.fromDate);
             return (
                 currentDate.getFullYear() === holidayDate.getFullYear() &&
                 currentDate.getMonth() === holidayDate.getMonth() &&
                 currentDate.getDate() === holidayDate.getDate()
             );
         });
     },

     // Method to get holidays for a specific day
     holidaysForDay(day) {
         const currentDate = new Date(this.year, this.selectedMonth - 1, day);
         return this.holidays.filter(holiday => {
             const holidayDate = new Date(holiday.fromDate);
             return (
                 currentDate.getFullYear() === holidayDate.getFullYear() &&
                 currentDate.getMonth() === holidayDate.getMonth() &&
                 currentDate.getDate() === holidayDate.getDate()
             );
         });
     },

     // Method to check if there are leaves for a specific day
     hasLeavesForDay(day) {
         const currentDate = new Date(this.year, this.selectedMonth - 1, day);
         return this.leaves.some(leave => {
             const fromDate = new Date(leave.fromDate);
             return (
                 currentDate.getFullYear() === fromDate.getFullYear() &&
                 currentDate.getMonth() === fromDate.getMonth() &&
                 currentDate.getDate() === fromDate.getDate()
             );
         });
     },

     // Method to get leaves for a specific day
     leavesForDay(day) {
         const currentDate = new Date(this.year, this.selectedMonth - 1, day);
         return this.leaves.filter(leave => {
             const fromDate = new Date(leave.fromDate);
             return (
                 currentDate.getFullYear() === fromDate.getFullYear() &&
                 currentDate.getMonth() === fromDate.getMonth() &&
                 currentDate.getDate() === fromDate.getDate()
             );
         });
     },

 
 
// Method to add holiday and leave elements to a date element
 addHolidayAndLeaveElements(dateElement, day) {
     const combinedUl = document.createElement('ul');
     combinedUl.classList.add('combined-list');

     // Add holidays to the combinedUl
     this.holidaysForDay(day).forEach(holiday => {
         const holidayLi = document.createElement('li');
         holidayLi.innerHTML = `<img src="${require('../../assets/icons/holiday.png')}" alt="">`;
         // Add tooltip functionality
         this.addTooltipHL(holidayLi, holiday.description);
         combinedUl.appendChild(holidayLi);
     });

     // Add leaves to the combinedUl
     this.leavesForDay(day).forEach(() => {
         const leaveLi = document.createElement('li');
         leaveLi.innerHTML = `<img src="${require('../../assets/icons/Leave icon.png')}" alt="">`;
         // Add tooltip functionality
         this.addTooltipHL(leaveLi, `leave`);
         combinedUl.appendChild(leaveLi);
     });

     dateElement.appendChild(combinedUl);
 },
 addTooltipHL(element, text) {
     element.addEventListener('mouseover', () => {
         const tooltipHL = document.createElement('div');
         tooltipHL.classList.add('custom-tooltipHL');
         tooltipHL.textContent = text;
         document.body.appendChild(tooltipHL);

         const rect = element.getBoundingClientRect();
         tooltipHL.style.top = rect.top + 'px';
         tooltipHL.style.left = rect.left + (rect.width / 6) + 'px';
     });

     element.addEventListener('mouseout', () => {
         const tooltipHL = document.querySelector('.custom-tooltipHL');
         if (tooltipHL) {
             tooltipHL.remove();
         }
     });
 },


*/
</script>
<!-- 
style="position: absolute; top: 100%; left: 0; width: 100%; background-color: #fff; border: 1px solid #ccc;  -->
<style>
/* General Styles */
@import url("https://db.onlinewebfonts.com/c/405f05e6bf58471a25729ed060cda2d8?family=Glacial+Indifference+Regular");
select:focus {
  outline: none;
  box-shadow: none;
}

.monthCalenderComponent {
  font-family: Arial, sans-serif;
  max-width: 100%;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 2.5%;
  height: 10%;
}

.header-left {
  display: flex;
  align-items: center;
}

.month-calender {
  display: flex;
  align-items: center;
}

.calendarMonthHeader img {
  width: 30px;
  margin-left: 10px;
  margin-right: 10px;
}

/* Additional styling for <select> */
.month-info select {
  background-color: transparent;
  border: none;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  /* Adjust padding as needed */
  margin: 1%;
  /* Adjust margin as needed */
}

#selectedMonth {
  -webkit-appearance: none;
  /* Chrome and Safari */
  -moz-appearance: none;
  /* Firefox */
  appearance: none;
}

#selectedMonth::-ms-expand {
  display: none;
}

.month-info {
  display: flex;
  align-items: center;
  margin: 10px;

  /* Adjust the margin to control the space between icons and month */
}

.month-info img {
  margin: 0;
  cursor: pointer;
}

.calenderMonthHead .month-view {
  padding-left: 60%;
  white-space: nowrap;
  display: flex;
  margin-top: 1%;
}

/* Calendar Grid Styles */
.calendar-Month {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1%;
  padding: 0;
}

.rte img {
  width: 50px;
}

/* Keep the weekdays row fixed at the top while scrolling */

.weekday {
  background-color: #bcbfc6;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #000000;
  font-weight: 500;
  margin-bottom: 2%;
  padding: 1%;
  border-radius: 5px;
}

.prev-month {
  cursor: pointer;
  width: 20px;
  margin-right: 10px;
}

.next-month {
  cursor: pointer;
  width: 20px;
}

.calendar-Month .date {
  height: 150px;
  /*Set a fixed height for the date cells */
  padding: 2%;
  text-align: left;
  border-radius: 5px;
  color: #000000;
  background-color: #f8f8f8 !important;
}
.calendar-Month .date:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
  cursor: pointer;
}

/* Style empty cells
.calendar-Month .date-empty {
    height: 150px;

    border: none;
    background-color: transparent;
    color: #000000
}*/

.plus-button {
  width: 50px;
  position: fixed;
  bottom: 3%;
  right: 2%;
  cursor: pointer;
}

.calendar-Month .date a {
  font-size: 1.2rem;
  display: flex;
  justify-content: left;
  width: 25%;
  color: #000000;
}

/* Style for holidays and leaves */
.combined-list {
  border: none;
  display: flex;
  justify-content: right;
  padding: 0;
  top: -15%;
  left: 5%;
  position: relative;
}

.combined-list li img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.calendar-elements-list {
  margin-top: 5px;
  font-display: flex;
  align-items: center;
  border: none;
}

.calendar-elements-list ul {
  display: flex;
  justify-content: left;
}

.calendar-elements-list li {
  display: flex;
  justify-content: left;
  align-items: center;
  color: blue;
  font-size: 12px;
  margin-bottom: 12px;
}

.calendar-elements-list li img {
  width: 12%;
  margin-right: 5%;

  cursor: pointer;
}

.custom-tooltip {
  position: absolute;
  margin-bottom: 50px;
  background-color: #bcbfc6;
  color: #084f56;
  padding: 3px;
  border-radius: 3px;
  font-size: 12px;
  border: none;
  outline: none;
}

.custom-tooltipHL {
  position: absolute;
  background-color: #ffbd59;
  color: #084f56;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  border: none;
  outline: none;
}

.viewSection {
  margin-top: 15px;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

#tooltip-RTEText {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  transform: translateY(-10%);
  background-color: #333;
  color: white;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease;
  height: auto;
}

#tooltip-RTEText::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
}

#tooltip-RTE:hover #tooltip-RTEText {
  top: 100%;
  visibility: visible;
  opacity: 1;
}

.drowpdown-content-RTE {
  position: fixed;
  bottom: 7.5%;
  right: 2.5%;
  background-color: #f8f8f8;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 6;
  border: 1px solid #ccc;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin-right: 30px;
}

.drowpdown-content-RTE button {
  color: #000;
  font-family: "Inter", sans-serif;
  text-decoration: none;
  transition: background-color 0.3s ease;
  margin: 5px;
}

.RTE-row {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.RTE-item img {
  width: 20px;
  height: 20px;

  object-fit: contain;
}

.RTE-item {
  display: flex;
  align-items: center;
  margin: 0px;
  /* Adjust as needed */
}

/* Styling for the reminder button */
.reminder-btn {
  appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  padding: 5px 10px;
  background-image: linear-gradient(to right, #aa8937);
  border-radius: 8px;
  color: black;
  font-size: 14px;
  font-weight: 500;

  transition: 0.4s ease-out;
}

/* Hover effect for the reminder button  box-shadow: 3px 3px rgba(0, 0, 0, 0.4);
&:hover {
  background-color: #f8f8f8;
  border-radius: 10px;
}*/

.task-btn {
  appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  padding: 5px 10px;
  background-image: linear-gradient(to right, #aa8937);
  border-radius: 8px;
  color: black;
  font-size: 14px;
  font-weight: 500;

  transition: 0.4s ease-out;
}

.event-btn {
  appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  padding: 5px 10px;
  background-image: linear-gradient(to right, #aa8937);
  border-radius: 8px;
  color: black;
  font-size: 14px;
  font-weight: 500;

  transition: 0.4s ease-out;
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 1000;
  /* Adjust the height as needed */
}

.loader {
  position: relative;
  width: 100px;
  height: 100px;
}

.spinner {
  border: 5px solid #bcbfc6;
  border-top: 5px solid #000000;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  position: absolute;

  animation: spin 1s linear infinite;
  /* Adjust animation properties as needed */
}
.RTE-item:hover {
  background-color: #ccc;
  border-radius: 5px;
}
.current-day a {
  padding-left: 9px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f8f8f8 !important; /* Text color for current day */
  background-color: purple; /* Highlight color for current day */
  border-radius: 5px; /* Make it circular */
  text-decoration: none; /* Remove underline */
  width: 30px; /* Set a fixed width for a circular shape */
  height: 30px; /* Set a fixed height for a circular shape */
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.loading-text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.header-center {
  font-weight: bolder;
  font-size: 18px;
  margin-left: 20%;
}
.header-center img {
  width: 5%;
  cursor: pointer;
}

.monthviewyear {
  font-weight: bold;
  font-size: 18px;
  margin-right: 10px;
}
@media (max-width: 767px) {
  .monthviewyear {
    font-weight: bold;
    font-size: 14px;
    margin-right: 10px;
  }
  select {
    font-weight: bold;
    font-size: 14px !important;
    width: fit-content;
  }
  .month {
    margin-top: 100px;
  }

  .viewSection {
    margin-top: 0px;
  }
  .header-center {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 13%;
  }
  .header-center img {
    width: 10%;
  }
  .calendar-elements-list li {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-right: 50%;
    color: blue;
    font-size: 12px;
    margin-bottom: 12px;
  }
  .calendar-elements-list li img {
    width: 80%;
    margin-right: 2%;

    cursor: pointer;
  }
  .calendar-Month {
    width: 100%;
    overflow: scroll;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 2.5%;
    width: 100%;
    overflow: hidden;
  }

  .header-left {
    display: flex;
    align-items: center;
  }
  .weekday {
    font-size: 10px !important;
  }
  .date a {
    font-size: 12px !important;
  }
  select {
    font-size: 10px !important;
    height: 31px !important;
  }
  .current-day a {
    padding-left: 2px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f8f8f8 !important; /* Text color for current day */
    background-color: purple; /* Highlight color for current day */
    border-radius: 5px; /* Make it circular */
    text-decoration: none; /* Remove underline */
    width: 30px; /* Set a fixed width for a circular shape */
    height: 30px; /* Set a fixed height for a circular shape */
  }
  .calendar-Month .date {
    height: 120px;
    /*Set a fixed height for the date cells */
    padding: 2%;
    text-align: left;
    border-radius: 5px;
    color: #000000;
    background-color: #f8f8f8 !important;
  }
}
</style>
