<template>
  <div>
    <!-- Offcanvas Modal -->
    <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="NotificationModal"
      aria-labelledby="NotificationModalLabel"
      style="
        height: calc(100% - 62px) !important;
        margin: 48px 0px 12px 0px !important;
        border-radius: 10px 0px 0px 10px;
      "
    >
      <div class="offcanvas-header" style="border-bottom: 2px solid #000; padding: 8px">
        <div class="d-flex align-items-center w-100 justify-content-between">
          <!-- Notifications Title -->
          <div class="d-flex align-items-center">
            <!-- <img
              src="../../assets/icons/notification.png"
              alt="Notifications"
              style="width: 20px; height: 20px; margin-right: 8px"
            /> -->
            <div class="notification-icon-wrapper position-relative me-1">
              <span
                v-if="UnreadCount > 0"
                class="notification-count badge text-danger position-absolute"
                title="New Notifications"
              >
                {{ UnreadCount }}
              </span>
              <img
                src="../../assets/icons/notification.png"
                alt="Notifications"
                style="width: 20px; height: 20px; margin-right: 8px"
              />
            </div>
            <h6 class="offcanvas-title mb-0" id="NotificationModalLabel">
              Notifications
            </h6>
          </div>

          <div class="d-flex align-items-center">
            <!-- Mark as Read Button -->
            <button
              v-if="UnreadCount"
              class="btn btn-sm me-2 text-white mark-read-button"
              style="font-size: 10px; padding: 2px 6px; background-color: purple"
              @click="confirmMarkAllAsRead"
            >
              Mark as All Read
            </button>

            <!-- Close Button -->
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
        </div>
      </div>

      <div class="offcanvas-body">
        <div class="mt-1">
          <!-- Notifications List -->
          <div class="activity-list">
            <!-- Display Notifications -->
            <div
              v-for="activity in notifications"
              :key="activity.id"
              class="activity-card d-flex justify-content-between align-items-start"
            >
              <div class="d-flex">
                <!-- Profile Picture or Placeholder -->

                <div class="profile-pic me-2">
                  <!-- If profilePicture exists -->
                  <img
                    v-if="activity.profilePicture"
                    :src="activity.profilePicture"
                    alt="Profile Picture"
                    class="img-fluid"
                  />
                  <!-- Else, use the default profile picture -->
                  <img
                    v-else
                    src="../../assets/icons/profile.png"
                    alt="Default Profile Picture"
                    class="img-fluid"
                  />
                  <br />
                  <!-- Blue Dot for Unread Notifications -->
                </div>

                <!-- Notification Content -->
                <div class="activity-content">
                  <!-- Employee Name -->
                  <span class="employee-name">{{ activity.employeeFullName }}</span>

                  <!-- Notification Text -->
                  <p class="notification-text mb-1">{{ activity.notification }}</p>

                  <!-- Blue Dot for Unread Notifications -->

                  <!-- Footer for Date and Time -->
                  <div class="activity-footer text-muted">
                    <small>{{ activity.date }} | {{ activity.time }}</small>
                  </div>
                </div>
              </div>
              <!-- <div>
                <div class="blue-dot" v-if="activity.read === 0"></div>
              </div> -->
              <div>
                <i
                  :class="[
                    'fas ms-3',
                    activity.read === 0
                      ? 'fa-thumbs-up text-primary'
                      : 'fa-thumbs-up text-secondary',
                  ]"
                  @click="markAsRead(activity)"
                  :title="activity.read === 0 ? 'Read' : 'Mark as read'"
                  style="cursor: pointer"
                >
                </i>
              </div>
            </div>
          </div>

          <!-- View More / View Less Buttons -->
          <div class="mt-3 text-center">
            <button
              v-if="notifications.length > 0 && currentPage > 1"
              class="btn btn-sm btn-secondary ms-2"
              @click="viewLess"
            >
              View Less
            </button>
            <button
              v-if="notifications.length > 0 && notifications.length < count"
              class="btn btn-sm btn-primary ms-2"
              @click="viewMore"
            >
              View More
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "NotificationModal",
  props: {
    projectId: {
      type: String,
      required: true,
    },
    team: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1, // Tracks the current page for pagination
      notifications: [], // Stores the notifications data
      UnreadCount: 0,
      count: 0, // Total count of notifications
    };
  },
  watch: {
    // Watcher for projectId to reset notifications on change
    projectId: {
      immediate: true,
      handler() {
        this.currentPage = 1; // Reset pagination
        this.notifications = []; // Clear notifications
        this.fetchNotification();
      },
    },
  },
  methods: {
    async confirmMarkAllAsRead() {
      const result = await this.$showAlert(
        "Are you sure?", // title as string
        "This will mark all notifications as read!", // text as string
        "warning", // icon as string
        "Yes, mark all", // confirmButtonText as string
        "Cancel" // cancelButtonText as string
      );

      // Check if the user confirmed
      if (result.isConfirmed) {
        try {
          // Replace with your actual API endpoint
          const response = await axios.put(
            `${this.$apiEndPoint}/notification/markAsRead`
          );

          if (response.status === 200) {
            this.UnreadCount = 0;

            // Optionally, update local notifications array
            this.notifications.forEach((notification) => {
              notification.read = 0;
            });
          }
        } catch (error) {
          console.error("Error marking all notifications as read:", error);
        }
      }
    },
    async markAsRead(activity) {
      activity.read = 0;

      try {
        // Call the API to update the status
        await axios.put(`${this.$apiEndPoint}/notification/markAsRead/${activity.id}`);
        this.$toast(" marked as read successfully", "success");
        this.UnreadCount--;
      } catch (error) {
        activity.read = 1;
        this.$handleError(error);
      }
    },

    fetchNotification() {
      if (!this.$LocalUser || !this.$LocalUser.user) {
        console.warn("User is not logged in or LocalUser is not defined.");
        return; // Exit early if user is not logged in
      }

      const url = `${this.$apiEndPoint}/notification/getNotification/${this.currentPage}`;

      axios
        .get(url)
        .then((response) => {
          // Update total unread notifications count
          this.UnreadCount = response.data.unreadCount;
          this.count = response.data.totalCounts;

          // Append new notifications to the existing list
          const newNotifications = response.data.result.map((activity) => ({
            id: activity.id,
            notification: activity.notification,
            date: activity.date,
            time: activity.time,
            read: activity.read,
            employeeFullName: activity.employee
              ? `${activity.employee.firstName} ${activity.employee.lastName}`
              : " ", // Default name if employee data is missing
            profilePicture:
              activity.employee?.signedUrl || "../../assets/icons/profile.png", // Use signed URL or default
          }));

          this.notifications = newNotifications;
          this.$emit("setProjectId");
        })
        .catch((error) => {
          console.error("Error fetching notifications:", error);
        });
    },

    viewMore() {
      this.currentPage++;
      this.fetchNotification();
    },
    viewLess() {
      this.currentPage = Math.max(1, this.currentPage - 1);
      this.notifications = this.notifications.slice(0, this.notifications.length - 10); // Adjust number as per page size
    },
  },
  mounted() {
    this.fetchNotification();
  },
};
</script>

<style scoped>
.offcanvas-title {
  font-size: 17px;
}
.offcanvas-body {
  overflow-y: auto;
  max-height: 100%;
}

.activity-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.activity-card {
  display: flex;
  gap: 1rem;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.profile-pic img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: 5px;
}

.activity-content {
  flex: 1;
}

.employee-name {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
}

.notification-text {
  font-size: 13px;
  margin-bottom: 5px;
}

.activity-footer {
  font-size: 12px;
  color: #666;
}

.btn-sm {
  font-size: 13px;
  padding: 5px 10px;
}
.blue-dot {
  display: inline-block; /* Ensures it's treated as a visible element */
  width: 8px;
  height: 8px;
  border-radius: 50%; /* Makes it circular */
  background-color: blue; /* Sets the color */
  margin-left: 8px; /* Adds spacing */
  vertical-align: middle; /* Aligns it with text if needed */
}

.notification-icon-wrapper {
  display: inline-block;
  position: relative;
}

.notification-count {
  top: -6px; /* Adjust to position the count above the image */
  right: -8px; /* Adjust for horizontal alignment */
  font-size: 10px; /* Adjust font size for readability */
  padding: 2px 6px;
  border-radius: 50%;
  min-width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mark-read-button {
  transition: all 0.3s ease-in-out;
  background-color: purple;
  border: none;
  border-radius: 4px;
}

/* Change background color and scale the button slightly on hover */
.mark-read-button:hover {
  background-color: #800080; /* Slightly darker purple */
  transform: scale(1.1); /* Scale up slightly */
  box-shadow: 0 4px 8px rgba(128, 0, 128, 0.4); /* Add shadow for depth */
}

/* Optional focus effect for accessibility */
.mark-read-button:focus {
  outline: 2px solid #d8bfea; /* Light purple outline */
}
.mark-read-button:hover {
  animation: pulse 0.6s infinite alternate;
}
</style>
