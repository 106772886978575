<template>
  <div>
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <div class="OnSiteProjectHeader">
          <div class="OnSiteProjectComponentHeader">
            <div class="DesignProjectComponentHeader p-2">
              <img
                src="../../assets/icons/black_Procurement.png"
                alt=""
                id="DesignProjectHeaderImage"
              />
              <div class="DesignProjectHeader">
                <section class="DesignProjectHeaderRow1">
                  <span class="text-nowrap">{{ this.ProjectName }}</span>

                  <span class="ms-2">
                    <img
                      src="../../assets/icons/blackDownarrow.png"
                      alt=""
                      @click="toggleProjectNameDropDown"
                      style="position: relative"
                    />
                    <transition name="fade">
                      <div
                        v-if="showProjectNameDropDown"
                        class="modal-overlay"
                        @click="toggleProjectNameDropDown"
                      ></div>
                    </transition>
                    <transition name="slide">
                      <div v-if="showProjectNameDropDown">
                        <!-- Dropdown content -->
                        <div class="showProjectNameDropDown p-1">
                          <ol>
                            <li
                              data-bs-toggle="modal"
                              data-bs-target="#shareProjectModal"
                            >
                              Assignee
                            </li>
                            <!-- <li>Export</li> -->

                            <select
                              style="border: none; outline: none"
                              v-model="ProjectStatus"
                              @change="ChangeProjectStatus(ProjectStatus)"
                            >
                              <option>In Progress</option>
                              <!-- <option>On Hold</option> -->
                              <option>Completed</option>
                            </select>
                          </ol>
                        </div>
                      </div>
                    </transition>
                  </span>

                  <div
                    class="ms-3"
                    style="display: flex; align-self: flex-start; padding-left: 20%"
                  >
                    <span>
                      <img
                        v-if="this.ProjectStatus === 'Completed'"
                        src="../../assets/icons/completed.png"
                        alt="Completed"
                        style="width: 15px; height: 15px"
                      />
                      <img
                        v-else-if="this.ProjectStatus === 'On Hold'"
                        src="../../assets/icons/onHold.png"
                        alt="On Hold"
                        style="width: 15px; height: 15px"
                      />
                      <img
                        v-else-if="this.ProjectStatus === 'In Progress'"
                        src="../../assets/icons/inProgress.png"
                        alt="In Progress"
                        style="width: 15px; height: 15px"
                      />
                    </span>
                    <span
                      class="ms-2 mt-1"
                      style="white-space: nowrap; font-size: 14px"
                      >{{ this.ProjectStatus }}</span
                    >
                  </div>
                </section>
                <div class="DesignProjectHeaderRow2">
                  <section class="OnsiteProjectHeaderRow2 WEB">
                    <h6
                      @click="setActiveTab('customerDetails')"
                      :class="{
                        'active-SubHeading': activeTab === 'customerDetails',
                        'inactive-SubHeading': activeTab !== 'customerDetails',
                      }"
                    >
                      Customer Details
                    </h6>

                    <h6
                      @click="setActiveTab('estimates')"
                      :class="{
                        'active-SubHeading': activeTab === 'estimates',
                        'inactive-SubHeading': activeTab !== 'estimates',
                      }"
                    >
                      Estimates
                    </h6>
                    <h6
                      @click="setActiveTab('purchaseRequest')"
                      :class="{
                        'active-SubHeading': activeTab === 'purchaseRequest',
                        'inactive-SubHeading': activeTab !== 'purchaseRequest',
                      }"
                    >
                      Purchase Request
                    </h6>
                    <h6
                      @click="setActiveTab('purchaseOrders')"
                      :class="{
                        'active-SubHeading': activeTab === 'purchaseOrders',
                        'inactive-SubHeading': activeTab !== 'purchaseOrders',
                      }"
                    >
                      Purchase Orders
                    </h6>
                    <h6
                      @click="setActiveTab('purchaseInvoice')"
                      :class="{
                        'active-SubHeading': activeTab === 'purchaseInvoice',
                        'inactive-SubHeading': activeTab !== 'purchaseInvoice',
                      }"
                    >
                      Purchase Invoice
                    </h6>
                    <h6
                      @click="setActiveTab('grn')"
                      :class="{
                        'active-SubHeading': activeTab === 'grn',
                        'inactive-SubHeading': activeTab !== 'grn',
                      }"
                    >
                      GRN
                    </h6>
                    <h6
                      @click="setActiveTab('remarks')"
                      :class="{
                        'active-SubHeading': activeTab === 'remarks',
                        'inactive-SubHeading': activeTab !== 'remarks',
                      }"
                    >
                      Remark
                    </h6>
                  </section>
                  <select
                    class="mt-2 ms-1 MOBILE"
                    v-model="activeTab"
                    style="border-radius: 5px"
                    @change="setActiveTab(activeTab)"
                  >
                    <option value="customerDetails">Customer Details</option>
                    <option value="estimates">Estimates</option>
                    <option value="purchaseRequest">Purchase Request</option>
                    <option value="purchaseOrders">Purchase Orders</option>
                    <option value="purchaseInvoice">Purchase Invoice</option>
                    <option value="grn">GRN</option>
                    <option value="remarks">Remark</option>
                  </select>
                </div>
              </div>
              <div class="DesignProjectHeaderUsers">
                <div
                  class="col-lg-2 col-md-2 col-sm-2 d-flex justify-content-center align-items-center"
                >
                  <div class="collaborators-container">
                    <div
                      v-for="(collaborator, index) in collaborators.slice(0, 3)"
                      :key="index"
                      class="collaborator-avatar"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        v-if="collaborator['employees.signedUrl'] !== null"
                        :src="collaborator['employees.signedUrl']"
                        class="initials"
                        :alt="collaborator.initials"
                      />
                      <span v-else class="initials">{{ collaborator.initials }}</span>
                    </div>

                    <!-- If more than 3 collaborators, show additional count -->
                    <div v-if="collaborators.length > 3" class="additional-count">
                      + {{ collaborators.length - 3 }}
                    </div>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <div
                        v-for="(employee, employeeIndex) in collaborators"
                        :key="employeeIndex"
                        class="d-flex p-2 text-start"
                      >
                        <span class="d-flex ms-2 text-nowrap text-start">
                          {{ employee["employees.firstName"] }}
                          {{ employee["employees.lastName"] }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <!-- Dropdown menu for showing all collaborator names -->
                </div>
                <button
                  @click="OpenProjectActivityModal()"
                  type="button"
                  class="btn btn-custom ms-5"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#ProjectActivityModal"
                >
                  <img src="../../assets/icons/activityIcon.png" alt="Activity" />
                  Activity
                </button>
              </div>
            </div>
          </div>
          <div class="headerbelowcontent p-1">
            <div v-if="activeTab === 'customerDetails'" class="p-2">
              <div class="row col-md-12 p-2 d-flex align-content-center">
                <div class="col-md-6">
                  <div class="form-group row mt-2">
                    <label for="salutation" class="col-md-3 col-form-label text-nowrap"
                      >Name:</label
                    >
                    <div class="col-md-2">
                      <select
                        @blur="ChangeCustomerData()"
                        id="salutation"
                        class="form-control form-select inputBg"
                        v-model="CustomerDetails.salutation"
                      >
                        <option value=""></option>
                        <option value="Mr.">Mr.</option>
                        <option value="Mrs.">Mrs.</option>
                        <option value="Mx.">Mx.</option>
                        <!-- Gender-neutral title for transgender individuals -->
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div class="col-md-7">
                      <!-- <div
                        class="form-control inputBg"
                        style="min-height: 40px; vertical-align: top"
                      >
                        {{ CustomerDetails.name }}
                      </div> -->
                      <input
                        type="text"
                        id="name"
                        class="form-control inputBg"
                        style="min-height: 40px; vertical-align: top"
                        v-model="CustomerDetails.name"
                        @blur="ChangeCustomerData()"
                      />
                    </div>
                  </div>
                  <div class="form-group row mt-2">
                    <label for="email" class="col-md-3 col-form-label text-nowrap"
                      >Email:</label
                    >
                    <div class="col-md-9">
                      <input
                        type="email"
                        id="email"
                        class="form-control inputBg"
                        style="min-height: 40px; vertical-align: top"
                        v-model="CustomerDetails.email"
                        @blur="ChangeCustomerData()"
                      />
                    </div>
                  </div>
                  <div class="form-group row mt-2">
                    <label for="contactNo" class="col-md-3 col-form-label text-nowrap"
                      >Contact No:</label
                    >
                    <div class="col-md-9">
                      <input
                        type="text"
                        id="contactNo"
                        class="form-control inputBg"
                        style="min-height: 40px; vertical-align: top"
                        v-model="CustomerDetails.contactNo"
                        @blur="ChangeCustomerData()"
                      />
                    </div>
                  </div>
                  <div class="form-group row mt-2">
                    <label for="residentialAddress" class="col-md-3 col-form-label"
                      >Customer Id:</label
                    >
                    <div class="col-md-9">
                      <div
                        class="form-control inputBg"
                        style="min-height: 40px; vertical-align: top"
                      >
                        {{ CustomerDetails.customerNumber }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group row mt-2">
                    <label for="propertyAddress" class="col-md-3 col-form-label"
                      >Property Address:</label
                    >
                    <div class="col-md-9">
                      <input
                        id="propertyAddress"
                        class="form-control inputBg"
                        v-model="PropertyData.address"
                        @blur="updateOrCreateAddress('Property', this.PropertyData)"
                      />
                    </div>
                  </div>
                  <div class="form-group row mt-2">
                    <label for="propertyCity" class="col-md-3 col-form-label text-nowrap"
                      >City:</label
                    >
                    <div class="col-md-9">
                      <input
                        type="text"
                        id="propertyCity"
                        class="form-control inputBg"
                        style="min-height: 40px; vertical-align: top"
                        v-model="PropertyData.city"
                        @blur="updateOrCreateAddress('Property', this.PropertyData)"
                      />
                    </div>
                  </div>
                  <div class="form-group row mt-2">
                    <label for="propertyState" class="col-md-3 col-form-label text-nowrap"
                      >State:</label
                    >
                    <div class="col-md-9">
                      <input
                        type="text"
                        id="propertyCity"
                        class="form-control inputBg"
                        style="min-height: 40px; vertical-align: top"
                        v-model="PropertyData.state"
                        @blur="updateOrCreateAddress('Property', this.PropertyData)"
                      />
                    </div>
                  </div>
                  <div class="form-group row mt-2">
                    <label for="gst" class="col-md-3 col-form-label text-nowrap"
                      >GST:</label
                    >
                    <div class="col-md-9">
                      <input
                        @blur="ChangeCustomerData()"
                        type="text"
                        id="gst"
                        class="form-control inputBg"
                        v-model="CustomerDetails.GSTNumber"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="sectionAddress row col-md-12 p-2">
                <section class="col-md-6">
                  <h5 class="text-primary e-underline d-flex text-nowrap">
                    Billing Address
                  </h5>
                  <div class="billingForm">
                    <div class="form-group row mt-2">
                      <label for="billingCompanyName" class="col-md-3 col-form-label"
                        >Name:</label
                      >
                      <div class="col-md-9">
                        <input
                          type="text"
                          id="billingCompanyName"
                          class="form-control inputBg"
                          style="min-height: 40px; vertical-align: top"
                          v-model="billingAddressData.name"
                          @blur="
                            updateOrCreateAddress('Billing', this.billingAddressData)
                          "
                        />
                      </div>
                    </div>

                    <div class="form-group row mt-2">
                      <label for="billingAddress" class="col-md-3 col-form-label"
                        >Address:</label
                      >
                      <div class="col-md-9">
                        <input
                          @blur="
                            updateOrCreateAddress('Billing', this.billingAddressData)
                          "
                          type="text"
                          class="form-control inputBg"
                          style="min-height: 40px; vertical-align: top"
                          id="billingAddress"
                          v-model="billingAddressData.address"
                        />
                      </div>
                    </div>
                    <div class="form-group row mt-2">
                      <label for="billingCity" class="col-md-3 col-form-label"
                        >City:</label
                      >
                      <div class="col-md-9">
                        <input
                          @blur="
                            updateOrCreateAddress('Billing', this.billingAddressData)
                          "
                          type="text"
                          class="form-control inputBg"
                          style="min-height: 40px; vertical-align: top"
                          id="billingAddress"
                          v-model="billingAddressData.city"
                        />
                      </div>
                    </div>
                    <div class="form-group row mt-2">
                      <label for="billingState" class="col-md-3 col-form-label"
                        >State:</label
                      >
                      <div class="col-md-9">
                        <input
                          @blur="
                            updateOrCreateAddress('Billing', this.billingAddressData)
                          "
                          type="text"
                          class="form-control inputBg"
                          style="min-height: 40px; vertical-align: top"
                          id="billingAddress"
                          v-model="billingAddressData.state"
                        />
                      </div>
                    </div>
                    <div
                      class="form-group row mt-2"
                      style="min-height: 40px; vertical-align: top"
                    >
                      <label for="billingPincode" class="col-md-3 col-form-label"
                        >Pincode:</label
                      >
                      <div class="col-md-9">
                        <input
                          @blur="
                            updateOrCreateAddress('Billing', this.billingAddressData)
                          "
                          type="text"
                          class="form-control inputBg"
                          style="min-height: 40px; vertical-align: top"
                          id="billingAddress"
                          v-model="billingAddressData.pincode"
                        />
                      </div>
                    </div>
                    <div class="form-group row mt-2">
                      <label for="billingPhoneNo" class="col-md-3 col-form-label"
                        >Phone No:</label
                      >
                      <div class="col-md-9">
                        <input
                          @blur="
                            updateOrCreateAddress('Billing', this.billingAddressData)
                          "
                          type="text"
                          class="form-control inputBg"
                          style="min-height: 40px; vertical-align: top"
                          id="billingAddress"
                          v-model="billingAddressData.contactNo"
                        />
                      </div>
                    </div>
                    <div class="form-group row mt-2">
                      <label for="billingEmailId" class="col-md-3 col-form-label"
                        >Email ID:</label
                      >
                      <div class="col-md-9">
                        <input
                          @blur="
                            updateOrCreateAddress('Billing', this.billingAddressData)
                          "
                          type="text"
                          class="form-control inputBg"
                          style="min-height: 40px; vertical-align: top"
                          id="billingAddress"
                          v-model="billingAddressData.email"
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <section class="col-md-6">
                  <h5
                    class="text-primary e-underline d-flex align-content-center text-nowrap"
                  >
                    Shipping Address
                  </h5>
                  <div class="shippingForm">
                    <div class="form-group row mt-2">
                      <label for="shippingCompanyName" class="col-md-3 col-form-label"
                        >Name:</label
                      >
                      <div class="col-md-9">
                        <input
                          type="text"
                          id="billingCompanyName"
                          class="form-control inputBg"
                          style="min-height: 40px; vertical-align: top"
                          v-model="addressData.name"
                          @blur="updateOrCreateAddress('Shipping', this.addressData)"
                        />
                      </div>
                    </div>
                    <div class="form-group row mt-2">
                      <label for="shippingAddress" class="col-md-3 col-form-label"
                        >Address:</label
                      >
                      <div class="col-md-9">
                        <input
                          type="text"
                          class="form-control inputBg"
                          id="shippingAddress"
                          v-model="addressData.address"
                          @blur="updateOrCreateAddress('Shipping', this.addressData)"
                        />
                      </div>
                    </div>
                    <div class="form-group row mt-2">
                      <label for="shippingState" class="col-md-3 col-form-label"
                        >State:</label
                      >
                      <div class="col-md-9">
                        <input
                          type="text"
                          class="form-control inputBg"
                          id="shippingState"
                          @blur="updateOrCreateAddress('Shipping', this.addressData)"
                          v-model="addressData.state"
                        />
                      </div>
                    </div>
                    <div class="form-group row mt-2">
                      <label for="shippingCity" class="col-md-3 col-form-label"
                        >City:</label
                      >
                      <div class="col-md-9">
                        <input
                          type="text"
                          class="form-control inputBg"
                          id="shippingCity"
                          v-model="addressData.city"
                          @blur="updateOrCreateAddress('Shipping', this.addressData)"
                        />
                      </div>
                    </div>
                    <div class="form-group row mt-2">
                      <label for="shippingPincode" class="col-md-3 col-form-label"
                        >Pincode:</label
                      >
                      <div class="col-md-9">
                        <input
                          type="text"
                          class="form-control inputBg"
                          id="shippingPincode"
                          v-model="addressData.pincode"
                          @blur="updateOrCreateAddress('Shipping', this.addressData)"
                        />
                      </div>
                    </div>
                    <div class="form-group row mt-2">
                      <label for="shippingPhoneNo" class="col-md-3 col-form-label"
                        >Phone No:</label
                      >
                      <div class="col-md-9">
                        <input
                          type="text"
                          class="form-control inputBg"
                          id="shippingPhoneNo"
                          v-model="addressData.contactNo"
                          @blur="updateOrCreateAddress('Shipping', this.addressData)"
                        />
                      </div>
                    </div>
                    <div class="form-group row mt-2">
                      <label for="shippingEmailId" class="col-md-3 col-form-label"
                        >Email ID:</label
                      >
                      <div class="col-md-9">
                        <input
                          type="text"
                          class="form-control inputBg"
                          id="shippingEmailId"
                          v-model="addressData.email"
                          @blur="updateOrCreateAddress('Shipping', this.addressData)"
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>

            <div v-if="activeTab === 'estimates'">
              <div class="table-container mt-2">
                <table class="">
                  <caption class="d-none">
                    Table
                  </caption>
                  <thead class="subHeaderHeadingBgColor">
                    <tr>
                      <th>S/N</th>
                      <th>Customer Name</th>
                      <th>Customer ID</th>
                      <th>Contact No</th>
                      <th>Date</th>
                      <th>Estimate No</th>
                      <th style="text-align: center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(estimate, index) in estimates" :key="estimate.id">
                      <td>{{ index + 1 }}</td>
                      <td>{{ estimate["customers.name"] }}</td>
                      <td>{{ estimate["customers.customerNumber"] }}</td>
                      <td>{{ estimate["customers.contactNo"] }}</td>
                      <td>{{ estimate.date }}</td>
                      <td>{{ estimate.estimateId }}</td>
                      <td class="text-center">
                        <div class="ActionImage">
                          <span
                            @click="
                              $viewFile(
                                estimate['awsFiles.awsFileName'],
                                estimate['awsFiles.fileName'],
                                'estimate',
                                'sales'
                              )
                            "
                          >
                            <img
                              v-if="estimate['awsFiles.awsFileName']"
                              src="../../assets/icons/view.png"
                              title="Edit"
                            />
                          </span>
                          <span
                            @click="
                              $downloadFile(
                                estimate['awsFiles.awsFileName'],
                                estimate['awsFiles.fileName'],
                                'estimate',
                                'sales'
                              )
                            "
                          >
                            <img
                              v-if="estimate['awsFiles.awsFileName']"
                              src="../../assets/icons/pinkDownold.png"
                              title="download"
                            />
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div v-if="activeTab === 'purchaseRequest'">
              <div class="table-container mt-2">
                <div class="d-flex justify-content-end mb-3">
                  <button
                    class="btn btn-sm text-light"
                    style="background-color: purple; margin-right: 30px"
                    @click="goToPurchaseCreate('Procurement')"
                  >
                    Purchase Request +
                  </button>
                </div>
                <table class="">
                  <caption class="d-none">
                    Table
                  </caption>
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>PR Number</th>
                      <th>Date</th>
                      <th>Requested By</th>
                      <th>Department</th>
                      <th>Status</th>
                      <th class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in PurchaseRequests" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-nowrap text-left">{{ row.prNumber }}</td>
                      <td>{{ row.date }}</td>
                      <td>
                        {{ row["employees.firstName"] }} {{ row["employees.lastName"] }}
                      </td>
                      <td>{{ row.department }}</td>
                      <td :class="statusClass(row.prStatus)">
                        <select
                          v-if="
                            ['Approved', 'Semi Ordered', 'Ordered'].includes(row.prStatus)
                          "
                          :style="optionStyle(row.prStatus)"
                          v-model="row.prStatus"
                          @change="handleStatusChange(row.id, row.prStatus)"
                          class="form-select form-select-sm w-75"
                        >
                          <option value="Approved">Approved</option>
                          <option value="Semi Ordered">Semi-Ordered</option>
                          <option value="Ordered">Ordered</option>
                        </select>

                        <span v-else>{{ row.prStatus }}</span>
                      </td>

                      <td class="text-center">
                        <div class="ActionImage">
                          <span @click="openModal(row.id)">
                            <img
                              src="../../assets/icons/view.png"
                              title="View"
                              data-bs-toggle="modal"
                              data-bs-target="#PRDetailsModal"
                            />
                          </span>
                          <span @click="handleDownload(row.id)">
                            <img
                              src="../../assets/icons/pinkDownold.png"
                              title="Download"
                            />
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-if="activeTab === 'purchaseOrders'">
              <div class="table-container mt-2">
                <div class="d-flex justify-content-end mb-3">
                  <button
                    class="btn btn-sm text-light"
                    style="background-color: purple; margin-right: 30px"
                    @click="goToPurchaseOrderCreate()"
                  >
                    Purchase Order +
                  </button>
                </div>
                <section class="">
                  <table class="">
                    <caption class="d-none">
                      Table
                    </caption>
                    <thead class="subHeaderHeadingBgColor">
                      <tr>
                        <th>S/N</th>
                        <th>PO Number</th>
                        <th>Reference</th>
                        <th>Date</th>
                        <th>Company Name</th>

                        <th>Status</th>
                        <th class="text-center">PO Amount</th>
                        <th class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in PurchaseOrders" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td class="text-nowrap text-left">
                          {{ row.poNumber }}
                        </td>

                        <td class="text-left">
                          <span v-if="row['purchaseRequests.prNumber']"
                            >{{ row["purchaseRequests.prNumber"] }}
                          </span>
                          <span style="color: purple" v-else>Direct PO</span>
                        </td>
                        <td>
                          {{ row.date }}
                        </td>
                        <td>{{ row["vendors.companyName"] }}</td>

                        <td :class="statusClass(row.poStatus)">
                          <select
                            v-if="
                              row.poStatus === 'Approved' ||
                              row.poStatus === 'Semi Delivered' ||
                              row.poStatus === 'Delivered' ||
                              row.poStatus === 'Ordered'
                            "
                            v-model="row.poStatus"
                            @change="handleStatusChangeOfPo(row.id, row.poStatus)"
                            class="form-select form-select-sm"
                            :class="statusClass(row.poStatus)"
                            style="
                              width: max-content;
                              color: inherit;
                              background-color: inherit;
                            "
                          >
                            <option value="Approved" :style="optionStyle('Approved')">
                              Approved
                            </option>
                            <option value="Ordered" :style="optionStyle('Ordered')">
                              Ordered
                            </option>
                            <option
                              value="Semi Delivered"
                              :style="optionStyle('Semi Delivered')"
                            >
                              Semi-Delivered
                            </option>
                            <option value="Delivered" :style="optionStyle('Delivered')">
                              Delivered
                            </option>

                            <!-- Add more status options here if needed -->
                          </select>

                          <span v-else> {{ row.poStatus }}</span>
                        </td>
                        <td class="text-nowrap text-end">
                          {{ this.$formatToIndianCurrency(row.totalAmount) }}
                        </td>
                        <td class="text-center">
                          <div class="ActionImage">
                            <span
                              @click="openPOModal(row.id)"
                              data-bs-toggle="modal"
                              data-bs-target="#PODetailsModal"
                            >
                              <img
                                src="../../assets/icons/view.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="Edit"
                                alt=""
                              />
                            </span>
                            <span>
                              <img
                                @click="handleDownloadPO(row.id)"
                                src="../../assets/icons/pinkDownold.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="downold"
                                alt=""
                              />
                            </span>
                          </div>
                        </td>
                      </tr>

                      <tr style="font-weight: 600" class="tfoot">
                        <td></td>
                        <td class="text-start" style="font-weight: 600">Total Value</td>
                        <td></td>
                        <td class="text-end" style="font-weight: 600"></td>

                        <td></td>
                        <td></td>
                        <td class="text-end text-success" style="font-weight: 600">
                          {{ $formatToIndianCurrency(this.PurchaseAmount) }}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              </div>
            </div>
            <div v-if="activeTab === 'purchaseInvoice'">
              <div class="d-flex justify-content-end" style="margin: 0.5% 2.9%">
                <button
                  class="btn btn-sm text-white"
                  @click="openPIModal(SelectedPIId)"
                  data-bs-toggle="modal"
                  style="background-color: purple; margin-right: 30px"
                  data-bs-target="#CreatePurchaseInvoice"
                >
                  Purchase Invoice +
                </button>
              </div>
              <div class="table-container mt-2">
                <section class="">
                  <table class="">
                    <caption class="d-none">
                      Table
                    </caption>
                    <thead class="subHeaderHeadingBgColor">
                      <tr>
                        <th>S/N</th>
                        <th>Document No.</th>
                        <th>Reference</th>
                        <th>Date</th>
                        <th>Company Name</th>
                        <th>Status</th>
                        <th class="text-center">Invoice Amount</th>
                        <th class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in PurchaseInvoice" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td class="text-nowrap text-left">
                          {{ row.documentNumber }}
                        </td>
                        <td>{{ row["purchaseOrders.poNumber"] }}</td>
                        <td>
                          {{ row.date }}
                        </td>

                        <td>{{ row["vendors.companyName"] }}</td>

                        <td :class="statusClass(row.invoiceStatus)">
                          <select
                            v-model="row.invoiceStatus"
                            @change="handleStatusChangeOfPI(row.id, row.invoiceStatus)"
                            class="form-select form-select-sm"
                            :class="statusClass(row.invoiceStatus)"
                            style="
                              width: max-content;
                              color: inherit;
                              background-color: inherit;
                            "
                          >
                            <option
                              value="Payment Pending"
                              :style="optionStyle('Payment Pending')"
                            >
                              Payment Pending
                            </option>
                            <option
                              value="Payment Done"
                              :style="optionStyle('Payment Done')"
                            >
                              Payment Done
                            </option>
                            <!-- Add more status options here if needed -->
                          </select>
                        </td>
                        <td class="text-end">
                          {{ this.$formatToIndianCurrency(row.amount) }}
                        </td>
                        <td class="text-center">
                          <div class="ActionImage">
                            <span>
                              <img
                                src="../../assets/icons/yellowEdit.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="Edit"
                                alt=""
                                @click="openPIModal(row.id)"
                                data-bs-toggle="modal"
                                data-bs-target="#UpdatePIModal"
                              />
                            </span>
                            <span
                              @click="
                                $viewFile(
                                  row['awsFiles.awsFileName'],
                                  row['awsFiles.fileName'],
                                  'purchaseInvoice',
                                  'procurement'
                                )
                              "
                            >
                              <img
                                src="../../assets/icons/view.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="attchment"
                                alt=""
                              />
                            </span>
                            <span>
                              <img
                                @click="
                                  $downloadFile(
                                    row['awsFiles.awsFileName'],
                                    row['awsFiles.fileName'],
                                    'purchaseInvoice',
                                    'procurement'
                                  )
                                "
                                src="../../assets/icons/pinkDownold.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="Delete"
                                alt=""
                              />
                            </span>
                          </div>
                        </td>
                      </tr>

                      <tr style="font-weight: 600" class="tfoot">
                        <td></td>
                        <td class="text-start" style="font-weight: 600">Total Value</td>
                        <td></td>
                        <td class="text-end" style="font-weight: 600"></td>

                        <td></td>
                        <td></td>
                        <td class="text-end text-success" style="font-weight: 600">
                          {{ $formatToIndianCurrency(this.PurchaseInvoiceAmount) }}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              </div>
            </div>
            <div v-if="activeTab === 'grn'">
              <div
                class="d-flex justify-content-end"
                style="margin: 0.5% 2.9%"
                v-if="grnListview"
              >
                <button @click="opengrnList(null)" class="btn btn-sm btn-primary">
                  + Add GRN
                </button>
              </div>
              <div class="d-flex justify-content-between" style="margin: 0.5% 1%" v-else>
                <span
                  class="SubheadingUnderLine"
                  style="display: flex; align-items: center; margin-left: 2px"
                  v-if="!selectedGrnId"
                >
                  New Goods Received Note
                </span>
                <span
                  class="SubheadingUnderLine"
                  style="display: flex; align-items: center; margin-left: 2px"
                  v-else
                >
                  Update Goods Received Note
                </span>
                <button class="btn btn-sm btn-close" @click="opengrnList(null)"></button>
              </div>

              <div class="table-container mt-2" v-if="grnListview">
                <section>
                  <table class="">
                    <caption class="d-none">
                      Table
                    </caption>
                    <thead class="subHeaderHeadingBgColor">
                      <tr>
                        <th>S/N</th>
                        <th>GRN No.</th>
                        <th>Received Date</th>
                        <th>Company Name</th>

                        <th class="text-leftS">Document Type</th>

                        <th class="text-end">Document No</th>

                        <th class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in grnList" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td class="text-nowrap text-left">
                          {{ row.grnNumber }}
                        </td>
                        <td>{{ row.receivedDate }}</td>
                        <td>{{ row["purchaseOrders.vendors.companyName"] }}</td>

                        <td class="text-left">{{ row.documentType }}</td>
                        <td class="text-end">
                          <span>
                            {{ row.documentNo }}
                          </span>
                          &nbsp;&nbsp;&nbsp;

                          <span v-if="row['awsFiles.awsFileName']">
                            <img
                              v-if="row.documentType === 'Tax Invoice'"
                              @click="
                                $viewFile(
                                  row['awsFiles.awsFileName'],
                                  row['awsFiles.fileName'],
                                  'purchaseInvoice',
                                  'procurement'
                                )
                              "
                              src="../../assets/icons/blueAttachemnet.png"
                              alt="Completed"
                              style="width: 15px; height: 15px; cursor: pointer"
                            />
                            <img
                              v-else
                              @click="
                                $viewFile(
                                  row['awsFiles.awsFileName'],
                                  row['awsFiles.fileName'],
                                  'deliveryChallen',
                                  'procurement'
                                )
                              "
                              src="../../assets/icons/blueAttachemnet.png"
                              alt="Completed"
                              style="width: 15px; height: 15px; cursor: pointer"
                            />
                          </span>
                        </td>

                        <td class="text-center">
                          <div class="ActionImage">
                            <span
                              @click="openGRNModal(row.id)"
                              data-bs-toggle="modal"
                              data-bs-target="#OnSiteGrnDetailsModal"
                            >
                              <img
                                src="../../assets/icons/view.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="view"
                                alt=""
                              />
                            </span>
                            <span @click="opengrnList(row.id)">
                              <img
                                src="../../assets/icons/yellowEdit.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="Edit"
                                alt=""
                              />
                            </span>
                            <span @click="handleDownloadGRN(row.id)">
                              <img
                                src="../../assets/icons/pinkDownold.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="downold"
                                alt=""
                              />
                            </span>
                            <span>
                              <img
                                @click="DeleteGrn(row.id, index)"
                                src="../../assets/icons/redDelete.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="Delete"
                                alt=""
                              />
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              </div>
              <div v-else>
                <div class="CreateGrnPage">
                  <section
                    style="
                      background-color: #f8f8f8;
                      border-radius: 5px;
                      padding: 20px;
                      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    "
                  >
                    <form>
                      <div class="row mb-3">
                        <div class="form-group col-md-4">
                          <label for="reference">PO Number</label>
                          <select
                            class="form-control form-select no-border"
                            id="category"
                            v-model="grn.poId"
                            @change="ShowReferenceDetails()"
                          >
                            <option
                              v-for="PurchaseOrder in ApprovedPurchaseOrders"
                              :key="PurchaseOrder.id"
                              :value="PurchaseOrder.id"
                            >
                              {{ PurchaseOrder.poNumber }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group col-md-4">
                          <label for="orderDate">Order Date</label>
                          <!-- <input
                            type="date"
                            class="form-control"
                            id="orderDate"
                            v-model="grn.POdate"
                          /> -->
                          <p class="form-control h-50">{{ formatDate(grn.POdate) }}</p>
                        </div>
                        <div class="form-group col-md-4">
                          <label for="vendorName">Company Name</label>
                          <input
                            type="text"
                            class="form-control"
                            id="vendorName"
                            placeholder="Company Name"
                            v-model="grn.vendorName"
                          />
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="form-group col-md-4">
                          <label for="vendorContact">Vendor Contact No.</label>
                          <input
                            type="text"
                            class="form-control"
                            id="vendorContact"
                            v-model="grn.vendorContactNo"
                            placeholder="Vendor Contact No."
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label for="deliveryDocType">Delivery Document Type</label>
                          <select
                            class="form-control form-select no-border"
                            id="category"
                            v-model="grn.documentType"
                          >
                            <option>Tax Invoice</option>

                            <option>Delivery Challan</option>
                          </select>
                        </div>
                        <div class="form-group col-md-4">
                          <label for="documentNo">Document No.</label>
                          <input
                            type="text"
                            class="form-control"
                            id="documentNo"
                            placeholder="Document No."
                            v-model="grn.documentNo"
                          />
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="form-group col-md-4">
                          <label for="receivedDate">Received Date</label>
                          <input
                            type="date"
                            class="form-control"
                            id="receivedDate"
                            v-model="grn.receivedDate"
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label for="receiverName">Receiver Name</label>
                          <input
                            type="text"
                            class="form-control"
                            id="receiverName"
                            placeholder="Receiver Name"
                            v-model="grn.receiverName"
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label for="receiverContact">Receiver Contact No.</label>
                          <input
                            type="text"
                            class="form-control"
                            id="receiverContact"
                            placeholder="Receiver Contact No."
                            v-model="grn.receiverContactNo"
                          />
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="form-group col-md-4">
                          <label for="uploadFile">Upload File</label>
                          <span
                            v-if="
                              this.selectedGrnId && grn.documentType === 'Tax Invoice'
                            "
                          >
                          </span>
                          <input
                            v-else
                            type="file"
                            class="form-control"
                            id="onSiteGrn"
                            name="onSiteGrn"
                            @change="handleFileUploadGrnFile"
                          />
                          <strong
                            class="ms-1"
                            style="cursor: pointer"
                            v-if="grn.documentType === 'Delivery Challan'"
                            @click="
                              $viewFile(
                                grn['awsFiles.awsFileName'],
                                grn['awsFiles.fileName'],
                                'deliveryChallen',
                                'procurement'
                              )
                            "
                          >
                            {{ this.grn.fileName }}
                          </strong>
                          <strong
                            style="cursor: pointer"
                            class="ms-1"
                            v-if="grn.documentType === 'Tax Invoice'"
                            @click="
                              $viewFile(
                                grn['awsFiles.awsFileName'],
                                grn['awsFiles.fileName'],
                                'purchaseInvoice',
                                'procurement'
                              )
                            "
                          >
                            {{ this.grn.fileName }}
                          </strong>
                        </div>
                        <div
                          class="form-group col-md-4"
                          v-if="grn.documentType === 'Tax Invoice'"
                        >
                          <label for="amount">Invoice Amount.</label>
                          <input
                            type="text"
                            class="form-control"
                            id="amount"
                            placeholder="Amount."
                            v-model="grn.amount"
                          />
                        </div>
                        <div
                          class="form-group col-md-4"
                          v-if="grn.documentType === 'Tax Invoice'"
                        >
                          <label for="date">Invoice Date.</label>
                          <input
                            type="date"
                            class="form-control"
                            id="date"
                            placeholder="Document No."
                            v-model="grn.date"
                          />
                        </div>
                      </div>
                    </form>
                  </section>
                  <section>
                    <section class="GrnTable">
                      <table>
                        <caption class="d-none">
                          Table
                        </caption>
                        <thead>
                          <tr>
                            <th>Product Name (Description)</th>
                            <th>Order Qty</th>
                            <th>Received Qty</th>
                            <th>Damaged Qty</th>
                            <th>Missing Qty</th>
                            <th>Remark</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in lineItems" :key="item.id">
                            <td>
                              {{ item["productLists.productName"] }} ({{
                                item["productLists.description"]
                              }})
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                v-model="item.checked"
                                @change="updateQuantities(index)"
                              />
                              {{ item.quantity }}
                            </td>
                            <td>
                              <input
                                type="number"
                                v-model="item.receivedQty"
                                :disabled="item.checked"
                                @input="validateInput(index, 'receivedQty')"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                v-model="item.damagedQty"
                                :disabled="item.checked"
                                @input="validateInput(index, 'damagedQty')"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                v-model="item.missingQty"
                                :disabled="item.checked"
                                @input="validateInput(index, 'missingQty')"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                v-model="item.remark"
                                :disabled="item.checked"
                              />
                            </td>
                          </tr>
                          <!-- Add more rows as needed -->
                        </tbody>
                      </table>
                    </section>
                    <textarea
                      class="form-control mb-3"
                      rows="4"
                      placeholder="Additional Remarks"
                      v-model="grn.comments"
                    ></textarea>
                  </section>
                </div>

                <div class="footerPuchaseRequest">
                  <button
                    v-if="!selectedGrnId"
                    class="confirmationButton GlobalCreateButton btn btn-sm btn-primary"
                    @click="saveAsGrnDraft"
                    :disabled="loading"
                  >
                    <span class="confirmationButton__text">Save</span>
                  </button>
                  <button
                    v-else
                    class="confirmationButton GlobalCreateButton btn btn-sm btn-primary"
                    @click="updateGrnDraft"
                    :disabled="loading"
                  >
                    <span class="confirmationButton__text">Update</span>
                  </button>
                  <button
                    class="save-button btn btn-sm btn-secondary"
                    @click="opengrnList(null)"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div v-if="activeTab === 'remarks'">
              <div>
                <div class="sectionComments">
                  <section
                    class="d-flex flex-column mt-4"
                    style="width: 90%; margin: 0 5%"
                  >
                    <textarea
                      name="leadComments"
                      id="leadComments"
                      class="form-control mb-2"
                      cols="20"
                      rows="5"
                      placeholder="Add Text....."
                      v-model="this.remark"
                    ></textarea>
                    <button class="browse align-self-end" @click="sendProjectComment()">
                      Done
                    </button>
                  </section>
                </div>
              </div>
              <div class="sectionOther col-md-12" style="width: 90%; margin: 0 2.5%">
                <div class="row">
                  <div
                    class="col-md-10"
                    style="word-break: break-all; white-space: pre-wrap"
                  >
                    <ol>
                      <li
                        v-for="(comment, index) in projectComments"
                        :key="index"
                        class="m-3"
                      >
                        <span style="font-size: 16px" v-if="comment.remark">
                          <span class="comment-marker"> </span>
                          {{ comment.remark }}</span
                        >
                        <pre
                          v-if="comment.remark"
                          style="font-size: 12px; letter-spacing: 2px"
                          class="text-muted d-flex justify-content-between col-md-6"
                        >
                          <span>{{ comment.created_at }}</span>
                        </pre>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CreatePurchaseInvoice
      :SelectedPIId="SelectedPIId"
      :projectId="this.mainProjectId"
      @fetchpurchaseInvoice="fetchpurchaseInvoice()"
    />
    <UpdatePIModal
      :SelectedPIId="SelectedPIId"
      @fetchpurchaseInvoice="fetchpurchaseInvoice()"
    />
  </div>
  <OnSiteStockConsumptionModal
    :CompanyprojectId="this.mainProjectId"
    :StockNumber="stockNumber"
    @fetchStockConsumptionEntries="fetchStockConsumptionEntries()"
  />
  <AddDesignActivityModal
    :projectId="Id"
    :onSitetimelineId="currentTimelineId"
    @fetchTimeLineData="fetchQuotationByStatus()"
  />
  <AddQuotationModal
    :projectId="Id"
    :remarksId="ContractorId"
    :quotationId="QuotationId"
    @fetchQuotations="fetchQuotationByStatus(this.currentTable)"
  />
  <ShareWithprojectModal :ProcurementProjectId="Id" />
  <AddContractorModal :projectId="Id" @featchData="fetchdisplayedData()" />
  <OnsiteContractorDetailsModal :remarksId="ContractorId" />
  <PRDetailsModal :PurchaseRequestId="selectedPRId" ref="PRDetailsModal" />
  <PODetailsModal :PurchaseOrderId="selectedPoId" ref="PODetailsModal" />
  <OnSiteGrnDetailsModal :GRNId="selectedGrnId" ref="OnSiteGrnDetailsModal" />
  <InwardEntriesDetailsModal
    :InwardEntryId="InwardEntryModalId"
    ref="InwardEntriesDetailsModal"
  />
  <StockConsumptionDetailsModal
    :StockNumber="stockNumber"
    ref="StockConsumptionDetailsModal"
  />
  <OutwardEntriesDetailsModal
    :StockNumber="OutwardEntryId"
    ref="OutwardEntriesDetailsModal"
  />

  <OnsiteEntriesModal :FromStore="StoreId" @fetchOutwardEntries="fetchOutwardEntries()" />
  <OnSiteOutwordEntriesModal
    :InwardEntryId="InwardEntryModalId"
    :FromStore="StoreId"
    @fetchInwardEntries="fetchInwardEntries()"
  />
  <ProjectActivityModal
    :projectId="DesignProjectId"
    :team="TeamName"
    @setProjectId="setProjectId"
  />
</template>

<script>
import ProjectActivityModal from "../Modal/ProjectActivityModal.vue";
import OutwardEntriesDetailsModal from "../Modal/OutwardEntriesDetailsModal.vue";
import StockConsumptionDetailsModal from "../Modal/StockConsumptionDetailsModal.vue";
import InwardEntriesDetailsModal from "../Modal/InwardEntriesDetailsModal.vue";
import OnSiteGrnDetailsModal from "../Modal/OnSiteGrnDetailsModal.vue";
import OnSiteStockConsumptionModal from "../Modal/OnSiteStockConsumptionModal.vue";
import OnSiteOutwordEntriesModal from "../Modal/OnSiteOutwordEntriesModal.vue";
import OnsiteEntriesModal from "../Modal/OnsiteEntriesModal.vue";
import CreatePurchaseInvoice from "../Modal/CreatePurchaseInvoice.vue";
import UpdatePIModal from "../Modal/UpdatePIModal.vue";
import PRDetailsModal from "../Modal/PRDetailsModal.vue";
import PODetailsModal from "../Modal/PODetailsModal.vue";
import AddQuotationModal from "../Modal/AddQuotationModal";
import OnsiteContractorDetailsModal from "../Modal/OnsiteContractorDetailsModal";
import ShareWithprojectModal from "../Modal/ShareWithProjectModal.vue";
import AddDesignActivityModal from "../Modal/AddDesignActivityModal.vue";
import AddContractorModal from "../Modal/AddContractorModal.vue";
import axios from "axios";
import Header from "../Header.vue";
import Nav from "../Nav.vue";

export default {
  name: "ProcurementProjectProfile",
  components: {
    Header,
    Nav,
    AddDesignActivityModal,
    AddContractorModal,
    ShareWithprojectModal,
    PRDetailsModal,
    AddQuotationModal,
    OnsiteContractorDetailsModal,
    CreatePurchaseInvoice,
    OnsiteEntriesModal,
    OnSiteOutwordEntriesModal,
    OnSiteStockConsumptionModal,
    PODetailsModal,
    OnSiteGrnDetailsModal,
    InwardEntriesDetailsModal,
    StockConsumptionDetailsModal,
    OutwardEntriesDetailsModal,
    UpdatePIModal,
    ProjectActivityModal,
  },
  data() {
    return {
      PurchaseAmount: 0,
      PurchaseInvoiceAmount: 0,
      TeamName: "Procurement",
      DesignProjectId: this.$route.params.Id,
      SelectedPIId: null,
      selectedGrnId: null,
      grn: {
        poId: null,
        awsFileId: null,
        documentType: "Tax Invoice",
        documentNo: "",
        receivedDate: null,
        receiverName: "",
        receiverContactNo: "",
        comments: "",
        fileName: "",
        piId: null,
      },
      estimates: [],
      lineItems: [],
      userRoles: this.$LocalUser.employeeRoles,
      showExecative: false,
      Employees: [],
      mainProjectId: null,
      selectedPoId: null,
      selectedPRId: null,
      showProjectNameDropDown: false,
      ApprovedPurchaseOrders: [],
      PurchaseOrders: [],
      PurchaseRequests: [],
      PurchaseInvoice: [],
      displayedData: [],
      Id: this.$route.params.Id,
      CustomerId: "",
      ProjectName: "",
      ProjectStatus: "",
      activeTab: "customerDetails",
      CustomerDetails: {},
      collaborators: [],
      remark: null,
      projectComments: [],
      grnList: [],
      grnListview: true,
      billingAddressData: {
        id: null,
        name: "", // Your initial companyName value
        email: "",
        address: "",
        city: "",
        state: "",
        country: "India",
        pincode: "",
        contactNo: "",
        addressType: "Billing", // Or use a variable if it varies
        entityId: this.CustomerId,
        entityName: "Customer",
      },
      addressData: {
        id: null,
        name: "", // Your initial companyName value
        email: "",
        address: "",
        city: "",
        state: "",
        country: "India",
        pincode: "",
        contactNo: "",
        addressType: "Shipping",
        entityId: this.CustomerId,
        entityName: "Customer",
      },
      PropertyData: {
        id: null,
        address: "",
        city: "",
        state: "",
        addressType: "Property",
        entityId: this.CustomerId,
        entityName: "Customer",
      },
    };
  },
  mounted() {
    this.Id = this.$route.params.Id;
    if (this.$route.params.Id) {
      this.featchProjectProfileData();
    }
    this.showExecative = this.userRoles.some(
      (role) => role.team === "Onsite" && role.role === "Manager"
    );
    if (this.$route.params.activeTab) {
      this.setActiveTab(this.$route.params.activeTab);
    } else {
      this.setActiveTab("customerDetails");
    }
  },

  methods: {
    OpenProjectActivityModal() {
      this.DesignProjectId = this.Id;
    },
    setProjectId() {
      this.DesignProjectId = null;
      console.log(this.DesignProjectId);
    },
    async updateOrCreateAddress(addressType, addressData) {
      try {
        await axios.put(
          `${this.$apiEndPoint}/address/${this.CustomerId}/Customer/${addressType}`,
          addressData
        );
      } catch (error) {
        this.$handleError(error);
      }
    },

    statusClass(status) {
      switch (status) {
        case "Approved":
          return "approvedText";
        case "Semi Ordered":
          return "semi-deliveredText";
        case "Ordered":
          return "orderedText";
        case "Delivered":
          return "deliveredText";
        case "Rejected":
          return "rejectedText";
        case "Pending":
          return "pendingText";
        case "Payment Pending":
          return "pendingText";
        case "Payment Done":
          return "approvedText";
        default:
          return "";
      }
    },
    optionStyle(status) {
      switch (status) {
        case "Payment Pending":
          return "color: #ffd159;"; // Yellow color
        case "Payment Done":
          return "color: #38b525;"; // Green color
        case "Delivered":
          return "color: #32587a;"; // Dark blue color
        case "Approved":
          return "color: #38b525;"; // Green color
        case "Semi Ordered":
          return "color: #6d756b;"; // Gray color
        case "Ordered":
          return "color: #2381f3;"; // Blue color
        case "Semi Delivered":
          return "color: #6d756b;"; // Gray color

        default:
          return "";
      }
    },
    openPIModal(PIId) {
      this.SelectedPIId = PIId;
    },
    async DeleteGrn(timeLineid, index) {
      const result = await this.$showAlert(
        "Are you sure?",
        "Are you sure you want to delete?",
        "warning",
        "Yes, delete it",
        "Cancel"
      );

      // If the user clicked Cancel, don't proceed
      if (!result.isConfirmed) return;
      this.grnList.splice(index, 1);
      try {
        await axios.delete(`${this.$apiEndPoint}/OnSiteGrn/${timeLineid}`);
        this.fetchgrn();
      } catch (error) {
        this.$handleError(error);
      }
    },

    async handleStatusChangeOfPI(id, newStatus) {
      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/purchaseInvoice/invoiceStatus/${id}`,
          {
            invoiceStatus: newStatus,
          }
        );

        if (response.status === 200) {
          this.$toast(`Status updated to ${newStatus}`, "success");
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    async handleStatusChangeOfPo(id, newStatus) {
      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/purchaseOrder/PoStatus/${id}`,
          {
            poStatus: newStatus,
          }
        );

        if (response.status === 200) {
          this.$toast(`Status updated to ${newStatus}`, "success");
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    async handleStatusChange(id, newStatus) {
      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/purchaseRequest/PrStatus/${id}`,
          {
            prStatus: newStatus,
          }
        );

        if (response.status === 200) {
          this.$toast(`Status updated to ${newStatus}`, "success");
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    handleDownloadGRN(id) {
      this.selectedGrnId = id;
      // Ensure the modal is fully loaded before triggering download
      setTimeout(() => {
        this.$refs.OnSiteGrnDetailsModal.downloadPDF();
      }, 1000); // Adjust the timeout as needed
    },

    openGRNModal(Id) {
      this.selectedGrnId = Id;
    },

    async handleFileUploadGrnFile() {
      this.$globalState.isLoading = true;
      const file = document.getElementById("onSiteGrn").files[0]; // Get the selected file
      if (file) {
        this.grn.awsFileId = null;
        let fileCategory;
        // Ensure this.grn.documentType is properly defined and compared
        if (this.grn.documentType === "Tax Invoice") {
          fileCategory = "purchaseInvoice";
        } else {
          fileCategory = "deliveryChallen";
        }

        try {
          // Make API call to get signed URL for file upload
          const response = await axios.post(
            `${this.$apiEndPoint}/company/putFileSignedURL`,
            {
              fileName: file.name,
              fileSize: file.size,
              contentType: file.type,
              fileCategory: fileCategory,
              moduleName: "procurement",
            }
          );

          const signedUrl = response.data.s3URL;
          this.grn.awsFileId = response.data.id;
          this.grn.fileName = response.data.name;

          // Use XMLHttpRequest to upload the file to the signed URL
          const xhr = new XMLHttpRequest();
          xhr.open("PUT", signedUrl, true);
          xhr.setRequestHeader("Content-Type", file.type);

          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                this.$toast("File uploaded successfully", "success");
                this.$globalState.isLoading = false;

                // Handle successful upload
              } else {
                this.$handleError(xhr.responseText);
                this.$globalState.isLoading = false;
                // Handle upload error
              }
            }
          };

          // Send the file as binary data
          xhr.send(file);
        } catch (error) {
          this.$handleError(error);
        }
      }
    },

    updateGrnDraft() {
      const btn = document.querySelector(".confirmationButton");
      btn.classList.add("confirmationButton--loading");
      // Construct the data object to send
      let dataToSend = {
        onSiteProjectId: this.Id,
        poId: this.grn.poId,
        projectId: this.mainProjectId || " ",
        awsFileId: this.grn.awsFileId,
        documentType: this.grn.documentType,
        documentNo: this.grn.documentNo,
        receivedDate: this.grn.receivedDate,
        receiverName: this.grn.receiverName,
        receiverContactNo: this.grn.receiverContactNo,
        comments: this.grn.comments,
        amount: this.grn.amount,
        date: this.grn.date,
        piId: this.grn.piId,
        lineItems: this.lineItems.map((item) => ({
          id: item.id,
          productId: item.productId,
          orderQuantity: item.quantity,
          receivedQuantity: item.receivedQty,
          damagedQuantity: item.damagedQty,
          missingQuantity: item.missingQty,
          remark: item.remark,
        })),
      };

      // Log the data to verify

      // Replace with actual API call to save the data
      // Example:
      axios
        .put(`${this.$apiEndPoint}/OnSiteGrn/${this.selectedGrnId}`, dataToSend)
        .then(() => {
          btn.classList.remove("confirmationButton--loading");
          this.$toast(` GRN Update successfully.`, "success");
          this.opengrnList(null);
          this.grn = {};
          this.lineItems = [];

          // Handle success
        })
        .catch((error) => {
          btn.classList.remove("confirmationButton--loading");
          this.$handleError(error);
        });
    },

    saveAsGrnDraft() {
      const btn = document.querySelector(".confirmationButton");
      btn.classList.add("confirmationButton--loading");
      // Construct the data object to send
      let dataToSend = {
        awsFileId: this.grn.awsFileId || null,
        projectId: this.mainProjectId || " ",
        poId: this.grn.poId || null,
        documentType: this.grn.documentType,
        documentNo: this.grn.documentNo || " ",
        receivedDate: this.grn.receivedDate || null,
        receiverName: this.grn.receiverName || " ",
        receiverContactNo: this.grn.receiverContactNo || " ",
        comments: this.grn.comments || "",
        amount: this.grn.amount,
        date: this.grn.date,
        piId: this.grn.piId,
        lineItems: this.lineItems.map((item) => ({
          productId: item.productId,
          orderQuantity: item.quantity || 0,
          receivedQuantity: item.receivedQty || 0,
          damagedQuantity: item.damagedQty || 0,
          missingQuantity: item.missingQty || 0,
          remark: item.remark || "",
        })),
      };
      console.log(dataToSend);
      axios
        .post(`${this.$apiEndPoint}/OnSiteGrn`, dataToSend)
        .then(() => {
          btn.classList.remove("confirmationButton--loading");
          this.$toast(`GRN created successfully.`, "success");
          this.opengrnList(null);
          this.grn = {};
          this.lineItems = [];
        })
        .catch((error) => {
          btn.classList.remove("confirmationButton--loading");
          this.$handleError(error);
        });
    },
    updateQuantities(index) {
      const item = this.lineItems[index];
      if (item.checked) {
        item.receivedQty = item.quantity;
        item.damagedQty = 0;
        item.missingQty = 0;
        item.remark = "";
      } else {
        // Ensure receivedQty doesn't exceed order quantity
        if (item.receivedQty > item.quantity) {
          item.receivedQty = item.quantity;
        }

        // Ensure sum of quantities matches order quantity
        let totalQty = item.quantity;
        let sumQty = item.receivedQty + item.damagedQty + item.missingQty;

        if (sumQty > totalQty) {
          item.damagedQty = 0;
          item.missingQty = 0;
          sumQty = item.receivedQty;

          if (sumQty > totalQty) {
            item.receivedQty = totalQty;
          }
        }

        if (sumQty < totalQty) {
          item.receivedQty = totalQty - item.damagedQty - item.missingQty;
        }
      }
    },
    validateInput(index, field) {
      let item = this.lineItems[index];
      let totalQty = item.quantity;
      let receivedQty = item.receivedQty;
      let damagedQty = item.damagedQty;
      let missingQty = item.missingQty;

      // Ensure no negative values
      if (receivedQty < 0) item.receivedQty = 0;
      if (damagedQty < 0) item.damagedQty = 0;
      if (missingQty < 0) item.missingQty = 0;

      // Reassign values after potential negative corrections
      receivedQty = item.receivedQty;
      damagedQty = item.damagedQty;
      missingQty = item.missingQty;

      // Ensure receivedQty does not exceed total quantity
      if (receivedQty > totalQty) {
        item.receivedQty = totalQty;
        receivedQty = totalQty;
      }

      // Adjust remainingQty based on receivedQty
      let remainingQty = totalQty - receivedQty;

      // Ensure damagedQty + missingQty does not exceed remaining quantity
      if (field === "damagedQty" && damagedQty > remainingQty) {
        item.damagedQty = remainingQty; // Adjust damagedQty if it exceeds remainingQty
        damagedQty = remainingQty; // Update the damagedQty value
      }

      if (field === "missingQty" && missingQty > remainingQty) {
        item.missingQty = remainingQty; // Adjust missingQty if it exceeds remainingQty
        missingQty = remainingQty; // Update the missingQty value
      }

      // Ensure the sum of received, damaged, and missing does not exceed total quantity
      let sumQty = receivedQty + damagedQty + missingQty;
      if (sumQty > totalQty) {
        let diff = sumQty - totalQty;

        // Adjust the field that triggered the change
        if (field === "receivedQty") {
          item.receivedQty -= diff;
        } else if (field === "damagedQty") {
          item.damagedQty -= diff;
        } else if (field === "missingQty") {
          item.missingQty -= diff;
        }
      }

      // Update remainingQty after all adjustments
      remainingQty = totalQty - (damagedQty + missingQty);

      // Ensure that receivedQty does not exceed the new remainingQty after changes to damaged or missing quantities
      if (field !== "receivedQty" && receivedQty > remainingQty) {
        item.receivedQty = remainingQty;
      }

      // Prevent negative results across any field
      if (item.receivedQty < 0) item.receivedQty = 0;
      if (item.damagedQty < 0) item.damagedQty = 0;
      if (item.missingQty < 0) item.missingQty = 0;

      // Re-assign updated values
      this.lineItems[index] = item;
    },

    ShowReferenceDetails() {
      const PoId = this.ApprovedPurchaseOrders.find(
        (purchaseOrder) => purchaseOrder.id === this.grn.poId
      );
      this.featchPurchaseOrderById(PoId.id);
      if (PoId) {
        this.grn.POdate = PoId.date;
        this.grn.vendorName = PoId["vendors.companyName"];
        this.grn.vendorContactNo = PoId["vendors.primaryContactNo"];
      } else {
        this.grn.POdate = PoId.statusDate;
        this.grn.vendorName = PoId.vendorId;
        this.grn.vendorContactNo = PoId.vendorId;
      }
    },
    async featchPurchaseOrderById(PoId) {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/purchaseOrder/${PoId}`);
        this.lineItems = response.data.Lineitems;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async opengrnList(Id) {
      this.selectedGrnId = Id;
      this.fetchpurchaseOrder();
      this.grn = {};
      this.lineItems = [];

      if (this.selectedGrnId && this.selectedGrnId !== null) {
        // If Id is present and not "null", fetch GRN details by ID
        await this.featchGrnById(Id);
      }

      // Toggle the GRN list view
      this.grnListview = !this.grnListview;
      this.fetchgrn(); // You can still fetch the general GRN list, if required
    },
    async featchGrnById(Id) {
      if (Id != null) {
        try {
          const response = await axios.get(
            `${this.$apiEndPoint}/OnSiteGrn/getById/${Id}`
          );
          this.grn = response.data;

          this.grn.fileName = response.data["awsFiles.fileName"];
          this.grn.POdate = response.data["purchaseOrders.date"];
          this.grn.vendorName = response.data["purchaseOrders.vendors.companyName"];
          this.grn.vendorContactNo =
            response.data["purchaseOrders.vendors.primaryContactNo"];
          // Adding virtual properties to each line item
          this.lineItems = response.data.lineItems.map((item) => ({
            ...item,
            quantity: item.orderQuantity, // Virtual property for order quantity
            receivedQty: item.receivedQuantity, // Virtual property for received quantity
            damagedQty: item.damagedQuantity, // Virtual property for damaged quantity
            missingQty: item.missingQuantity, // Virtual property for missing quantity
            remark: item.remark, // Virtual property for remark
          }));

          if (response.data.documentType === "Tax Invoice") {
            this.grn.date = response.data["purchaseInvoices.date"];
            this.grn.amount = response.data["purchaseInvoices.amount"];
            this.grn.piId = response.data.piId;
          }
        } catch (error) {
          this.$handleError(error);
        }
      }
    },
    async fetchgrn() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/onSiteGrn/getGrnByProjectId/${this.mainProjectId}`
        );
        this.grnList = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async sendProjectComment() {
      try {
        const payload = {
          entityId: this.Id,
          entityName: "Project",
          remark: this.remark,
        };
        this.projectComments.push(payload);

        await axios.post(`${this.$apiEndPoint}/procurementRemark`, payload);
        this.remark = "";

        this.fetchProjectComments();
      } catch (error) {
        this.$handleError(error);
      }
    },
    fetchProjectComments() {
      axios
        .get(`${this.$apiEndPoint}/procurementRemark/${this.Id}/Project/`)
        .then((response) => {
          this.projectComments = response.data; // Assuming the API returns the comments as an array under the key 'comments'
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    generatePDF(EstimateId) {
      this.$router.push({
        name: "PrintEstimate",
        params: { estimateIndex: EstimateId },
      });
    },
    async fetchEstimates(CustomerId) {
      try {
        // Make HTTP GET request to fetch data from the API
        const response = await axios.get(
          `${this.$apiEndPoint}/estimates/byCustomer/${CustomerId}`
        );

        this.estimates = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async featchProjectProfileData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/procurementProject/getById/${this.Id}`
        );
        this.CustomerId = response.data.customerId;
        this.ProjectName = response.data["projects.projectName"];
        this.ProjectStatus = response.data.status;
        this.collaborators = response.data.Collaborators;
        this.mainProjectId = response.data["projects.id"];
        this.fetchcustomerDetails();
        this.fetchLeadAddresses(this.CustomerId);
        this.fetchpurchaseRequest();
        this.fetchpurchaseOrder();
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchcustomerDetails() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/customer/getCustomer/${this.CustomerId}`
        );
        this.CustomerDetails = response.data;
        this.fetchLeadAddresses(this.CustomerId);
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchLeadAddresses(leadId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/address/Customer/${leadId}`
        );
        const addresses = response.data;
        const addressTypes = {
          Billing: "billingAddressData",
          Shipping: "addressData",

          Property: "PropertyData",
        };

        addresses.forEach((addr) => {
          const addressKey = addressTypes[addr.addressType];
          if (addressKey) {
            this[addressKey] = {
              ...this[addressKey],
              id: addr.id,
              name: addr.name || "",
              address: addr.address,
              city: addr.city,
              state: addr.state,
              country: addr.country || "",
              pincode: addr.pincode || "",
              contactNo: addr.contactNo || "",
              email: addr.email || "",
              entityId: addr.entityId,
            };
          }
        });
      } catch (error) {
        this.$handleError(error);
      }
    },
    async ChangeCustomerData() {
      try {
        await axios.put(
          `${this.$apiEndPoint}/customer/${this.CustomerId}`,
          this.CustomerDetails
        );
        this.fetchcustomerDetails();

        // Continue with other logic if needed
      } catch (error) {
        this.$handleError(error);
        this.$emit("fetchData");
      }
    },

    goToPurchaseCreate(moduleName) {
      this.$router.push({
        name: "PurchaseRequestDetails",
        params: {
          moduleName: moduleName,
          ProjectId: this.mainProjectId,
          Id: this.Id,
          Projectname: this.ProjectName,
        },
      });
    },

    getEmployeeName(employeeId) {
      const employee = this.Employees.find((emp) => emp.employeeId === employeeId);
      return employee ? employee["employees.signedUrl"] : "";
    },

    openPOModal(Id) {
      this.selectedPoId = Id;
    },

    handleDownloadPO(id) {
      this.selectedPoId = id;
      // Ensure the modal is fully loaded before triggering download
      setTimeout(() => {
        this.$refs.PODetailsModal.downloadPDF();
      }, 1000); // Adjust the timeout as needed
    },

    handleDownload(id) {
      this.selectedPRId = id;
      // Ensure the modal is fully loaded before triggering download
      setTimeout(() => {
        this.$refs.PRDetailsModal.downloadPDF();
      }, 1000); // Adjust the timeout as needed
    },
    openModal(leadId) {
      this.selectedPRId = leadId;
    },
    async ChangeProjectStatus(Status) {
      const payload = {
        status: Status,
      };
      try {
        await axios.put(
          `${this.$apiEndPoint}/procurementProject/updateProject/${this.Id}`,
          payload
        );
        this.featchProjectProfileData();
      } catch (error) {
        this.$handleError(error);
      }
    },
    toggleProjectNameDropDown() {
      this.showProjectNameDropDown = !this.showProjectNameDropDown;
    },

    async fetchEmployees() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/employee/myTeam/Onsite`);
        this.Employees = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      if (this.activeTab === "customerDetails") {
        this.fetchcustomerDetails();
      } else if (this.activeTab === "estimates") {
        this.fetchEstimates(this.CustomerId);
      } else if (this.activeTab === "purchaseRequest") {
        this.fetchpurchaseRequest();
      } else if (this.activeTab === "purchaseOrders") {
        this.fetchpurchaseOrder();
      } else if (this.activeTab === "purchaseInvoice") {
        this.fetchpurchaseInvoice();
        this.fetchpurchaseOrder();
      } else if (this.activeTab === "grn") {
        this.fetchgrn();
        this.fetchpurchaseOrder();
      } else if (this.activeTab === "remarks") {
        this.fetchProjectComments();
      }
    },
    formatDate(dateStr) {
      if (!dateStr) return "";
      const date = new Date(dateStr);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },
    async fetchpurchaseRequest() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/purchaseRequest/prByProjectId/unarchive/All/${this.mainProjectId}`
        );
        this.PurchaseRequests = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchpurchaseOrder() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/purchaseOrder/getByProjectId/${this.mainProjectId}`
        );
        this.PurchaseOrders = response.data.purchaseOrder;
        this.PurchaseAmount = response.data.totalAmount;
        this.fetchApprovedPurchaseOrder();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchApprovedPurchaseOrder() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/purchaseOrder/getPoNumbers/${this.mainProjectId}/createGrn`
        );
        this.ApprovedPurchaseOrders = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    goToPurchaseOrderCreate() {
      this.$router.push({
        name: "CreatePurchaseOrder",
        params: {
          ProjectId: this.mainProjectId,
          Id: this.Id,
          Projectname: this.ProjectName,
        },
      });
    },
    async fetchpurchaseInvoice() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/purchaseInvoice/piByProjectId/${this.mainProjectId}`
        );
        this.PurchaseInvoice = response.data.invoices;
        this.PurchaseInvoiceAmount = response.data.totalAmount;
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style scoped>
.OnSiteProjectHeader {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.OnSiteProjectComponentHeader {
  position: sticky;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  z-index: 99999;
  top: 0;
  width: calc(100%);

  background-color: #ffffff;
}

.OnSiteProjectHeaderRow1 {
  display: flex;
  align-items: center;
}

.mainHeading {
  flex: content;
}

.active-SubHeading {
  font-size: 14px;
  text-decoration: underline;
  color: #000000;
  font-weight: 500;
}

#OnSiteProjectHeaderImage {
  width: 45px;
  height: 45px;
}

.OnsiteProjectHeaderRow2 {
  display: flex !important;
  justify-content: space-between;
}

.DesignProjectComponentHeader {
  display: flex;
  align-items: center;
  padding: 0.5% 0;
  position: sticky;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  z-index: 99999;
  top: 0;
  background-color: #ffffff;
}

.DesignProjectComponent .DesignProjectComponentHeader::-webkit-scrollbar {
  display: none;
}

#DesignProjectHeaderImage {
  width: 50px;
  height: 50px;
  margin-left: 0.5%;
  display: flex;
}

.DesignProjectHeader {
  display: flex;
  flex-direction: column;
  position: relative;
}

.DesignProjectHeader .DesignProjectHeaderRow1 {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 3%;
  position: relative;
  width: 50%;
  margin-bottom: 10px;
}

.DesignProjectHeader .DesignProjectHeaderRow1 input {
  display: inline-block;
  max-width: fit-content;
  padding: 1%;
  border: none;
  outline: none;
  color: black;
  font-size: 18px;
  flex-grow: 1;
  /* Allows the input to grow to fit the content */
  margin-right: 1%;
  /* Adds space between the input and the next icon */
}

.DesignProjectHeader .DesignProjectHeaderRow1 input:hover {
  outline: 1px solid #ccc;
  border-radius: 5px;
}

.DesignProjectHeader .DesignProjectHeaderRow1 img {
  display: inline-block;
  margin: 0 2%;
  width: 20px;
  cursor: pointer;
}

.DesignProjectHeader .DesignProjectHeaderRow2 {
  display: flex;
  align-items: center;
  width: 100%;
}

.DesignProjectHeader .DesignProjectHeaderRow2 h6 {
  margin: 0 3%;
  white-space: nowrap;
  cursor: pointer;
  font-size: 14px;
}

.inactive-SubHeading {
  font-size: 14px;

  color: #868686;
  font-weight: 500;
}

.ProjectName:hover {
  outline: 1px solid #ccc;
}

.DesignProjectHeaderUsers {
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 2%;
}

.btn-custom {
  width: fit-content;
  height: 30px;
  font-size: 12px;
  font-weight: bold;
  background-color: inherit;
  color: #0f0e0e;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  border: 1px solid #b1c5df;
  letter-spacing: 1px;
  gap: 8px;
  border-radius: 6px;
}

.btn-custom img {
  width: 18px;
  height: 18px;
}
.btn-custom:hover {
  background-color: purple;
  color: white;
}

#BellImage {
  margin-right: 40%;
}

/* Container to hold all collaborator avatars */
.collaborators-container {
  display: flex;
  align-items: center;
  position: relative;
}
.collaborator-avatar {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: left;
  margin-right: -5px;

  /* Adjust margin to control the overlap */
}

/* Style for initials */
.initials {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #4884f7; /* Blue background color */
  color: #ffffff; /* White text color */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  z-index: 1;
  margin-right: -4px; /* Adjust margin to control the overlap */
  position: relative; /* Ensure z-index works correctly */
  border: 2px solid white;
}
.additional-count {
  width: 15px;
  /* Adjust size as needed */
  height: 15px;
  /* Adjust size as needed */
  border-radius: 50%;
  /* Make the container circular */
  background-color: #b1c5df;
  /* Provide a background color */
  color: #000000;
  /* Set text color */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  /* Adjust font size as needed */
  cursor: pointer;

  z-index: 2;
}

.headerbelowcontent {
  width: 100%;
  height: 80%;
  padding: 0.5% 0.5%;
}

.active-view {
  background-color: #007bff;
  color: white;
}

.stockEntryview button {
  background-color: #f8f8f8;
  font-weight: 500;
}

.ActionImage img {
  padding: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  white-space: nowrap;
}

.ActionImage img:hover {
  background-color: #868686;
  border-radius: 50%;
}

.CreateGrnPage {
  padding: 1% 3%;
  width: 100%;
  height: 70vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.small-wrapper {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  height: 65vh;
  overflow-y: scroll;
}

.small-table {
  width: 100%;
  margin-bottom: 20px;
  table-layout: fixed;
}

.table-header-heading th {
  background-color: #f2f2f2;
  text-align: center;
  position: sticky;
  top: 0;
}

.table-header-heading th:nth-child(1) {
  width: 5%;
}

.table-header-heading th:nth-child(2),
.table-header-heading th:nth-child(4),
.table-header-heading th:nth-child(5),
.table-header-heading th:nth-child(6) {
  width: 15%;
}

.table-header-heading th:nth-child(3) {
  width: 20%;
}

.table-header-heading td:nth-child(1) {
  text-align: center;
}

input {
  border: none;
  padding: 5px;
}

.add-item {
  margin: 10px 0px;
  float: left;
  width: 100px;
}

.footerPuchaseRequest {
  width: calc(100% - 78px);
  border-radius: 0px 0px 15px 15px;
  margin-top: 20px;
  position: fixed;
  bottom: 15px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerPuchaseRequest .btn {
  margin: 5px;
}

.input-bg {
  background-color: #fff;
}

.description {
  width: 20%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.quantity-input {
  width: 100%;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}

.GrnTable {
  margin-top: 20px;
  width: 100%;
  overflow-x: auto;
  background-color: #f8f8ff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.GrnTable table {
  width: 100%;
  border-collapse: collapse;
}

.GrnTable th,
.GrnTable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.GrnTable th {
  background-color: #f2f2f2;
}

.showProjectNameDropDown {
  position: fixed;
  top: 14%;
  border-radius: 5px;
  background-color: #f5f5f5;
  z-index: 999999;
  border: 1px solid #333;
  min-width: 135px;
  max-width: max-content;
  min-height: fit-content;
  max-height: max-content;
}

.showProjectNameDropDown ol {
  list-style-type: none;
  padding: 2% 5%;
}

.showProjectNameDropDown ol li {
  color: #333;
  margin: 5% 2%;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  padding: 1%;
}

.showProjectNameDropDown ol li:hover {
  background-color: #868686;
  color: white;
  border-radius: 5px;
}

.FilesPageHeader section {
  background-color: #f5f5f5;
  border-radius: 5px;
  text-align: center;
  padding: 0 10px;
  font-size: 14px;
  letter-spacing: 1px;
}

.FilesPageHeader section:hover {
  background-color: #f8f8f8;
}

.FilesPageHeader section img {
  margin: 0 5px;
  width: 15px;
  height: 15px;
}

.scope-of-work-container {
  height: 100%;
  overflow: hidden;
}

.content-wrapper {
  height: 100%;
  padding: 1rem;
}

.subHeaderHeadingBgColor {
  background-color: #ccc;
  position: sticky;
  top: 0;
  z-index: 1;
}

.header-input-cell {
  text-align: center;
  width: 100%;
}

.row-content td {
  flex: 1;
  padding: 0.75rem;
  text-align: left;
  align-items: center;
}

.row-content {
  justify-content: center;
  border-bottom: 1px solid #dee2e6;
}

.description-cell {
  flex: 2;
  word-break: break-word;
  width: 200px;
}

.AddChildRowIcon {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-top: 10px;
}

.ChildRowIcon {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-top: 10px;
}

.delete-icon {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.childRow {
  background-color: #f9f9f9;
}

.comment-marker {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: blue;
}
.headerbelowcontent {
  width: 100%;
  height: 80%;
  padding: 0.5% 0.5%;
}

.table-container {
  width: 100%;
  max-height: 76vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 20px;
  padding: 0px 20px;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container thead {
  position: sticky;
  top: 0;
  background-color: #bcbfc6; /* Match the header color */
  z-index: 2; /* Ensure it stays above scrolling content */
}

.table-container th:first-child,
.table-container td:first-child {
  width: 50px;
}
.table-container th,
.table-container td {
  font-size: 14px;
  padding: 5px 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table-container th {
  background-color: #bcbfc6; /* Header background color */
}

.table-container tbody {
  display: block;
  overflow-y: auto;
  max-height: 65vh;
}

.table-container tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-container thead tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-container tbody tr:hover {
  background-color: #f5f5f5;
}

.table-container th:first-child {
  border-radius: 5px 0 0 5px;
}

.table-container th:last-child {
  border-radius: 0px 5px 5px 0px;
}

/* Action buttons hover effect */
.ActionImage img {
  padding: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.ActionImage img:hover {
  background-color: #868686;
  border-radius: 50%;
}

.GlobalCreateButton {
  margin: 10% 30%;
  margin-top: 10%;
  padding: 5px;
  background-color: #4884f7;
  color: #fff;
  font-size: 1rem;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
}

.inputBg {
  background-color: transparent;
  border: 1px solid black;
  box-shadow: none;
}
tfoot {
  background-color: #f9f9f9;
  position: sticky;
  z-index: 1;
  bottom: 0;
}

@media screen and (max-width: 768px) {
  .showProjectNameDropDown {
    position: fixed;
    top: 10%;

    border-radius: 5px;
    background-color: #f5f5f5;
    z-index: 999;
    border: 1px solid #333;
    width: 130px;
    height: 110px;
  }

  .DesignProjectComponentHeader {
    overflow-x: scroll;
  }

  .DesignProjectHeaderUsers button {
    padding: 2px 4px;

    margin: 0 30px;
    margin-bottom: 10px;
    white-space: nowrap;
  }

  .DesignProjectHeaderUsers {
    display: flex;
    flex-direction: column;

    margin-bottom: 0px;
    justify-content: center !important;
  }

  .DesignProjectHeaderUsers .collaborator-avatar {
    margin-right: 0;
  }

  .DesignProjectHeaderUsers .additional-count {
    margin-left: 5px;
  }

  .WEB {
    display: none !important;
  }

  .MOBILE {
    display: block !important;
  }

  .itemBlock {
    display: block;
    width: 100%;
  }

  #itemrowAdding {
    width: 100%;
  }
}
</style>
