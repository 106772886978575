<template>
  <div>
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <div class="DesignProjectComponent">
          <div class="DesignProjectComponentHeader p-2">
            <img
              src="../../assets/icons/black_Designing.png"
              alt=""
              id="DesignProjectHeaderImage"
            />
            <div class="DesignProjectHeader">
              <section class="DesignProjectHeaderRow1">
                <span
                  style="letter-spacing: 1px; white-space: nowrap"
                  class="mainHeading"
                >
                  {{ this.ProjectName }}
                </span>
                <span class="ms-3">
                  <img
                    v-if="$isManager('Design')"
                    src="../../assets/icons/blackDownarrow.png"
                    alt=""
                    @click="toggleProjectNameDropDown"
                    style="position: relative; width: 20px"
                  />

                  <transition name="fade">
                    <div
                      v-if="showProjectNameDropDown"
                      class="modal-overlay"
                      @click="toggleProjectNameDropDown"
                    ></div>
                  </transition>
                  <transition name="slide">
                    <div v-if="showProjectNameDropDown">
                      <!-- Dropdown content -->
                      <div class="showProjectNameDropDown p-1">
                        <ol>
                          <li
                            data-bs-toggle="modal"
                            data-bs-target="#UpdateDesignProjectModal"
                            @click="acceptProject(this.$route.params.Id)"
                          >
                            Edit Project
                          </li>
                          <li data-bs-toggle="modal" data-bs-target="#shareProjectModal">
                            Share with
                          </li>

                          <li
                            data-bs-toggle="modal"
                            data-bs-target="#deleteProjectModal"
                            class="text-danger"
                          >
                            Delete
                          </li>
                        </ol>
                      </div>
                    </div>
                  </transition>
                </span>
                <span
                  class="ms-3"
                  v-if="DesignProjectProfile['designProjectTeam.favourite'] === 1"
                >
                  <img
                    src="../../assets/icons/pinkStarfilled.png"
                    alt="Filled Star"
                    @click="Faviorate"
                    style="width: 20px"
                  />
                </span>
                <span
                  class="ms-3"
                  v-if="DesignProjectProfile['designProjectTeam.favourite'] === 0"
                >
                  <img
                    src="../../assets/icons/pinkStarunfiled.png"
                    alt="Unfilled Star"
                    @click="Faviorate"
                    style="width: 20px"
                  />
                </span>
              </section>
              <section class="DesignProjectHeaderRow2">
                <h6
                  @click="toggleOverviewPage"
                  :class="{
                    'active-SubHeading': showOverview,
                    'inactive-SubHeading': !showOverview,
                  }"
                >
                  Overview
                </h6>
                <h6
                  @click="toggleProjectInfoPage"
                  :class="{
                    'active-SubHeading': showProjectInfo,
                    'inactive-SubHeading': !showProjectInfo,
                  }"
                >
                  Project-InfoBox
                </h6>
                <h6
                  @click="toggleProjecTimeLine"
                  :class="{
                    'active-SubHeading': showProjectTimeLine,
                    'inactive-SubHeading': !showProjectTimeLine,
                  }"
                >
                  Timeline
                </h6>
                <h6
                  @click="toggleProjecEstimate"
                  :class="{
                    'active-SubHeading': showEstimateOfDesignProject,
                    'inactive-SubHeading': !showEstimateOfDesignProject,
                  }"
                >
                  Estimate
                </h6>
                <h6
                  @click="toggleProjectFile"
                  :class="{
                    'active-SubHeading': showFileOfDesignProject,
                    'inactive-SubHeading': !showFileOfDesignProject,
                  }"
                >
                  Files
                </h6>
              </section>
            </div>
            <div class="DesignProjectHeaderUsers">
              <div
                class="col-lg-2 col-md-2 col-sm-2 d-flex justify-content-center align-items-center"
              >
                <div class="collaborators-container">
                  <div
                    v-for="(collaborator, index) in collaborators.slice(0, 3)"
                    :key="index"
                    class="collaborator-avatar"
                    @mouseover="showEmployeeName(collaborator)"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      v-if="collaborator['employees.signedUrl'] !== null"
                      :src="collaborator['employees.signedUrl']"
                      class="initials"
                      :alt="collaborator['employees.initials']"
                    />
                    <span v-else class="initials">{{
                      collaborator["employees.initials"]
                    }}</span>
                  </div>

                  <!-- If more than 3 collaborators, show additional count -->
                  <div v-if="collaborators.length > 3" class="additional-count">
                    + {{ collaborators.length - 3 }}
                  </div>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <div
                      v-for="(employee, employeeIndex) in collaborators"
                      :key="employeeIndex"
                      class="d-flex p-2 text-start"
                    >
                      <span class="d-flex ms-2 text-nowrap text-start">
                        {{ employee["employees.firstName"] }}
                        {{ employee["employees.lastName"] }}
                      </span>
                    </div>
                  </div>
                </div>

                <!-- Dropdown menu for showing all collaborator names -->
              </div>

              <button
                @click="OpenProjectActivityModal()"
                type="button"
                class="btn btn-custom ms-5"
                data-bs-toggle="offcanvas"
                data-bs-target="#ProjectActivityModal"
              >
                <img src="../../assets/icons/activityIcon.png" alt="Activity" />
                Activity
              </button>
            </div>
          </div>

          <div class="headerbelowcontent StandardScroller overflow-scroll p-1">
            <div v-if="showOverview" class="DesignProjectProfileDetails mb-5">
              <div class="DesignProjectProfileDetailsSections">
                <h5 class="subHeadingHeader">Customer Details</h5>
                <div class="DesignProjectProfileThings">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label for="customerName" class="col-sm-4 col-form-label"
                            >Customer Name:</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control col-sm-6"
                              v-model="DesignProjectProfile.customerName"
                              @blur="updateCustomerField(DesignProjectProfile.customerId)"
                            />
                          </div>
                        </div>
                        <div class="form-group row mt-sm-1">
                          <label for="customerId" class="col-sm-4 col-form-label"
                            >Customer Id:</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              v-model="DesignProjectProfile.customerNumber"
                              @blur="
                                updateField(
                                  'customerNumber',
                                  DesignProjectProfile.customerNumber
                                )
                              "
                              readonly
                            />
                          </div>
                        </div>

                        <div class="form-group row mt-sm-1">
                          <label for="familyStatus" class="col-sm-4 col-form-label"
                            >Family Status:</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              v-model="DesignProjectProfile.familyStatus"
                              @blur="
                                updateField(
                                  'familyStatus',
                                  DesignProjectProfile.familyStatus
                                )
                              "
                            />
                          </div>
                        </div>
                        <div class="form-group row mt-sm-1">
                          <label for="designStartDate" class="col-sm-4 col-form-label"
                            >Project Start Date:</label
                          >
                          <div class="col-sm-8">
                            <span class="form-control">
                              {{
                                this.$formatDateDMY(DesignProjectProfile.designStartDate)
                              }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label for="contactNo" class="col-sm-5 col-form-label"
                            >Contact Number:</label
                          >
                          <div class="col-sm-7">
                            <input
                              type="text"
                              class="form-control"
                              v-model="DesignProjectProfile.contactNo"
                              @blur="updateCustomerField(DesignProjectProfile.customerId)"
                            />
                          </div>
                        </div>
                        <div class="form-group row mt-sm-1">
                          <label for="EmailId" class="col-sm-5 col-form-label"
                            >Email Id:</label
                          >
                          <div class="col-sm-7">
                            <input
                              type="text"
                              class="form-control"
                              v-model="DesignProjectProfile.EmailId"
                              @blur="updateCustomerField(DesignProjectProfile.customerId)"
                            />
                          </div>
                        </div>
                        <div class="form-group row mt-sm-1">
                          <label
                            for="buildingConfiguration"
                            class="col-sm-5 col-form-label text-nowrap"
                            >Apartment Configuration:</label
                          >
                          <div class="col-sm-7">
                            <input
                              type="text"
                              class="form-control"
                              v-model="DesignProjectProfile.buildingConfiguration"
                              @blur="
                                updateField(
                                  'buildingConfiguration',
                                  DesignProjectProfile.buildingConfiguration
                                )
                              "
                            />
                          </div>
                        </div>
                        <div class="form-group row mt-sm-1">
                          <label for="propertyAddress" class="col-sm-5 col-form-label"
                            >Property Address:</label
                          >
                          <div class="col-sm-7">
                            <input
                              type="text"
                              class="form-control"
                              v-model="DesignProjectProfile.propertyAddress"
                              @blur="
                                updateField(
                                  'propertyAddress',
                                  DesignProjectProfile.propertyAddress
                                )
                              "
                              readonly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              <div class="DesignProjectProfileDetailsSections">
                <h5 class="subHeadingHeader">Project Design Stages</h5>
                <ul>
                  <li @click="activateStep(1)">
                    <div
                      :class="{ progress: true, active: currentStep >= 1 }"
                      @click="updateProjectStage('Discovery Session')"
                    >
                      <p>1</p>
                      <img
                        class="uil"
                        src="../../assets/icons/whiteRight.png"
                        alt=""
                        style="width: 55%"
                      />
                    </div>
                    <p class="text">Discovery Session</p>
                  </li>

                  <li @click="activateStep(2)">
                    <div
                      :class="{ progress: true, active: currentStep >= 2 }"
                      @click="updateProjectStage('3d Design')"
                    >
                      <p>2</p>
                      <img
                        class="uil"
                        src="../../assets/icons/whiteRight.png"
                        alt=""
                        style="width: 55%"
                      />
                    </div>
                    <p class="text">3D Design Phase</p>
                  </li>

                  <li @click="activateStep(3)">
                    <div
                      :class="{ progress: true, active: currentStep >= 3 }"
                      @click="updateProjectStage('Material Selection')"
                    >
                      <p>3</p>
                      <img
                        class="uil"
                        src="../../assets/icons/whiteRight.png"
                        alt=""
                        style="width: 55%"
                      />
                    </div>
                    <p class="text">Material Selection</p>
                  </li>

                  <li @click="activateStep(4)">
                    <div
                      :class="{ progress: true, active: currentStep >= 4 }"
                      @click="updateProjectStage('2d Drafting')"
                    >
                      <p>4</p>
                      <img
                        class="uil"
                        src="../../assets/icons/whiteRight.png"
                        alt=""
                        style="width: 55%"
                      />
                    </div>
                    <p class="text">2D Drafting Stage</p>
                  </li>

                  <li @click="activateStep(5)">
                    <div
                      :class="{ progress: true, active: currentStep >= 5 }"
                      @click="updateProjectStage('Sign Off Document')"
                    >
                      <p>5</p>
                      <img
                        class="uil"
                        src="../../assets/icons/whiteRight.png"
                        alt=""
                        style="width: 55%"
                      />
                    </div>
                    <p class="text">Sign off Document</p>
                  </li>

                  <li @click="activateStep(6)">
                    <div
                      :class="{ progress: true, active: currentStep >= 6 }"
                      @click="updateProjectStage('Sent For Execution')"
                    >
                      <p>6</p>
                      <img
                        class="uil"
                        src="../../assets/icons/whiteRight.png"
                        alt=""
                        style="width: 55%"
                      />
                    </div>
                    <p class="text">Sent for Execution</p>
                  </li>
                </ul>
              </div>
              <hr />
              <div class="container-fluid row">
                <div class="col-lg-12">
                  <div class="DesignProjectProfileDetailsSections">
                    <h5 class="subHeadingHeader">Project Description</h5>
                    <section class="DesctiptionOfDesignProject">
                      <textarea
                        class="form-control"
                        rows="8"
                        v-model="DesignProjectProfile.projectDescription"
                        @blur="updateProjectDescription"
                        placeholder="Write Description here about this Project......."
                        style="outline: none; border: none"
                      ></textarea>
                    </section>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="showProjectInfo" class="ProjectInfoContent StandardScroller mb-5">
              <div class="d-flex flex-column">
                <div
                  v-for="(section, sectionIndex) in sections"
                  :key="section.id"
                  class="ProjectInfoContentSection"
                >
                  <span style="display: flex; align-items: center; gap: 10px">
                    <img
                      src="../../assets/icons/3blackDots.png"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      style="
                        width: 15px;
                        height: 15px;
                        cursor: pointer;
                        margin-left: 10px;
                      "
                      alt=""
                    />
                    <div class="dropdown-menu p-0" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item" href="#" @click="DeleteSection(section.id)"
                        >Delete</a
                      >
                    </div>
                    <img
                      v-if="section.showInfoBox"
                      @click="toggleInfoBox(sectionIndex)"
                      src="../../assets/icons/blackUparrow-1.png"
                      alt=""
                      style="width: 15px; height: 10px; margin-left: 2px; cursor: pointer"
                    />
                    <img
                      v-else
                      @click="toggleInfoBox(sectionIndex)"
                      src="../../assets/icons/rightArrowbrown.png"
                      alt=""
                      style="width: 15px; height: 15px; margin-left: 2px; cursor: pointer"
                    />
                    <input
                      type="text"
                      :ref="'sectionNameInput' + sectionIndex"
                      v-model="section.sectionName"
                      @blur="CreateSection(sectionIndex)"
                      :placeholder="'Section ' + (sectionIndex + 1) + ' Header'"
                      class="tableHeaderHeading"
                    />
                  </span>
                  <div class="sectionAreaOfProjectInfo1" v-if="section.showInfoBox">
                    <div
                      v-for="(value, index) in section.values"
                      :key="value.id"
                      class="sectionAreaOfProjectInfo"
                    >
                      <div
                        class="d-flex flex-column"
                        style="
                          border: 3px solid #000000;
                          border-radius: 15px;
                          max-height: 350px;
                          max-width: 400px;
                          padding: 5px;
                        "
                      >
                        <section
                          class="d-flex justify-content-between align-items-center"
                          style="width: 100%"
                        >
                          <input
                            v-model="value.title"
                            :placeholder="'Area Title'"
                            type="text"
                            class="text-muted text-decoration-underline"
                            style="width: 50%"
                            :ref="'areaTitleInput' + sectionIndex + index"
                            @blur="
                              handleTextAreaChange(
                                section.id,
                                value.title,
                                value.text,
                                sectionIndex,
                                index
                              )
                            "
                          />

                          <span
                            class="image-icons-wrapper d-flex justify-content-end align-items-center"
                            style="width: 50%"
                          >
                            <!-- <img
                            src="../../assets/icons/downArrowbrown.png"
                            alt="plus-icon"
                            style="height: 13px; width: 13px; transform: rotate(-270deg)"
                          /> -->

                            <span
                              v-if="
                                sectionImages[value.id] &&
                                sectionImages[value.id].length > 0
                              "
                              class="d-flex flex-row"
                            >
                              <span
                                v-for="(image, imageIndex) in sectionImages[
                                  value.id
                                ].slice(0, 4)"
                                :key="image.id"
                                class="d-flex align-items-center"
                                @click="
                                  GoToDesignProjectInformodal(
                                    value.id,
                                    sectionIndex,
                                    index
                                  )
                                "
                              >
                                <img
                                  :src="image.sectionPhotoUrl"
                                  :alt="'Image ' + (imageIndex + 1)"
                                  class="fetched-image"
                                  data-bs-toggle="modal"
                                  data-bs-target="#DesignInfoBoxModal"
                                />
                              </span>
                            </span>

                            <img
                              v-if="value.id"
                              src="../../assets/icons/addImagePib.png"
                              alt="plus-icon"
                              class="fetched-image"
                              data-bs-toggle="modal"
                              data-bs-target="#DesignInfoBoxModal"
                              @click="
                                GoToDesignProjectInformodal(value.id, sectionIndex, index)
                              "
                            />
                            <img
                              v-else
                              src="../../assets/icons/addImagePib.png"
                              alt="plus-icon"
                              class="add-icon-disabled"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="First fill the box title"
                            />
                            <!-- 
                          <img
                            src="../../assets/icons/downArrowbrown.png"
                            alt="plus-icon"
                            style="height: 13px; width: 13px; transform: rotate(-90deg)"
                          /> -->

                            <img
                              src="../../assets/icons/historyBlack.png"
                              alt="plus-icon"
                              class="fetched-image"
                              data-bs-toggle="modal"
                              data-bs-target="#HistoryModal"
                              @click="
                                GoToDesignProjectInformodal(value.id, sectionIndex, index)
                              "
                            />
                            <img
                              src="../../assets/icons/commentBlack.png"
                              alt="plus-icon"
                              class="fetched-image"
                              data-bs-toggle="modal"
                              data-bs-target="#CommentModal"
                              @click="
                                GoToDesignProjectInformodal(value.id, sectionIndex, index)
                              "
                            />
                          </span>
                        </section>

                        <textarea
                          :readonly="value.isReadOnly"
                          v-model="value.text"
                          :placeholder="'Notes'"
                          @blur="
                            handleTextAreaChange(
                              section.id,
                              value.title,
                              value.text,
                              sectionIndex,
                              index
                            )
                          "
                        ></textarea>

                        <section class="d-flex justify-content-end me-1 mb-1">
                          <!-- <button
                    
                    type="button"
                    class="btn btn-sm small-btn d-flex align-items-center"
                    :style="{
                      backgroundColor: '#007bff',
                      color: 'white',
                      border: 'none',
                      padding: '4px 12px',
                      fontSize: '12px',
                      borderRadius: '4px',
                      cursor: 'pointer',
                      marginRight: '5px'
                    }"
                  >
                    T
                  </button> -->

                          <button
                            @click="
                              toggleReadOnly(
                                value,
                                section.id,
                                value.title,
                                value.text,
                                sectionIndex,
                                index
                              )
                            "
                            type="button"
                            class="btn btn-sm small-btn d-flex align-items-center"
                            :style="{
                              backgroundColor: value.isReadOnly ? '#ccc' : '#28a745',
                              color: 'white',
                              border: 'none',
                              padding: '4px 12px',
                              fontSize: '12px',
                              borderRadius: '4px',
                              cursor: 'pointer',
                              transition: 'background-color 0.3s ease',
                            }"
                            @mouseover="hoverEffect(value)"
                            @mouseleave="resetEffect(value)"
                          >
                            <!-- <img
                              v-if="value.isReadOnly"
                              src="../../assets/icons/greyEdit.png"
                              alt="Edit Icon"
                              class="me-2"
                            /> -->
                            {{ value.isReadOnly ? "Edit" : "Save" }}
                          </button>
                        </section>
                      </div>
                    </div>

                    <span id="AddSectionAreaImage">
                      <img
                        src="../../assets/icons/addArea.png"
                        alt="plus-icon"
                        @click="addTextArea(sectionIndex)"
                      />
                    </span>
                  </div>
                </div>
                <div class="row container mt-1">
                  <div class="col-3">
                    <button
                      @click="addSection"
                      class="btn btn-sm btn-outline-primary text-nowrap d-flex justify-content-start"
                    >
                      Add Section
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <img
                  src="../../assets/icons/threeDot.jpg"
                  alt=""
                  id="btnGroupDrop1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style="
                    width: 15px;
                    height: 15px;
                    position: fixed; /* Fixed position */
                    right: 2%; /* Position from right */
                    top: 20%; /* Position from top */
                    cursor: pointer;
                    z-index: 999; /* Ensure it stays above other elements */
                  "
                />

                <div
                  class="dropdown-menu ms-3 text-black p-2"
                  aria-labelledby="btnGroupDrop1"
                >
                  <p data-bs-toggle="modal" data-bs-target="#PibShareModal">Share</p>
                  <p @click="downloadPDF" :disabled="isDownloadable">Download</p>
                  <!-- <p>Delete</p> -->
                </div>
              </div>

              <div v-if="isDownloadable" ref="pdfContent" class="project-info-content">
                <div class="text-center p-2" style="font-weight: 700">
                  Project Info Box
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    padding: 10px 5px;
                  "
                >
                  <!-- Company Details -->
                  <div style="width: 60%; text-align: left">
                    <div v-if="companyDetails" style="font-size: 13px">
                      <div style="font-weight: 600">
                        {{ companyDetails.companyName }}
                      </div>
                      <div style="font-size: 14px">
                        {{ companyDetails.address }} {{ companyDetails.city }} <br />
                        {{ companyDetails.state }}
                      </div>
                      <div style="font-size: 14px">
                        {{ companyDetails.contactNumber }}
                      </div>
                      <div style="font-size: 14px">
                        {{ companyDetails.companyEmail }}
                      </div>
                    </div>
                  </div>
                  <!-- Company Logo -->
                  <div style="text-align: end">
                    <img
                      v-if="$CompanyLogoUrl"
                      :src="$CompanyLogoUrl"
                      alt="Company Logo"
                      id="companylogoInput"
                    />
                  </div>
                </div>
                <div style="border: 3px solid #000"></div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    line-height: 1.8;
                    padding: 10px 5px;
                    font-size: 14px;
                  "
                >
                  <!-- Designer Details -->
                  <div style="width: 48%; text-align: left">
                    <div style="display: flex">
                      <span>Designer Name:</span>
                      <span>{{ this.DesignProjectProfile["employees.firstName"] }}</span>
                    </div>
                    <div style="display: flex">
                      <span>Contact No.:</span>
                      <span>{{
                        this.DesignProjectProfile["employees.mobileNumber"]
                      }}</span>
                    </div>
                    <div style="display: flex">
                      <span>Email ID:</span>
                      <span>{{
                        this.DesignProjectProfile["employees.employeeEmailId"]
                      }}</span>
                    </div>
                  </div>

                  <!-- Customer Details -->
                  <div style="width: 48%; text-align: left">
                    <div style="display: flex">
                      <span>Customer Name:</span>
                      <span>{{ this.DesignProjectProfile["customers.name"] }}</span>
                    </div>
                    <div style="display: flex">
                      <span>Contact No.:</span>
                      <span>{{ this.DesignProjectProfile["customers.contactNo"] }}</span>
                    </div>
                    <div style="display: flex">
                      <span>Project Name:</span>
                      <span>{{ this.DesignProjectProfile.projectName }}</span>
                    </div>
                  </div>
                </div>

                <!-- Sections -->
                <div v-for="(section, index) in sections" :key="section.id" class="">
                  <!-- <h2 class="section-title">{{ section.sectionName }}</h2> -->
                  <div class="blackBgColor">
                    <p style="font-weight: 600" class="HeaderOfBillItem">
                      {{ index + 1 }}. {{ section.sectionName }}
                    </p>
                  </div>
                  <div
                    class="section-content"
                    v-for="value in section.values"
                    :key="value.id"
                  >
                    <p class="section-title">{{ value.title }}</p>
                    <pre class="section-pre">{{ value.text }}</pre>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="showProjectTimeLine">
              <div class="DesignProjectTimeLineWrapper">
                <div class="d-flex justify-content-end">
                  <button
                    class="btn btn-sm"
                    :class="{ 'active-view': showTabularView }"
                    @click="switchToTabularView"
                  >
                    Tabular view
                  </button>
                  <button
                    class="btn btn-sm"
                    :class="{ 'active-view': showGraphicalView }"
                    @click="switchToGraphicalView"
                  >
                    Graphical view
                  </button>

                  <button
                    @click="openAddActivityModal(null)"
                    class="btn btn-sm btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#AddDesignActivity"
                  >
                    + Add Activity
                  </button>
                </div>

                <div class="DesignProjectLinePage">
                  <section v-if="showTabularView">
                    <table class="DesignProjectTimeLine files-table">
                      <caption class="d-none">
                        Timeline Table For Design Team
                      </caption>
                      <thead>
                        <tr>
                          <th>S/N</th>

                          <th>Activity Name</th>
                          <th>Assignee</th>
                          <th>Planned Start date</th>
                          <th>Planned End Date</th>
                          <th>Actual Start Date</th>
                          <th>Actual End Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in timelineRows" :key="index">
                          <td>{{ index + 1 }}</td>
                          <td class="text-nowrap text-left" :title="row.activity">
                            <span>
                              <img
                                src="../../assets/icons/taskPending.png"
                                alt="task pending"
                                v-if="!row.actualEndDate"
                                style="width: 17px; height: 17px"
                              />
                              <img
                                v-else
                                src="../../assets/icons/taskCompleted.png"
                                alt="task completed"
                                style="width: 17px; height: 17px"
                              />
                            </span>
                            <span
                              class="ms-1"
                              @click="openAddActivityModal(row.id)"
                              data-bs-toggle="modal"
                              data-bs-target="#AddDesignActivity"
                              data-bs-whatever="@mdo"
                            >
                              {{ row.activity }}
                            </span>
                          </td>
                          <td>
                            {{ row["employees.firstName"] }}
                            {{ row["employees.lastName"] }}
                          </td>
                          <td>
                            {{ formatDate(row.plannedStartDate) }}
                          </td>
                          <td>
                            {{ formatDate(row.plannedEndDate) }}
                          </td>

                          <td>
                            <input
                              class="w-75"
                              type="date"
                              v-model="row.actualStartDate"
                              @blur="handleRowUpdate(row)"
                            />
                          </td>
                          <td>
                            <input
                              class="w-75"
                              type="date"
                              v-model="row.actualEndDate"
                              @blur="handleRowUpdate(row)"
                            />
                          </td>
                          <td>
                            <div class="ActionImage">
                              <span
                                @click="openAddActivityModal(row.id)"
                                data-bs-toggle="modal"
                                data-bs-target="#AddDesignActivity"
                              >
                                <img
                                  src="../../assets/icons/yellowEdit.png"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Edit"
                                  alt="Edit"
                                />
                              </span>
                              <span @click="DeleteTimeLines(row.id, index)">
                                <img
                                  src="../../assets/icons/redDelete.png"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Delete"
                                  alt="Delete"
                                />
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                  <section v-if="showGraphicalView" class="scrollable-section">
                    <div id="chart">
                      <apexchart
                        type="rangeBar"
                        :height="chartHeight"
                        :options="chartOptions"
                        :series="JSON.parse(JSON.stringify(series))"
                      >
                      </apexchart>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            <div v-if="showEstimateOfDesignProject">
              <div class="DesignProjectEstimateWrapper table-responsive">
                <table class="files-table" aria-hidden="true">
                  <caption class="d-none">
                    Files Table For Design Team
                  </caption>
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>Client Name</th>
                      <th>Customer ID</th>
                      <th>Contact No</th>
                      <th>Date</th>
                      <th>Estimate No</th>
                      <th style="text-align: center">Amount</th>
                      <th style="text-align: center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(estimate, index) in estimates" :key="estimate.id">
                      <td>{{ index + 1 }}</td>
                      <td>{{ estimate["customers.name"] }}</td>
                      <td>{{ estimate["customers.customerNumber"] }}</td>
                      <td>{{ estimate["customers.contactNo"] }}</td>
                      <td>{{ estimate.date }}</td>
                      <td>{{ estimate.estimateId }}</td>
                      <td style="text-align: right">
                        {{ $formatToIndianCurrency(estimate.grandTotal) }}
                      </td>
                      <td class="text-center">
                        <div class="ActionImage">
                          <span
                            @click="
                              $viewFile(
                                estimate['awsFiles.awsFileName'],
                                estimate['awsFiles.fileName'],
                                'estimate',
                                'sales'
                              )
                            "
                          >
                            <img
                              src="../../assets/icons/view.png"
                              data-toggle="tooltip"
                              data-placement="top-start"
                              title="View"
                              alt="View"
                            />
                          </span>
                          <span
                            @click="
                              $downloadFile(
                                estimate['awsFiles.awsFileName'],
                                estimate['awsFiles.fileName'],
                                'estimate',
                                'sales'
                              )
                            "
                          >
                            <img
                              src="../../assets/icons/pinkDownold.png"
                              data-toggle="tooltip"
                              data-placement="top-start"
                              title="download"
                              alt="Download"
                            />
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div v-if="showFileOfDesignProject" class="mt-3">
              <div class="container-fluid">
                <div class="d-flex justify-content-between">
                  <div v-if="!this.TrashFiles">
                    <img
                      src="../../assets/icons/leftmove.png"
                      alt=""
                      class="mb-1"
                      @click="goToParentFolder('null')"
                      style="width: 25px; height: 25px; padding: 5px; cursor: pointer"
                      v-if="this.HeaderName.length > 0"
                    />
                    <span
                      class="ms-2"
                      style="font-size: 16px; cursor: pointer"
                      @click="goToParentFolder('0')"
                      >All Files
                    </span>
                    <span v-for="(headName, index) in HeaderName" :key="index">
                      /
                      <span
                        @click="goToHeaderFolder(headName.id, headName)"
                        style="cursor: pointer"
                      >
                        {{ headName.folderName }}</span
                      >
                    </span>
                  </div>
                  <div v-if="this.TrashFiles">
                    <span>Trash Files</span>
                  </div>
                  <div class="d-flex FilesPageHeader">
                    <section class="p-1" style="cursor: pointer" v-if="!this.TrashFiles">
                      <input
                        type="file"
                        @change="handleFileUpload"
                        multiple
                        accept=".pdf,.doc,.docx"
                        style="display: none"
                        ref="fileInput"
                      />
                      <img
                        src="../../assets/icons/upload.png"
                        alt=""
                        @click="$refs.fileInput.click()"
                      />
                      <span @click="$refs.fileInput.click()">Upload</span>
                    </section>
                    <section
                      style="cursor: pointer"
                      class="p-1 ms-2"
                      @click="createFolder"
                      v-if="ParentFolderName !== 'Sign Off Document'"
                    >
                      <img src="../../assets/icons/add.png" alt="" />
                      <span>Create Folder</span>
                    </section>
                    <section
                      class="p-1"
                      style="cursor: pointer; display: none"
                      v-if="ParentFolderName === 'Sign Off Document' && !this.TrashFiles"
                      @click="this.TrashFiles = true"
                    >
                      <span>Trash Files</span>
                      <img src="../../assets/icons/greyDelete.png" alt="" />
                    </section>
                    <section
                      class="p-1"
                      style="cursor: pointer"
                      v-if="ParentFolderName === 'Sign Off Document' && this.TrashFiles"
                      @click="this.TrashFiles = false"
                    >
                      <span>Sign Off Documents </span>
                      <img src="../../assets/icons/back.png" alt="" />
                    </section>
                  </div>
                </div>
                <div v-if="this.ParentFolderName !== 'Sign Off Document'">
                  <table class="files-table FolderTable">
                    <caption class="d-none">
                      Files Table
                    </caption>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Created By</th>
                        <th>Last Modified</th>
                        <th>File Size</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="(folder, index) in Folders" :key="index">
                        <td v-if="!folder.isNew">
                          <span
                            v-if="folder.folderName"
                            @click="goToFolder(folder.id, folder)"
                          >
                            <img src="../../assets/icons/folder.png" alt="" />
                            <span style="cursor: pointer">{{ folder.folderName }}</span>
                          </span>

                          <a
                            v-else
                            @click="
                              $viewFile(
                                folder['awsFiles.awsFileName'],
                                folder['awsFiles.fileName'],
                                'files',
                                'design'
                              )
                            "
                          >
                            <img
                              src="../../assets/icons/imageIcon.png"
                              alt=""
                              style="width: 20px; height: 20px"
                              v-if="folder['awsFiles.contentType'] === 'image/jpeg'"
                            />
                            <img
                              src="../../assets/icons/dwg.jpeg"
                              alt=""
                              style="width: 20px; height: 20px"
                              v-if="folder['awsFiles.contentType'] === 'application/acad'"
                            />
                            <img
                              src="../../assets/icons/skp.jpeg"
                              alt=""
                              style="width: 20px; height: 20px"
                              v-if="
                                folder['awsFiles.contentType'] ===
                                'application/vnd.sketchup.skp'
                              "
                            />
                            <img
                              src="../../assets/icons/PDF.png"
                              v-if="folder['awsFiles.contentType'] === 'application/pdf'"
                              alt=""
                            />

                            {{ folder["awsFiles.fileName"] }}
                          </a>
                        </td>
                        <td v-else>
                          <img src="../../assets/icons/folder.png" alt="" />
                          <input
                            type="text"
                            v-model="folder.folderName"
                            @blur="saveFolder(folder, index)"
                          />
                        </td>
                        <td>
                          <span
                            v-if="folder.folderName"
                            @click="goToFolder(folder.id, folder)"
                            >{{ folder.contentType }}</span
                          >
                          <span
                            v-else
                            @click="
                              $viewFile(
                                folder['awsFiles.awsFileName'],
                                folder['awsFiles.fileName'],
                                'files',
                                'design'
                              )
                            "
                            >{{ folder["awsFiles.contentType"] }}</span
                          >
                        </td>

                        <td>
                          <span
                            @click="goToFolder(folder.id, folder)"
                            v-if="folder.folderName !== 'Sign Off Document'"
                          >
                            {{ folder["employees.firstName"]
                            }}{{ folder["employees.lastName"] }}</span
                          >
                          <span v-else> System</span>
                        </td>
                        <td @click="goToFolder(folder.id, folder)">
                          {{ folder.updated_at }}
                        </td>
                        <td @click="goToFolder(folder.id, folder)">-</td>
                        <td>
                          <img
                            v-if="folder.folderName !== 'Sign Off Document'"
                            src="../../assets/icons/3blackDots.png"
                            alt=""
                            style="height: 17px; cursor: pointer"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                          />
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a
                              v-if="folder.folderName"
                              class="dropdown-item"
                              href="#"
                              @click="Rename(folder)"
                              >Rename</a
                            >

                            <a
                              class="dropdown-item"
                              href="#"
                              @click="deleteFolder(folder, index)"
                              >Delete</a
                            >
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div v-else style="height: 100%">
                  <div style="height: 50%; overflow-y: auto">
                    <table
                      class="files-table FolderTable"
                      v-if="!this.TrashFiles"
                      style="width: 100%"
                    >
                      <caption class="d-none">
                        Files Table For Design Team
                      </caption>
                      <thead>
                        <tr>
                          <th style="width: 20%">Name</th>
                          <th style="width: 10%">Type</th>
                          <th style="width: 15%">Created By</th>
                          <th style="width: 15%">Status</th>
                          <th style="width: 15%">Last Modified</th>
                          <th style="width: 10%">File Size</th>
                          <th style="width: 5%"></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(folder, index1) in Folders.recentDocument"
                          :key="index1"
                        >
                          <td
                            style="
                              width: 200px;
                              max-width: 150px;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              cursor: pointer;
                              white-space: nowrap;
                            "
                            :title="folder['files.awsFiles.fileName']"
                          >
                            <a
                              @click="
                                $viewFile(
                                  folder['files.awsFiles.awsFileName'],
                                  folder['files.awsFiles.fileName'],
                                  'signOffDocument',
                                  'design'
                                )
                              "
                            >
                              <img src="../../assets/icons/PDF.png" alt="PDF Icon" />
                              {{ folder["files.awsFiles.fileName"] }}
                            </a>
                          </td>
                          <td>
                            <span>{{ folder["files.awsFiles.contentType"] }}</span>
                          </td>
                          <td>
                            {{ folder["employees.firstName"] }}
                            {{ folder["employees.lastName"] }}
                          </td>
                          <td v-if="$isExecutive('Design')">
                            <span
                              v-if="
                                folder.status === 'Pending' || folder.status === 'Sent'
                              "
                              class="text-warning"
                            >
                              {{ folder.status }}
                            </span>
                            <span
                              v-if="folder.status === 'Verified'"
                              class="text-success"
                            >
                              {{ folder.status }}
                            </span>
                            <span
                              v-if="folder.status === 'Approved'"
                              class="text-success"
                            >
                              {{ folder.status }}
                              <img
                                :title="folder.comment"
                                v-if="folder.comment"
                                src="../../assets/icons/estimateInfo.png"
                                alt="Info Icon"
                                style="
                                  padding: 5px;
                                  height: 25px;
                                  width: 25px;
                                  cursor: pointer;
                                "
                                @mouseover="hover1 = index1"
                                @mouseleave="hover1 = null"
                              />
                              <!-- <div
                                v-if="hover1 === index1"
                                class="comment-tooltip"
                                style="position: absolute"
                              >
                                <p>{{ folder.comment }}</p>
                              </div> -->
                            </span>
                            <span v-if="folder.status === 'Rejected'" class="text-danger">
                              {{ folder.status }}
                              <img
                                :title="folder.comment"
                                v-if="folder.comment"
                                src="../../assets/icons/estimateInfo.png"
                                alt="Info Icon"
                                style="
                                  padding: 5px;
                                  height: 25px;
                                  width: 25px;
                                  cursor: pointer;
                                "
                                @mouseover="hover1 = index1"
                                @mouseleave="hover1 = null"
                              />
                              <!-- <div
                                v-if="hover1 === index1"
                                class="comment-tooltip"
                                style="position: absolute"
                              >
                                <p>{{ folder.comment }}</p>
                              </div> -->
                            </span>
                          </td>
                          <td v-else :class="statusClass(folder.status)">
                            <select
                              v-model="folder.status"
                              @change="handleStatusChange(folder.id, folder.status)"
                              class="form-select form-select-sm w-75"
                              style="width: max-content; color: inherit"
                            >
                              <option value="Approved" :style="optionStyle('Approved')">
                                Approved
                              </option>
                              <option value="Verified" :style="optionStyle('Verified')">
                                Verified
                              </option>
                              <option value="Rejected" :style="optionStyle('Rejected')">
                                Rejected
                              </option>
                              <option value="Pending" :style="optionStyle('Pending')">
                                Pending
                              </option>
                              <option value="Sent" :style="optionStyle('Sent')">
                                Sent
                              </option>
                            </select>
                          </td>
                          <td>{{ folder.updated_at }}</td>
                          <td>{{ folder["files.awsFiles.fileSize"] }}</td>
                          <td>
                            <img
                              src="../../assets/icons/3blackDots.png"
                              alt="Options"
                              style="height: 17px; cursor: pointer"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                            />
                            <div
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                class="dropdown-item"
                                @click="AddDocumentsId(folder.id)"
                                data-bs-toggle="modal"
                                data-bs-target="#emailModal"
                              >
                                Send Mail
                              </a>
                              <a
                                class="dropdown-item text-danger"
                                @click="MoveToTrashFiles(folder, index1)"
                              >
                                Move To Trash
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div style="height: 50%; overflow-y: auto; margin-top: 5%">
                    <p class="text-decoration-underline">Trash Files</p>
                    <table class="files-table FolderTable text-muted" style="width: 100%">
                      <caption class="d-none">
                        Trash Table
                      </caption>
                      <thead>
                        <tr>
                          <th style="width: 20%">Name</th>
                          <th style="width: 10%">Type</th>
                          <th style="width: 15%">Created By</th>
                          <th style="width: 15%">Status</th>
                          <th style="width: 15%">Last Modified</th>
                          <th style="width: 10%">File Size</th>
                          <th style="width: 5%"></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(folder, index) in Folders.signOffWithAttachment"
                          :key="index"
                        >
                          <td
                            style="
                              width: 200px;
                              max-width: 150px;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              cursor: pointer;
                              white-space: nowrap;
                            "
                            :title="folder['files.awsFiles.fileName']"
                          >
                            <a
                              @click="
                                $viewFile(
                                  folder['files.awsFiles.awsFileName'],
                                  folder['files.awsFiles.fileName'],
                                  'signOffDocument',
                                  'design'
                                )
                              "
                            >
                              <img src="../../assets/icons/PDF.png" alt="" />
                              {{ folder["files.awsFiles.fileName"] }}
                            </a>
                          </td>
                          <td>
                            <span>{{ folder["files.awsFiles.contentType"] }}</span>
                          </td>
                          <td>
                            {{ folder["employees.firstName"] }}
                            {{ folder["employees.lastName"] }}
                          </td>
                          <td>
                            <span
                              v-if="folder.status === 'Approved'"
                              class="text-success"
                            >
                              {{ folder.status }}
                              <img
                                :title="folder.comment"
                                v-if="folder.comment"
                                src="../../assets/icons/estimateInfo.png"
                                alt=""
                                style="
                                  padding: 5px;
                                  height: 25px;
                                  width: 25px;
                                  cursor: pointer;
                                "
                                @mouseover="hover = index"
                                @mouseleave="hover = null"
                              />
                              <!-- <div
                                v-if="hover === index"
                                class="comment-tooltip"
                                style="position: absolute"
                              >
                                <p>{{ folder.comment }}</p>
                              </div> -->
                            </span>
                            <span
                              v-if="folder.status === 'Verified'"
                              class="text-success"
                              >{{ folder.status }}</span
                            >
                            <span
                              v-if="folder.status === 'Pending'"
                              class="text-warning"
                              >{{ folder.status }}</span
                            >
                            <span v-if="folder.status === 'Sent'" class="text-warning">{{
                              folder.status
                            }}</span>
                            <span v-if="folder.status === 'Deleted'" class="text-danger">
                              {{ folder.status }}
                              <img
                                :title="folder.comment"
                                v-if="folder.comment"
                                src="../../assets/icons/estimateInfo.png"
                                alt=""
                                style="
                                  padding: 5px;
                                  height: 25px;
                                  width: 25px;
                                  cursor: pointer;
                                "
                                @mouseover="hover = index"
                                @mouseleave="hover = null"
                              />
                              <!-- <div v-if="hover === index" class="comment-tooltip">
                                <p>{{ folder.comment }}</p>
                              </div> -->
                            </span>
                            <span v-if="folder.status === 'Rejected'" class="text-danger">
                              {{ folder.status }}
                              <img
                                :title="folder.comment"
                                v-if="folder.comment"
                                src="../../assets/icons/estimateInfo.png"
                                alt=""
                                style="
                                  padding: 5px;
                                  height: 25px;
                                  width: 25px;
                                  cursor: pointer;
                                "
                                @mouseover="hover = index"
                                @mouseleave="hover = null"
                              />
                              <!-- <div v-if="hover === index" class="comment-tooltip">
                                <p>{{ folder.comment }}</p>
                              </div> -->
                            </span>
                          </td>
                          <td>
                            <span class="d-flex justify-content-between">
                              <span>{{ folder.updated_at }}</span>
                              <img
                                v-if="folder.attachments && folder.attachments.length > 0"
                                src="../../assets/icons/blueAttachemnet.png"
                                alt=""
                                style="
                                  padding: 5px;
                                  height: 25px;
                                  width: 25px;
                                  cursor: pointer;
                                "
                                @click="
                                  $viewFile(
                                    folder.attachments[0].awsFileName,
                                    folder.attachments[0].fileName,
                                    'signOffAttachment',
                                    'design'
                                  )
                                "
                              />
                            </span>
                          </td>
                          <td>{{ folder["files.awsFiles.fileSize"] }}</td>
                          <td style="visibility: hidden">
                            <img
                              src="../../assets/icons/3blackDots.png"
                              alt=""
                              style="height: 17px; cursor: pointer"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                            />
                            <div
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                class="dropdown-item text-danger"
                                @click="DeletePermanently(folder, index)"
                                >Delete Permanently</a
                              >
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <AddDesignActivityModal
    :projectId="Id"
    :timelineId="currentTimelineId"
    @fetchTimeLineData="fetchTimeLineData()"
  />
  <ShareWithprojectModal :projectId="Id" :ProjectName="ProjectName" />
  <DeleteDesignProjectModa :projectId="Id" :ProjectName="ProjectName" />
  <UpdateDesignProjectModal
    :projectId="selectedProjectId"
    @fetchData="featchProjectProfileData"
  />
  <DesignProjectInfoModal
    :ProjectInfoBoxId="ProjectInfoBoxId"
    :currentImageId="currentImageId"
    @fetchSectionData="fetchSectionDataOfBox(ProjectInfoBoxId)"
    @fetchWholeSectionData="fetchSectionData()"
  />
  <MailSentModal
    :signOffDocumentId="this.selectedSignOffDocumentId"
    @fetchData="fetchFolderOrFiles()"
  />
  <CommentModal :ProjectInfoBoxId="ProjectInfoBoxId" />
  <HistoryModal :ProjectInfoBoxId="ProjectInfoBoxId" />
  <PibShareModal :designProjectId="Id" />
  <ProjectActivityModal
    :projectId="DesignProjectId"
    :team="TeamName"
    @setProjectId="setProjectId"
  />
</template>

<script>
import ProjectActivityModal from "../Modal/ProjectActivityModal.vue";
import html2pdf from "html2pdf.js";
import PibShareModal from "../Modal/PibShareModal.vue";
import HistoryModal from "../Modal/HistoryModal.vue";
import CommentModal from "../Modal/CommentModal.vue";
import DesignProjectInfoModal from "../Modal/DesignProjectInfoModal.vue";
import AddDesignActivityModal from "../Modal/AddDesignActivityModal.vue";
import UpdateDesignProjectModal from "../Modal/UpdateDesignProjectModal.vue";
import DeleteDesignProjectModa from "../Modal/DeleteDesignProjectModal.vue";
import ShareWithprojectModal from "../Modal/ShareWithProjectModal.vue";
import MailSentModal from "@/components/Modal/MailSentModal.vue";
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import axios from "axios";
import VueApexCharts from "vue3-apexcharts";

export default {
  name: "DesignProjectProfile",
  components: {
    Header,
    Nav,
    UpdateDesignProjectModal,
    DeleteDesignProjectModa,
    ShareWithprojectModal,
    AddDesignActivityModal,
    DesignProjectInfoModal,
    apexchart: VueApexCharts,
    MailSentModal,
    CommentModal,
    HistoryModal,
    PibShareModal,
    ProjectActivityModal,
  },

  data() {
    return {
      DesignProjectId: this.$route.params.Id,
      TeamName: "Design",
      companyDetails: {},
      isDownloadable: false,
      sectionIndex: null,
      valueIndex: null,
      selectedSignOffDocumentId: null,
      hover1: null,
      hover: null,
      selectedProjectId: null,
      TrashFiles: false,
      PhotoUrl: null,
      Id: this.$route.params.Id,

      ProjectName: this.$route.params.projectName,
      showProjectNameDropDown: false,
      showOverview: true,
      showProjectInfo: false,
      showProjectTimeLine: false,
      showTabularView: true,
      showGraphicalView: false,
      showEstimateOfDesignProject: false,
      sectionHeader: "",
      textAreas: [{ value: "" }],
      sections: [],
      sectionImages: [],
      attachedPhoto: [],
      attachedPhotoIds: [],
      timelineRows: [],
      estimates: [],
      EditDesignProject: false,
      SharewithDesignProject: false,
      AddMemberdiv: false,
      showTeamMember: false,
      showCollaborators: true,
      deleteDesignProject: false,
      mainProjectId: null,
      DesignProjectProfile: {
        customerName: "",
        projectName: "", // Ensure projectName is initialized
        customerNumber: "",
        customerId: "",
        customerPropertyName: "",
        familyStatus: "",
        buildingConfiguration: "",
        assignTo: "",
        projectStatus: "",
        designStage: "",
        projectDescription: "",
        designStartDate: "",
        contactNo: "",
        EmailId: "",
        propertyAddress: "",
      },
      currentStep: "",
      collaborators: [],
      showDropdown1: false,
      selectedEmployee: "",
      Employees: [],
      employeeId: "",
      enteredProjectName: "",
      confirmationText: "",
      showFileOfDesignProject: false,
      ParentFolderId: "0",
      ParentFolderName: "Root",
      Folders: [],
      attachedFiles: [],
      awsFileId: [],
      BackFolderId: "null",
      HeaderName: [],
      currentTimelineId: null,
      ProjectInfoBoxId: null,
      currentImageId: null,
      showInfoBox: true,

      series: [],
      chartOptions: {
        chart: {
          type: "rangeBar",
        },

        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "15px",
          },
        },
        xaxis: {
          type: "datetime",
          position: "top",
          horizontalAlign: "left",
          tickAmount: 6, // Number of ticks for better readability
        },

        stroke: {
          width: 10, // Adjust stroke width
        },
        fill: {
          type: "solid",
          opacity: 0.6,
        },
        legend: {
          position: "top",
          horizontalAlign: "center",
        },
        colors: ["#0000FF", "#008000", "#FF0000"], // Blue, Green, Red
      },
      faviorate: false,
    };
  },

  computed: {
    isDesignExecutive() {
      const roles = this.$LocalUser.employeeRoles;
      return roles.some((role) => role.team === "Design" && role.role === "Executive");
    },
    filteredEmployees() {
      const searchTerm = this.selectedEmployee.toLowerCase();
      return this.Employees.filter((Employee) => {
        const fullName = `${Employee["users.firstname"]} ${Employee["users.lastname"]}`.toLowerCase();
        return fullName.includes(searchTerm);
      });
    },
  },
  mounted() {
    this.Id = this.$route.params.Id;
    this.featchProjectProfileData();
    this.fetchCollaboratorData();
    this.featchCompanyData();
  },
  // watch: {
  //   "$route.params.Id"(newId) {
  //     this.Id = newId;
  //     this.fetchTimeLineData();
  //     this.featchProjectProfileData();
  //     this.fetchSectionData();
  //     this.fetchCollaborators();
  //     this.fetchEmployees();

  //     this.fetchFolderOrFiles();
  //   },
  // },

  methods: {
    OpenProjectActivityModal() {
      this.DesignProjectId = this.Id;
    },
    setProjectId() {
      this.DesignProjectId = null;
      console.log(this.DesignProjectId);
    },
    featchCompanyData() {
      axios
        .get(`${this.$apiEndPoint}/company/${this.$LocalUser.user.companyId}`)
        .then((response) => {
          this.companyDetails = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    hoverEffect(value) {
      value.hoverColor = value.isReadOnly ? "#5a6268" : "#218838";
    },
    resetEffect(value) {
      value.hoverColor = value.isReadOnly ? "#6c757d" : "#28a745";
    },

    async fetchSectionData() {
      this.sectionImages = [];
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/projectInfoBoxSection/${this.Id}`
        );
        this.sections = response.data;

        // Set showInfoBox and isReadOnly to true for every section and value
        this.sections.forEach((section) => {
          section.showInfoBox = true;
          section.values.forEach((value) => {
            value.isReadOnly = true; // Make each value read-only initially
          });
        });

        await Promise.all(
          this.sections.map((section) =>
            Promise.all(
              section.values.map((value) => this.fetchProjectInfoBoxImages(value.id))
            )
          )
        );
      } catch (error) {
        this.$handleError(error);
      }
    },
    toggleReadOnly(value, sectionId, title, text, sectionIndex, valueIndex) {
      value.isReadOnly = !value.isReadOnly;

      // Optional: Add save logic when changing back to read-only
      if (value.isReadOnly) {
        this.handleTextAreaChange(sectionId, title, text, sectionIndex, valueIndex);
      }
    },

    async fetchSectionDataOfBox(ProjectInfoBoxId) {
      this.ProjectInfoBoxId = null;
      this.fetchProjectInfoBoxImages(ProjectInfoBoxId);
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/projectInfoBoxArea/${ProjectInfoBoxId}`
        );

        this.sections[this.sectionIndex].values[this.valueIndex].title =
          response.data.title;
        this.sections[this.sectionIndex].values[this.valueIndex].text =
          response.data.text;
        this.sectionIndex = null;
        this.valueIndex = null;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchProjectInfoBoxImages(boxId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/projectInfoBoxImages/${boxId}`
        );

        // Ensure response.data is an array and push it directly to sectionImages
        let data = Array.isArray(response.data) ? response.data : [response.data];
        this.sectionImages[boxId] = data; // Push the array as a whole

        await Promise.all(
          this.sectionImages[boxId].map(async (image) => {
            image.sectionPhotoUrl = await this.fetchInfoImage(
              image["awsFiles.awsFileName"],
              image["awsFiles.fileName"]
            );
          })
        );
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchInfoImage(AwsFileName, FileName) {
      const PayLoad = {
        awsFileName: AwsFileName,
        fileCategory: "projectInfoBox",
        moduleName: "design",
        fileName: FileName,
      };

      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/company/getFileSignedURL/`,
          PayLoad
        );
        return Object.values(response.data)[0];
      } catch (error) {
        this.$handleError(error);
        return null;
      }
    },

    addRow() {
      this.timelineRows.push({
        description: "",
        actualDate: "",
        finishedDate: "",
        remarks: "",
      });
    },
    OpenEstimate(estimate) {
      if (estimate?.estimateId) {
        this.$router.push({
          name: "FinalEstimate",
          params: {
            EstimateId: estimate.estimateId,
            estimateIndex: estimate.id,
          },
        });
      }
    },

    statusClass(status) {
      switch (status) {
        case "Approved":
          return "approvedText";
        case "Pending":
          return "orderedText";
        case "Verified":
          return "deliveredText";
        case "Rejected":
          return "rejectedText";
        case "Sent":
          return "orderedText";
        default:
          return "";
      }
    },
    optionStyle(status) {
      switch (status) {
        case "Pending":
          return "color: #ffd159;"; // Yellow color
        case "Sent":
          return "color: #32587a";
        case "Approved":
          return "color: #38b525;"; // Green color
        case "Verified":
          return "color: #2381f3;"; // Blue color
        case "Rejected":
          return "color: red;"; // Gray color

        default:
          return "";
      }
    },
    async handleStatusChange(id, newStatus) {
      try {
        const response = await axios.put(`${this.$apiEndPoint}/designFiles/${id}`, {
          status: newStatus,
        });

        if (response.status === 200) {
          this.$toast(`Status updated to ${newStatus}`, "success");
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    AddDocumentsId(Id) {
      this.selectedSignOffDocumentId = Id;
    },

    acceptProject(projectId) {
      this.selectedProjectId = projectId;
    },
    generatePDF(EstimateId) {
      this.$router.push({
        name: "PrintEstimate",
        params: {
          estimateIndex: EstimateId,
          // otherteam: "Design",
        },
      });
    },
    toggleInfoBox(sectionIndex) {
      this.sections[sectionIndex].showInfoBox = !this.sections[sectionIndex].showInfoBox;
    },
    async DeleteTimeLines(timeLineid, index) {
      const result = await this.$showAlert(
        "Are you sure?",
        "Are you sure you want to delete?",
        "warning",
        "Yes, delete it",
        "Cancel"
      );

      // If the user clicked Cancel, don't proceed
      if (!result.isConfirmed) return;

      this.timelineRows.splice(index, 1);

      try {
        await axios.delete(`${this.$apiEndPoint}/designTimeline/${timeLineid}`);
        this.fetchTimeLineData();
      } catch (error) {
        this.$handleError(error);
      }
    },

    formatDate(dateStr) {
      if (!dateStr) return "";
      const date = new Date(dateStr);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },
    async Faviorate() {
      if (this.DesignProjectProfile["designProjectTeam.favourite"] === 1) {
        this.DesignProjectProfile["designProjectTeam.favourite"] = 0;
      } else {
        this.DesignProjectProfile["designProjectTeam.favourite"] = 1;
      }
      try {
        await axios.put(
          `${this.$apiEndPoint}/designProjects/favouriteDesignProject/${this.Id}`
        );
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchCollaboratorData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/designProjectTeam/${this.Id}`
        );
        this.collaborators = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    GoToDesignProjectInformodal(ProjectInfoBoxId, sectionIndex, valueIndex) {
      this.ProjectInfoBoxId = ProjectInfoBoxId;

      this.sectionIndex = sectionIndex;
      this.valueIndex = valueIndex;
    },

    triggerFileInput() {
      // Ensure the file input ref is available
      if (this.$refs.fileInput2) {
        this.$refs.fileInput2.click();
      } else {
        this.$toast("File input ref not available", "error");
      }
    },
    handlePhotoUpload(InfoBoxId, event) {
      const files = event.target.files;
      for (const file of files) {
        const payload = {
          fileName: file.name,
          fileSize: file.size,
          contentType: file.type,
          moduleName: "design",
          fileCategory: "projectInfoBox",
        };

        axios
          .post(`${this.$apiEndPoint}/company/putFileSignedURL`, payload)
          .then((response) => {
            const signedUrl = response.data.s3URL;
            const fileId = response.data.id;
            const fileName = response.data.fileName;

            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUrl, true);
            xhr.setRequestHeader("Content-Type", file.type);

            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  this.$toast("File uploaded successfully", "success");
                  this.attachedPhoto.push({
                    name: fileName,
                    size: file.size,
                    id: fileId,
                  });
                  this.SendPhotoToInfoBoxArea(InfoBoxId);
                } else {
                  this.$toast("Error uploading file", "error");
                }
              }
            };

            xhr.send(file);
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },

    adjustChartHeight() {
      this.chartHeight = this.timelineRows.length * 50 + 100; // Adjust 30 and 100 based on your preference
    },
    switchToTabularView() {
      this.showTabularView = true;
      this.showGraphicalView = false;
    },
    switchToGraphicalView() {
      this.showTabularView = false;
      this.showGraphicalView = true;
      this.adjustChartHeight();
    },
    getRowClass(row) {
      if (row.actualEndDate && row.plannedEndDate) {
        const plannedEndDate = new Date(row.plannedEndDate);
        const actualEndDate = new Date(row.actualEndDate);
        if (actualEndDate > plannedEndDate) {
          return "late";
        } else if (actualEndDate <= plannedEndDate) {
          return "on-time";
        }
      }
      return "";
    },
    openAddActivityModal(timelineId) {
      this.projectId = this.Id;
      this.currentTimelineId = timelineId;
    },

    async fetchTimeLineData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/designTimeline/${this.Id}`
        );
        this.timelineRows = response.data;

        if (this.timelineRows) {
          const plannedData = [];
          const actualData = [];
          const delayData = [];

          this.timelineRows.forEach((row) => {
            const plannedStart = new Date(row.plannedStartDate).getTime();
            const plannedEnd = new Date(row.plannedEndDate).getTime();

            const actualStart = row.actualStartDate
              ? new Date(row.actualStartDate).getTime()
              : null;

            let actualEnd = null;
            if (row.actualEndDate && row.actualEndDate !== "0000-00-00") {
              actualEnd = new Date(row.actualEndDate).getTime();
            }

            plannedData.push({
              x: row.activity,
              y: [plannedStart, plannedEnd],
            });

            // Only push to actualData if both actualStart and actualEnd are valid
            if (actualStart !== null && actualEnd !== null) {
              actualData.push({
                x: row.activity,
                y: [actualStart, actualEnd],
              });

              // Check for delay only if plannedEnd is less than actualEnd
              if (plannedEnd < actualEnd) {
                delayData.push({
                  x: row.activity,
                  y: [plannedEnd, actualEnd],
                });
              }
            }
          });

          this.series = [
            {
              name: "Planned",
              data: plannedData,
            },
            {
              name: "Actual",
              data: actualData,
            },
            {
              name: "Delay",
              data: delayData,
            },
          ];
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    async goToParentFolder(FolderId) {
      if (FolderId === "0") {
        // Changed to string comparison
        this.HeaderName = [];
        this.ParentFolderId = FolderId;
        this.ParentFolderName = "";

        this.fetchFolderOrFiles();
      } else if (FolderId === "null" && this.HeaderName.length > 1) {
        // Changed to string comparison
        const FolderIds = this.HeaderName[this.HeaderName.length - 2].id;

        this.HeaderName.splice(-1, 1);
        this.ParentFolderId = FolderIds;

        this.fetchFolderOrFiles();
      } else {
        this.HeaderName = [];
        this.Folder = [];
        this.FolderId = 0;
        this.ParentFolderName = "";
        this.ParentFolderId = FolderId;

        this.fetchFolderOrFiles();
      }
    },

    goToFolder(FolderId, Folder) {
      this.Folders = [];
      this.HeaderName.push(Folder);
      this.ParentFolderId = FolderId;
      this.ParentFolderName = Folder.folderName;

      this.fetchFolderOrFiles();
    },
    goToHeaderFolder(FolderId, Folder) {
      // Check if the folder is already present in HeaderName
      const folderIndex = this.HeaderName.indexOf(Folder);

      if (folderIndex === -1) {
        // Folder not present, add to the end
        this.HeaderName.push(Folder);
      } else {
        // Folder is present, remove subsequent folders
        this.HeaderName = this.HeaderName.slice(0, folderIndex + 1);
      }
      // Update the ParentFolderId
      this.ParentFolderId = FolderId;

      // Fetch the contents of the folder
      this.fetchFolderOrFiles();
    },

    async fetchFolderOrFiles() {
      if (this.ParentFolderName === "Sign Off Document") {
        try {
          const response = await axios.get(`${this.$apiEndPoint}/designFiles/${this.Id}`);

          this.Folders = response.data;
        } catch (error) {
          this.$handleError(error);
        }
      } else {
        try {
          const response = await axios.get(
            `${this.$apiEndPoint}/folder/design/${this.Id}/designProject/${this.ParentFolderId}`
          );
          this.Folders = response.data;
        } catch (error) {
          this.$handleError(error);
        }
      }
    },
    createFolder() {
      const newFolder = {
        isNew: true,
        folderName: "",
        createdBy: "Current User",
        lastModified: new Date().toLocaleDateString(),
      };
      this.Folders.push(newFolder);
    },
    async MoveToTrashFiles(Folder, index) {
      this.Folders.recentDocument.splice(index, 1);
      try {
        await axios.put(`${this.$apiEndPoint}/designFiles/trashSignOff/${Folder.id}`);

        this.fetchFolderOrFiles();
      } catch (error) {
        this.$handleError(error);
      }
    },

    async deleteFolder(Folder, index) {
      const result = await this.$showAlert(
        "Are you sure?",
        `Are you sure you want to delete the folder ${Folder.folderName || "this item"}?`,
        "warning",
        "Yes, delete it",
        "Cancel"
      );

      // If the user clicked Cancel, don't proceed
      if (!result.isConfirmed) return;
      this.Folders.splice(index, 1);
      if (Folder.folderName) {
        try {
          await axios.delete(`${this.$apiEndPoint}/folder/deleteFolder`, {
            data: { id: [Folder.id] },
          });

          this.fetchFolderOrFiles();
        } catch (error) {
          this.$handleError(error);
        }
      } else {
        try {
          await axios.delete(`${this.$apiEndPoint}/files/deleteFolder`, {
            data: { id: [Folder.id] },
          });

          this.fetchFolderOrFiles();
        } catch (error) {
          this.$handleError(error);
        }
      }
    },
    Rename(folder) {
      folder.isNew = true;
    },

    async saveFolder(folder, index) {
      if (folder.folderName.trim() === "") {
        alert("Folder name cannot be empty");
        return;
      }

      if (!folder.id) {
        try {
          await axios.post(`${this.$apiEndPoint}/folder/`, {
            parentFolderId: this.ParentFolderId,
            folderName: folder.folderName,
            moduleName: "design",
            entityId: this.Id,
            entityName: "designProject",
          });
          this.fetchFolderOrFiles();
          console.log(index);

          // Update the folder array item directly to remove `isNew`
          if (index >= 0 && index < this.Folders.length) {
            this.Folders[index] = {
              ...folder,
              isNew: false, // Mark as not new
            };
          }
        } catch (error) {
          this.$handleError(error);
        }
      } else {
        try {
          await axios.put(`${this.$apiEndPoint}/folder/${folder.id}`, {
            folderName: folder.folderName,
          });
          this.fetchFolderOrFiles();
        } catch (error) {
          this.$handleError(error);
        }
      }
    },

    handleFileUpload(event) {
      const files = event.target.files;
      this.$globalState.isLoading = true;
      let fileCategory = "files";
      let entityId;
      let entityName;

      if (this.ParentFolderName === "Sign Off Document") {
        fileCategory = "signOffDocument";
        entityId = this.Id;
        entityName = "designProject";
      }

      for (const file of files) {
        let contentType = file.type || this.getContentTypeByExtension(file.name);
        const payload = {
          fileName: file.name,
          fileSize: file.size,
          contentType: contentType,
          moduleName: "design",
          fileCategory: fileCategory,
          entityId: entityId,
          entityName: entityName,
        };

        axios
          .post(`${this.$apiEndPoint}/company/putFileSignedURL`, payload)
          .then((response) => {
            const signedUrl = response.data.s3URL;
            const fileId = response.data.id; // Get the ID from the response
            const fileName = response.data.fileName; // Get the filename from the response

            // Use XMLHttpRequest to upload file to signed URL
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUrl, true);
            xhr.setRequestHeader("Content-Type", file.type);

            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  // Push the file object with ID and filename into the attachedFiles array
                  this.attachedFiles.push({
                    name: fileName,
                    size: file.size,
                    id: fileId,
                    type: file.type.split("/")[1], // Assuming file.type is in the format "image/png", "application/pdf", etc. We split it to get the second part.
                  });
                  this.$globalState.isLoading = false;
                  this.UploadFilesToFolder();
                } else {
                  this.$handleError(xhr.responseText);
                  this.$globalState.isLoading = false;
                }
              }
            };
            xhr.send(file);
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },
    // Utility function to get MIME type based on file extension
    getContentTypeByExtension(fileName) {
      const extension = fileName.split(".").pop().toLowerCase();
      switch (extension) {
        case "dwg":
          return "application/acad";
        case "skp":
          return "application/vnd.sketchup.skp";
        // Add more cases as needed for other file types
        default:
          return "application/octet-stream"; // Default binary type
      }
    },
    async UploadFilesToFolder() {
      try {
        const requestBody = {
          folderId: this.ParentFolderId,
          moduleName: "design",
          entityId: this.Id,
          entityName: "designProject",
          awsFileId: this.attachedFiles.map((file) => file.id), // Use the file IDs from the attachedFiles array
        };

        const response = await axios.post(`${this.$apiEndPoint}/files`, requestBody);

        if (this.ParentFolderName === "Sign Off Document") {
          this.CreateSignOffDocument(response.data[0].id);
        } else {
          this.fetchFolderOrFiles();

          this.attachedFiles = [];
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    async CreateSignOffDocument(FileId) {
      try {
        const requestBody = {
          fileId: FileId,
          designProjectId: this.Id,
          projectId: this.mainProjectId,
        };

        await axios.post(`${this.$apiEndPoint}/designFiles/`, requestBody);

        this.fetchFolderOrFiles();

        this.attachedFiles = [];
      } catch (error) {
        this.$handleError(error);
      }
    },

    addTextArea(sectionIndex) {
      const ValuesArray = this.sections[sectionIndex].values;

      // Check if the last added value has both title and text
      if (ValuesArray.length < 1 || ValuesArray[ValuesArray.length - 1].title) {
        this.sections[sectionIndex].values.push({ text: "", title: "" });

        // Use nextTick to focus on the new title input after DOM updates
        this.$nextTick(() => {
          const newValueIndex = this.sections[sectionIndex].values.length - 1;
          const newInput = this.$refs["areaTitleInput" + sectionIndex + newValueIndex][0];

          if (newInput) {
            newInput.focus();
          }
        });
      } else {
        alert("Please fill box title");
      }
    },

    addSection() {
      this.sections.push({
        id: null,
        sectionName: "",
        values: [{}],
        showInfoBox: true,
      });

      // Wait for DOM update and then focus on the new section's input field
      this.$nextTick(() => {
        const newSectionIndex = this.sections.length - 1;
        this.$refs[`sectionNameInput${newSectionIndex}`][0].focus();
      });
    },

    async CreateSection(sectionIndex) {
      const newSectionName = this.sections[sectionIndex].sectionName;

      // Check if the section name already exists
      const existingSection = this.sections.find(
        (section) => section.sectionName === newSectionName
      );

      try {
        if (existingSection.id) {
          // If the section name already exists, update the existing section
          await axios.put(
            `${this.$apiEndPoint}/projectInfoBoxSection/${existingSection.id}`,
            {
              sectionName: newSectionName,
              designProjectId: this.Id,
            }
          );
        } else {
          // If the section name doesn't exist, create a new section
          const response = await axios.post(
            `${this.$apiEndPoint}/projectInfoBoxSection/`,
            {
              sectionName: newSectionName,
              designProjectId: this.Id,
            }
          );
          this.sections[sectionIndex].id = response.data.id;
          this.sections[sectionIndex].sectionName = response.data.sectionName;
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    async handleTextAreaChange(sectionId, title, text, sectionIndex, valueIndex) {
      this.sectionIndex = sectionIndex;
      this.valueIndex = valueIndex;
      try {
        // Check if the text already exists in any section
        const existingSection = this.sections.find((section) => section.id === sectionId);
        const existingTextArea = existingSection
          ? existingSection.values.find((value) => value.title === title)
          : null;

        if (existingTextArea.id) {
          await axios.put(
            `${this.$apiEndPoint}/projectInfoBoxArea/${existingTextArea.id}`,
            {
              text: text,
              title: title,
            }
          );
          existingTextArea.isReadOnly = true;
          this.fetchSectionDataOfBox(existingTextArea.id);
        } else {
          // If the text area doesn't exist, create a new one
          const response = await axios.post(`${this.$apiEndPoint}/projectInfoBoxArea/`, {
            sectionId: sectionId,
            text: text,
            title: title,
          });
          this.sections[sectionIndex].values[this.valueIndex].id = response.data.id;

          this.fetchSectionDataOfBox(response.data.id);
        }
      } catch (error) {
        this.$handleError(error);
      }
      //
    },
    async downloadPDF() {
      // Show the content for PDF generation
      this.isDownloadable = true;

      // Wait for Vue to finish rendering the DOM
      await this.$nextTick();

      // Reference the hidden content using $refs
      const element = this.$refs.pdfContent;

      // Options for the PDF generation
      const opt = {
        margin: 0.4,
        filename: "ProjectInfo.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };

      try {
        // Generate the PDF as a Blob
        const worker = html2pdf().from(element).set(opt);
        const pdfBlob = await worker.outputPdf("blob");

        // Create a Blob URL and open it in a new tab
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
      } catch (error) {
        console.error("Error generating PDF:", error);
      } finally {
        // Reset `isDownloadable` after generation if needed
        this.isDownloadable = false;
      }
    },

    //Timeline Creation
    async saveRowData(row) {
      try {
        await axios.post(`${this.$apiEndPoint}/designTimeline/`, {
          description: row.description,
          designProjectId: this.Id,
        });
        this.fetchTimeLineData();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async updateRowData(row) {
      try {
        await axios.put(`${this.$apiEndPoint}/designTimeline/${row.id}`, {
          actualStartDate: row.actualStartDate,
          actualEndDate: row.actualEndDate,
        });
        this.fetchTimeLineData();
      } catch (error) {
        this.$handleError(error);
      }
    },

    // Add a method to handle updating a row when blurred (loses focus)
    async handleRowUpdate(row) {
      try {
        await this.updateRowData(row);
      } catch (error) {
        this.$handleError(error);
      }
    },

    async updateField(field, value) {
      try {
        // Prepare the updated field
        const updatedField = {
          [field]: value,
        };

        await axios.put(`${this.$apiEndPoint}/designProjects/${this.Id}`, updatedField);
      } catch (error) {
        this.$handleError(error);
      }
    },

    async submitDesignForm() {
      try {
        // Prepare the updated fields
        const updatedFields = {
          projectName: this.ProjectName,
          // Add other fields you want to update here
        };

        await axios.put(`${this.$apiEndPoint}/designProjects/${this.Id}`, updatedFields);
      } catch (error) {
        this.$handleError(error);
      }
    },

    async sendInvitationToAll() {
      const allEmployeeIds = this.Employees.map((employee) => employee.id);

      try {
        // Construct the request body with all employee IDs and the design project ID
        const requestData = {
          employeeId: allEmployeeIds,
          designProjectId: this.Id,
        };

        await axios.post(`${this.$apiEndPoint}/designProjectTeam`, requestData);

        this.fetchCollaborators();
        // Optionally, handle response or navigate to a different route
      } catch (error) {
        this.$handleError(error);
        alert("Failed to send invitations. Please try again.");
      }
    },

    serchEmployee() {
      this.showDropdown1 = this.selectedEmployee.trim() !== "";
    },
    selectEmployee(Employee) {
      this.selectedEmployee = Employee["users.firstname"];
      this.employeeId = Employee.id;
      this.showDropdown1 = false;
    },
    async fetchCollaborators() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/designProjectTeam/${this.Id}`
        );
        this.collaborators = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async sendInvitations() {
      // Check if any employees are selected
      if (this.employeeId.length === 0) {
        alert("Please select at least one employee to send invitations.");
        return;
      }

      // Construct the request body with selected employee IDs and the design project ID
      const requestData = {
        employeeId: this.employeeId,
        designProjectId: this.Id,
      };

      try {
        await axios.post(`${this.$apiEndPoint}/designProjectTeam`, requestData);
      } catch (error) {
        this.$handleError(error);
        alert("Failed to send invitations. Please try again.");
      }
    },

    async featchProjectProfileData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/designProjects/getById/${this.$route.params.Id}`
        );
        const projectData = response.data;
        this.DesignProjectProfile = response.data;
        this.ProjectName = projectData.projectName;
        this.mainProjectId = projectData.projectId;
        this.DesignProjectProfile.customerName = projectData["customers.name"];
        this.DesignProjectProfile.projectName = projectData.projectName;

        this.DesignProjectProfile.customerNumber =
          projectData["customers.customerNumber"];
        this.DesignProjectProfile.customerPropertyName = projectData["property.name"];
        this.DesignProjectProfile.familyStatus = projectData.familyStatus;
        this.DesignProjectProfile.buildingConfiguration =
          projectData.buildingConfiguration;
        this.DesignProjectProfile.assignTo = projectData.assignTo;
        this.DesignProjectProfile.projectStatus = projectData.projectStatus;
        this.DesignProjectProfile.designStage = projectData.designStage;
        this.DesignProjectProfile.designStartDate = projectData.designStartDate;
        this.DesignProjectProfile.projectDescription = projectData.projectDescription;
        this.DesignProjectProfile.contactNo = projectData["customers.contactNo"];
        this.DesignProjectProfile.EmailId = projectData["customers.email"];
        this.DesignProjectProfile.propertyAddress = `${
          projectData["addresses.address"] || ""
        } ${projectData["addresses.city"] || ""} ${
          projectData["addresses.state"] || ""
        }`.trim();

        this.DesignProjectProfile.customerId = projectData["customers.id"];

        this.fetchEstimates(projectData["customers.id"]);

        this.currentStep = this.mapDesignStageToStep(projectData.designStage);
      } catch (error) {
        this.$handleError(error);
      }
    },

    mapDesignStageToStep(designStage) {
      const stageMap = {
        "Discovery Session": 1,
        "3d Design": 2,
        "Material Selection": 3,
        "2d Drafting": 4,
        "Sign Off Document": 5,
        "Sent For Execution": 6,
        // Add more mappings as needed
      };
      return stageMap[designStage] || 1; // Default to the first step if not found
    },
    activateStep(step) {
      if (this.currentStep !== 6) {
        const steping = step;
        if (steping === 6) {
          const userConfirmed = confirm(
            "Are you sure you want to send the project to the Execution Team?"
          );
          if (userConfirmed) {
            alert("Project is Sending For Execution Team");
          } else {
            // If the user clicked "No", you can handle it here (optional).
            return; // Exit the function, so the step is not changed.
          }
        }
        this.currentStep = step;
      }
    },

    async updateProjectStage(stage) {
      if (this.DesignProjectProfile.projectStatus === "Completed") {
        this.$toast("The project is already completed and cannot be updated.", "warning");
        return; // Exit the function if the project is completed
      }

      try {
        await axios.put(`${this.$apiEndPoint}/designProjects/status/${this.Id}`, {
          designStage: stage,
        });

        this.$toast(`Stage updated to ${stage}`, "success");
        // Update the local data with the new stage
        this.DesignProjectProfile.designStage = stage;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async updateProjectDescription() {
      try {
        // Make the API call to update the project description
        await axios.put(
          `${this.$apiEndPoint}/designProjects/update/description/${this.Id}`,
          { projectDescription: this.DesignProjectProfile.projectDescription }
        );
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchEstimates(CustomerId) {
      try {
        // Make HTTP GET request to fetch data from the API
        const response = await axios.get(
          `${this.$apiEndPoint}/estimates/byCustomer/${CustomerId}`
        );
        this.estimates = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchEmployees() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/employee`);
        this.Employees = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async deleteProject() {
      // Check if entered project name and confirmation text are correct
      if (
        this.enteredProjectName !== this.ProjectName ||
        this.confirmationText !== "delete My Project"
      ) {
        alert(
          `Entered project name: ${this.ProjectName} & confirmation text as 'delete My Project' incorrect.`
        );

        return;
      }

      // If correct, proceed with deletion
      try {
        await axios.delete(`${this.$apiEndPoint}/designProjects/${this.Id}`);
      } catch (error) {
        this.$handleError(error);
      }
    },

    toggleAddMember() {
      this.AddMemberdiv = !this.AddMemberdiv;
    },
    toggleSharewithDesignProject() {
      this.deleteDesignProject = false;
      this.EditDesignProject = false;
      this.SharewithDesignProject = !this.SharewithDesignProject;
    },
    toggleEditDesignProject() {
      this.deleteDesignProject = false;
      this.SharewithDesignProject = false;
      this.EditDesignProject = !this.EditDesignProject;
    },

    toggleProjectNameDropDown() {
      this.showProjectNameDropDown = !this.showProjectNameDropDown;
    },
    toggleProjectFile() {
      this.fetchFolderOrFiles();
      this.showFileOfDesignProject = true;
      this.showProjectInfo = false;
      this.showOverview = false;
      this.showProjectTimeLine = false;
      this.showEstimateOfDesignProject = false;
    },
    toggleProjecEstimate() {
      this.showEstimateOfDesignProject = true;
      this.showFileOfDesignProject = false;
      this.showProjectInfo = false;
      this.showOverview = false;
      this.showProjectTimeLine = false;
    },
    toggleProjecTimeLine() {
      this.fetchTimeLineData();
      this.showProjectTimeLine = true;
      this.showFileOfDesignProject = false;
      this.showEstimateOfDesignProject = false;
      this.showProjectInfo = false;
      this.showOverview = false;
    },
    // Corrected from "method" to "methods"
    toggleOverviewPage() {
      this.showOverview = true;
      this.showFileOfDesignProject = false;
      this.showEstimateOfDesignProject = false;
      this.showProjectTimeLine = false;
      this.showProjectInfo = false;
    },
    toggleProjectInfoPage() {
      this.fetchSectionData();
      this.showProjectInfo = true;
      this.showFileOfDesignProject = false;
      this.showEstimateOfDesignProject = false;
      this.showProjectTimeLine = false;
      this.showOverview = false;
    },

    hideDesignProjectModal() {
      this.EditDesignProject = false;
    },
    hideSharewithDesignProject() {
      this.SharewithDesignProject = false;
    },
    hideAddMemberdiv() {
      this.AddMemberdiv = false;
    },
    toggleTeamMembers() {
      this.AddMemberdiv = false;
      this.showTeamMember = !this.showTeamMember;
      this.showCollaborators = false;
    },
    toggleCollaboratorsMember() {
      this.showCollaborators = !this.showCollaborators;
      this.showTeamMember = false;
      this.fetchCollaborators();
    },
    toggleDeleteProject() {
      this.SharewithDesignProject = false;
      this.EditDesignProject = false;
      this.deleteDesignProject = !this.deleteDesignProject;
    },
    hidedeleteDesignProject() {
      this.deleteDesignProject = false;
    },
    showEmployeeName() {
      // Show the dropdown
      this.showDropdown = true;
    },

    async updateCustomerField(Id) {
      try {
        await axios.put(`${this.$apiEndPoint}/customer/${Id}`, {
          email: this.DesignProjectProfile.EmailId,
          contactNo: this.DesignProjectProfile.contactNo,
          name: this.DesignProjectProfile.customerName,
        });
        this.featchProjectProfileData();
        this.$toast("Customer details updated successfully.", "success");
      } catch (error) {
        this.$toast("Failed to update customer details. Please try again.", "error");
        this.$handleError(error);
      }
    },

    async DeleteSection(sectionId) {
      // Use JavaScript confirm to ask for user confirmation
      const confirmed = confirm("Are you sure you want to delete this section?");

      if (confirmed) {
        try {
          // Call the delete API
          await axios.delete(
            `${this.$apiEndPoint}/projectInfoBoxSection/deleteSection/${sectionId}`
          );
          alert("Section deleted successfully.");

          // Remove the deleted section from the local `sections` array
          this.sections = this.sections.filter((section) => section.id !== sectionId);
        } catch (error) {
          console.error("Error deleting section:", error);
          alert("Failed to delete the section. Please try again.");
        }
      } else {
        // If the user cancels, log or handle accordingly
        console.log("User canceled the delete action.");
      }
    },
  },
};
</script>
<style scoped>
@import url("https://db.onlinewebfonts.com/c/405f05e6bf58471a25729ed060cda2d8?family=Glacial+Indifference+Regular");

.files-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.files-table th,
.files-table td {
  text-align: left;
  padding: 6px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}

.files-table thead tr {
  border-bottom: 2px solid #ccc;
}

.files-table th {
  background-color: #f8f8f8;
}

.files-table tr:hover {
  background-color: #f5f5f5;
}

.folder-input {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.FilesPageHeader section {
  background-color: #f8f8f8;
  border-radius: 5px;
  text-align: center;
  padding: 3px 14px;
  font-size: 14px;
  margin: 0 20px;
}

.FilesPageHeader section img {
  margin: 0 5px;
  width: 17px;
  height: 17px;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}

.formField {
  margin: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.formField label {
  white-space: nowrap;
  left: 0;
  margin-left: 10%;
  position: absolute;
}

.formField input {
  right: 0;
  margin-right: 10%;
  position: absolute;
  outline: none;
  border: none;
  padding: 1% 6%;
  background-color: #084f5b;
  white-space: nowrap;
  color: #000000;
}

.logoContainer {
  border: 2px dotted #333;
  padding: 10%;
  text-align: center;
  margin: 5%;
  max-width: 86%;
  max-height: 86%;
  min-width: 86%;
  min-height: 86%;
  border-radius: 15px;
}

.dropArea {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.dropArea p {
  display: flex;
  justify-content: center;
  margin: 5% 0;
}

#fileInput {
  display: none;
  margin-top: 5%;
}

label[for="fileInput"] {
  cursor: pointer;
  padding: 2% 20%;
  border-radius: 5px;
  margin-top: 2%;
}

.withImage {
  padding: 5%;
  /* Adjust the padding when an image is present */
  max-width: 100%;
  /* Ensure the image fits within the container */
  max-height: 100px;
  /* Limit the height of the image */
}

.withImage img {
  max-width: 100%;
  /* Ensure the image fits within the container */
  max-height: 100px;
  /* Limit the height of the image */
}

.headerbelowcontent {
  z-index: 999;
  height: 100%;
  width: 100%;
}

.headerbelowcontent::-webkit-scrollbar {
  display: none;
}

.DesignProjectComponent {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  width: 100%;
  height: 100%;
}

.DesignProjectComponent .DesignProjectComponentHeader {
  display: flex;
  align-items: center;
  padding: 0.5% 0;
  position: sticky;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  z-index: 99999;
  top: 0;
  background-color: #ffffff;
}

.DesignProjectComponent .DesignProjectComponentHeader::-webkit-scrollbar {
  display: none;
}

.subHeadingHeader {
  letter-spacing: 1px;
}

#DesignProjectHeaderImage {
  width: 45px;
  height: 45px;
  margin-left: 0.5%;
  display: flex;
}

.DesignProjectHeader {
  display: flex;
  flex-direction: column;
  position: relative;
}

.DesignProjectHeader .DesignProjectHeaderRow1 {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 3%;
  width: 50%;
  margin-bottom: 10px;
}

.DesignProjectHeader .DesignProjectHeaderRow1 input {
  display: inline-block;
  max-width: fit-content;
  padding: 1%;
  border: none;
  outline: none;
  color: black;
  font-size: 18px;
  flex-grow: 1;
  /* Allows the input to grow to fit the content */
  margin-right: 1%;
  /* Adds space between the input and the next icon */
}

.DesignProjectHeader .DesignProjectHeaderRow1 input:hover {
  outline: 1px solid #ccc;
  border-radius: 5px;
}

.DesignProjectHeader .DesignProjectHeaderRow1 img {
  display: inline-block;
  margin: 0 2%;
  width: 10%;
  cursor: pointer;
}

.DesignProjectHeader .DesignProjectHeaderRow2 {
  display: flex;
  align-items: center;
}

.DesignProjectHeader .DesignProjectHeaderRow2 h6 {
  margin: 0 3%;
  white-space: nowrap;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 1px;
}

.inactive-SubHeading {
  font-size: 14px;

  color: #868686;
  font-weight: 500;
}

.ProjectName:hover {
  outline: 1px solid #ccc;
}

.active-SubHeading {
  font-size: 14px;

  text-decoration: underline;
  color: #000000;
  font-weight: 500;
}

.DesignProjectHeaderUsers {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  right: 5%;
}

.DesignProjectHeaderUsers img {
  width: 25px;
  height: 25px;
  align-items: center;
}

#BellImage {
  margin-right: 40%;
}

/* Styling for the modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 98;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.deleteDesignProject {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  top: 30%;
  left: 40%;
  position: fixed;
  width: 35%;
  height: 50%;
  border: 2px solid black;
  letter-spacing: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 5);

  z-index: 99990;
  background-color: whitesmoke;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.deleteDesignProject header img {
  width: 3%;
  top: 0;
  right: 2%;
  margin-top: 1%;
  margin-right: 2%;
  position: absolute;
  cursor: pointer;
}

.deleteDesignProject header {
  display: flex;
  align-items: center;
  padding: 2%;
  border-bottom: 2px solid black;
}

.deleteDesignProject header h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: bolder;
  margin-left: 35%;
}

.DeleteDesignProjectdiv {
  color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.DeleteDesignProjectdiv section {
  margin: 1%;
  padding: 1% 3%;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.DeleteDesignProjectdiv .DeleteDesignProjectsection1 {
  display: flex;
}

.DeleteDesignProjectsection1 span {
  margin-top: 2%;
  color: white;
  background-color: red;
  padding: 1%;
  border-radius: 10px;
}

.DeleteDesignProjectsection2 span {
  margin-top: 2%;
  padding: 1%;
  border-radius: 10px;
}

.DeleteDesignProjectsection2 label span {
  color: #ff4732;
  font-weight: bolder;
}

.correct-project-name label span {
  color: green;
  font-weight: bold;
}

.DeleteDesignProjectsection2 span input {
  padding: 1%;
  color: #000000;
}

.deleteDesignProject footer {
  margin-top: 1%;
  display: flex;
}

.deleteDesignProject footer button {
  background-color: #ffbd59;
  border-radius: 10px;
  font-size: 1rem;
  color: black;
  cursor: pointer;
}

.deleteDesignProject footer button:nth-child(1) {
  padding: 1%;
  margin-left: 30%;
}

.deleteDesignProject footer button:nth-child(2) {
  display: flex;
  margin-left: 20%;
  padding: 1%;
}

.deleteDesignProject footer button:nth-child(1):hover {
  background-color: green;
}

.deleteDesignProject footer button:nth-child(2):hover {
  background-color: red;
}

.SharewithDesignProject {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  top: 30%;
  left: 40%;
  position: fixed;
  width: 35%;
  height: 50%;
  border: 2px solid black;
  letter-spacing: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 5);

  z-index: 99990;
  background-color: whitesmoke;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.SharewithDesignProject header img {
  width: 3%;
  top: 0;
  right: 2%;
  margin-top: 1%;
  margin-right: 2%;
  position: absolute;
  cursor: pointer;
}

.SharewithDesignProject header {
  display: flex;
  align-items: center;
  padding: 2%;
  border-bottom: 2px solid black;
}

.SharewithDesignProject header h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: bolder;
  margin-left: 35%;
}

.sharewithdiv {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 0.5% 4%;
}

.sharewithdiv section {
  width: 94%;
  margin: 1%;
  padding: 1%;
  border: 2px solid #333;
  border-radius: 20px;
  color: #084f5b;
  align-items: center;
}

.AddPeopelInProject {
  display: flex;
  color: hsl(0, 0%, 20%);
  height: 20%;
  align-items: center;
  flex-direction: column;
  width: 88%;
  margin: 1% 5%;
  border: 2px solid #333;
  border-radius: 10px;
}

.AddPeopelInProject section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1% 2%;
}

.AddPeopelInProject section h4 {
  margin-left: 13px;
  white-space: nowrap;
}

.AddPeopelInProject section input {
  padding: 1%;
  position: relative;
}

.EmployeeDrowpDown {
  position: absolute;
  background-color: #396e76;
  margin-top: 15%;
  width: 70%;
  z-index: 1;
  max-height: 100px;
  border-radius: 10px;
  overflow: auto;
}

.EmployeeDrowpDown::-webkit-scrollbar {
  display: none;
}

.EmployeeDrowpDowns li {
  list-style-type: none;
  padding: 8px;
  cursor: pointer;
  display: flex;
  justify-content: left;
}

.EmployeeDrowpDown li:hover {
  background-color: #084f5b;
  color: #ffbd59;
}

.AddPeopelInProject section img {
  width: 3%;
  margin-left: 63%;
  cursor: pointer;
}

.AddPeopelInProject section button {
  margin-left: 1%;
  background-color: blue;
  color: white;
  padding: 1px 2px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
}

.AddPeopelInProject section button:hover {
  color: #ffbd59;
  background-color: #084f5b;
}

.sharewithPeopleHeader {
  display: flex;
  color: #333;
  height: 8%;
  align-items: center;
  width: 90%;
  margin: 0 4%;
}

.sharewithPeopleHeader h3 {
  margin-left: 5%;
  white-space: nowrap;
}

.activeSubPageofShareWith {
  background-color: #396e76;
  color: whitesmoke;
}

.sharewithPeopleHeader button {
  margin-left: 46%;
  color: #333;
  background-color: #ffbd59;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 1%;
  align-items: center;
  white-space: nowrap;
}

.AllEmployeeList {
  display: flex;
  color: #333;
  width: 85%;
  align-items: center;
  margin: 0 3%;
}

.AllEmployeeList ol {
  overflow-y: scroll;
  max-height: 160px;
  color: #333;
  margin: 1% 2%;
  align-items: center;
  text-align: left;
}

.AllEmployeeList ol::-webkit-scrollbar {
  display: none;
}

.AllEmployeeList ol li {
  font-size: 1rem;
  margin: 8px 0;
  cursor: pointer;
  padding: 5px;
  list-style-type: none;
}

.AllEmployeeList ol li:hover {
  background-color: #ccc;
  color: #084f5b;
  border-radius: 5px;
}

.showProjectNameDropDown {
  position: absolute;
  border-radius: 5px;
  background-color: #f5f5f5;
  z-index: 999;
  border: 1px solid #333;
  width: 130px;
  height: 110px;
}

.showProjectNameDropDown ol {
  list-style-type: none;
  padding: 2% 5%;
}

.showProjectNameDropDown ol li {
  font-weight: 900;
  color: #333;
  margin: 5% 2%;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  padding: 1%;
}

.showProjectNameDropDown ol li:hover {
  background-color: #868686;
  color: white;
  border-radius: 5px;
}

/* Firefox scrollbar customization */
.ProjectInfoContent {
  height: calc(100% - 74px);
  width: 100%;
  display: flex;

  overflow: auto;
  position: relative;

  /* Thumb and track colors */
}

.ProjectInfoContentSection {
  display: flex;
  flex-direction: column;
}

.sectionAreaOfProjectInfo1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 10px;
  position: relative;
}

.sectionAreaOfProjectInfo1::-webkit-scrollbar {
  display: none;
}

.sectionAreaOfProjectInfo1 #AddSectionAreaImage {
  align-items: center;
  position: relative;
  width: 20%;
}

.sectionAreaOfProjectInfo1 #AddSectionAreaImage img {
  display: flex;
  align-items: center;
  margin-top: 5%;
  cursor: pointer;
  width: 35px;
  height: 35px;
}

.ProjectInfoContent input {
  background-color: transparent;
  padding: 0.5% 1%;
  border: none;
  outline: none;
  font-size: 14px;
  color: #000000;
}

.sectionAreaOfProjectInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.sectionAreaOfProjectInfo div {
  margin: 10px 10px;
}

.sectionAreaOfProjectInfo textarea {
  height: 350px;
  width: 385px;
  margin-right: 5px;
  padding-left: 5px;
  border: none;
  font-size: 12px;
  border-radius: 0 0 15px 15px;
  resize: none; /* Disable resizing if needed */
}

.sectionAreaOfProjectInfo img {
  cursor: pointer;
}

.DesignProjectFileUpload {
  margin-top: 2%;
  width: 100%;
}

.DesignProjectFileUpload .DesignProjectFileUploadSection {
  margin: 2% 20%;
}

.DesignProjectFileUpload h3 {
  margin-bottom: 10px;
  color: #ffbd59;
}

.DesignProjectFileUpload section div {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: whitesmoke;
  border-radius: 20px;
}

.DesignProjectFileUpload label {
  cursor: pointer;
  background-color: whitesmoke;
}

.DesignProjectFileUpload label img {
  width: 100px;
  margin-bottom: 10px;
}

.DesignProjectFileUpload input[type="file"] {
  display: none;
}

.DesignProjectFileUpload label button {
  margin-top: 2%;
  background-color: #084f5b;
  padding: 2% 5%;
  border-radius: 20px;
  color: whitesmoke;
}

.DesignProjectFileUpload p {
  margin-top: 10px;
  color: black;
}

.DesignProjectUploadedFiles {
  display: flex;
  margin: 1%;
}

.DesignProjectLinePage {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 74px);
}

.DesignProjectTimeLine {
  width: 96%;
  margin: 0 2%;
  border-collapse: collapse;
}

.DesignProjectLinePage section:nth-child(1) {
  overflow: scroll;

  width: 100%;
  height: 80vh;
  z-index: 999;
}

.DesignProjectLinePage section:nth-child(2) {
  height: 90%;
  width: 94%;
  overflow: auto;
  margin-top: 20%;
  background-color: #ffffff;
}

.DesignProjectTimeLine th,
.DesignProjectEstimate td {
  padding: 4px 8px;
  text-align: left;
  font-size: 2;
}

.DesignProjectTimeLine td {
  padding: 4px 8px;
  text-align: left;
  font-size: 14px;
  max-width: 250px;
  overflow: hidden;
  /* Ensures that the overflowed content is hidden */
  text-overflow: ellipsis;
  /* Shows the ellipsis (...) for truncated text */

  cursor: pointer;
  /* Indicates that the text is hoverable */
}

.DesignProjectTimeLine th:nth-child(1),
.DesignProjectEstimate th:nth-child(1) {
  border-radius: 5px 0 0 5px;
}

.DesignProjectTimeLine th:nth-child(6),
.DesignProjectEstimate th:nth-child(7) {
  border-radius: 0px 5px 5px 0px;
}

.DesignProjectTimeLine thead,
.DesignProjectEstimate thead {
  background-color: #ccc;
  border-radius: 10px;
  top: 0;
  position: sticky;
  color: #333;
  font-size: 14px;
}

.DesignProjectTimeLine tbody {
  overflow-y: auto;
  max-height: calc(100vh - 200px);
}

.DesignProjectTimeLine tbody td input {
  padding: 1% 1%;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  text-align: left;
}

.DesignProjectEstimateWrapper {
  max-height: calc(100vh - 150px);
  overflow: scroll;
  width: 100%;
  padding: 1% 2%;
  font-size: 14px;
}

.DesignProjectEstimateWrapper::-webkit-scrollbar {
  display: none;
}
.DesignProjectTimeLineWrapper {
  height: calc(100% - 74px);
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
}
.DesignProjectTimeLineWrapper button {
  border-radius: 5px;
  padding: 0.5% 1%;
  margin: 1% 2%;

  display: block;
}

.DesignProjectEstimate {
  width: 98%;
  margin: 0 1%;
  border-collapse: collapse;
}

.DesignProjectEstimate th,
.DesignProjectEstimate td {
  padding: 4px 8px;
  text-align: left;
  font-size: 12px;
}

.DesignProjectEstimate tbody tr {
  border-bottom: 2px solid #000000;
}

.DesignProjectEstimate thead th {
  background-color: #ccc;

  top: 0;
  position: sticky;
  font-size: 12px;

  color: #000000;
  font-weight: 500;
  margin: 2px;
  text-align: left;
}

.ActionImage img {
  padding: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.ActionImage img:hover {
  background-color: #ccc;
  border-radius: 50%;
}

.DesignProjectEstimate tbody {
  overflow-y: auto;
  max-height: calc(100vh - 200px);
  font-size: 14px;

  color: #000000;
}

.DesignProjectEstimate::-webkit-scrollbar {
  display: none;
}

.DesignProjectEstimate tbody td input {
  padding: 1% 1%;
  width: 100%;

  border: none;
  outline: none;
  color: whitesmoke;
}

.step-wizard {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1%;
}

.step-wizard-list {
  width: 90%;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
  color: #333;
  list-style-type: none;
  border-radius: 10px;
  display: flex;
  padding: 15px 10px;
  position: relative;
  z-index: 10;
}

.step-wizard-item {
  padding: 0 20px;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 170px;
  position: relative;
  cursor: pointer;
}

.step-wizard-item + .step-wizard-item:after {
  content: "";
  position: absolute;
  left: 0;
  top: 19px;
  background: black;
  width: 100%;
  height: 2px;
  transform: translateX(-50%);
  z-index: -10;
}

.progress-count {
  content: "";
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 600;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  color: transparent;
}

.progress-count:after {
  content: "";
  height: 40px;
  width: 40px;
  background: #ffbd59;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -10;
}

.progress-count:before {
  content: "";
  height: 10px;
  width: 20px;
  border-left: 3px solid black;
  border-bottom: 3px solid black;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%) rotate(-45deg);
  transform-origin: center center;
}

.progress-label {
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  color: whitesmoke;
  white-space: nowrap;
}

.current-item .progress-count:before,
.current-item ~ .step-wizard-item .progress-count:before {
  display: none;
}

.current-item ~ .step-wizard-item .progress-count:after {
  height: 10px;
  width: 10px;
}

.current-item ~ .step-wizard-item .progress-label {
  opacity: 0.5;
}

.current-item .progress-count:after {
  background: #ffbd59;
  border: 2px solid black;
}

.current-item .progress-count {
  color: black;
}

.DesignProjectProfileDetails {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
}

.DesignProjectProfileThings {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
}

.DesignProjectProfileThings section {
  width: 49%;
  display: flex;
  flex-direction: column;
  margin: 1%;
}

.DesignProjectProfileThings section span {
  display: flex;
  width: 100%;
  margin: 1%;
}

.DesignProjectProfileThings section span label {
  white-space: nowrap;
  font-size: 1rem;
  flex: 0.4;
  text-align: left;
}

.DesignProjectProfileThings section span input {
  background-color: transparent;
  flex: 0.6;
  font-size: 1rem;
  color: black;
  outline: none;
  border: 2px solid black;
  padding: 0.5% 1%;
}

.activePage {
  color: #000000;
  border-bottom: 2px solid #000000;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.DesignProjectHeaderUsers #plusIconDesignProject {
  width: 1.2%;
  margin-left: 0.1%;
  align-items: center;
  position: absolute;
  vertical-align: center;
  margin-top: 0.5%;
  cursor: pointer;
}

.DesctiptionOfDesignProject {
  border-radius: 10px;
  border: 2px solid #333;
  padding: 0.2%;
}

.DesignProjectProfileDetails .DesignProjectProfileDetailsSections {
  display: flex;
  flex-direction: column;
}

.DesignProjectProfileDetails .DesignProjectProfileDetailsSections h5 {
  display: flex;
  margin: 1% 1%;
  color: #000000;
}

.DesignProjectProfileDetailsSections ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1%;
}

ul li {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2%;
}

ul li .text {
  font-size: 1rem;
  font-weight: 600;

  color: #000000;
  white-space: nowrap;
}

/* Progress Div Css  */

ul li .progress {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #1e2125;
  margin: 14px 0;
  display: grid;
  place-items: center;
  color: #fff;
  position: relative;
  cursor: pointer;
}

.progress::after {
  content: " ";
  position: absolute;
  width: 250px;
  height: 5px;
  background-color: #000000;
  /* right: 30px;*/
  z-index: 1;
}

ul li .progress .uil {
  display: none;
}

ul li .progress p {
  font-size: 13px;
  z-index: 100;
  margin-top: 5px;
}

/* Active Css  */

ul li .active {
  background-color: rgb(11 103 240);
  display: grid;
  place-items: center;
}

li .active::after {
  background-color: rgb(11 103 240);
}

ul li .active p {
  display: none;
}

ul li .active .uil {
  display: flex;
  z-index: 9999;
}

.active-view {
  background-color: #007bff;
  color: white;
}

.DesignProjectTimeLineWrapper .d-flex button {
  margin-right: 10px;
  font-size: 12px;
}

.DesignProjectTimeLineWrapper .d-flex button.active-view {
  background-color: purple;
  color: white;
}

.DesignProjectTimeLine tr td.late {
  background-color: #ffcccc;
}

.DesignProjectTimeLine tr td.on-time {
  background-color: #ccffcc;
}

/* Container to hold all collaborator avatars */
.collaborators-container {
  display: flex;
  align-items: center;
  position: relative;
}

.collaborator-avatar {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: left;
  margin-right: -5px;

  /* Adjust margin to control the overlap */
}

/* Style for initials */
.initials {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #4884f7; /* Blue background color */
  color: #ffffff; /* White text color */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  z-index: 1;
  margin-right: -4px; /* Adjust margin to control the overlap */
  position: relative; /* Ensure z-index works correctly */
  border: 2px solid white;
}
.additional-count {
  width: 15px;
  /* Adjust size as needed */
  height: 15px;
  /* Adjust size as needed */
  border-radius: 50%;
  /* Make the container circular */
  background-color: #b1c5df;
  /* Provide a background color */
  color: #000000;
  /* Set text color */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  /* Adjust font size as needed */
  cursor: pointer;

  z-index: 2;
}
.activity-text {
  display: inline-block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  /* Optional: change cursor on hover to indicate it's clickable */
}

.scrollable-section {
  overflow-x: auto;
  white-space: nowrap;
}

#chart-container {
  display: inline-block;
  min-width: 100%;
  /* Ensure it at least takes up 100% width initially */
  width: 2000px;
  /* Arbitrary large width to ensure scrolling; adjust as needed */
}

#chart {
  width: 100%;
}

a {
  text-decoration: none;
}

.remarks-container {
  position: relative;
}

details {
  position: relative;
}

details summary {
  cursor: pointer;
}

.remarks-content {
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 300px;
  max-width: 400px;

  overflow: scroll;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 8px;
  color: #000000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 10;
  display: none;
}

/* Show remarks content when details is open */
details[open] .remarks-content {
  display: block;
}

.comment-tooltip {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  max-width: 200px;
  height: 100px;
  height: auto;
  z-index: 1000;
  word-break: break-all;
  white-space: pre-wrap;
}

.comment-tooltip p {
  margin: 0;
}

.image-icons-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px; /* Space between images and icons */
  overflow-x: auto;
  padding-right: 5px;
}

.fetched-image {
  height: 20px;
  width: 20px;
  border-radius: 5px;
  margin: 0 2px;
  object-fit: cover; /* Ensures image is contained within its container */
}

.add-icon,
.add-icon-disabled {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.add-icon-disabled:hover {
  cursor: not-allowed;
}
.dropdown-menu ul {
  list-style: none; /* Optional: Remove bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.btn-custom {
  width: fit-content;
  height: 30px;
  font-size: 12px;
  font-weight: bold;
  background-color: inherit;
  color: #0f0e0e;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  border: 1px solid #b1c5df;
  letter-spacing: 1px;
  gap: 8px;
  border-radius: 6px;
}

.btn-custom img {
  width: 18px;
  height: 18px;
}
.btn-custom:hover {
  background-color: purple;
  color: white;
}

/* Responsive Css  */

.small-btn {
  font-size: 12px; /* Smaller font size */
  padding: 6px 8px; /* Reduced padding */
  line-height: 1; /* Tighter spacing */
}
.small-btn img {
  height: 14px; /* Adjust icon size */
  width: 14px; /* Adjust icon size */
}
.project-info-content {
  padding: 5px;
  border-radius: 8px;
}

.section-title {
  font-size: 20px;
  font-weight: 700;
  color: #333;
  margin-bottom: 5px;
  padding-bottom: 5px;
  margin-top: 5px;
}

.section-content {
  padding: 5px 15px;
  word-break: break-word;
  border-bottom: 2px solid #000000;
}

.section-title {
  font-weight: 700;
}

.section-pre {
  margin: 0; /* Optional, adjust spacing between elements */
}

.section-content p:first-child {
  font-weight: 600;
  font-size: 14px;
  color: #000;
  margin-bottom: 5px;
}

.section-content pre {
  all: unset;
  font-size: 13px;
  color: #000;
  font-family: "Inter", sans-serif;
  white-space: pre-wrap;
  word-break: break-word;
  line-height: 1.6;
}

.sign {
  width: 30%;
  border: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
#companylogoInput {
  height: 100px;
  width: fit-content;
  padding: 5px;
  border-radius: 5px;
  outline: none;
  max-width: 100%;
}
.HeaderOfBillItem {
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  margin: 10px auto;
  padding: 5px;
  color: #ffffff;
}

@media (max-width: 767px) {
  .showProjectNameDropDown {
    border-radius: 5px;
    background-color: #f5f5f5;
    z-index: 999;
    border: 1px solid #333;
    width: 130px;
    height: 110px;
  }

  .head .head_1 {
    font-size: 24px;
  }

  .head .head_2 {
    font-size: 16px;
  }

  .DesignProjectHeader .DesignProjectHeaderRow1 input {
    display: flex;
    width: 25%;
    padding: 1%;
    border: none;
    outline: none;
    color: black;
  }

  .DesignProjectHeaderUsers {
    right: 2%;
  }

  .DesignProjectComponent .DesignProjectComponentHeader {
    ul {
      flex-direction: column;
    }

    ul li {
      flex-direction: row;
    }

    ul li .progress {
      margin: 0 30px;
    }

    .progress::after {
      width: 5px;
      height: 20px;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }

    .one::after {
      height: 0;
    }

    ul li .icon {
      margin: 15px 0;
    }

    .DesignProjectProfileThings {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: left;
    }

    .DesignProjectProfileThings section span {
      display: flex;
      width: 100%;
      margin: 2%;
    }
  }

  ul {
    flex-direction: column;
  }

  ul li {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  ul li .text {
    margin-top: 5%;
  }

  ul li:nth-child(1) {
    margin-right: 3%;
  }

  ul li .progress {
    margin: 0 30px;
  }

  .progress::after {
    width: 5px;
    height: 20px;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }

  .one::after {
    height: 0;
  }

  ul li .icon {
    margin: 15px 0;
  }

  .DesignProjectProfileThings {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: left;
  }

  .DesignProjectProfileThings section span label {
    white-space: nowrap;
    font-size: 1rem;
  }

  .DesignProjectProfileThings section span input {
    background-color: transparent;
    font-size: 1rem;
    color: white;
    outline: none;
    border: 2px solid black;
    padding: 0.5% 1%;
  }
  .project-info-content {
    box-shadow: none; /* Remove shadows for clean print */
    background-color: transparent; /* Set background to white for PDF */
  }

  /* Prevent content from splitting across pages */
  .project-info-content,
  .section,
  .section-content {
    page-break-inside: avoid;
    page-break-before: auto;
    page-break-after: auto;
  }
}
</style>
