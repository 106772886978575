<template>
  <MailSentModal :SalesOrderId="routeParams.SalesOrderId" />
  <Header />
  <div class="content-Container">
    <Nav />
    <div class="containerChild" style="background-color: #f4f4f4">
      <div class="FinalEstimate border-black">
        <div class="row align-items-center FinalEstimateHeader m-3 p-1">
          <div class="col-8">
            <h5 style="font-weight: bold">Sales Order</h5>
          </div>
          <div class="col-4 p-1 d-flex justify-content-end">
            <img
              v-if="isApproved || fetchedSalesOrderData.status === 'Sent'"
              src="../../../assets/icons/sentMailGreenIcon.png"
              alt=""
              data-toggle="tooltip"
              data-placement="bottom"
              title="Sent"
              data-bs-toggle="modal"
              data-bs-target="#emailModal"
            />
            <img
              v-else
              src="../../../assets/icons/sentMail.png"
              alt=""
              data-toggle="tooltip"
              data-placement="bottom"
              title="Send"
              @click="sendSalesOrder"
              data-bs-toggle="modal"
              data-bs-target="#emailModal"
            />
            <img
              src="../../../assets/icons/print.png"
              alt=""
              data-toggle="tooltip"
              data-placement="bottom"
              title="Print"
              @click="generatePDF"
            />
            <img
              src="../../../assets/icons/cancel.png"
              alt=""
              @click="goBack"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Close"
            />
          </div>
        </div>
        <div class="EstimateContent mb-2">
          <header class="salesOrderHeaderThings">
            <div
              class="col"
              style="
                font-size: 32px;
                color: #000000;
                width: 30%;
                text-align: left;
                font-weight: bold;
              "
            >
              Sales Order
            </div>
            <div class="col">
              <div style="font-weight: bold">
                <span>Date</span><br />
                <span>{{ fetchedSalesOrderData.salesOrderDate }}</span>
              </div>
            </div>
            <div class="col">
              <div style="font-weight: bold">
                <span>Sales Order No.</span> <br />
                <span>{{ fetchedSalesOrderData.salesOrderNumber }}</span>
              </div>
            </div>
            <div class="col">
              <div style="font-weight: bold">
                <span>Customer No.</span> <br />
                <span>{{ fetchedSalesOrderData["customers.customerNumber"] }}</span>
              </div>
            </div>
          </header>

          <div class="salesOrderHeaderDetails">
            <section class="salesOrderIssuedBy">
              <h6 class="text-nowrap">Issued By</h6>
              <span class="bodyText">
                <p style="font-weight: 800">
                  {{ fetchedSalesOrderData["company.companyName"] }}
                </p>
                <p>{{ fetchedSalesOrderData["company.address"] }}</p>
                <p>
                  {{ fetchedSalesOrderData["company.city"] }}
                  {{ fetchedSalesOrderData["company.state"] }}
                </p>
                <p>{{ fetchedSalesOrderData["company.contactNumber"] }}</p>
                <p>{{ fetchedSalesOrderData["company.companyEmail"] }}</p>
              </span>
            </section>
            <section class="salesOrderBillTo">
              <h6 class="text-nowrap">Bill To</h6>
              <span class="bodyText">
                <p style="font-weight: 800">
                  {{ fetchedSalesOrderData["billingAddress.name"] }}
                </p>
                <p>{{ fetchedSalesOrderData["billingAddress.address"] }}</p>
                <p>
                  {{ fetchedSalesOrderData["billingAddress.city"] }}
                  {{ fetchedSalesOrderData["billingAddress.state"] }}
                </p>

                <p>{{ fetchedSalesOrderData["billingAddress.contactNo"] }}</p>
                <p>{{ fetchedSalesOrderData["billingAddress.email"] }}</p>
              </span>
            </section>
            <section class="salesOrderShipTo">
              <h6 class="text-nowrap">Ship To</h6>
              <span class="bodyText">
                <p style="font-weight: 800">
                  {{ fetchedSalesOrderData["shippingAddress.name"] }}
                </p>
                <p>{{ fetchedSalesOrderData["shippingAddress.address"] }}</p>
                <p>
                  {{ fetchedSalesOrderData["shippingAddress.city"] }}
                  {{ fetchedSalesOrderData["shippingAddress.state"] }}
                </p>
                <p>{{ fetchedSalesOrderData["shippingAddress.contactNo"] }}</p>
                <p>{{ fetchedSalesOrderData["shippingAddress.email"] }}</p>
              </span>
            </section>
          </div>
          <div class="EstimateBill">
            <table class="tableOfEstimate table">
              <caption class="d-none">
                Table
              </caption>
              <thead class="">
                <tr class="p-1">
                  <th>SL.No</th>
                  <th>Description</th>

                  <th>Qty</th>
                  <th>Unit</th>
                  <th>Rate</th>
                  <th>Discount</th>
                  <th>GST</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <div v-for="(item, index) in items" :key="index" class="itemBlock">
                  <span class="ItemHeaderOfThings">
                    <input
                      type="text"
                      v-model="item.header"
                      placeholder="Header Name"
                      id="HeaderOfBillItem"
                      readOnly
                    />

                    <p class="p-0 m-0">
                      {{ $formatToIndianCurrency(item.header) }}
                    </p>
                  </span>

                  <tr
                    v-for="(row, rowIndex) in item.rows"
                    :key="rowIndex"
                    id="itemRowEstimate"
                  >
                    <td>
                      {{ rowIndex + 1 }}
                    </td>
                    <td>
                      <pre class="EstimateDescription">
                        {{ row.description }}
                      </pre>
                    </td>
                    <td>
                      <input
                        type="number"
                        @input="row.quantity = calculateQuantity(row)"
                        v-model="row.quantity"
                        readOnly
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        v-model="row.unitType"
                        class="text-center"
                        readOnly
                      />
                    </td>

                    <td>
                      <input
                        type="number"
                        inputmode="numeric"
                        v-model="row.unitPrice"
                        readOnly
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        inputmode="numeric"
                        v-model="row.discount"
                        readOnly
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        inputmode="numeric"
                        v-model="row.GSTpercentage"
                        readOnly
                      />
                    </td>
                    <td>
                      {{ $formatToIndianCurrency(row.amount) }}
                    </td>
                  </tr>
                </div>
              </tbody>
            </table>
          </div>
          <div class="col-md-12 mt-2">
            <div
              class="container p-2 text-nowrap"
              style="border-radius: 5px; font-weight: bold; width: 99%; margin: 0.5%"
            >
              <div class="row">
                <div class="col-6">
                  <p style="font-weight: bold; margin-bottom: 0">
                    Total Amount
                    <span style="font-size: 14px">(Excluding GST)</span>
                  </p>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <p class="text-right">
                    {{ $formatToIndianCurrency(fetchedSalesOrderData.total) }}
                  </p>
                </div>
              </div>
              <div v-for="(detail, index) in GSTgrouping" :key="index" style="padding: 0">
                <div class="row">
                  <div class="col-6 d-flex flex-column justify-content-end">
                    <span> CGST ({{ detail.gst / 2 }}%):</span>
                    <span> SGST ({{ detail.gst / 2 }}%):</span>
                  </div>
                  <div
                    class="col-6"
                    style="
                      display: flex;
                      flex-direction: column;
                      justify-content: flex-end;

                      text-align: right;
                    "
                  >
                    <span class="text-right">
                      {{ $formatToIndianCurrency((detail.amount / 2).toFixed(2)) }}</span
                    >
                    <span class="text-right">{{
                      $formatToIndianCurrency((detail.amount / 2).toFixed(2))
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 d-flex">
                  <p style="font-weight: bolder; border-top: 2px solid #000">
                    Grand Total
                    <span style="font-size: 14px">(Including GST)</span>
                  </p>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <p
                    class="text-right"
                    style="font-weight: bolder; border-top: 2px solid #000"
                  >
                    {{ $formatToIndianCurrency(this.fetchedSalesOrderData.grandTotal) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row m-2 border-black salesOrderFooterDetails">
            <div class="col-6">
              <span class="inputWithImage">
                <img
                  :src="companyLogo.signedUrl"
                  alt="Company Logo"
                  id="companylogoInput"
                />
              </span>
            </div>

            <div
              class="col-6 text-end d-flex flex-column justify-content-start SalesPersonDetail"
            >
              <p>For billing inquiries:</p>
              <span
                >{{ fetchedSalesOrderData["employees.firstName"] }}
                {{ fetchedSalesOrderData["employees.lastName"] }}</span
              >
              <!-- Display last name as 'empname' -->
              <span>{{ fetchedSalesOrderData["employees.employeeEmailId"] }}</span>
              <!-- Display email as 'username' -->
              <span>{{ fetchedSalesOrderData["employees.mobileNumber"] }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MailSentModal from "@/components/Modal/MailSentModal.vue";
import Header from "../../Header.vue";
import Nav from "../../Nav.vue";
import axios from "axios";
export default {
  name: "CreateSalesOrder",
  components: {
    Header,
    Nav,
    MailSentModal,
  },
  data() {
    return {
      companyLogo: {},
      GSTgrouping: [],
      GSTOptions: [5, 12, 18, 28],
      estimateId: "",
      fetchedSalesOrderData: {},
      routeParams: {
        SalesOrderNumber: this.$route.params.SalesOrderNumber || "",
        SalesOrderId: this.$route.params.SalesOrderId || "",
        date: "",
      },
      items: [],
    };
  },
  computed: {
    // Computed property to determine if the sales order is approved
    isApproved() {
      if (this.fetchedSalesOrderData?.status) {
        return this.fetchedSalesOrderData.status === "Approved";
      }
      this.fetchSalesData();
      return false;
    },
  },
  mounted() {
    this.routeParams.SalesOrderId = this.$route.params.SalesOrderId || "";
    this.fetchSalesData();
  },
  methods: {
    generatePDF() {
      this.$router.push({
        name: "PrintEstimate",
        params: { salesOrderIndex: this.$route.params.SalesOrderId },
      });
    },
    async sendSalesOrder() {
      try {
        await axios.put(
          `${this.$apiEndPoint}/salesOrder/status/${this.routeParams.SalesOrderId}`,
          {
            status: "Sent",
          }
        );
        await this.fetchSalesData();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async toggleApprovalStatus() {
      try {
        if (this.isApproved) {
          await axios.put(
            `${this.$apiEndPoint}/salesOrder/status/${this.routeParams.SalesOrderId}`,
            {
              status: "sent",
            }
          );
        } else {
          await axios.put(
            `${this.$apiEndPoint}/salesOrder/status/${this.routeParams.SalesOrderId}`,
            {
              status: "Approved",
            }
          );
        }
        await this.fetchSalesData();
      } catch (error) {
        this.$handleError(error);
      }
    },
    goBack() {
      this.$router.go(-1); // Navigate back one step in the history stack
    },

    async fetchSalesData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/salesOrder/${this.routeParams.SalesOrderId} `
        );
        this.fetchedSalesOrderData = response.data;
        this.GSTgrouping = response.data.GSTgrouping;
        this.companyLogo = response.data.companyLogo;
        if (this.fetchedSalesOrderData?.lineItems) {
          this.items = this.fetchedSalesOrderData.lineItems.map((item) => ({
            header: item.header,
            rows: item.Rows.map((row) => ({
              ...row,
              GSTpercentage: row.GSTpercentage, // Assign GSTpercentage directly from response
            })),
          }));
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    // calculateTotalAmountofItem(item) {
    //   let totalAmount = 0;
    //   item.rows.forEach((row) => {
    //     totalAmount += parseFloat(row.amount || 0);
    //   });
    //   return totalAmount.toFixed(2);
    // },
  },
};
</script>
<style scoped>
.salesOrderHeaderThings {
  display: flex;
  border-bottom: 5px solid #000000;
  align-items: center;
  justify-content: space-between;
  padding: 0.5% 1%;
  margin: 0 1%;
}

.salesOrderHeaderThings div:nth-child(2),
.salesOrderHeaderThings div:nth-child(3),
.salesOrderHeaderThings div:nth-child(4) {
  width: 20%;
  font-size: 1rem;
  color: #000000;
  font-weight: 400;
}

.salesOrderHeaderDetails {
  display: flex;
  align-items: center;
  border-radius: 20px;
  margin: 0.2%;
  padding: 1% 5%;
  height: 25%;
  width: 100%;
  flex-direction: row;
  overflow-x: scroll;
}
.salesOrderHeaderDetails::-webkit-scrollbar {
  display: none;
}
.salesOrderHeaderDetails section {
  width: 30%;
  margin: 0% 5%;
  min-height: 150px;
  max-height: auto;
}

.salesOrderHeaderDetails section h6 {
  color: #000000;
  text-align: left;

  font-size: 18px;
  white-space: nowrap;
  font-weight: bold;
  display: flex;
  align-items: flex-start;
}
.salesOrderHeaderDetails section span {
  text-align: left;
  font-size: 16px;
  color: #000000;

  margin: 0;
  padding: 0;
}
.salesOrderHeaderDetails section span p {
  margin: 0;
  padding: 0;
}

.salesOrderFooterDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  margin: 0.2%;
  height: 25%;
  width: 100%;
}
.SalesPersonDetail {
  color: #000000;
  font-weight: bold;
}

.FinalEstimateHeader img {
  width: 4%; /* To make images responsive */
  margin: 0 2%; /* Adjust margin as needed */
  aspect-ratio: 1/1; /* Maintain aspect ratio */
  object-fit: contain;
  cursor: pointer;
}

.FinalEstimateHeader label,
.FinalEstimateHeader p {
  font-size: 14px;
  font-weight: bold;
}

.active {
  text-decoration: underline;
}

.FinalEstimateHeader {
  padding: 0 5%;
  border-radius: 5px;
  background-color: #bcbfc6;
}

.glass-effect {
  position: relative;
}

.glass-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.EstimateContent {
  margin: 0 10%;
  padding: 3% 2%;
  background-color: #ffffff;
}

.EstimateBill::-webkit-scrollbar {
  display: none;
}

.tableOfEstimate thead tr .tableOfEstimate thead tr th {
  color: #000000;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f8f8f8;
  text-align: center;
}

.tableOfEstimate tbody {
  width: 100%;
}

.tableOfEstimate tbody::-webkit-scrollbar {
  display: none;
}

.tableOfEstimate tbody tr,
.tableOfEstimate thead tr {
  display: flex;
}

.tableOfEstimate thead th,
.tableOfEstimate tbody tr td {
  min-width: 9%;
  max-width: 9%;
  text-align: center;
}

.tableOfEstimate thead th:nth-child(1),
.tableOfEstimate tbody tr td:nth-child(1) {
  min-width: 60px;
  max-width: 60px;
}

.tableOfEstimate tbody tr td input[type="number"] {
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  text-align: right;
}

.tableOfEstimate tbody tr td input[type="text"] {
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: left;
}

.tableOfEstimate tbody td:nth-child(2),
.tableOfEstimate thead th:nth-child(2) {
  min-width: 39.5%;
  max-width: 39.5%;
  word-break: break-all;
}

.itemBlock {
  background-color: transparent;
  display: flex;
  flex-direction: column;
}

.ItemHeaderOfThings {
  display: flex;
  align-items: center;
  white-space: nowrap;
  background-color: #ccc;
  height: 30px;
  width: 100%;
  font-size: 16px;
  font-weight: bolder;
  padding: 2px !important;
}

.ItemHeaderOfThings p {
  position: relative;
  margin-top: 5px;
  height: 20px;
  color: #000000;
  font-size: 16px;
  background-color: #ccc;
  font-weight: bolder;
}

.itemBlock::-webkit-scrollbar {
  display: none;
}

.itemBlock span button {
  background: transparent;
  display: flex;
  padding: 0.5%;
  border-radius: 10px;
  color: #000000;
  outline: none;
  border: none;
}

#itemRowEstimate {
  border: 1px solid #edeae9;
}

.itemBlock #HeaderOfBillItem {
  background-color: transparent;
  width: 100%;
  margin-left: 45%;
  border: none;
  outline: none;
  color: #000000;
  font-size: 16px;
  font-weight: bolder;
  text-align: left;
}

#itemAdding {
  display: flex;
  background-color: #084f5b;
  margin-left: 1%;
  padding: 0.5% 1%;
  border-radius: 10px;
  color: whitesmoke;
  cursor: pointer;
  border: 1px solid #ffaa23;
}

#itemrowAdding {
  display: flex;
  color: #000000;
  margin-left: 1%;
  padding: 0.5% 1%;
  border-radius: 10px;
  white-space: nowrap;
  cursor: pointer;
  width: 10%;
  background-color: transparent;
  border: none;
  outline: none;
}

.toggleWidththings,
.toggleHeightthings {
  position: fixed;
  background-color: #ffffff;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 10px 20px;
  letter-spacing: 2px;
}

.toggleWidththings p,
.toggleHeightthings p {
  padding: 4px 8px;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: #000000;
  font-weight: 100;
}

/* Responsive table styles */
.tableOfEstimate {
  width: 100%;
  border-collapse: collapse;
}

.tableOfEstimate tbody tr {
  padding: 4px !important;
}

.subHeaderHeadingBgColor {
  background-color: #f0f0f0;
}

.ItemHeaderOfThings input {
  flex: 1;
  text-align: center;
}

.dropdownToggle {
  cursor: pointer;
}

.EstimateBill {
  width: 100%;
  font-size: 14px;
  margin-top: 20px;
}

/* Responsive table container */
.table-responsive {
  overflow-x: auto;
}

button {
  margin-top: 10px;
}

.container {
  font-size: 14px;
}
#companylogoInput {
  height: 100px;
  width: fit-content;
  border: 1px solid #ccc;
  border-radius: 10px;
  outline: none;
  max-width: 300px;
}

.EstimateDescription {
  color: #000000;
  font-family: "Inter", sans-serif;
  word-break: break-all;
  white-space: wrap;
  text-align: left;
}
/* Styles for responsiveness */
@media only screen and (max-width: 768px) {
  .FinalEstimate {
    width: 100%;
    overflow-x: scroll;
  }
  .FinalEstimateHeader {
    overflow: scroll;
  }
  .FinalEstimateHeader img {
    width: 20%; /* To make images responsive */
    margin: 0 5%; /* Adjust margin as needed */
    aspect-ratio: 1/1; /* Maintain aspect ratio */
    object-fit: contain;
  }

  .tableOfEstimate tbody td,
  .tableOfEstimate thead th {
    white-space: nowrap;
    min-width: 120px;
    position: relative;
  }

  .tableOfEstimate tbody tr,
  .tableOfEstimate thead tr {
    display: flex;
    overflow: scroll;
  }

  .footerOfEstimate {
    margin-bottom: 50px;
  }
  .salesOrderHeaderThings {
    overflow: scroll;
  }
  .salesOrderHeaderThings span {
    margin: 0 15%;
    white-space: nowrap;
  }

  #companylogoInput {
    width: fit-content;
    height: 100px;
    border: 1px solid #ccc;
    border-radius: 10px;
    outline: none;
  }
  .salesOrderFooterDetails {
    display: flex;
    justify-content: left;
    border-radius: 20px;
    white-space: nowrap;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 10%;
  }
  .salesOrderFooterDetails :nth-child(2) {
    margin-top: 5%;
    text-align: left;
  }
  .salesOrderHeaderDetails section {
    width: 90%;
  }
  .EstimateBill {
    overflow: scroll;
  }

  /* Responsive table container */
  .table-responsive {
    overflow-x: auto;
  }
}
</style>
