<template>
  <!-- Modal Structure -->
  <div
    class="modal fade"
    id="DesignInfoBoxModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="DesignInfoBoxModal"
    aria-hidden="true"
  >
    <div class="modal-dialog custom-modal-width modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-end">
          <div>
            <button
              type="button"
              class="btn btn-secondary btn-sm"
              @click="triggerFileInput"
              style="margin-right: 20px"
            >
              Upload Photo
            </button>
            <input
              type="file"
              ref="fileInput"
              style="display: none"
              @change="handlePhotoUpload"
              multiple
              accept="image/*"
            />
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div class="modal-body d-flex justify-content-around gap-3">
          <!-- Left Section -->
          <div class="left-section ms-1 p-1" style="width: 45%">
            <input
              v-model="projectData.title"
              type="text"
              class="form-control text-decoration-underline mb-3"
            />
            <textarea
              v-model="projectData.text"
              class="form-control"
              rows="18"
            ></textarea>
          </div>
          <!-- Right Section -->
          <div class="right-section" style="width: 55%">
            <div v-if="projectData.getImages.length > 0">
              <div
                id="carouselExampleControls"
                class="carousel slide"
                style="display: flex; align-items: center"
              >
                <!-- data-bs-ride="carousel" -->
                <img
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev"
                  class="justify-content-center position-sticky mt-1"
                  src="../../assets/icons/blackLeftarrow.png"
                  alt="Prev"
                  @click="changeImage(currentImage - 1)"
                  style="height: 30px; width: 12px; cursor: pointer"
                />
                <div class="carousel-inner" style="margin: 0 5px">
                  <div
                    v-for="(image, index) in projectData.getImages"
                    :key="image.id"
                    :class="['carousel-item', { active: index === currentImage }]"
                  >
                    <img
                      :src="image.sectionPhotoUrl"
                      class="d-block w-100"
                      :alt="'Image ' + (index + 1)"
                      style="height: 430px; border-radius: 15px; object-fit: contain"
                    />
                    <span
                      style="
                        object-fit: cover;
                        height: 30px;
                        width: 30px;
                        position: absolute;
                        bottom: 0px;
                        right: 15px;
                        cursor: pointer;
                        border-radius: 50%;
                        background-color: #ffffff;
                      "
                    >
                      <img
                        class="justify-content-center ms-1 mt-1"
                        src="../../assets/icons/redDelete.png"
                        alt="Delete"
                        @click="deleteImage(image.id, index)"
                        style="
                          object-fit: cover;
                          height: 20px;
                          width: 20px;
                          position: absolute;

                          cursor: pointer;
                        "
                      />
                    </span>
                  </div>
                </div>
                <img
                  @click="changeImage(currentImage + 1)"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="next"
                  class="justify-content-center mt-1"
                  src="../../assets/icons/blackRightarrow.png"
                  alt="Next"
                  style="height: 30px; width: 12px; cursor: pointer"
                />
              </div>
              <!-- Carousel Indicators (Dots) -->
              <div class="carousel-indicators">
                <button
                  v-for="(image, index) in projectData.getImages"
                  :key="image.id"
                  :data-bs-target="'#carouselExampleControls'"
                  :data-bs-slide-to="index"
                  :class="{ active: index === currentImage }"
                  :aria-label="'Slide ' + (index + 1)"
                  :aria-current="index === currentImage ? 'true' : 'false'"
                  class="dot-indicator"
                  @click="changeImage(index)"
                ></button>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer d-flex justify-content-center">
          <button
            type="button"
            @click="deleteBoxArea"
            class="btn btn-sm btn-danger me-2"
            data-bs-dismiss="modal"
          >
            Delete
          </button>
          <button
            type="button"
            @click="updateBoxArea"
            class="btn btn-sm btn-primary"
            data-bs-dismiss="modal"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DesignProjectInfoModal",
  props: {
    ProjectInfoBoxId: {
      type: String,
      required: true,
    },
    currentImageId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentImage: 0,
      attachedPhoto: [],
      attachedPhotoIds: "",
      projectData: {
        title: "",
        text: "",
        getImages: [],
      },
    };
  },
  mounted() {
    this.projectInfoBoxId = this.ProjectInfoBoxId;
    this.CurrentImageId = this.currentImageId;
  },
  watch: {
    ProjectInfoBoxId(newValue) {
      if (newValue !== null) {
        this.fetchSectionImages();
      } else {
        this.$handleError("ProjectInfoBoxId is null");
      }
    },
    currentImageId(newValue) {
      if (newValue !== null) {
        this.fetchSectionImages();
      } else {
        this.$handleError("currentImageId is null");
      }
    },
  },
  methods: {
    changeImage(newIndex) {
      // If newIndex exceeds the array length, reset to 0 (first image)
      if (newIndex >= this.projectData.getImages.length) {
        this.currentImage = 0; // loop back to the first image
      }
      // If newIndex is less than 0, set it to the last image
      else if (newIndex < 0) {
        this.currentImage = this.projectData.getImages.length - 1; // loop to the last image
      }
      // Otherwise, set it to the newIndex
      else {
        this.currentImage = newIndex;
      }
    },
    async deleteBoxArea() {
      // Use JavaScript confirm to ask for user confirmation
      const confirmed = confirm("Are you sure you want to delete this box area?");

      if (confirmed) {
        try {
          // Call the delete API
          await axios.delete(
            `${this.$apiEndPoint}/projectInfoBoxArea/deleteArea/${this.ProjectInfoBoxId}`
          );
          alert("Box area deleted successfully.");

          this.$emit("fetchWholeSectionData");
        } catch (error) {
          console.error("Error deleting box area:", error);
          alert("Failed to delete the box area. Please try again.");
        }
      } else {
        // If the user cancels, log or handle accordingly
        console.log("User canceled the delete action.");
      }
    },
    async deleteImage(imageId, index) {
      const userConfirmed = window.confirm("Are you sure you want to delete?");
      if (!userConfirmed) return;
      this.projectData.getImages.splice(index, 1);
      try {
        await axios.delete(`${this.$apiEndPoint}/projectInfoBoxImages/${imageId}`);
        this.fetchSectionImages();
      } catch (error) {
        this.$handleError(error);
      }
      this.fetchSectionImages();
    },
    async updateBoxArea() {
      try {
        await axios.put(
          `${this.$apiEndPoint}/projectInfoBoxArea/${this.ProjectInfoBoxId}`,
          {
            text: this.projectData.text,
            title: this.projectData.title,
          }
        );

        this.$emit("fetchSectionData");
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchSectionImages() {
      // this.projectData.getImages = [];
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/projectInfoBoxArea/${this.ProjectInfoBoxId}`
        );
        this.projectData = response.data;
        // Fetch image URLs for each image in the array
        await Promise.all(
          this.projectData.getImages.map(async (image) => {
            image.sectionPhotoUrl = await this.fetchInfoImage(
              image["awsFiles.awsFileName"],
              image["awsFiles.fileName"]
            );
          })
        );
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchInfoImage(AwsFileName, FileName) {
      const PayLoad = {
        awsFileName: AwsFileName,
        fileName: FileName,
        fileCategory: "projectInfoBox",
        moduleName: "design",
      };

      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/company/getFileSignedURL/`,
          PayLoad
        );
        return Object.values(response.data)[0];
      } catch (error) {
        this.$handleError(error);
        return null;
      }
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handlePhotoUpload(event) {
      const files = event.target.files;
      this.$globalState.isLoading = true;
      for (const file of files) {
        const payload = {
          fileName: file.name,
          fileSize: file.size,
          contentType: file.type,
          moduleName: "design",
          fileCategory: "projectInfoBox",
        };

        axios
          .post(`${this.$apiEndPoint}/company/putFileSignedURL`, payload)
          .then((response) => {
            const signedUrl = response.data.s3URL;
            const fileId = response.data.id;
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUrl, true);
            xhr.setRequestHeader("Content-Type", file.type);

            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4 && xhr.status === 200) {
                this.SendPhotoToInfoBoxArea(this.ProjectInfoBoxId, fileId);
                this.$globalState.isLoading = false; // Hide spinner
              } else if (xhr.readyState === 4) {
                this.$handleError("Error uploading file to S3:", xhr.responseText);
              }
            };

            xhr.send(file);
          })
          .catch((error) => {
            this.$globalState.isLoading = false; // Hide spinner
            this.$handleError(error);
          });
      }
    },
    async SendPhotoToInfoBoxArea(InfoBoxId, fileIds) {
      try {
        const requestBody = {
          infoBoxAreaId: InfoBoxId,
          awsFileId: fileIds,
        };
        await axios.post(`${this.$apiEndPoint}/projectInfoBoxImages`, requestBody);

        this.fetchSectionImages();
      } catch (error) {
        this.$handleError(error);
        this.$globalState.isLoading = false; // Hide spinner
      }
    },
  },
};
</script>

<style scoped>
/* Modal alignment and overall styling */
.custom-modal-width {
  height: 60%;
  background-color: #ffffff;
  max-width: 70%;
  min-width: 70%;
  margin: auto; /* Align modal in the center of the page */
  top: 7%;
  transform: translateY(-50%); /* Vertically center the modal */
  border-radius: 10px;
  position: fixed;
  left: 0;
  right: 0;
  border: 2px solid #000000; /* Add a border to the modal */
  overflow: hidden; /* Hide any overflow to make the modal cleaner */
}

/* Modal header, body, and footer adjustments */
.modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.modal-body {
  flex-grow: 1;
  overflow-y: auto; /* Only the modal body will scroll */
  padding: 20px;
}

.modal-header {
  border-bottom: 1px solid #ddd; /* Add a separator line */
}

.modal-footer {
  border-top: 1px solid #ddd; /* Add a separator line */
}

/* Left and right section styling */
.left-section,
.right-section {
  border: 2px solid #000000; /* Add border to both sections */
  border-radius: 10px; /* Soft rounded corners */
  padding: 10px;
}

.left-section {
  background-color: #f8f9fa; /* Light background for the left section */
}

.right-section {
  background-color: #ffffff; /* Keep the right section background white */
}

/* Text inputs and textarea styling */
.left-section input,
.left-section textarea {
  width: 100%;
  border: 1px solid #ddd; /* Add a subtle border */
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
}

.left-section input {
  text-decoration: underline;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
}

/* Carousel indicators */
.carousel-indicators {
  position: relative;
  top: 20px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.carousel-indicators .dot-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
}

.carousel-indicators .dot-indicator.active {
  transform: scale(1.2); /* Make the active dot larger */
  transition: transform 0.3s ease;
}

/* Remove scrollbar appearance */
.modal::-webkit-scrollbar {
  display: none;
}

/* Focus styles for inputs and textareas */
input:focus,
textarea:focus,
select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}
</style>
