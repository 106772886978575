<template>
  <div>
    <!-- Header Section -->
    <Header />
    <div class="content-container">
      <Nav />
      <div class="containerChild">
        <div class="ticket-header">
          <h5>Create Ticket</h5>
          <button
            type="button"
            class="btn-close"
            @click="closePage"
            aria-label="Close"
          ></button>
        </div>

        <!-- Ticket Form -->
        <div class="ticket-form">
          <!-- Issue Title -->
          <div class="form-group">
            <label for="issue-title">Issue Title <span class="mandatory">*</span></label>
            <input
              type="text"
              id="issue-title"
              v-model="form.issueTitle"
              placeholder="Enter issue title"
              class="form-control"
            />
          </div>

          <!-- Issue Description -->
          <div class="form-group">
            <label for="issue-description"
              >Issue Description <span class="mandatory">*</span></label
            >
            <textarea
              id="issue-description"
              v-model="form.issueDescription"
              placeholder="Enter issue description"
              class="form-control"
              rows="4"
            ></textarea>
          </div>

          <section class="row gap-3">
            <!-- Department Name -->
            <div class="col-md-4 form-group">
              <label for="department"
                >Department Name <span class="mandatory">*</span></label
              >
              <select
                id="department"
                v-model="form.moduleName"
                class="form-control form-select"
              >
                <option value="" disabled>Select department</option>
                <option
                  v-for="role in $LocalUser.employeeRoles"
                  :key="role.team"
                  :value="role.team"
                >
                  {{ role.team }}
                </option>
              </select>
            </div>

            <!-- Issue Date -->
            <div class="col-md-3 form-group">
              <label for="issue-date">Issue Date <span class="mandatory">*</span></label>
              <input
                type="date"
                id="issue-date"
                v-model="form.raisedDate"
                class="form-control"
              />
            </div>

            <!-- Severity -->
            <div class="form-group col-md-4">
              <label for="severity">Severity <span class="mandatory">*</span></label>
              <select
                id="severity"
                v-model="form.priority"
                class="form-control form-select"
              >
                <option value="" disabled>Select severity</option>
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
                <option value="Critical">Critical</option>
              </select>
            </div>
          </section>

          <!-- Attachment -->
          <div class="form-group mt-2 attatchmentlabel d-flex align-items-center">
            <label for="attachment" class="me-3">Attachment</label>
            <div class="attachment-input d-flex align-items-center">
              <input
                type="file"
                id="attachment"
                @change="handleFileUpload"
                class="form-control"
                style="display: none"
                multiple
              />
              <div
                class="add-files-btn d-flex justify-content-center align-items-center"
                @click="addMoreFiles"
                title="Add files"
              >
                +
              </div>
            </div>
          </div>

          <!-- Uploaded Files List -->
          <div class="uploaded-files mt-3" v-if="uploadedFiles.length">
            <h6>Uploaded Files:</h6>
            <ul>
              <li
                v-for="(file, index) in uploadedFiles"
                :key="index"
                class="uploaded-file"
              >
                <span>{{ file.name }}</span>
                <button
                  class="remove-btn"
                  @click="removeFile(index)"
                  title="Remove this file"
                >
                  ❌
                </button>
              </li>
            </ul>
          </div>

          <!-- Buttons -->
        </div>
        <div class="form-actions text-end">
          <button class="btn btn-secondary btn-sm me-2" @click="closePage">Cancel</button>
          <button type="button" class="btn btn-primary btn-sm" @click="submitTicket">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import axios from "axios";

export default {
  name: "CreateTicket",

  components: {
    Header,
    Nav,
  },
  data() {
    return {
      form: {
        issueTitle: "",
        issueDescription: "",
        moduleName: "",
        raisedDate: "",
        priority: "",
      },
      uploadedFiles: [],
      uploadedFileIds: [],
    };
  },
  methods: {
    closePage() {
      this.$router.go(-1);
    },
    handleFileUpload(event) {
      const files = Array.from(event.target.files);
      this.uploadedFiles.push(...files);

      files.forEach((file) => {
        const contentType = file.type;
        const payload = {
          fileName: file.name,
          fileSize: file.size,
          contentType,
          moduleName: "customerSupportTicket",
          fileCategory: "customerSupportTicketAttachment",
        };

        axios
          .post(`${this.$apiEndPoint}/company/putFileSignedURL`, payload)
          .then((response) => {
            const signedUrl = response.data.s3URL;
            const fileId = response.data.id;

            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUrl, true);
            xhr.setRequestHeader("Content-Type", contentType);

            xhr.onload = () => {
              if (xhr.status === 200) {
                this.uploadedFileIds.push(fileId);
                this.$toast("File uploaded successfully", "success");
              } else {
                this.$toast("Error uploading file", "error");
              }
            };

            xhr.onerror = () => {
              this.$toast("Error uploading file", "error");
            };

            xhr.send(file);
          })
          .catch((error) => {
            this.$handleError(error);
          });
      });
    },
    addMoreFiles() {
      document.getElementById("attachment").click();
    },
    removeFile(index) {
      this.uploadedFiles.splice(index, 1);
    },
    async submitTicket() {
      event.preventDefault();
      try {
        const payload = {
          title: this.form.issueTitle,
          description: this.form.issueDescription,
          moduleName: this.form.moduleName,
          raisedDate: this.form.raisedDate,
          priority: this.form.priority,
          raisedByEntityId: this.$LocalUser.user.employeeId,
          raisedByEntityName: "Employee",
          attachments: this.uploadedFileIds,
        };
        console.log(payload);
        const response = await axios.post(`${this.$apiEndPoint}/ticket`, payload);
        const ticketId = response.data.id;

        await this.UploadFilesToFolder(ticketId);

        this.$toast("Ticket submitted successfully", "success");
        this.closePage();
        // this.closePage();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async UploadFilesToFolder(ticketId) {
      try {
        const requestBody = {
          ticketId,
          type: "raised",
          awsFileId: this.uploadedFileIds,
        };
        console.log(requestBody);
        await axios.post(`${this.$apiEndPoint}/ticketAttachment`, requestBody);

        this.uploadedFileIds = [];
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style scoped>
.ticket-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 2rem;
  width: 100%;
  background-color: #f8f9fa;

  padding: 1rem 2rem;
}

.close-button {
  background-color: transparent;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: red;
}

.ticket-form {
  width: 96%;
  margin: 0.5rem 2rem;
  height: 79%;
  overflow-y: auto;
  overflow-x: hidden;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 14px;
}

.mandatory {
  color: red;
}

.attachment-input {
  display: flex;
  align-items: center;
}

.uploaded-files {
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.uploaded-files ul {
  list-style: none;
  padding: 0;
}

.uploaded-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.remove-btn {
  background-color: transparent;
  border: none;
  color: red;
  font-size: 14px;
  cursor: pointer;
}

.form-actions {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f8f9fa; /* Optional: Background for better visibility */
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #ddd; /* Optional: Top border for separation */
}
.btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
}

.btn-secondary {
  background-color: #6c757d;
  color: #fff;
}
.attatchmentlabel.form-group {
  margin-bottom: 1.5rem;
}

.attatchmentlabel .attachment-input {
  gap: 10px; /* Adds spacing between elements */
}

.attatchmentlabel .add-files-btn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px dashed #ccc;
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s, border-color 0.3s;
}

.attatchmentlabel .add-files-btn:hover {
  background-color: #f5f5f5;
  border-color: #999;
}

.attatchmentlabel label {
  font-size: 14px;
  font-weight: bold;
}
input:focus,
textarea:focus,
select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}
@media (max-width: 768px) {
  .ticket-header h5 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .close-button {
    align-self: flex-end;
  }

  .ticket-form {
    margin: 0rem 0rem;
    width: 98%;
    padding: 0 1rem;
  }

  .form-group label {
    font-size: 12px;
  }

  .btn {
    font-size: 14px;
    padding: 0.4rem 0.8rem;
  }

  .form-actions {
    padding: 0.5rem;
  }

  .uploaded-file {
    font-size: 14px;
  }

  .add-files-btn {
    width: 24px;
    height: 24px;
    font-size: 16px;
  }
}
</style>
