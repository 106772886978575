<template>
  <br />
  <tr
    v-for="index in rows"
    :key="'skeleton-' + index"
    :class="['skeleton-row', index % 2 === 0 ? 'plain-row' : '']"
  >
    <td
      v-for="i in columns"
      :key="'col-' + i"
      :class="[
        'skeleton-cell',
        i === 1 ? 'skeleton-cell-first' : '',
        index % 2 === 0 ? 'plain-cell' : '',
      ]"
    ></td>
  </tr>
</template>

<script>
export default {
  name: "TableSkeletonLoader",
  props: {
    rows: {
      type: Number,
      default: 50,
    },
    columns: {
      type: Number,
      default: 15,
    },
  },
};
</script>

<style scoped>
/* Add spacing between rows */
.skeleton-row {
  height: 30px; /* Small height for rows */
  background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  width: 100%;
}

.skeleton-cell {
  padding: 8px;
  text-align: left;
  white-space: nowrap; /* Prevent text wrapping */
}

/*
.skeleton-cell-first {
  background-color: #ffffff;
  height: 100%;
}
*/
/* Even rows with a plain background */
.plain-row td {
  background: #ffffff !important;
  animation: none; /* Disable shimmer animation */
}

/* Remove shimmer effect for even cells */
.plain-cell {
  background: #ffffff !important;
  animation: none; /* Disable shimmer animation */
}

/* Shimmer animation */
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
</style>
